import React from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { useTranslation } from "react-i18next";
// import Debug from 'debug';

import { FusePageCarded } from "@fuse";
import LineChart from "./LineChart";
import StatTable from "./StatTable";
import DangerBlock from "./DangerBlock";
import { i18nNamespaces, Dashboard as DBConst } from "constants.js";
import PFEThemeEnhance from "app/parami-layouts/PFEThemeEnhance";
import { HeaderTitle } from "app/parami-layouts";
import { nameList } from "app/utils/entities/plfacc";

// const debug = Debug('pfe:dashboard:main');

// Chart Configs
const ucConfig = {
  stacked: true,
  lines: {
    color_range: ["828282", "F0F0F0"],
  },
  background: "#fff",
  color: "#000",
};

const acConfig = {
  stacked: false,
  custom_ds_names: true,
  background: "#fff",
  palette: {
    type: "default",
    primary: {
      light: "#EEEEEE",
      main: "#a391b9",
      dark: "#7C51AB",
      contrastText: "#222",
    },
    secondary: {
      light: "#B2E7ED",
      main: "#00BDD6",
      dark: "#325474",
      contrastText: "#222",
    },
  },
};

const popularFlowConfig = {
  type: "table",
  background: "#fff",
};

const Dashboard = (props) => {
  const { plfacc_ids } = props,
    { t: ttt } = useTranslation();
  return (
    <FusePageCarded
      header={
        <HeaderTitle
          key="DashboardHeader"
          text={ttt("dashboard:page header.title")}
          icon="dashboard"
        ></HeaderTitle>
      }
      contentDisplay="block"
      innerScroll
      contentScroll
      moreClasses={{
        content: "pb-96",
      }}
      content={
        <React.Fragment>
          <LineChart
            className="my-12"
            name="new_users"
            filters={{
              plfaccs: plfacc_ids,
              limit: 5,
            }}
            scales={Object.values(DBConst.duration)}
            widgetConfig={ucConfig}
          />

          <LineChart
            className="my-12"
            name="activities"
            filters={{
              plfaccs: plfacc_ids,
              limit: 5,
            }}
            scales={Object.values(DBConst.duration)}
            widgetConfig={acConfig}
          />

          <StatTable
            className="my-12"
            name="plfacc_runs"
            filters={{
              plfaccs: plfacc_ids,
              limit: 5,
            }}
            scales={Object.values(DBConst.duration)}
            widgetConfig={popularFlowConfig}
          />

          <DangerBlock className="my-12" />
        </React.Fragment>
      }
    />
  );
};

function mapStateToProps({ dashboard, company }) {
  return {
    plfacc_ids: nameList(company.plfaccs),
  };
}

let DashboardComp = connect(mapStateToProps)(Dashboard);

const DashboardExport = (props) => {
  return (
    <PFEThemeEnhance>
      <DashboardComp />
    </PFEThemeEnhance>
  );
};
export default DashboardExport;
