import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Switch,
  CardHeader,
  CardContent,
  Card,
  FormControlLabel,
  withStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import Debug from "debug";

import { i18nNamespaces } from "constants.js";
import {
  getAutoReply,
  updateAutoReply,
  openDialog,
  closeDialog,
  showMessage,
} from "app/store/actions";
import { DoubleButtonDialog } from "app/parami-layouts/dialogs";

const debug = Debug("pfe:dashboard:dangerBlock");

const danger_yellow = "#FEBC06";
const styles = (theme) => ({
  header: {
    "& .title": {
      fontSize: "3.4rem",
      fontWeight: 600,
      padding: "1rem 0.5rem",
      backgroundColor: danger_yellow,
    },
  },
  stripe: {
    background:
      /* On "top" */
      `repeating-linear-gradient(
        45deg,
        ${danger_yellow},
        ${danger_yellow} 10px,
        #000000 10px,
        #000000 20px
      )`,
  },
});

const DangerBlock = (props) => {
  const { className: cns, auto_reply = false } = props,
    {
      getAutoReply,
      updateAutoReply,
      showMessage,
      openDialog,
      closeDialog,
      classes,
    } = props,
    { t: ttt } = useTranslation();
  // debug('auto_reply', auto_reply);

  const onChangeAutoReply = async (value) => {
    // debug('onChangeAutoReply', value);

    const name = "dashboard:danger zone.auto reply.title",
      onOff = value ? "dashboard:enable" : "dashboard:disable";
    openDialog({
      dialog: (_props) => {
        return (
          <DoubleButtonDialog
            open={_props.state}
            onClose={_props.closeDialog}
            title={ttt("dashboard:danger zone.warning dialog.title", {
              name,
              onOff,
            })}
            messages={[
              ttt("dashboard:danger zone.warning dialog.desc", {
                name,
                onOff,
              }),
            ]}
            rightButtonCaption={ttt("general:ok")}
            onLeft={() => {
              closeDialog();
            }}
            onRight={async () => {
              closeDialog();
              try {
                await updateAutoReply(value);
              } catch (err) {
                debug("onChangeAutoReply - err", err.stack);
                showMessage({
                  message: ttt(
                    "dashboard:danger zone.auto reply.cannot change auto reply"
                  ),
                });
              }
            }}
          />
        );
      },
    });
  };

  useEffect(() => {
    getAutoReply();
  }, []);

  return (
    <Card id="danger-block" className={classNames(cns)}>
      <CardHeader
        className={classNames(classes.header, classes.stripe)}
        title={ttt("dashboard:danger zone.title")}
        classes={{ title: "title" }}
      />
      <CardContent className={classNames("max-w-md")}>
        <div className="by-function pb-32">
          <Typography
            color="textSecondary"
            variant="body2"
            className={classNames("my-8")}
          >
            {ttt("dashboard:danger zone.auto reply.description")}
          </Typography>
          <div className={classNames("flex items-center justify-between")}>
            <Typography variant="subtitle2">
              {ttt("dashboard:danger zone.auto reply.switch label")}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={auto_reply}
                  onChange={(ev, val) => onChangeAutoReply(val)}
                />
              }
              label={ttt("dashboard:danger zone.auto reply.status_on")}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAutoReply,
      updateAutoReply,
      showMessage,
      openDialog,
      closeDialog,
    },
    dispatch
  );
}

function mapStateToProps({ company }) {
  return {
    auto_reply: company.emergency.auto_reply,
  };
}

let myComp = withStyles(styles, { withTheme: true })(DangerBlock);
export default connect(mapStateToProps, mapDispatchToProps)(myComp);
