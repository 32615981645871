import "@fake-db";
import React from "react";
import { createGenerateClassName, jssPreset } from "@material-ui/core";
import { FuseAuthorization, FuseLayout, FuseTheme } from "@fuse";
import JssProvider from "react-jss/lib/JssProvider";
import Provider from "react-redux/es/components/Provider";
import { Router } from "react-router-dom";
import { create } from "jss";
import jssExtend from "jss-extend";
import history from "../history";
import { Auth } from "./auth";
import store from "./store";
import AppContext from "./AppContext";
import routes from "./fuse-configs/routesConfig";
import { api as PBERest, Test } from "../constants";
import axios from "axios";

import { RoutedCYDispatch } from "app/main/cypress/cyDispatch";
import "../mockSocket/server";

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById("jss-insertion-point");
const generateClassName = createGenerateClassName({
  productionPrefix: "pmx",
});

if (Test.expose_store) {
  window.__restore = store;
}

// Set Base URL for axios globally
axios.defaults.timeout = PBERest.request_timeout;
// axios.defaults.baseURL = `http://${PBERest.host}:${PBERest.port}/${PBERest.base_path}/${PBERest.version}`;
// console.log('API Base:', axios.defaults.baseURL);

const cyHoles = true ? <RoutedCYDispatch /> : null;

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <Auth>
            <Router history={history}>
              {cyHoles}
              <FuseAuthorization>
                <FuseTheme>
                  <FuseLayout />
                </FuseTheme>
              </FuseAuthorization>
            </Router>
          </Auth>
        </Provider>
      </JssProvider>
    </AppContext.Provider>
  );
};

export default App;
