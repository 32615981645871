import axios from "axios";
import jwtDecode from "jwt-decode";
import FuseUtils from "@fuse/FuseUtils";
import { AUTH_CONFIG } from "./auth0ServiceConfig";

class AuthExt extends FuseUtils.EventEmitter {
  init() {
    this.token_name = "authExt_acc_token";
    // this.setInterceptors();
    this.handleAuthentication();
  }

  // setInterceptors = () => {
  //     axios.interceptors.response.use(response => {
  //         return response;
  //     }, err => {
  //         return new Promise((resolve, reject) => {
  //             if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest )
  //             {
  //                 // if you ever get an unauthorized response, logout the user
  //                 this.emit('onAutoLogout', 'Invalid access_token');
  //                 this.setSession(null);
  //             }
  //             throw err;
  //         });
  //     });
  // };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      return;
    }

    if (true || this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  get_users() {
    const { url } = AUTH_CONFIG.authExt;
    // return axios.get(`/api/user-manage-app/users`)
    return axios.get(`${url}/api/users`).then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw response.data.error;
      }
    });
  }

  // signInWithEmailAndPassword = (email, password) => {
  //     return new Promise((resolve, reject) => {
  //         axios.get('/api/auth', {
  //             data: {
  //                 email,
  //                 password
  //             }
  //         }).then(response => {
  //             if ( response.data.user )
  //             {
  //                 this.setSession(response.data.access_token);
  //                 resolve(response.data.user);
  //             }
  //             else
  //             {
  //                 reject(response.data.error);
  //             }
  //         });
  //     });
  // };

  signInWithToken = () => {
    const { domain } = AUTH_CONFIG,
      { client_id, client_secret } = AUTH_CONFIG.authExt;
    return axios
      .post(`https://${domain}/oauth/token`, {
        client_id,
        client_secret,
        audience: "urn:auth0-authz-api",
        grant_type: "client_credentials",
      })
      .then((response) => {
        if (response.data.access_token) {
          this.setSession(response.data.access_token);
        } else {
          throw response.data.error;
        }
      });
  };

  // updateUserData = (user) => {
  //     return axios.post('/api/auth/user/update', {
  //         user: user
  //     });
  // };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem(this.token_name, access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      localStorage.removeItem(this.token_name);
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // logout = () => {
  //     this.setSession(null);
  // };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    let token = localStorage.getItem(this.token_name);
    return token;
  };
}

const instance = new AuthExt();

export default instance;
