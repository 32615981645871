import React from "react";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { withStyles, Hidden } from "@material-ui/core";
import { FuseScrollbars, FuseMessage, FuseDialog } from "@fuse";
import { connect } from "react-redux";

import { Purpose } from "constants.js";
import ToolbarLayout4 from "./components/ToolbarLayout4";
import FooterLayout4 from "./components/FooterLayout4";
import LeftSideLayout4 from "./components/LeftSideLayout4";
import RightSideLayout4 from "./components/RightSideLayout4";
import NavbarWrapperLayout4 from "./components/NavbarWrapperLayout4";
import SettingsPanel from "app/fuse-layouts/shared-components/SettingsPanel";
import classNames from "classnames";
import AppContext from "app/AppContext";

const styles = (theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "&.boxed": {
      maxWidth: 1280,
      margin: "0 auto",
      boxShadow: theme.shadows[3],
    },
    "&.scroll-body": {
      "& $wrapper": {
        height: "auto",
        flex: "0 0 auto",
        overflow: "auto",
      },
      "& $contentWrapper": {},
      "& $content": {},
    },
    "&.scroll-content": {
      "& $wrapper": {},
      "& $contentWrapper": {},
      "& $content": {},
    },
  },
  wrapper: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    flex: "1 1 auto",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 3,
    overflow: "hidden",
    flex: "1 1 auto",
  },
  content: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
    "-webkit-overflow-scrolling": "touch",
    zIndex: 2,
  },
});

const Layout4 = ({ classes, settings, children }) => {
  // console.warn('FuseLayout:: rendered');
  const layoutConfig = settings.layout.config;
  return (
    <AppContext.Consumer>
      {({ routes }) => (
        <div
          id="fuse-layout"
          className={classNames(
            classes.root,
            layoutConfig.mode,
            "scroll-" + layoutConfig.scroll
          )}
        >
          <div className="flex flex-1 flex-col overflow-hidden relative">
            {layoutConfig.toolbar.display &&
              layoutConfig.toolbar.position === "above" && (
                <Hidden smUp>
                  <ToolbarLayout4 />
                </Hidden>
              )}

            <div className={classes.wrapper}>
              {layoutConfig.navbar.display &&
                layoutConfig.navbar.position === "left" && (
                  <NavbarWrapperLayout4 />
                )}

              <div className={classes.contentWrapper}>
                {layoutConfig.toolbar.display &&
                  layoutConfig.toolbar.position === "below" &&
                  layoutConfig.toolbar.style === "fixed" && (
                    <Hidden smUp>
                      <ToolbarLayout4 />
                    </Hidden>
                  )}

                <FuseScrollbars className={classes.content}>
                  {layoutConfig.toolbar.display &&
                    layoutConfig.toolbar.position === "below" &&
                    layoutConfig.toolbar.style !== "fixed" && (
                      <Hidden smUp>
                        <ToolbarLayout4 />
                      </Hidden>
                    )}

                  <FuseDialog />

                  {renderRoutes(routes)}
                  {children}

                  {layoutConfig.footer.display &&
                    layoutConfig.footer.position === "below" && (
                      <FooterLayout4 />
                    )}
                </FuseScrollbars>
              </div>

              {layoutConfig.navbar.display &&
                layoutConfig.navbar.position === "right" && (
                  <NavbarWrapperLayout4 />
                )}
            </div>

            {layoutConfig.footer.display &&
              layoutConfig.footer.position === "above" && <FooterLayout4 />}
          </div>

          {layoutConfig.rightSidePanel.display && <RightSideLayout4 />}

          <FuseMessage />
        </div>
      )}
    </AppContext.Consumer>
  );
};

function mapStateToProps({ fuse }) {
  return {
    settings: fuse.settings.current,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps)(Layout4))
);
