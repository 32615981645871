import React from "react";
import { Card, withStyles } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { i18nNamespaces } from "constants.js";

const styles = (theme) => ({
  glowing: {
    outline: "none",
    borderColor: "#9ecaed",
    boxShadow: "0 0 10px 3px #9ecaed",
  },
});

const PlatformCard = (props) => {
  const { classes, className, platform, chosen } = props,
    { onClick } = props,
    { t: ttt } = useTranslation();

  return (
    <FuseAnimate animation="transition.fadeIn" delay={50}>
      <span
        className={classNames(
          "sm:w-auto w-full cursor-pointer inline-flex items-center flex-col"
        )}
        onClick={onClick.bind(null, platform.code)}
      >
        <Card
          className={classNames("inline-flex p-0 m-12", className)}
          elevation={platform.code == "asangaweb" ? 0 : 1}
          // onClick={onClick(platform.code)}
        >
          <img
            alt={platform.name}
            src={platform.icon}
            className="h-60 w-60 mx-0 my-auto"
          />
        </Card>
        <div className="mx-16 my-auto text-16 font-bold">
          {ttt(`general:platform.${platform.code}`)}
        </div>
      </span>
    </FuseAnimate>
  );
};

let myComp = withStyles(styles, { withTheme: true })(PlatformCard);
export default myComp;
