import React, { Component } from "react";
import {
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
} from "@material-ui/core";
import { connect } from "react-redux";
import { map as ldMap } from "lodash";
import { useTranslation } from "react-i18next";

import { setLanguage } from "app/auth/store/actions";
import locales from "app/data/locales";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import i18n from "i18n.js";
const ttt = i18n.t.bind(i18n);

function genMenuItems(ttt, pAction) {
  return ldMap(locales, (tuple, loc) => (
    <MenuItem component={Link} value={loc} key={loc} onClick={pAction}>
      <ListItemIcon>
        <Icon>{tuple.icon}</Icon>
      </ListItemIcon>
      <ListItemText className="pl-0" primary={ttt(`general:language.${loc}`)} />
    </MenuItem>
  ));
}

class LanguageMenu extends Component {
  state = {
    langMenu: null,
  };

  langMenuClick = (event) => {
    this.setState({ langMenu: event.currentTarget });
  };

  langMenuClose = () => {
    this.setState({ langMenu: null });
  };

  chooseLang = (event) => {
    const { setLanguage } = this.props;
    const new_lang = event.currentTarget.getAttribute("value");
    //   console.log('LLL', new_lang);
    setLanguage(new_lang);

    this.setState({ langMenu: null });
  };

  render() {
    const { langMenu } = this.state;

    return (
      <React.Fragment>
        <Button className="h-64" onClick={this.langMenuClick}>
          <ListItemText
            className="pl-0"
            primary={ttt("general:Language")}
            data-i18n="Language"
          />

          <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
            keyboard_arrow_down
          </Icon>
        </Button>

        <Popover
          open={Boolean(langMenu)}
          anchorEl={langMenu}
          onClose={this.langMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "py-8",
          }}
        >
          <React.Fragment>{genMenuItems(ttt, this.chooseLang)}</React.Fragment>
        </Popover>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLanguage,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

let myLanguageMenu = connect(mapStateToProps, mapDispatchToProps)(LanguageMenu);
myLanguageMenu = myLanguageMenu;
export default myLanguageMenu;
