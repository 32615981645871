import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  AppBar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  withStyles,
  Button,
  Switch,
  Typography,
  FormLabel,
  FormGroup,
  MenuItem,
} from "@material-ui/core";
import { DangerButton } from "app/parami-layouts";
import { VpnKey as KeyIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { FuseScrollbars, CheckboxFormsy, SelectFormsy } from "@fuse";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Formsy from "formsy-react";
import ld from "lodash";
import Debug from "debug";
import * as imupdate from "object-path-immutable";
import { SimpleAccordions } from "app/parami-layouts";

import { i18nNamespaces, Rights, Salutation, Gender } from "constants.js";
import { DialogModes } from "./Staff";
import Locales from "app/data/locales.js";
import { showMessage } from "app/store/actions/fuse";
import { Pavatar, TextFieldPFE, PasswordField } from "app/parami-layouts";
import { fullname, profilePic } from "app/utils/entities/staff";

const debug = Debug("pfe:settings:staff:StaffInfoDialog"),
  fmt = ld.get(Locales, `en.formatter.fullname.main`);
const styles = (theme) => ({
  deleteIcon: {
    marginLeft: theme.spacing.unit,
  },
  button: {
    margin: `${theme.spacing.unit}px`,
  },
  actionBar: {
    backgroundColor: theme.palette.background.default,
    margin: 0,
  },
  groupBorder: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: theme.palette.grey[400],
  },
  textField: {
    flexBasis: 80,
  },
});

const StaffInfoDialog = (props) => {
  const {
      staff: inStaff,
      company_code,
      open,
      classes,
      enableDeleteButton = true,
      enableOKButton = true,
      dlgMode = DialogModes.UPDATE,
    } = props,
    {
      onClose,
      onCloseStaffInfoDialog,
      saveStaffInfo,
      deleteStaff,
      toSetPassword,
    } = props,
    { t: ttt } = useTranslation(),
    [canSub, setSubmit] = useState(false),
    [dlgValue, setValues] = useState({
      salutation: Salutation.mr,
      name: "",
      username: "",
      suspended: inStaff ? inStaff.suspended || false : false,
      [Rights.EDIT_FLOW]: ld.get(inStaff, `rights.${Rights.EDIT_FLOW}`, false),
      [Rights.VIEW_DASHBOARD]: ld.get(
        inStaff,
        `rights.${Rights.VIEW_DASHBOARD}`,
        false
      ),
    });
  let staff = inStaff || {};
  debug("StaffInfoDialog", inStaff, enableOKButton);

  const disableButton = () => {
    setSubmit(false);
  };

  const enableButton = () => {
    setSubmit(true);
  };

  const onTextChange = (event) => {
    setValues(imupdate.set(dlgValue, event.target.name, event.target.value));
  };

  const onSwitchChange = (event, checked) => {
    setValues(imupdate.set(dlgValue, event.target.name, checked));
  };

  let onValidSubmit,
    showAppBar,
    showUserNameField,
    showDeleteButton,
    showSetPWButton;

  if (dlgMode === DialogModes.UPDATE) {
    onValidSubmit = (updates) =>
      saveStaffInfo(staff, {
        ...updates,
        suspended: dlgValue.suspended,
      });

    showAppBar = (
      <AppBar position="static" elevation={1}>
        <div className="flex flex-col items-center justify-center py-16">
          <div className="flex">
            {
              <Pavatar
                className="w-64 h-64"
                src={profilePic(staff)}
                alt={fullname(staff)}
                fallback
              />
            }
          </div>
          <Typography variant="h6" color="inherit" className="pt-8">
            {fmt(staff)}
          </Typography>
        </div>
      </AppBar>
    );

    showUserNameField = (
      <div className="flex items-center mt-16 mb-24">
        <FormLabel className="text-14 w-1/3">
          {ttt("settings-staff:staff info dialog.username")}
        </FormLabel>
        <FormLabel className="text-14 w-2/3 text-black">
          {staff.username}
        </FormLabel>
      </div>
    );

    showSetPWButton = (
      <div className="flex items-center">
        <FormLabel className="text-14 w-1/3">
          {ttt("settings-staff:staff info dialog.set password")}
        </FormLabel>
        <Button
          variant="contained"
          className={classNames(classes.button, "w-2/3")}
          onClick={toSetPassword.bind(null, staff)}
        >
          {ttt("settings-staff:staff info dialog.button.set password")}
          <KeyIcon className={classes.deleteIcon} />
        </Button>
      </div>
    );

    showDeleteButton = (
      <div className="flex items-center">
        <FormLabel className="text-14 w-1/3">
          {ttt("settings-staff:staff info dialog.delete user")}
        </FormLabel>
        <DangerButton
          variant="contained"
          disabled={!enableDeleteButton}
          className={classNames(classes.button, "w-2/3")}
          onClick={deleteStaff.bind(null, staff)}
        >
          {ttt("delete")}
          <DeleteIcon className={classes.deleteIcon} />
        </DangerButton>
      </div>
    );
  } else if (dlgMode === DialogModes.CREATE) {
    onValidSubmit = (updates) =>
      saveStaffInfo(staff, {
        ...updates,
        name: dlgValue.name,
        username: dlgValue.username,
        suspended: dlgValue.suspended,
      });

    showAppBar = (
      <DialogTitle id="create-staff-dialog-heading">
        {ttt("settings-staff:create staff dialog.title")}
      </DialogTitle>
    );

    showUserNameField = (
      <div className="mb-16">
        <div className="text-14 mt-8 mb-16">
          {ttt("settings-staff:create staff dialog.username desc", {
            username: dlgValue.name || "user01",
            suffix: company_code,
          })}
        </div>
        <div className="flex mt-8">
          <TextFieldPFE
            required
            className="w-3/5 mr-8"
            id="username-prefix"
            name="name"
            label={ttt("form-fields:user.username")}
            value={dlgValue.name}
            onChange={onTextChange}
            pfeValidType="user.username_prefix"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">@{company_code}</InputAdornment>
              ),
            }}
            aria-describedby="username-suffix"
            variant="outlined"
          />

          <PasswordField
            required
            fullWidth
            className="w-2/5"
            name="password"
            label={ttt("settings-staff:staff info dialog.password")}
            value={staff.first_name}
          />
        </div>
      </div>
    );

    showSetPWButton = null;

    showDeleteButton = null;
  }

  let details = (
    <React.Fragment>
      <div className="flex">
        <SelectFormsy
          label={ttt("settings-staff:staff info dialog.salutation")}
          name="salutation"
          value={staff.salutation || ""}
          className="w-1/5 sm:w-1/5 mb-16 sm:mb-0 mr-16"
          // validations="isExisty"
          // validationError="Choose one"
          variant="outlined"
          onChange={onTextChange}
        >
          {ld.map(Salutation, (val) => (
            <MenuItem name="ui_salutation" key={val} value={val}>
              {ttt(`form-fields:salutations.${val}`)}
            </MenuItem>
          ))}
        </SelectFormsy>

        <TextFieldPFE
          className="mb-16 mr-8 w-2/5"
          type="text"
          name="first_name"
          label={ttt("settings-staff:staff info dialog.first name")}
          pfeValidType="user.first_name"
          value={staff.first_name}
          variant="outlined"
          fullWidth
        />

        <TextFieldPFE
          className="mb-16 w-2/5"
          type="text"
          name="last_name"
          label={ttt("settings-staff:staff info dialog.last name")}
          pfeValidType="user.last_name"
          value={staff.last_name || ""}
          variant="outlined"
          fullWidth
        />
      </div>

      <div className="flex">
        <SelectFormsy
          label={ttt("settings-staff:staff info dialog.gender")}
          name="gender"
          value={staff.gender || ""}
          className="w-1/3 sm:w-1/3 mb-16 sm:mb-0 mr-16"
          // validations="isExisty"
          // validationError="Choose one"
          variant="outlined"
          onChange={onTextChange}
        >
          {ld.map(Gender, (val) => (
            <MenuItem name="ui_gender" key={val} value={val}>
              {ttt(`form-fields:genders.${val}`)}
            </MenuItem>
          ))}
        </SelectFormsy>

        <TextFieldPFE
          className="w-2/3 sm:w-2/3 mb-16"
          type="text"
          name="job_title"
          label={ttt("settings-staff:staff info dialog.job title")}
          pfeValidType="user.job_title"
          variant="outlined"
          value={staff.job_title || ""}
        />
      </div>

      <TextFieldPFE
        className="mb-8"
        type="text"
        name="phone_number"
        label={ttt("settings-staff:staff info dialog.phone number")}
        pfeValidType="general.phone_number"
        value={staff.phone_number}
        variant="outlined"
      />

      {showSetPWButton}

      <div className="flex items-center mb-8">
        <FormLabel className="text-14 w-1/3">
          {ttt("settings-staff:staff info dialog.suspended")}
        </FormLabel>
        <Switch
          name="suspended"
          checked={dlgValue.suspended}
          // value={dlgValue.suspended}
          onChange={onSwitchChange}
          // aria-label={formControl.title}
        />
      </div>

      <FormLabel component="legend" className="text-14 pb-8">
        {ttt("settings-staff:staff info dialog.rights.title")}
      </FormLabel>
      <FormGroup className={classNames(classes.groupBorder, "py-4 px-12 mb-8")}>
        <CheckboxFormsy
          className=""
          name={`rights.${Rights.EDIT_FLOW}`}
          label={ttt(
            `settings-staff:staff info dialog.rights.${Rights.EDIT_FLOW}`
          )}
          // checked={dlgValue[Rights.EDIT_FLOW]}
          value={dlgValue[Rights.EDIT_FLOW]}
          // onChange={handleCBChange(Rights.EDIT_FLOW)}
          // validations="equals:true"
          // validationError="You need to accept"
        />
        <CheckboxFormsy
          className=""
          name={`rights.${Rights.VIEW_DASHBOARD}`}
          label={ttt(
            `settings-staff:staff info dialog.rights.${Rights.VIEW_DASHBOARD}`
          )}
          value={dlgValue[Rights.VIEW_DASHBOARD]}
        />
      </FormGroup>

      {showDeleteButton}
    </React.Fragment>
  );
  if (dlgMode === DialogModes.CREATE)
    details = (
      <SimpleAccordions content={[["More...", details]]}></SimpleAccordions>
    );
  return (
    <Dialog
      id="staff-info-dialog"
      classes={{
        paper: "m-24",
      }}
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
    >
      {showAppBar}
      <DialogContent>
        <FuseScrollbars>
          <Formsy
            id="staff-info-form"
            onValidSubmit={onValidSubmit}
            onValid={enableButton}
            onInvalid={disableButton}
            className="flex flex-col justify-center"
          >
            {showUserNameField}
            {details}
          </Formsy>
        </FuseScrollbars>
      </DialogContent>
      <DialogActions className={classNames(classes.actionBar)}>
        <Button
          aria-label="CLOSE"
          color="primary"
          onClick={onCloseStaffInfoDialog}
        >
          {ttt("general:button.cancel")}
        </Button>
        <Button
          type="submit"
          form="staff-info-form"
          aria-label="SAVE"
          disabled={!(enableOKButton && canSub)}
          color="primary"
        >
          {ttt("general:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage,
    },
    dispatch
  );
}

let myComp = withStyles(styles, { withTheme: true })(StaffInfoDialog);
myComp = connect(null, mapDispatchToProps)(myComp);
export default myComp;
