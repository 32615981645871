import * as Actions from "app/store/actions/globals";

const initialState = {
  formSubmissions: {},
  /**
   * formSubmissions = {
   *   submission1: [
   *     {
   *       changed: false,
   *       valid: false
   *     },
   *     {
   *       changed: false,
   *       valid: false
   *     },
   *   ],
   *
   *   submission2: ...
   *
   *   ...
   * }
   */
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.INIT_FORM_SUBMISSION: {
      /**
       * Usage: Initialize a form for checking validations and changes
       *
       * Parameters:
       * {
       *  id: the name or id for specifying a form,
       *  num_forms: the amount of fields in the form
       * }
       *
       * Example form:
       *  OutputNodeProps:
       *    fields: {
       *      PlainText,
       *      Button,
       *      Quick Reply
       *    }
       *
       * Example Code:
       * initFormSubmission("output", 3);
       */

      let { id, num_forms } = action;
      let formSubmissions = { ...state.formSubmissions };
      formSubmissions[id] = [];

      for (let i = 0; i < num_forms; i++)
        formSubmissions[id].push({
          changed: false,
          valid: false,
        });

      return { ...state, formSubmissions };
    }
    case Actions.SET_FORM_SUBMISSION: {
      /**
       * Usage: set states for a field in a form
       *
       * Parameters:
       * {
       *  id: the name or id for specifying a form,
       *  form_index: indicate the field to be changed,
       *  changes: includes the changes in the field
       *  {
       *    valid: set the validity of the field,
       *    changed: set to true if the field is changed (to prevent changing page)
       *
       *    // Remarks: Can set both or either one.
       *  }
       * }
       *
       * Example form:
       *  OutputNodeProps:
       *    fields: {
       *      Field Name  | form_index
       *      PlainText   |     0
       *      Button      |     1
       *      Quick Reply |     2
       *    }
       *
       * Example Code:
       * // For changing the validity of PlainText to true
       * setFormSubmission("output", 0, { valid: true });
       */

      let { id, index, changes } = action;
      let formSubmissions = { ...state.formSubmissions };
      let forms = [...formSubmissions[id]];

      forms[index] = { ...forms[index], ...changes };

      formSubmissions[id] = forms;

      return { ...state, formSubmissions };
    }

    case Actions.CLEAR_FORM_SUBMISSION: {
      /**
       * Usage: remove all forms from Redux
       *
       * Example Code:
       * clearFormSubmission()
       */

      let formSubmissions = { ...state.formSubmissions };
      formSubmissions = {};

      return { ...state, formSubmissions };
    }

    default: {
      return state;
    }
  }
}
