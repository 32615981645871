import React from "react";
import {
  Hidden,
  withStyles,
  IconButton,
  Icon,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "app/store/actions";
import FuseNavBadge from "./../FuseNavBadge";

import { useTranslation } from "react-i18next";
import { usePopup, PopupHelp } from "app/parami-layouts";

import withEnhancedRouter from "app/parami-layouts/withEnhancedRouter";

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

const defaultProps = {};

const styles = (theme) => ({
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingRight: 12,
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + "!important",
      transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
      "& .list-item-text-primary": {
        color: "inherit",
      },
      "& .list-item-icon": {
        color: "inherit",
      },
    },
    "&.square, &.active.square": {
      width: "100%",
      borderRadius: "0",
    },
    "& .list-item-icon": {},
    "& .list-item-text": {},
    color: "inherit!important",
    textDecoration: "none!important",
  },
  folded: {
    width: "40px",
    borderRadius: "20px",
    padding: "12px",
    marginLeft: "12px",
  },
});

const isActive = (match, location) => {
  if (!match) {
    return false;
  }

  if (match !== location.pathname) return false;

  return true;
};

function FuseNavVerticalItem({
  item,
  classes,
  nestedLevel,
  userRole,
  navbarCloseMobile,
  active,
  folded,
  location,
  goTo,
}) {
  const { t: ttt } = useTranslation();
  //const { history } = this.props;
  let popupComp, setOpen;
  if (item.help) {
    [popupComp, setOpen] = usePopup(PopupHelp[item.help]);
  }
  if (
    item.auth &&
    (!item.auth.includes(userRole) ||
      (userRole !== "guest" &&
        item.auth.length === 1 &&
        item.auth.includes("guest")))
  ) {
    return null;
  }

  let paddingValue = 40 + nestedLevel * 16;
  const listItemPadding =
    nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-24";

  let onClick = () => {
    goTo({ pathname: item.url });
    navbarCloseMobile();
  };

  const tooltipFactory = (title) => (
    <>
      <Tooltip title={title} placement="right">
        <ListItem
          button
          /*
          component={NavLink}
          to={item.url}
          activeClassName="active"
          */
          className={classNames(
            classes.item,
            !folded && listItemPadding,
            "list-item",
            isActive(item.url, location) ? "active" : undefined,
            folded && classes.folded
          )}
          onClick={onClick}
          exact={item.exact}
        >
          {item.icon && (
            <Icon
              className="list-item-icon text-16 flex-no-shrink"
              color="action"
            >
              {item.icon}
            </Icon>
          )}
          <ListItemText
            className="list-item-text"
            primary={
              <span className="flex items-center">
                {ttt(`navigation:${item.title}`)}
                {item.help && (
                  <>
                    <Tooltip
                      title={ttt("general:What is this")}
                      placement="right"
                    >
                      <span
                        className="p-4 ml-4"
                        onClick={(ev) => {
                          setOpen(true);
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                      >
                        <Icon
                          className="list-item-icon text-12 flex-no-shrink inline-flex"
                          color="action"
                        >
                          help
                        </Icon>
                      </span>
                    </Tooltip>
                  </>
                )}
              </span>
            }
            classes={{ primary: "text-14 list-item-text-primary" }}
          />
          {item.badge && <FuseNavBadge badge={item.badge} />}
        </ListItem>
      </Tooltip>
      {popupComp}
    </>
  );

  return (
    <div>
      <Hidden smUp>{tooltipFactory("")}</Hidden>
      <Hidden xsDown>
        {tooltipFactory(folded ? ttt(`navigation:${item.title}`) : "")}
      </Hidden>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  );
}

function mapStateToProps({ auth, fuse }) {
  return {
    userRole: auth.user.role,
    folded: fuse.settings.current.layout.config.navbar.folded,
  };
}

FuseNavVerticalItem.propTypes = propTypes;
FuseNavVerticalItem.defaultProps = defaultProps;

const NavVerticalItem = withStyles(styles, { withTheme: true })(
  withEnhancedRouter(
    connect(mapStateToProps, mapDispatchToProps)(FuseNavVerticalItem)
  )
);

export default NavVerticalItem;
