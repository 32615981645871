import { authRoles } from "app/auth";
import Company from "./company/Company";
import { Staff } from "./staff/Staff";
import { PlatformAccounts } from "./plfaccs/PlatformAccounts";
import User from "./user/User";

export const SettingPagesConfig = {
  settings: {
    layout: {
      style: "layout4",
      config: {
        // navbar        : {
        //     display: false
        // },
        // toolbar       : {
        //     display: true
        // },
        // footer        : {
        //     display: false
        // },
      },
    },
  },
  auth: authRoles.acc_usage,
  routes: [
    {
      path: "/settings",
      component: User,
    },
    {
      path: "/account",
      component: Company,
    },
    {
      path: "/users",
      component: Staff,
    },
    {
      path: "/channels",
      component: PlatformAccounts,
    },
    // {
    //     path     : '/apps/e-commerce',
    //     component: () => <Redirect to="/settings/staff"/>
    // }
  ],
};
