import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Debug from "debug";

import { bindActionCreators } from "redux";

import { useTranslation } from "react-i18next";

import {
  openDialog,
  closeDialog,
  clearFormSubmission,
} from "app/store/actions";

import { DoubleButtonDialog } from "app/parami-layouts/dialogs";

const debug = Debug("pfe:router");

const projectPage = {};

function withEnhancedRouter(WrappedComponent) {
  function routerDialog(ttt, props, callback, name, returnToLastlayer) {
    const { openDialog, closeDialog } = props;

    openDialog({
      dialog: (_props) => {
        return (
          <DoubleButtonDialog
            open={_props.state}
            onClose={_props.closeDialog}
            title={ttt("general:Router.leave page")}
            messages={[ttt("general:Router.changes may not be saved")]}
            rightButtonCaption={ttt("general:Router.leave")}
            onLeft={() => {
              closeDialog();
            }}
            onRight={() => {
              closeDialog();
              callback(name, true, returnToLastlayer);
            }}
          />
        );
      },
    });
  }

  function middleman(props) {
    const { t: ttt } = useTranslation();
    const goBack = (name, force, returnToLastlayer) => {
      const { history, location, formSubmissions, clearFormSubmission } = props;

      let changed = Object.values(formSubmissions).some((form) =>
        form.some((v) => v.changed)
      );

      const same_page =
        history.location.state &&
        location.pathname === history.location.state.from;

      console.log(
        location.pathname,
        "==",
        history.location.state.from,
        projectPage
      );

      if (force === true) {
        if (
          returnToLastlayer ||
          typeof history.location.state === "undefined" ||
          typeof history.location.state.from === "undefined" ||
          same_page
        ) {
          // Url dropped-in, try to reconstruct upper level path and goes 'back'

          // From: Intention Detail: ${APP_ROOT}/../${name}/..
          // To: Intention list: ${APP_ROOT}/../${name}/..
          // i.e. Keep pathname up to '../${name}/'
          if (name) {
            const start = location.pathname.lastIndexOf(name);
            if (start >= 0) {
              const pathname = location.pathname.substring(
                0,
                start + name.length
              );
              debug("With name [History] goes back to: " + pathname);

              clearFormSubmission();
              return history.push({
                pathname,
                search: same_page ? projectPage.current : location.search,
                state: { from: location.pathname, ...name.state },
              });
            }
          } else {
            const pathname = location.pathname
              .split("/")
              .slice(0, -1)
              .join("/");
            debug("[History] goes back to: " + pathname);

            clearFormSubmission();
            return history.push({
              pathname,
              search: location.search,
              state: { from: location.pathname, ...name.state },
            });
          }
        }
        debug("[History] goes back react default", {
          history,
          location,
          name,
          state: history.location.state,
        });
        // Fallback: browser history back

        clearFormSubmission();
        return history.goBack();
      } else {
        if (changed) {
          routerDialog(ttt, props, goBack, name, returnToLastlayer);
        } else {
          goBack(name, true, returnToLastlayer);
        }
      }
    };

    const goTo = (name, force) => {
      const { history, formSubmissions, clearFormSubmission } = props;

      if (history.location.pathname === "/projects") {
        projectPage.current = history.location.search;
      }

      let changed = Object.values(formSubmissions).some((form) =>
        form.some((v) => v.changed)
      );

      debug("[History] goes to: ", name);

      if (force === true) {
        clearFormSubmission();
        return history.push(name);
      } else {
        if (changed) {
          routerDialog(ttt, props, goTo, name);
        } else {
          goTo(name, true);
        }
      }
    };

    const replace = (name, force) => {
      const { history, formSubmissions, clearFormSubmission } = props;

      if (history.location.pathname === "/projects") {
        projectPage.current = history.location.search;
      }

      let changed = Object.values(formSubmissions).some((form) =>
        form.some((v) => v.changed)
      );

      if (force === true) {
        clearFormSubmission();
        return history.replace(name);
      } else {
        if (changed) {
          routerDialog(ttt, props, replace, name);
        } else {
          replace(name, true);
        }
      }
    };

    return (
      <WrappedComponent
        {...props}
        goBack={goBack}
        goTo={goTo}
        replace={replace}
      />
    );
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        clearFormSubmission,
        openDialog,
        closeDialog,
      },
      dispatch
    );
  }

  function mapStateToProps({ globals }) {
    return {
      formSubmissions: globals.formSubmissions.formSubmissions,
    };
  }

  let WithEnhancedRouter = withRouter(middleman);
  WithEnhancedRouter = connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithEnhancedRouter);
  WithEnhancedRouter.displayName = `withEnhancedRouter(${WithEnhancedRouter.displayName ||
    WithEnhancedRouter.name ||
    "Component"})`;
  return WithEnhancedRouter;
}

export default withEnhancedRouter;
