import "./styles/index.css";
import "babel-polyfill";
import "typeface-muli";
import "./react-table-defaults";
import "./react-chartjs-2-defaults";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "app/App";
import _validations from "./validations";

// authExt.init();
// authExt.signInWithToken();

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

window.onload = () => {
  render();
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
  function resize_window() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
  }
  if (window.attachEvent) {
    window.attachEvent("onresize", resize_window);
  } else if (window.addEventListener) {
    window.addEventListener("resize", resize_window, true);
  } else {
    //The browser does not support Javascript event binding
  }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();
