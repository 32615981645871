import React, { useState, useEffect, Component, Fragment } from "react";
import PropTypes from "prop-types";
import { SelectFormsy } from "@fuse";
import {
  Button,
  FormLabel,
  withStyles,
  MenuItem,
  Typography,
  Select,
  TextField,
  FormControl,
  InputLabel,
  FilledInput,
  OutlinedInput,
  FormControlLabel,
  Input,
  ListItemText,
  Checkbox,
  Chip,
  Switch,
  FormHelperText,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon, IconButton } from "@material-ui/core";

import { validations as validationRules } from "validations";

import { FuseChipSelect } from "@fuse";
import Formsy from "formsy-react";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import _ from "@lodash";
import classNames from "classnames";
import { connect } from "react-redux";
import Debug from "debug";
import { withFormsy } from "formsy-react";

import { matchSorter } from "match-sorter";
import { genFormHandler, useForceUpdate, usePrevious } from "app/utils/common";
import { showMessage, setGlobalVariables } from "app/store/actions";
import { TextFieldPFE } from "app/parami-layouts";

import IconX from "app/parami-layouts/IconX";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

import i18n from "i18n.js";
import { isElementAccessExpression } from "typescript";
const gttt = i18n.t.bind(i18n);

const debug = Debug("pfe:lazyForm");
const debugValidation = Debug("pfe:formValidation");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const styles = {
  row: {
    display: "flex",
    marginTop: "0",
    marginBottom: "0",
  },
  beforeNone: { "&:before": { display: "none" } },
  afterNone: { "&:after": { display: "none" } },
  pseudoBgWhite: {
    "&:before": {
      content: "''",
      position: "absolute",
      width: "calc(100% + 10px)",
      marginLeft: "-0.5em",
      background:
        "linear-gradient(to right, #fff0 0%, #fff 5px, #fff calc(100% - 5px), #fff0 100%)",
      // height: "2rem",
      height: "0.4rem",
      marginBottom: "0.9em",
      display: "block !important",
      border: "none !important",
      zIndex: -1,
    },
    zIndex: 1,
  },
  select: {
    paddingBottom: "0.2em",
    paddingTop: "0.3em",
    paddingLeft: "0.3em",
  },
  label: {
    marginRight: "1.2em",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  selectTab: {
    animation: "fadein 0.2s",
  },
  previewWindow: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    bottom: 0,
    transform: "translateY(100%)",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    borderRadius: 4,
    zIndex: "5",
    background: "white",
  },
  previewWindowWrapper: {
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "#ddd4",
      zIndex: 2,
      pointerEvents: "none",
    },
    "&:hover:before": {
      background: "#ddd6",
    },
    position: "relative",
    width: "100%",
    cursor: "pointer",
    userSelect: "none",
    animation: "fadein 0.2s",
  },
  previewWindowTab: {
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    zIndex: 5,
    fontSize: "0.8em",
    marginLeft: 8,
    padding: "0 4px",
    background: "#f5f5f5",
    marginTop: 2,
    borderRadius: 4,
    border: "1px solid #ccc",
  },
  showOnHoverParent: {
    "&:hover $showOnHover": {
      opacity: 1,
    },
  },
  showOnHover: {
    opacity: 0,
    transition: "all 0.2s ease !important",
  },
};

function memobind(thisArg, funcName) {
  if (typeof thisArg !== "object" || !thisArg) {
    throw new TypeError("Invalid thisArg parameter.");
  }

  var func = thisArg[funcName];
  if (typeof func !== "function") {
    throw new TypeError("'" + funcName + "' is not a function.");
  }

  if (!thisArg._memobind_cache) thisArg._memobind_cache = {};
  var cache = thisArg._memobind_cache[funcName];
  if (!cache) {
    cache = thisArg._memobind_cache[funcName] = {};
  }

  var args = Array.prototype.slice.call(arguments, 2);
  var memoKey = JSON.stringify(args);
  if (!cache[memoKey]) {
    args.unshift(thisArg);
    cache[memoKey] = Function.prototype.bind.apply(func, args);
  }
  return cache[memoKey];
}

const handleValidation = (
  value,
  validations,
  validationErrors,
  setErrorMessage,
  context
) => {
  const validation_result = validate(
    validations,
    value,
    validationErrors,
    context
  );
  if (setErrorMessage)
    setErrorMessage(
      validation_result &&
        !validation_result.valid &&
        Object.values(validation_result.errors)[0]
    );
  return validation_result;
};

const digestOnChangeEvent = (event) => {
  const { type, checked, value } = event.target;
  return type === "checkbox" ? checked : value;
};

export const validate = (validations, value, validationErrors, context) => {
  // All validations will use this function
  if (!validations) return { valid: true, errors: {} };
  const keys = Object.keys(validations);
  var i,
    valid = true;
  const errors = {};

  /**
   * keys = {
   *   isNotBlankString: true,
   *   maxLength: 1000,
   *   minLength: 1,
   * }
   */

  for (i = 0; i < keys.length; i++) {
    const validation_name = keys[i];
    const args = validations[validation_name];
    // For each validation rule, do the following

    if (validation_name == "function") {
      if (typeof args !== "function") throw "validation args is not a function";
      const rule_result = args(value, context);
      // rule_result = { valid, errors }
      if (rule_result && !rule_result.valid) {
        Object.keys(rule_result.errors).forEach((vName) => {
          let message = rule_result.errors[vName];
          errors[vName] =
            message && gttt([`form-fields:validations.${message}`, message]);
          if (typeof errors[vName] != "string") {
            console.error({
              errors,
            });
            throw "error is not a string";
          }
        });
        valid = false;
      }
      if (!rule_result) valid = false;
    } else {
      const rule = validationRules[validation_name];
      if (!rule) throw "Unknown rule " + validation_name;
      const rule_result = rule(_, value, args);
      if (!rule_result) {
        let message;
        if (validationErrors !== undefined) {
          const errorGetter = validationErrors[validation_name];
          if (typeof errorGetter === "function")
            message = validationErrors(value, args);
          else message = errorGetter;
        }
        errors[validation_name] =
          message &&
          gttt([`form-fields:validations.${message}`, message], { args });
        if (typeof errors[validation_name] != "string") {
          console.error({
            errors,
          });
          throw "error is not a string";
        }
        valid = false;
      }
    }
  }
  return {
    valid,
    errors,
  };
};

/********************************************
 ********************************************
 **********                        **********
 **********         Switch         **********
 **********                        **********
 ********************************************
 ********************************************/

let switchFactory = (props) => {
  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const {
    label,
    onChange,
    name,
    FormControlProps,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, [validations]);

  const pushForm = (value) => {
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      setErrorMessage
    );
    if (hdlForm) hdlForm(value, name, validation_result);
    if (onChange) onChange(value, name, validation_result);
  };

  const switchComp = (
    <Fragment>
      <Switch
        {...props}
        key={lazyFormIndex}
        checked={name && _.get(form, name)}
        onChange={(event) => {
          const value = digestOnChangeEvent(event);
          pushForm(value);
        }}
        name={name}
        inputProps={{ "aria-label": name, type: "checkbox" }}
      />
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText>
        )
      ) : null}
    </Fragment>
  );
  if (!label) return switchComp;
  return (
    <FormControlLabel
      key={lazyFormIndex}
      {...FormControlProps}
      control={switchComp}
      label={label}
    />
  );
};

/********************************************
 ********************************************
 **********                        **********
 **********          Tags          **********
 **********                        **********
 ********************************************
 ********************************************/

let tagsFactory = (props) => {
  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const {
    onChange,
    items,
    name,
    FormControlProps,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, [validations]);

  const pushForm = (value) => {
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      setErrorMessage
    );
    if (hdlForm) hdlForm(value, name, validation_result);
    if (onChange) onChange(value, name, validation_result);
  };

  return (
    <Fragment>
      <Select
        label="label"
        name="name"
        className="w-full"
        multiple
        value={name && _.get(form, name)}
        {..._.omit(props, ["items", "mainProps", "lazyFormIndex"])}
        onChange={(event) => {
          const value = digestOnChangeEvent(event);
          pushForm(value);
        }}
        input={<Input />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={{
          disableEnforceFocus: true,
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
        }}
      >
        {items.map((item, ind) => (
          <MenuItem key={ind} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText>
        )
      ) : null}
    </Fragment>
  );
};

/********************************************
 ********************************************
 **********                        **********
 **********     MultipleSelect     **********
 **********                        **********
 ********************************************
 ********************************************/

class MultipleSelect extends Component {
  constructor(props) {
    super(props);
    debug("MultipleSelect construct", props);
    this.state = { value: props.value || [] };
  }

  handleChanges = (value, data) => {
    const { maxItems } = this.props;
    if (maxItems !== undefined) {
      if (value.length > maxItems) {
        value = value.slice(0, -1);
        if (this.props.onItemsOverflow) this.props.onItemsOverflow(value, data);
      }
    }
    this.props.setState({ value });
    if (this.props.onChange) this.props.onChange(value, this.props.name);
  };

  render() {
    const value = this.state.value || [];

    return (
      <FuseChipSelect
        className="mt-8 mb-16"
        onChange={this.handleChanges}
        value={value}
        options={this.props.options}
        textFieldProps={{
          label: this.props.label,
          InputLabelProps: {
            shrink: true,
          },
          variant: "outlined",
        }}
        variant="fixed"
        isMulti
        {...this.props}
      />
    );
  }
}

let multipleSelectFactory = (props) => {
  /**
   * Example Usage:
   * 

{
  type: "multiple-select",
  label: ttt('form-fields:user.language')
  name: "language",
  value: user.preferences.ui_language || [],
  required: true,
  items: [
      {
          name:"lang",
          value: 0,
          text: "First"
      }
  ]
}

   * 
   */

  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const {
    items,
    onChange,
    name,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, [validations]);

  const pushForm = (value, callback) => {
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      setErrorMessage
    );
    if (hdlForm) hdlForm(value, name, validation_result, callback);
    if (onChange) onChange(value, name, validation_result);
  };

  return (
    <Fragment>
      <MultipleSelect
        key={lazyFormIndex}
        label="label"
        name="name"
        className="w-full"
        variant="outlined"
        value={(name && _.get(form, name)) || []}
        {..._.omit(props, ["items", "mainProps", "lazyFormIndex"])}
        onChange={(value) => {
          pushForm(value, forceUpdate);
        }}
        options={items.map((item) =>
          typeof item == "string"
            ? {
                value: item,
                label: item,
              }
            : {
                value: item.value,
                value: item.label || item.label,
              }
        )}
      />
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText>
        )
      ) : null}
    </Fragment>
  );
};

/********************************************
 ********************************************
 **********                        **********
 **********         Select         **********
 **********                        **********
 ********************************************
 ********************************************/

class _SelectComp extends Component {
  render() {
    const importedProps = _.pick(this.props, [
      "autoWidth",
      "children",
      "classes",
      "disabled",
      "displayEmpty",
      "input",
      "inputProps",
      "MenuProps",
      "multiple",
      "native",
      "onChange",
      "onClose",
      "onOpen",
      "open",
      "renderValue",
      "SelectDisplayProps",
      "value",
      "variant",
      "name",
    ]);

    const {
      required,
      error,
      className,
      style,
      SelectInputProps,
      label,
      name,
      variant,
      errorMessage,
    } = this.props;

    return (
      <FormControl
        required={required}
        error={error}
        className={className}
        style={style}
        variant={variant}
      >
        {label && (
          <InputLabel
            htmlFor={name}
            shrink={true}
            FormLabelClasses={{ root: "" }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          {...importedProps}
          input={
            (variant == "outlined" && (
              <OutlinedInput
                labelWidth={("" + label).length * 8}
                id={name}
                {...SelectInputProps}
              />
            )) ||
            (variant == "filled" && (
              <FilledInput id={name} {...SelectInputProps} />
            )) || <Input id={name} />
          }
        />
        {Boolean(errorMessage) && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

let SelectComp = withTranslation()(_SelectComp);

let selectFactory = (props) => {
  /**
     * Example Usage:
     * 

{
    type: "select",
    label: ttt('form-fields:user.language')
    name:"language",
    value: user.preferences.ui_language || '',
    required: false,
    onChange={hdlForm}
    options: [
        {
            name:"lang",
            value: 0,
            text: "First"
        }
    ]
}

     * 
     */
  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const {
    options,
    onChange,
    name,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, [validations]);

  const pushForm = (value) => {
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      setErrorMessage
    );
    if (hdlForm) hdlForm(value, name, validation_result);
    if (onChange) onChange(value, name, validation_result);
    forceUpdate();
  };

  return (
    <Fragment>
      <SelectComp
        key={lazyFormIndex}
        label={name}
        name="name"
        className="w-full"
        variant="outlined"
        value={(name && _.get(form, name)) || ""}
        {..._.omit(props, ["mainProps", "lazyFormIndex", "options"])}
        MenuProps={{
          disableEnforceFocus: true,
        }}
        onChange={(event) => {
          const value = digestOnChangeEvent(event);
          pushForm(value);
        }}
      >
        {options
          .map((item) =>
            typeof item == "string"
              ? {
                  value: item,
                  label: item,
                }
              : {
                  value: item.value,
                  label: item.label,
                }
          )
          .map((item, ind) => (
            <MenuItem {...item} key={`${ind}`}>
              {item.label}
            </MenuItem>
          ))}
      </SelectComp>
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText>
        )
      ) : null}
    </Fragment>
  );
};

/********************************************
 ********************************************
 **********                        **********
 **********          Text          **********
 **********                        **********
 ********************************************
 ********************************************/

let labelFactory = (props) => {
  /**
     * 
     * Example Usage:
     * 

{
    type: "label",
    text: "First"
}

     * 
     */
  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const { className } = props;

  return (
    <FormLabel
      key={lazyFormIndex}
      {..._.omit(props, ["mainProps", "lazyFormIndex"])}
      className={
        className && typeof className != "string"
          ? className
          : classNames(classes.label, className || "")
      }
    >
      {props.label}
    </FormLabel>
  );
};

/********************************************
 ********************************************
 **********                        **********
 **********      Text Field        **********
 **********                        **********
 ********************************************
 ********************************************/

let textFieldFactory = (props) => {
  /**
     * Example Usage:
     * 

{
    type: "text",
    name: "first_name",
    label: ttt('form-fields:user.first_name'),
    text: "First Name",
    value: user.first_name,
    onChange: f
}

     * 
     */

  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const {
    className,
    onChange,
    name,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";
  const forceUpdate = useForceUpdate();
  const passOnProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "classes",
    "color",
    "defaultValue",
    "disabled",
    "error",
    "FormHelperTextProps",
    "fullWidth",
    "helperText",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "margin",
    "multiline",
    "name",
    "onChange",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "size",
    "type",
    "value",
    "variant",
  ]);

  /*
  debug("textMulti creation", {
    props,
    form,
    name,
    value: (name && _.get(form, name)) || "",
  });
  */

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, [validations]);

  const pushForm = (value) => {
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      setErrorMessage
    );
    if (hdlForm) hdlForm(value, name, validation_result);
    if (onChange) onChange(value, name, validation_result);
  };

  return (
    <Fragment>
      <TextField
        key={lazyFormIndex}
        variant="outlined"
        InputProps={{
          className: "w-full",
          ...props.InputProps,
        }}
        {...passOnProps}
        value={(name && _.get(form, name)) || ""}
        className={
          className && typeof className != "string"
            ? className
            : classNames(className || "")
        }
        onChange={(event) => {
          const value = digestOnChangeEvent(event);

          pushForm(value);
          forceUpdate();
        }}
      />
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText>
        )
      ) : null}
    </Fragment>
  );
};

/********************************************
 ********************************************
 **********                        **********
 **********    Textarea Field      **********
 **********                        **********
 ********************************************
 ********************************************/
const TEXTAREAX_SEP = _.escapeRegExp(
  "\u0001\u0002\u0003\u0004SEP\u0004\u0003\u0002\u0001"
);
const TEXTAREAX_SEP2 = TEXTAREAX_SEP.slice(0, -1);
const TEXTAREAX_START_CLS = "TextAreaX--cursor-start";
const TEXTAREAX_END_CLS = "TextAreaX--cursor-end";
const TEXTAREAX_CURSOR_CLS = "TextAreaX--anchor-cursor";
const TEXTAREAX_START_CURSOR_CLS = "TextAreaX--anchor-cursor-start";
const TEXTAREAX_END_CURSOR_CLS = "TextAreaX--anchor-cursor-end";
const TEXTAREAX_IS_BLOCK = "TextAreaX--is-block";
const TEXTAREAX_IS_BLOCK_SELECTED = "TextAreaX--is-block-selected";
const TEXTAREAV_COPY_MAGIC = '<span id="asanga-data"></span>';

styles.textareaDisabled = {
  opacity: 0.5,
};

styles.textareaX = {
  border: "1px solid transparent",
  position: "relative",
  width: "100%",
  lineHeight: "1.5",
  verticalAlign: "top",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  counterReset: "section",
  boxShadow:
    "inset 0 0.0625em 0.125em rgba(10,10,10,.05), 0 0 0 0.125em rgb(114,36,161,0%)",
  transition: "all 0.2s ease",
  // backgroundColor: "#fff",
  borderColor: "#dbdbdb",
  borderRadius: "4px",
  cursor: "auto",
  display: "block",
  padding: "18.5px 14px",
  mozAppearance: "none",
  webkitAppearance: "none",
  alignItems: "center",
  outline: "none",
  "&:hover": {
    borderColor: "#b5b5b5",
  },
  "&:focus": {
    borderColor: "#a391b9",
    boxShadow:
      "inset 0 0.0625em 0.125em rgba(10,10,10,0), 0 0 0 0.125em rgb(114,36,161,25%)",
  },
  [`& .${TEXTAREAX_START_CLS}, & .${TEXTAREAX_END_CLS}`]: {
    display: "none",
  },

  "&.textareaXEmpty:before": {
    content: "attr(placeholder)",
    color: "#d7d7d7",
    pointerEvents: "none",
    position: "absolute",
  },
  /*
  [`& .${TEXTAREAX_IS_BLOCK} > *::selection, & .${TEXTAREAX_IS_BLOCK}::selection`]: {
    fontWeight: "bold",
    // backgroundColor: "transparent",
  },
  */
};
styles.withHashTag = {
  height: "100%",
  "& .hash-tag": {
    color: "red",
  },
};

class TextAreaXFormsy extends Component {
  constructor(props) {
    super(props);
    if (props.debug) debug("TextAreaXFormsy construct", props);

    if (props.debug)
      debug("TextAreaXFormsy clsRef", {
        clsRef: props.clsRef,
      });
    if (props.clsRef) {
      if (typeof props.clsRef === "function") props.clsRef(this);
      else props.clsRef.current = this;
    }

    this.state = {};
    this.history_i = 0;
    this.histories = [];
    this.paragraph = "";
    this.triggerSelectionChange = this.triggerSelectionChange.bind(this);

    this.insertAtCursor = this.insertAtCursor.bind(this);
    this.insertBlockAtCursor = this.insertBlockAtCursor.bind(this);
    this.insertAroundCursor = this.insertAroundCursor.bind(this);
    this.insertAnchorAroundCursor = this.insertAnchorAroundCursor.bind(this);
    this.replaceText = this.replaceText.bind(this);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleInput = this.handleInput.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleCut = this.handleCut.bind(this);

    this.handleCompositionStart = this.handleCompositionStart.bind(this);
    this.handleCompositionEnd = this.handleCompositionEnd.bind(this);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.handleSelectionChange = this.handleSelectionChange.bind(this);

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.update = this.update.bind(this);
    this.step = this.step.bind(this);

    this.is_descendant_of_block = this.is_descendant_of_block.bind(this);

    this.origValidations = this.props.validations;
    this.origValidationErrors = this.props.validationErrors;
  }

  insertBlockAtCursor(type, value, add_space) {
    const block_factories = this.props.blockFactories;
    if (block_factories) {
      const block_factory = block_factories[type];
      if (block_factory)
        this.insertAtCursor(block_factory(value) + (add_space ? " " : ""));
      else
        throw `insertBlockAtCursor took a type "${type}", but he doesn't know this type;`;
    }
  }

  insertAtCursor(text, insertOnlyInFocus) {
    if (this.props.disabled) {
      return false;
    }
    let selection = window.getSelection();
    if (!selection.anchorNode) return;
    let range = selection.getRangeAt(0);

    if (
      this.textarea !== undefined &&
      (!this.is_descendant_of(range.startContainer, this.textarea) ||
        !this.is_descendant_of(range.endContainer, this.textarea))
    ) {
      if (insertOnlyInFocus) return false;
      debug("TextAreaXFormsy insertAtCursor", {
        lastRange: this.lastRange,
      });
      if (this.lastRange) {
        range = this.lastRange;
      } else {
        range = new Range();
        range.selectNodeContents(this.textarea);
        range.collapse(false);
      }
    }

    if (this.props.debug)
      debug("TextAreaXFormsy insertAtCursor", {
        selection,
        range,
      });

    range.deleteContents();
    let node = document.createTextNode(text);
    range.insertNode(node);

    var sel = window.getSelection();
    var r2 = document.createRange();
    r2.selectNodeContents(node);
    r2.collapse(false);
    sel.removeAllRanges();
    sel.addRange(r2);

    this.setState({}, this.step);
  }

  nextNonEmptySibling(x) {
    while ((x = x.nextSibling)) if (x.nodeType != 3 || x.textContent) break;
    return x;
  }

  is_descendant_of(a, b, inclusive) {
    // a is the element we are checking
    if (!a) return false;
    if (inclusive && a == b) return true;
    while ((a = a.parentNode)) {
      if (a === b) return true;
    }
    return false;
  }

  is_block(x) {
    // a is the element we are checking
    return x && x.classList && x.classList.contains(TEXTAREAX_IS_BLOCK);
  }

  is_descendant_of_block(a, inclusive) {
    // a is the element we are checking
    if (!a) return false;
    if (inclusive && this.is_block(a)) return a;
    while ((a = a.parentNode)) {
      if (this.is_block(a)) return a;
    }
    return false;
  }

  is_descendant_of_class(a, cls) {
    // a is the element we are checking
    if (!a) return false;
    while ((a = a.parentNode)) {
      if (a.classList && a.classList.contains(cls)) return a;
    }
    return false;
  }

  replaceText(text) {
    if (this.props.disabled) {
      return false;
    }
    this.textarea.innerText = text;
  }

  insertAroundCursor(text) {
    if (this.props.disabled) {
      return false;
    }
    let selection = window.getSelection();
    if (!selection.anchorNode || selection.anchorNode == document) return;
    let range = selection.getRangeAt(0);

    if (
      this.textarea !== undefined &&
      (!this.is_descendant_of(range.startContainer, this.textarea) ||
        !this.is_descendant_of(range.endContainer, this.textarea))
    )
      return;

    let node = document.createTextNode(text);
    range.insertNode(node);
    node = document.createTextNode(text);
    range.collapse(false);
    range.insertNode(node);

    for (let position = 0; position != text.length; position++) {
      selection.modify("move", "right", "character");
    }
  }

  insertAnchorAroundCursor() {
    let selection = window.getSelection();
    if (!selection.anchorNode || selection.anchorNode == document) return;
    let range = selection.getRangeAt(0).cloneRange();
    if (this.props.debug)
      debug("TextAreaXFormsy22 insertAnchorAroundCursor", {
        selection,
        range,
      });
    if (
      this.textarea === undefined ||
      !this.is_descendant_of(range.startContainer, this.textarea, true) ||
      !this.is_descendant_of(range.endContainer, this.textarea, true)
    )
      return;
    if (this.props.debug)
      debug("TextAreaXFormsy22 insertAnchorAroundCursor", {
        selection,
        range,
      });

    let isCaret = selection.type == "Caret";
    let isReverseSelection = false;
    var position = selection.anchorNode.compareDocumentPosition(
      selection.focusNode
    );

    if (
      (!position && selection.anchorOffset > selection.focusOffset) ||
      position === Node.DOCUMENT_POSITION_PRECEDING
    )
      isReverseSelection = true;

    this.textarea
      .querySelectorAll("." + TEXTAREAX_CURSOR_CLS)
      .forEach((e) => e.remove());

    this.cursor_is_caret = isCaret;
    var r2 = new Range(selection);
    let node = document.createElement("span");
    let anchorNode = node;
    node.classList.add(TEXTAREAX_CURSOR_CLS);
    node.classList.add(TEXTAREAX_START_CURSOR_CLS);
    this.cursor_keep_start = node;
    range.insertNode(node);
    node = document.createElement("span");
    let focusNode = node;
    node.classList.add(TEXTAREAX_CURSOR_CLS);
    node.classList.add(TEXTAREAX_END_CURSOR_CLS);
    this.cursor_keep_end = node;
    range.collapse(false);
    range.insertNode(node);

    r2.setStart(anchorNode, 0);
    r2.setEnd(isCaret ? anchorNode : focusNode, 0);
    if (isReverseSelection) {
      let tmpNode = anchorNode;
      anchorNode = focusNode;
      focusNode = tmpNode;

      let range = r2.cloneRange();
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      selection.extend(r2.startContainer, r2.startOffset);
    } else {
      selection.removeAllRanges();
      selection.addRange(r2);
    }

    this.handleSelectionLast = [
      this.lastStep,
      selection.anchorNode,
      selection.anchorOffset,
      selection.focusNode,
      selection.focusOffset,
      selection.type,
    ];

    this.next_update = +new Date();

    this.triggerSelectionChange();
  }

  clearHistories() {
    this.histories = [];
    this.history_i = 0;
    this.step();
  }

  triggerSelectionChange(empty) {
    if (empty) {
      this.textarea
        .querySelectorAll("." + TEXTAREAX_CURSOR_CLS)
        .forEach((e) => e.remove());
      this.cursor_keep_start = null;
      this.cursor_keep_end = null;
    }
    if (this.props.onSelectionChange) {
      let previous_node = this.cursor_keep_start;
      while (previous_node) {
        if (!previous_node.previousSibling) {
          previous_node = previous_node.parentElement;
          if (previous_node == this.textarea) {
            previous_node = null;
            break;
          }
          continue;
        }
        previous_node = previous_node.previousSibling;
        if (previous_node) break;
      }
      let args = {
        cursor_keep_start: this.cursor_keep_start,
        cursor_keep_end: this.cursor_keep_end,
        cursor_is_caret: this.cursor_is_caret,
        previous_node,
        is_descendant_of_block: this.is_descendant_of_block,
      };
      args.get_text_before_cursor_start = this.getTextBeforeCursorStart.bind(
        null,
        args
      );
      this.props.onSelectionChange(args);
    }
  }

  getTextBeforeCursorStart({
    cursor_keep_start,
    cursor_keep_end,
    cursor_is_caret,
    previous_node,
    is_descendant_of_block,
  }) {
    let text_before_cursor_start = "";
    let is_block = false;
    let inside_block = false;
    if (previous_node) {
      inside_block =
        is_descendant_of_block(cursor_keep_start) &&
        is_descendant_of_block(cursor_keep_end);
      if (!cursor_is_caret && inside_block) {
        text_before_cursor_start = inside_block.textContent.trim();
        is_block = true;
      } else {
        // Collect Text nodes
        text_before_cursor_start = previous_node.textContent;
        while (
          previous_node &&
          previous_node.previousSibling &&
          (previous_node = previous_node.previousSibling) &&
          previous_node.nodeType == 3
        )
          text_before_cursor_start =
            previous_node.textContent + text_before_cursor_start;
      }
    }
    return { text_before_cursor_start, is_block, inside_block };
  }

  escapeHtml(html) {
    var text = document.createTextNode(html);
    var p = document.createElement("p");
    p.appendChild(text);
    return p.innerHTML;
  }

  saveSelection() {
    this.insertAroundCursor(TEXTAREAX_SEP);
  }

  restoreSelection(update) {
    if (this.props.debug)
      debug("TextAreaXFormsy restoreSelection", {
        text: this.textarea.textContent,
      });
    var cursor_start;
    var cursor_keep;
    var cursor_start_div = this.textarea.querySelector(
      "." + TEXTAREAX_START_CLS
    );
    this.textarea
      .querySelectorAll("." + TEXTAREAX_CURSOR_CLS)
      .forEach((e) => e.remove());

    if (cursor_start_div) {
      var cursor_start_div_prev = cursor_start_div.previousSibling;
      if (cursor_start_div_prev)
        cursor_start = cursor_start_div_prev.textContent.length;
      else {
        cursor_start_div_prev = cursor_start_div.parentElement;
        cursor_start = 0;
      }
      cursor_keep = document.createElement("span");
      cursor_keep.classList.add(TEXTAREAX_CURSOR_CLS);
      cursor_keep.classList.add(TEXTAREAX_START_CURSOR_CLS);
      this.cursor_keep_start = cursor_keep;
      cursor_start_div.parentNode.insertBefore(cursor_keep, cursor_start_div);
      cursor_start_div.remove();

      var cursor_end;
      var cursor_end_div = this.textarea.querySelector("." + TEXTAREAX_END_CLS);
      var cursor_end_div_prev = cursor_end_div.previousSibling;
      if (cursor_end_div_prev)
        cursor_end = cursor_end_div_prev.textContent.length;
      else {
        cursor_end_div_prev = cursor_end_div.parentElement;
        cursor_end = 0;
      }
      cursor_keep = document.createElement("span");
      cursor_keep.classList.add(TEXTAREAX_CURSOR_CLS);
      cursor_keep.classList.add(TEXTAREAX_END_CURSOR_CLS);
      this.cursor_keep_end = cursor_keep;
      cursor_end_div.parentNode.insertBefore(cursor_keep, cursor_end_div);
      cursor_end_div.remove();

      var sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        sel = sel.getRangeAt(0);
      }
      var r2 = new Range(sel);

      while (true) {
        try {
          r2.setStart(cursor_start_div_prev, cursor_start);
          break;
        } catch (err) {
          cursor_start -= 1;
          if (cursor_start < 0) throw "unknown range";
        }
      }

      while (true) {
        try {
          r2.setEnd(cursor_end_div_prev, cursor_end);
          break;
        } catch (err) {
          cursor_end -= 1;
          if (cursor_end < 0) throw "unknown range";
        }
      }

      if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(r2);
      } else if (document.selection && r2.select) {
        r2.select();
      }
      this.insertAnchorAroundCursor();
      if (update) this.next_update = +new Date();
    }
  }

  handleSelectionChange(ev) {
    if (this.dragging || this.stepping) return;

    let selection = window.getSelection(),
      {
        anchorNode,
        anchorOffset,
        focusNode,
        focusOffset,
        type, // Caret, Range
      } = selection;
    /**
      anchorNode: text
      anchorOffset: 6
      baseNode: text
      baseOffset: 6
      extentNode: text
      extentOffset: 6
      focusNode: text
      focusOffset: 6
      isCollapsed: true
      rangeCount: 1
      type: "Range"
    */
    if (
      this.is_descendant_of(anchorNode, this.textarea, true) &&
      this.is_descendant_of(focusNode, this.textarea, true)
    ) {
      let last = [
        this.lastStep,
        anchorNode,
        anchorOffset,
        focusNode,
        focusOffset,
        type,
      ];
      let isReverseSelection = false;
      var position = selection.anchorNode.compareDocumentPosition(
        selection.focusNode
      );

      if (
        (!position && selection.anchorOffset > selection.focusOffset) ||
        position === Node.DOCUMENT_POSITION_PRECEDING
      )
        isReverseSelection = true;

      if (_.isEqual(this.handleSelectionLast, last)) return;
      const range = selection.getRangeAt(0);
      this.lastRange = range.cloneRange();
      if (this.props.debug)
        debug("TextAreaXFormsy handleSelectionChange", {
          anchorNode,
          anchorOffset,
          focusNode,
          focusOffset,
          type,
          range,
        });
      const startInBlock = this.is_descendant_of_block(anchorNode),
        endInBlock = this.is_descendant_of_block(focusNode);

      // this.textarea.querySelectorAll("."+TEXTAREAX_IS_BLOCK).forEach(e=>e.classList.remove(TEXTAREAX_IS_BLOCK_SELECTED));

      // TODO :
      // Parse from `anchorNode` to `focusNode`, for every `block`, add a class `TEXTAREAX_IS_BLOCK_SELECTED` to it.

      if (startInBlock !== false || endInBlock !== false) {
        var r2 = new Range(range);

        if (type != "Caret" || this.last_key == "MouseDown") {
          if (startInBlock !== false) {
            anchorNode = startInBlock;
            anchorOffset = 0;
          }
          if (endInBlock !== false) {
            focusNode = endInBlock;
            focusOffset = endInBlock.textContent.length;
          }
        } else {
          if (startInBlock !== false) {
            if (
              this.last_key == "ArrowRight" ||
              (anchorNode.previousSibling &&
                anchorNode.previousSibling.classList &&
                anchorNode.previousSibling.classList.contains(TEXTAREAXV_R2)) ||
              !this.is_descendant_of_class(anchorNode, TEXTAREAXV_R2)
            ) {
              let nextSibling = anchorNode.nextSibling;
              if (nextSibling) {
                anchorNode = nextSibling;
                focusNode = nextSibling;
                anchorOffset = 0;
                focusOffset = 0;
              } else {
                anchorNode = startInBlock;
                focusNode = endInBlock;
                focusOffset = startInBlock.textContent.length;
                anchorOffset = startInBlock.textContent.length;
              }
            } else {
              anchorNode = startInBlock;
              focusNode = endInBlock;
              anchorOffset = 0;
              focusOffset = 0;
            }
          }
        }

        if (this.props.debug)
          debug("TextAreaXFormsy handleSelectionChange in block", {
            anchorNode,
            anchorOffset,
            focusNode,
            focusOffset,
            key: this.last_key,
            type,
          });

        if (isReverseSelection) {
          let tmpNode = anchorNode;
          let tmpOffset = anchorOffset;
          anchorNode = focusNode;
          anchorOffset = focusOffset;
          focusNode = tmpNode;
          focusOffset = tmpOffset;
        }

        while (true) {
          try {
            r2.setStart(anchorNode, anchorOffset);
            break;
          } catch (err) {
            anchorOffset -= 1;
            if (anchorOffset < 0) throw "unknown range";
          }
        }

        while (true) {
          try {
            r2.setEnd(focusNode, focusOffset);
            break;
          } catch (err) {
            focusOffset -= 1;
            if (focusOffset < 0) throw "unknown range";
          }
        }

        if (isReverseSelection) {
          let range = r2.cloneRange();
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
          selection.extend(r2.startContainer, r2.startOffset);
        } else {
          selection.removeAllRanges();
          selection.addRange(r2);
        }
      }
      this.insertAnchorAroundCursor();
    }
  }

  step(paragraphOverride, forceUpdate) {
    const { histories, history_i } = this;

    const div = this.textarea;
    let updated = forceUpdate;
    let paragraph = this.escapeHtml(
      paragraphOverride === undefined ? div.textContent : paragraphOverride
    );

    if (this.just_dropped && paragraph.length < this._last_paragraph.length) {
      /*
      paragraph = paragraph
          .replace(TEXTAREAX_SEP, `<span class="${TEXTAREAX_START_CLS}">${TEXTAREAX_SEP2}</span>`)
          .replace(TEXTAREAX_SEP, `<span class="${TEXTAREAX_END_CLS}">${TEXTAREAX_SEP}'</span>`);
      div.innerHTML = paragraph;
        this.restoreSelection();
      */
      this.just_dropped = false;
      return;
    }

    this.stepping = true;

    this._last_paragraph = paragraph;
    this.saveSelection();

    paragraph = this.escapeHtml(
      paragraphOverride === undefined ? div.textContent : paragraphOverride
    );

    if (this.props.debug)
      debug("TextAreaXFormsy step", {
        paragraph,
        last_paragraph: this._last_paragraph,
        just_dropped: this.just_dropped,
      });

    if (this.props.pre_modifier) paragraph = this.props.pre_modifier(paragraph);

    let new_histories = histories.slice(0, history_i + 1),
      new_history_i = histories.length;

    if (new_history_i == 0 || new_histories[new_history_i - 1] != paragraph) {
      new_histories.push(paragraph);
      updated = true;
    }

    paragraph = paragraph
      .replace(
        TEXTAREAX_SEP,
        `<span class="${TEXTAREAX_START_CLS}">${TEXTAREAX_SEP2}</span>`
      )
      .replace(
        TEXTAREAX_SEP,
        `<span class="${TEXTAREAX_END_CLS}">${TEXTAREAX_SEP}</span>`
      );

    if (this.props.post_modifier)
      paragraph = this.props.post_modifier(paragraph);

    new_histories = new_histories.slice(
      Math.max(new_histories.length - 150, 0)
    );
    this.histories = new_histories;
    this.history_i = new_history_i;
    this.paragraph = paragraph;

    if (!paragraph.endsWith("\n")) paragraph += "\n";
    div.innerHTML = paragraph;
    if (
      !div.textContent
        .replace(TEXTAREAX_SEP2, "")
        .replace(TEXTAREAX_SEP, "")
        .trim()
    )
      div.classList.add("textareaXEmpty");
    else div.classList.remove("textareaXEmpty");
    this.restoreSelection();

    if (this.props.onChange) {
      let value = div.textContent;
      if (this.props.serialize) value = this.props.serialize(value);
      if (this.props.debug) console.error("TextAreaXFormsy updated", { value });

      this.lastStep = +new Date();
      this.value = value;
      const text = this.props.stringify ? this.props.stringify(value) : value;
      this.props.onChange(value, this.props.name, {
        text,
        div,
      });

      this.next_update = +new Date();
    }
    if (this.props.onStep) {
      this.props.onStep({
        cursor_keep_start: this.cursor_keep_start,
        cursor_keep_end: this.cursor_keep_end,
        cursor_is_caret: this.cursor_is_caret,
      });
    }

    this.stepping = false;
  }

  handleMouseDown(ev) {
    const key = ev.button,
      { target } = ev;
    if (this.props.debug)
      debug("TextAreaXFormsy handleMouseDown", { key, target });
    this.last_key = "MouseDown";
    this.lastMouseDown = { key, target };
  }

  handleMouseUp(ev) {
    if (!this.lastMouseDown) return;
    const key = ev.button,
      { target } = ev,
      MouseDownTarget = this.lastMouseDown.target,
      MouseDownKey = this.lastMouseDown.key;
    if (this.props.debug)
      debug("TextAreaXFormsy handleMouseUp", {
        key,
        target,
        MouseDownKey,
        MouseDownTarget,
      });
    this.last_key = "MouseUp";
    if (MouseDownKey == key) {
      let block = this.is_descendant_of_block(target, true);
      let block2 = this.is_descendant_of_block(MouseDownTarget, true);
      if (block && block2 == block) {
        this.handleClickOnBlock(target, key, ev);
      }
    }
  }

  handleClickOnBlock(block, key, ev) {
    if (this.props.debug)
      debug("TextAreaXFormsy clickOnBlock", { key, block, ev });
    if (this.props.onClickOnBlock) this.props.onClickOnBlock({ key, block });
  }

  handleKeyPress(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }
    const { key } = ev;
    if (this.props.debug) debug("TextAreaXFormsy handleKeyPress", { key });
    switch (key) {
      case "Control":
        break;
      case "Enter":
        this.insertAtCursor("\n");
        ev.preventDefault();
        this.step();
        break;
    }
  }

  handleKeyDown(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }
    const { key, ctrlKey, shiftKey } = ev;
    let selection, anchorNode, anchorOffset, focusNode, focusOffset, type; // Caret, Range
    if (this.props.debug)
      debug("TextAreaXFormsy handleKeyDown", { key, ctrlKey, shiftKey });
    switch (key) {
      case "ArrowLeft":
      case "ArrowRight":
        this.last_key = key;
        break;
      case "z":
      case "y":
        if (ctrlKey) {
          ev.preventDefault();
          const old_history_i = this.history_i;
          let new_history_i = old_history_i;
          if (this.histories.length == 0) return;
          if (shiftKey || key === "y") new_history_i += 1;
          else new_history_i -= 1;
          if (new_history_i >= this.histories.length)
            new_history_i = this.histories.length - 1;
          if (new_history_i < 0) new_history_i = 0;

          let paragraph = this.histories[new_history_i];
          const div = this.textarea;
          this.saveSelection(div);
          paragraph = paragraph
            .replace(
              TEXTAREAX_SEP,
              `<span class="${TEXTAREAX_START_CLS}">${TEXTAREAX_SEP2}</span>`
            )
            .replace(
              TEXTAREAX_SEP,
              `<span class="${TEXTAREAX_END_CLS}">${TEXTAREAX_SEP}'</span>`
            );

          if (this.props.post_modifier)
            paragraph = this.props.post_modifier(paragraph);
          div.innerHTML = paragraph;

          this.restoreSelection(true);
          this.history_i = new_history_i;
          let value = div.textContent;
          if (this.props.serialize) value = this.props.serialize(value);
          const text = this.props.stringify
            ? this.props.stringify(value)
            : value;
          if (this.props.onChange)
            this.props.onChange(value, this.props.name, {
              text,
              div,
            });
        }

        break;
      case "Control":
        break;
      case "Enter":
        break;
      case "Delete":
        selection = window.getSelection();
        anchorNode = selection.anchorNode;
        anchorOffset = selection.anchorOffset;
        focusNode = selection.focusNode;
        focusOffset = selection.focusOffset;
        type = selection.type;

        if (this.props.debug)
          debug("TextAreaXFormsy Delete", {
            anchorNode,
            anchorOffset,
            focusNode,
            focusOffset,
            type,
          });

        if (type == "Caret") {
          let block = this.is_descendant_of_block(anchorNode, true);
          /**
           *
           *  Case 1 :  <cursor-start/><cursor-end/>  <block>  ...... </block>
           *
           */
          if (!block) {
            let afterCursor = this.nextNonEmptySibling(focusNode.nextSibling);
            if (this.is_block(afterCursor)) {
              afterCursor.remove();
              ev.preventDefault();
              this.step();
            }
          }

          /**
           *
           *  Case 2 :  <block>  <cursor-start/><cursor-end/>    ...... </block>
           *
           */
          if (
            block &&
            !anchorNode.previousSibling &&
            anchorNode.parentElement == block
          ) {
            block.remove();
            ev.preventDefault();
            this.step();
          }
        }
        break;

      case "Backspace":
        selection = window.getSelection();
        anchorNode = selection.anchorNode;
        anchorOffset = selection.anchorOffset;
        focusNode = selection.focusNode;
        focusOffset = selection.focusOffset;
        type = selection.type;

        if (this.props.debug)
          debug("TextAreaXFormsy Backspace", {
            anchorNode,
            anchorOffset,
            focusNode,
            focusOffset,
            type,
          });
        if (type == "Caret") {
          let block = this.is_descendant_of_block(anchorNode, true);
          /**
           *
           *  Edge case 1:  <block>  <cursor-start/><cursor-end/>    ...... </block>
           *
           */
          if (
            !anchorNode.previousElementSibling &&
            anchorNode.parentElement == block
          )
            break;

          if (block) {
            block.remove();
            ev.preventDefault();
            this.step();
          }
          break;
        }
    }
  }

  handlePaste(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }
    let text = ev.clipboardData.getData("text/plain");
    let value = ev.clipboardData
      .getData("text/html")
      .replace(
        /[\s\S]*<!--StartFragment-->([\s\S]*?)<!--EndFragment-->[\s\S]*/gm,
        "$1"
      );

    if (!value.startsWith(TEXTAREAV_COPY_MAGIC)) value = text;
    else value = value.replace(TEXTAREAV_COPY_MAGIC, "");

    if (this.props.deserialize) value = this.props.deserialize(value);
    if (this.props.debug)
      debug("TextAreaXFormsy handlePaste", {
        items: ev.clipboardData.items,
        text,
        value,
      });

    this.insertAtCursor(value);
    this.step();
    ev.preventDefault();
  }

  handleCopy(ev) {
    if (this.props.debug)
      debug("TextAreaXFormsy handleCopy", { items: ev.clipboardData.items });
    let selection = window.getSelection();
    let orig = selection.toString();
    let text = orig;
    if (this.props.stringify) text = this.props.stringify(text);
    let value = orig;
    if (this.props.serialize) value = this.props.serialize(value);
    ev.clipboardData.setData("text/plain", text);
    ev.clipboardData.setData("text/html", TEXTAREAV_COPY_MAGIC + value);
    ev.preventDefault();
  }

  handleCut(ev) {
    if (this.props.debug)
      debug("TextAreaXFormsy handleCut", { items: ev.clipboardData.items });
    let selection = window.getSelection();
    let orig = selection.toString();
    let text = orig;
    if (this.props.stringify) text = this.props.stringify(text);
    let value = orig;
    if (this.props.serialize) value = this.props.serialize(value);
    ev.clipboardData.setData("text/plain", text);
    ev.clipboardData.setData("text/html", TEXTAREAV_COPY_MAGIC + value);
    ev.preventDefault();
    selection.deleteFromDocument();
    this.step();
  }

  handleCompositionStart(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }
    if (this.props.debug)
      debug("TextAreaXFormsy handleCompositionStart", { ev });
    this.composing = true;
  }

  handleCompositionEnd(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleCompositionEnd", { ev });
    this.composing = false;
    this.step();
  }

  handleFocus(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleFocus", { ev });
    if (this.props.disabled) return;
    if (this.props.onFocus) this.props.onFocus(this.name, this.textarea);
    this.focusing = true;
    this.update();
  }

  handleBlur(ev) {
    if (!this.focusing) return;
    if (this.props.disabled) return;
    if (this.last_key == "MouseDown") {
      this.last_key = null;
      return;
    }
    if (this.props.debug) debug("TextAreaXFormsy handleBlur", { ev });
    if (this.props.onBlur) this.props.onBlur(this.name);
    this.focusing = false;
    this.triggerSelectionChange(true);
    this.update();
  }

  handleInput(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }
    if (this.props.debug) debug("TextAreaXFormsy handleInput", { ev });
    if (this.composing) return;
    this.step();
  }

  handleDragStart(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleDragStart", { ev });
    ev.preventDefault();
    return false;
  }

  handleDragEnd(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleDragEnd", { ev });
  }

  handleDragEnter(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleDragEnter", { ev });
    this.dragging = true;
  }

  handleDragLeave(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleDragLeave", { ev });
    this.dragging = false;
  }

  handleDragOver(ev) {
    if (this.props.debug) debug("TextAreaXFormsy handleDragOver", { ev });
  }

  handleDrop(ev) {
    if (this.props.disabled) {
      ev.preventDefault();
      return false;
    }
    const { target } = ev;
    if (this.props.debug) debug("TextAreaXFormsy handleDrop", { target });

    if (this.is_descendant_of_block(target)) {
      ev.preventDefault();
    }
    this.dropped_count += 1;
    if (this.dropped_count > 100) this.dropped_count = 0;
    let this_dropped_count = this.dropped_count;

    this.just_dropped = true;
    this.dragging = false;
    setTimeout((e) => {
      if (this_dropped_count == this.dropped_count) this.just_dropped = false;
    }, 5);
  }

  componentDidMount() {
    if (this.props.debug) debug("TextAreaXFormsy componentDidMount", {});
    document.addEventListener("selectionchange", this.handleSelectionChange);
    this.setState({
      id: "TextAreaXFormsy-" + +new Date(),
    });
  }

  componentWillUnmount() {
    if (this.props.debug) debug("TextAreaXFormsy componentWillUnmount", {});
    document.removeEventListener("selectionchange", this.handleSelectionChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const should_be_focusing = document.activeElement == this.textarea;
    if (should_be_focusing) {
      if (!this.focusing) this.handleFocus();
    } else if (this.focusing) this.handleBlur();
    this.focusing = should_be_focusing;

    let validationUpdate =
      !_.isEqual(this.props.validations, nextProps.validations) ||
      !_.isEqual(this.props.validationErrors, nextProps.validationErrors);

    let internal_should_update =
      this.next_update === undefined || this.last_update != this.next_update;
    let update =
      !_.isEqual(nextProps.options, this.props.options) ||
      (nextProps.value != this.props.value && nextProps.value != this.value) ||
      nextProps.name != this.props.name ||
      nextProps.children != this.props.children ||
      nextProps.showAutoComplete != this.props.showAutoComplete ||
      nextProps.AutocompleteProps != this.props.AutocompleteProps ||
      validationUpdate ||
      this.do_update_next ||
      nextState.id !== this.state.id ||
      internal_should_update;

    if (update) {
      this.last_update = this.next_update;
      this.do_update_next = false;
      return true;
    }

    return false;
  }

  update() {
    if (this.props.debug) debug("TextAreaXFormsy forceUpdate", {});

    this.do_update_next = true;
    this.setState({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.id !== this.state.id ||
      (prevProps.value != this.props.value && this.props.value != this.value)
    ) {
      let value = this.props.value || "";
      if (this.props.deserialize) value = this.props.deserialize(value);
      this.step(value);
    }
  }

  render() {
    const { ttt } = this.props.mainProps;

    return (
      <ClickAwayListener key={0} onClickAway={this.handleBlur}>
        <div
          tabIndex="0"
          className={classNames("relative w-full")}
          {...this.props.WrapperProps}
        >
          {this.props.label && (
            <span
              {...this.props.LabelProps}
              className={classNames(
                this.props.classes.pseudoBgWhite,
                this.props.classes.afterNone,
                "px-8 ml-8 absolute",
                this.props.LabelProps && this.props.LabelProps.className
              )}
              key={-1}
            >
              {this.props.label}
            </span>
          )}
          <div
            key={"TextAreaXFormsy"}
            ref={(elem) => (this.textarea = elem)}
            id={this.state.id}
            label="label"
            name="name"
            tabIndex={-1}
            className={classNames(
              this.props.label && "mt-8",
              this.props.classes.textareaX,
              this.props.className,
              this.props.disabled && this.props.classes.textareaDisabled
            )}
            placeholder={this.props.placeholder}
            contentEditable={this.props.disabled ? "false" : "true"}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
            onInput={this.handleInput}
            onPaste={this.handlePaste}
            onCopy={this.handleCopy}
            onCut={this.handleCut}
            onCompositionStart={this.handleCompositionStart}
            onCompositionEnd={this.handleCompositionEnd}
            onFocus={this.handleFocus}
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
            onDragEnter={this.handleDragEnter}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
          ></div>
          {this.props.showAutoComplete && this.focusing && (
            <Autocomplete
              {...this.props.AutocompleteProps}
              key={1}
              ref={(elem) => (this.autocomplete = elem)}
            />
          )}
          {this.props.children}
        </div>
      </ClickAwayListener>
    );
  }
}

TextAreaXFormsy = withStyles((theme) => styles, { withTheme: true })(
  TextAreaXFormsy
);

let Autocomplete = (props) => {
  const { classes, options, renderer, onClick } = props;

  const handleClick = (option, key) => {
    if (this.props.debug) debug("Autocomplete handleClick");
    if (onClick) {
      onClick(option, key);
    }
  };

  return (
    <div className={classes.root}>
      {options &&
        options.map((option, i) => {
          return renderer ? (
            renderer(option)
          ) : (
            <MenuItem
              key={i}
              onClick={(ev) => {
                handleClick(option, ev.button);
              }}
              className={classes.item}
              {...option}
            >
              {option.label}
            </MenuItem>
          );
        })}
    </div>
  );
};

styles.autocomplete = {
  root: {
    flexGrow: 1,
    maxHeight: 200,
    overflow: "overlay",
    width: "100%",
    position: "absolute",
    background: "white",
    zIndex: 25,
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },
  item: {
    paddingTop: "8px",
    paddingBottom: "4px",
  },
};
Autocomplete = withStyles((theme) => styles.autocomplete, { withTheme: true })(
  Autocomplete
);

let textareaXFactory = (props) => {
  /**
     * Example Usage:
     * 

{
    type: "textarea",
    name: "first_name",
    label={ttt('form-fields:user.first_name')},
    text: "First Name",
    value: user.first_name,
    onChange: f
}

     * 
     */

  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm, classes, ttt } = mainProps;
  const {
    onChange,
    name,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const [errorMessage, setErrorMessage] = React.useState();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";
  const importedProps = _.omit(props, ["classes"]);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, []);

  useEffect(() => {
    handleValidation(
      name && _.get(form, name),
      validations,
      validationErrors,
      setErrorMessage
    );
  }, [validations]);

  const pushForm = (value, context) => {
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      setErrorMessage,
      context
    );
    if (hdlForm) hdlForm(value, name, validation_result, null, context);
    if (onChange) onChange(value, name, validation_result);
  };

  return (
    <Fragment>
      <TextAreaXFormsy
        value={(name && _.get(form, name)) || ""}
        {...props}
        key={lazyFormIndex}
        onChange={(value, _, context) => {
          pushForm(value, context);
        }}
      >
        {props.children}
      </TextAreaXFormsy>
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText>
        )
      ) : null}
    </Fragment>
  );
};
const TEXTAREAXV_PREFIX = "TEXTAREAXV--";
const TEXTAREAXV_WRAPPER = TEXTAREAXV_PREFIX + "wrapper";
const TEXTAREAXV_IS_LINK = TEXTAREAXV_PREFIX + "is-link";
const TEXTAREAXV_L1 = TEXTAREAXV_PREFIX + "L1";
const TEXTAREAXV_L2 = TEXTAREAXV_PREFIX + "L2";
const TEXTAREAXV_R1 = TEXTAREAXV_PREFIX + "R1";
const TEXTAREAXV_R2 = TEXTAREAXV_PREFIX + "R2";
const TEXTAREAXV_NAME = TEXTAREAXV_PREFIX + "name";
const TEXTAREAXV_ID = TEXTAREAXV_PREFIX + "id";

export const TEXTAREAXV_WRAPPER_STYLE = {
  padding: "2px 8px",
  paddingRight: "4px",
  background: "#cfb4eb",
  borderRadius: "0.4em",
  whiteSpace: "nowrap",
};

export const TEXTAREAXV_IS_LINK_STYLE = {
  padding: "2px 8px",
  paddingRight: "4px",
  background: "#aeedfb",
  borderRadius: "0.4em",
  whiteSpace: "normal",
  wordBreak: "break-word",
};

const TEXTAREAXV_PREBLOCK = TEXTAREAXV_PREFIX + "preblock";

const TEXTAREAXV_PREBLOCK_SEP = _.escapeRegExp(
  "\u0001\u0002\u0003\u0004PREBLOCK_SEP\u0004\u0003\u0002\u0001"
);
const TEXTAREAXV_PREBLOCK_SEP2 = TEXTAREAXV_PREBLOCK_SEP.slice(0, -1);

const textareaXV_rgx_blocks_serialize = new RegExp(
  `([@#]{2})\\[(.+?)\\]\\((.+?)\\)\\1( )?( )? *`,
  "g"
);
const textareaXV_rgx_links_serialize = new RegExp(
  `\\[(.+?)\\]((?:${TEXTAREAX_SEP})+)?\\((https?:\\/\\/.+?)\\)`,
  "g"
);
const textareaXV_rgx_preblock_before_cursor_ = `([@#][^\s@#]*?)${TEXTAREAX_SEP}`;
const textareaXV_rgx_preblock_before_cursor = new RegExp(
  textareaXV_rgx_preblock_before_cursor_
);

styles.textareaXV = {
  [[TEXTAREAXV_L1, TEXTAREAXV_L2, TEXTAREAXV_R1, TEXTAREAXV_R2, TEXTAREAXV_ID]
    .map((e) => "& ." + e)
    .join(", ")]: {
    width: "1px",
    display: "inline-block",
    overflow: "hidden",
    height: "1px",
    opacity: 0,
    marginLeft: "-1px",
    pointerEvent: "none",
  },
  [`& .${TEXTAREAXV_WRAPPER}`]: TEXTAREAXV_WRAPPER_STYLE,
  [`& .${TEXTAREAXV_IS_LINK}`]: TEXTAREAXV_IS_LINK_STYLE,
};
styles.variable_item = {
  borderBottom: "1px solid #ddd",
  borderLeft: "1px solid #ddd",
  borderRight: "1px solid #ddd",
  fontSize: "1.2rem",
};
styles.operator_item = {};
styles.variable_type_item = {};

const textareaXV_block_factories = {
  variable: ({ label, value }) => `@@[${label}](${value})@@`,
  operator: ({ label, value }) => `##[${label}](${value})##`,
};

/**
 *  Hardcoded Operators here :D
 */

const operator_to_name = {
  "o.1": "+",
  "o.2": "−",
  "o.3": "*",
  "o.4": "/",
  "o.5": "(",
  "o.6": ")",
};

const textareaXV_ICONS = {};

class textareaXVFactory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      options: [],
    };
    this.textareaXV_blocks_deserialize_replacer = this.textareaXV_blocks_deserialize_replacer.bind(
      this
    );
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.pushForm = this.pushForm.bind(this);
    this.ref_ = this.ref_.bind(this);
    this.textareaXV_blocks_deserialize_replacer = this.textareaXV_blocks_deserialize_replacer.bind(
      this
    );
    this.textareaXV_blocks_deserialize = this.textareaXV_blocks_deserialize.bind(
      this
    );
    this.refreshErrorMessage = this.refreshErrorMessage.bind(this);

    this.symbols = {
      "@@": "variable",
      "##": "operator",
    };
  }

  ref_(el) {
    if (this.props.clsRef) {
      if (typeof this.props.clsRef === "function") this.props.clsRef(el);
      else this.props.clsRef.current = el;
    }
    this.textareaX = el;
  }

  textareaXV_blocks_deserialize_replacer(_, prefix, content, s1, s2) {
    return `${prefix}[${this.props.mainProps.system_variable_to_name[content] ||
      this.props.mainProps.user_variable_to_name[content] ||
      operator_to_name[content] ||
      "None"}](${content})${prefix} ${s1 || ""}`;
  }

  textareaXV_blocks_deserialize(str) {
    try {
      let after = str.replace(
        /([@#]{2})([a-z]\.\d+?)\1( )?( )? */g,
        this.textareaXV_blocks_deserialize_replacer
      );
      return after;
    } catch (error) {
      console.error(error, {
        str,
      });
      throw error;
    }
  }

  textareaXV_blocks_serialize(str) {
    let after = str
      .replace(/([@#]{2})\[(.+?)\]\((.+?)\)\1( )?( )? */g, "$1$3$1$5")
      .replace(/\s+$/, "");
    return after;
  }
  textareaXV_blocks_stringify(str) {
    return str.replace(/([@#]{2})\[(.+?)\]\((.+?)\)\1( )?( )? */g, "$2$5");
  }

  handleSelectionChange({
    cursor_keep_start,
    cursor_keep_end,
    cursor_is_caret,
    previous_node,
    is_descendant_of_block,
    get_text_before_cursor_start,
  }) {
    let {
      text_before_cursor_start,
      is_block,
      inside_block,
    } = get_text_before_cursor_start();
    let just_before_block =
      cursor_keep_start &&
      cursor_keep_start.previousSibling &&
      cursor_keep_start.previousSibling.classList &&
      cursor_keep_start.previousSibling.classList.contains(TEXTAREAX_IS_BLOCK);
    if (inside_block) {
      if (cursor_is_caret) text_before_cursor_start = "";
      else text_before_cursor_start = text_before_cursor_start.charAt(0);
    }
    if (just_before_block) text_before_cursor_start = "";

    if (this.props.debug)
      debug("textareaXVFactory handleSelectionChange", {
        cursor_keep_start,
        cursor_keep_end,
        is_block,
        cursor_is_caret,
        previous_node,
        is_descendant_of_block,
        text_before_cursor_start,
      });
    let match_pos = text_before_cursor_start.search(/[@#][^\s@#]*?$/g);
    let new_options = [];
    if (match_pos > -1) {
      let type = text_before_cursor_start.charAt(match_pos);
      let name = text_before_cursor_start.slice(match_pos + 1);
      if (this.props.debug)
        debug("ExpressionInput caught autocomplete", {
          type,
          name,
        });

      if (type == "@") {
        new_options = matchSorter(this.props.variables || [], name, {
          keys: ["display"],
        });
        new_options = new_options.map((e) => ({
          label: e.display,
          value: e.id,
          type: e.type,
          symbol: e.symbol,
          block_type: "variable",
          icon: textareaXV_ICONS[e.type] || e.type,
          className: this.props.mainProps.classes.variable_item,
        }));
      } else if (type == "#") {
        new_options = matchSorter(this.props.operators || [], name, {
          keys: ["display"],
        });
        new_options = new_options.map((e) => ({
          label: e.display,
          value: e.id,
          type: e.type,
          symbol: e.symbol,
          block_type: "operator",
          icon: "math",
          className: this.props.mainProps.classes.operator_item,
        }));
      } else {
        throw "You should not see this...";
      }
    }

    if (!_.isEqual(this.state.options, new_options))
      this.setState({ options: new_options });
  }

  refreshErrorMessage(value, context) {
    this.last_context = context;
    const validation_result = validate(
      this.props.validations,
      value,
      this.props.validationErrors,
      context
    );
    const newErrorMessage =
      validation_result &&
      !validation_result.valid &&
      Object.values(validation_result.errors)[0];
    if (this.state.errorMessage != newErrorMessage)
      this.setState({ errorMessage: newErrorMessage });
    return validation_result;
  }

  pushForm(value, context) {
    const validation_result = this.refreshErrorMessage(value, context);
    if (this.props.mainProps.hdlForm)
      this.props.mainProps.hdlForm(
        value,
        this.props.name,
        validation_result,
        null,
        context
      );
    if (this.props.onChange)
      this.props.onChange(value, this.props.name, validation_result);
  }

  shouldComponentUpdate(nextProps, nextState) {
    var validationUpdate =
      !_.isEqual(this.props.validations, nextProps.validations) ||
      !_.isEqual(this.props.validationErrors, nextProps.validationErrors);
    if (
      !_.isEqual(this.props.name, nextProps.name) ||
      !_.isEqual(
        _.get(this.props.mainProps.form, this.props.name),
        _.get(nextProps.mainProps.form, this.props.name)
      ) ||
      !_.isEqual(this.props.options, nextProps.options) ||
      !_.isEqual(this.props.children, nextProps.children) ||
      !_.isEqual(this.props.showAutoComplete, nextProps.showAutoComplete) ||
      !_.isEqual(this.props.AutocompleteProps, nextProps.AutocompleteProps) ||
      !_.isEqual(this.state.errorMessage, nextState.errorMessage) ||
      validationUpdate
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    var validationUpdate =
      !_.isEqual(prevProps.validations, this.props.validations) ||
      !_.isEqual(prevProps.validationErrors, this.props.validationErrors);
    if (validationUpdate) {
      this.pushForm(
        _.get(this.props.mainProps.form, this.props.name),
        this.last_context
      );
    }
  }

  render() {
    const that = this;

    return (
      <Fragment>
        <TextAreaXFormsy
          value={
            (this.props.name &&
              _.get(this.props.mainProps.form, this.props.name)) ||
            ""
          }
          {...this.props}
          key={this.props.mainProps.lazyFormIndex}
          clsRef={this.ref_}
          className={classNames(
            this.props.mainProps.classes.textareaXV,
            this.props.className
          )}
          blockFactories={textareaXV_block_factories}
          deserialize={this.textareaXV_blocks_deserialize}
          serialize={this.textareaXV_blocks_serialize}
          stringify={this.textareaXV_blocks_stringify}
          pre_modifier={(paragraph) => {
            paragraph = paragraph
              .replace(
                textareaXV_rgx_blocks_serialize,
                `<span block-type="$1" class="${TEXTAREAXV_WRAPPER} ${TEXTAREAX_IS_BLOCK}"><span class="${TEXTAREAXV_L1}">$1[</span><span class="${TEXTAREAXV_NAME}">$2</span><span class="${TEXTAREAXV_R1}">]</span><span class="${TEXTAREAXV_L2}">(</span><span class="${TEXTAREAXV_ID}">$3</span><span class="${TEXTAREAXV_R2}">)$1</span>$4</span>$5`
              )
              .replace(
                textareaXV_rgx_links_serialize,
                `<span class="${TEXTAREAXV_IS_LINK}">[$1]$2($3)</span>`
              )
              .replace(
                textareaXV_rgx_preblock_before_cursor,
                `${TEXTAREAXV_PREBLOCK_SEP}$1${TEXTAREAXV_PREBLOCK_SEP2}${TEXTAREAX_SEP}`
              );

            return paragraph;
          }}
          post_modifier={(paragraph) => {
            paragraph = paragraph
              .replace(
                TEXTAREAXV_PREBLOCK_SEP,
                `<span class="${TEXTAREAXV_PREBLOCK}">`
              )
              .replace(TEXTAREAXV_PREBLOCK_SEP2, `</span>`);
            return paragraph;
          }}
          onSelectionChange={this.handleSelectionChange}
          showAutoComplete={
            !this.props.disabled && this.state.options.length > 0
          }
          AutocompleteProps={{
            options: this.state.options,
            renderer: (option, i) => {
              return (
                <MenuItem
                  key={i}
                  onClick={(ev) => {
                    if (that.props.debug)
                      debug("textareaXVFactory menu onClick", {
                        option,
                        textareaX: that.textareaX,
                      });
                    that.textareaX.textarea
                      .querySelectorAll("." + TEXTAREAXV_PREBLOCK)
                      .forEach((e) => e.remove());
                    if (option.block_type == "variable")
                      that.textareaX.insertBlockAtCursor(
                        "variable",
                        option,
                        true
                      );
                    else if (option.block_type == "operator")
                      that.textareaX.insertBlockAtCursor(
                        "operator",
                        option,
                        true
                      );
                    else {
                      throw "Wait... you should not be here...";
                    }
                    window
                      .getSelection()
                      .getRangeAt(0)
                      .collapse(false);
                  }}
                  className={classNames(
                    that.props.mainProps.classes.item,
                    option.className,
                    that.props.mainProps.classes.variable_type_item[option.type]
                  )}
                >
                  <IconX key="0" className="mr-12" name={option.icon}></IconX>
                  {option.label}
                </MenuItem>
              );
            },
          }}
          onChange={(value, name, context) => {
            that.pushForm(value, context);
          }}
        />
        {this.state.errorMessage ? (
          <FormHelperText className="message mb-8 text-red">
            {this.state.errorMessage}
          </FormHelperText>
        ) : null}
      </Fragment>
    );
  }
}

/********************************************
 ********************************************
 *******                              *******
 *******   TextareaXV Field (Multi)   *******
 *******                              *******
 ********************************************
 ********************************************/

const persistentObserved = new Set();

let factoryToMultiple = (Factory, extraProps) => (props) => {
  const { mainProps, lazyFormIndex } = props;
  const { form, hdlForm } = mainProps;
  const { classes, globals, ttt, setGlobalVariables } = mainProps;
  const {
    clsRef,
    name,
    tabs,
    tab: initTab,
    persistent,
    value: value_,
    className,
    showTabsOnFocus,
    hideTabs: hideTabs_,
    SelectProps,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
    previewFilterEmpty = false,
    previewOthers = true,
  } = props;
  const use_persistent = persistent !== undefined;
  const [focusing, setFocusing] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const selectRef = React.useRef();
  const ref = React.useRef();
  const errorStyle = validationStyle
    ? validationStyle
    : "message mb-8 text-red";
  if (!tabs || !Array.isArray(tabs)) throw "tabs is required in multi mode";
  if (tabs.some((tab) => !tab.value))
    throw "some tabs are not having values: " + JSON.stringify(tabs);

  const tabsProvided = tabs && tabs.length;
  const hideTabs = hideTabs_ || !tabsProvided;
  const [tab, setTab] = useState(
    initTab || (tabsProvided && tabs[0].value) || "default"
  );

  const tab_in_use_ =
    (use_persistent ? globals.variables[persistent] : tab) ||
    (tabsProvided && tabs[0].value) ||
    "default";
  const tab_in_use = tabs.some((tab) => tab.value == tab_in_use_)
    ? tab_in_use_
    : (tabs[0] || {}).value;

  if (use_persistent && tab_in_use && tab_in_use != tab_in_use_) {
    setGlobalVariables({
      [persistent]: tab_in_use,
    });
  }

  useEffect(() => {
    let values = _.get(form, name);
    let updated = false;
    if (!values) {
      values = {};
      updated = true;
    }
    if (typeof values == "string" || typeof values == "number") {
      values = { [tab_in_use]: values };
      updated = true;
    } else if (!values[tab_in_use]) {
      values[tab_in_use] = "";
      updated = true;
    }
    if (updated) {
      let validation_result = validate(validations, values, validationErrors);
      setErrorMessage(
        validation_result &&
          !validation_result.valid &&
          Object.values(validation_result.errors)[0]
      );
      // hdlForm(values, name, validation_result);
    }
  }, []);

  useEffect(() => {
    if (use_persistent) {
      if (!persistentObserved.has(persistent)) {
        debug("factoryToMultiple useEffect", {
          use_persistent,
          persistentObserved,
          persistent,
        });
        persistentObserved.add(persistent);
        setGlobalVariables({
          [persistent]:
            initTab || (tabs && tabs.length && tabs[0].value) || "default",
        });
      }
    }
  }, [globals.variables[persistent]]);

  const passOnProps = _.omit(props, [
    "ref",
    "persistent",
    "onChange",
    "ValidationErrors",
    "validations",
    "value",
  ]);
  // const passOnMainProps = _.omit(mainProps, ["hdlForm"]);
  passOnProps.mainProps = {
    ...mainProps,
    hdlForm: (
      this_value,
      this_name,
      _validation_result,
      _callback,
      context
    ) => {
      const cloned = _.cloneDeep(_.get(form, name));
      _.set(cloned, this_name.replace(name + ".", ""), this_value);
      const validation_result = validate(
        validations,
        cloned,
        validationErrors,
        context
      );
      setErrorMessage(
        validation_result &&
          !validation_result.valid &&
          Object.values(validation_result.errors)[0]
      );
      hdlForm(this_value, this_name, validation_result, null, context);
    },
  };

  if (extraProps)
    Object.keys(extraProps).forEach((k) => (passOnProps[k] = extraProps[k]));

  passOnProps.className =
    (className &&
      (typeof className == "function" ? className(hideTabs) : className)) ||
    (hideTabs ? "" : "mt-8");
  passOnProps.name = name + "." + tab_in_use;
  // passOnProps.value = values[tab_in_use] || "";
  passOnProps.clsRef = (el) => {
    if (props.debug)
      debug("factoryToMultiple clsRef", {
        el,
        clsRef,
      });
    if (clsRef) {
      if (typeof clsRef === "function") clsRef(el);
      else clsRef.current = el;
    }
    ref.current = el;
  };

  const handleSelectChange = (value) => {
    if (use_persistent) setGlobalVariables({ [persistent]: value });
    else setTab(value);

    if (ref.current && ref.current.update) ref.current.update();

    if (SelectProps && SelectProps.onChange !== undefined)
      SelectProps.onChange(value, name);
  };

  const preview_tabs = tabs.filter(
    (tab) =>
      tab.value != tab_in_use &&
      (!previewFilterEmpty ||
        (_.get(form, name + "." + tab.value) || "").trim().length)
  );

  const selectComp = (!hideTabs ||
    (previewOthers && focusing && preview_tabs.length > 0)) &&
    tabs &&
    tabs.length > 0 &&
    (!showTabsOnFocus || focusing) && (
      <Select
        displayEmpty
        {...SelectProps}
        MenuProps={{
          disableEnforceFocus: true,
          className: "lazyform-select-modal",
          id: `lazyform-select-modal-${persistent}`,
        }}
        value={tab_in_use}
        key={0}
        ref={selectRef}
        onChange={(event) => {
          const value = digestOnChangeEvent(event);
          handleSelectChange(value);
        }}
        className={classNames(
          classes.pseudoBgWhite,
          classes.selectTab,
          classes.afterNone,
          "text-xs",
          SelectProps && SelectProps.className
        )}
        classes={{
          select: classes.select,
          ...(SelectProps && SelectProps.classes),
        }}
      >
        {tabs.map((item, i) => (
          <MenuItem key={i} {...item}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );

  const previewComp = previewOthers && focusing && (
    <span className={classNames(classes.previewWindow)}>
      {preview_tabs.map((tab, idx) => {
        return (
          <span
            key={idx}
            className={classes.previewWindowWrapper}
            onMouseUp={() => {
              handleSelectChange(tab.value);
            }}
          >
            <span className={classNames(classes.previewWindowTab)}>
              {tab.label}
            </span>
            <span className="w-full relative flex flex-col">
              <Factory
                key={idx}
                className="pointer-events-none pb-8 pt-20"
                name={name + "." + tab.value}
                WrapperProps={{
                  className: "pointer-events-none",
                }}
                mainProps={{
                  ...mainProps,
                  hdlForm: () => {},
                }}
              />
            </span>
          </span>
        );
      })}
    </span>
  );

  const handleFocus = (__, elem) => {
    setFocusing(true);
    if (props.onFocus) props.onFocus(name + "." + tab_in_use, elem);
  };

  const handleBlur = (__, elem) => {
    setFocusing(false);
    if (props.onBlur) props.onBlur(name + "." + tab_in_use, elem);
  };

  return (
    <span
      {...props.multipleWrapperProps}
      key={lazyFormIndex}
      className={classNames(
        "relative flex flex-1 whitespace-no-wrap flex-col",
        props.multipleWrapperProps && props.multipleWrapperProps.className
      )}
    >
      <span style={{ zIndex: 1 }} className={classNames("ml-8 text-xs h-0")}>
        {selectComp}
      </span>

      <Factory
        key="a"
        {...passOnProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {previewComp}
      {errorMessage ? (
        validationIcon ? (
          <Tooltip title={errorMessage} placement="top">
            <Icon className={errorStyle}>warning</Icon>
          </Tooltip>
        ) : (
          <FormHelperText className={errorStyle}>{errorMessage}</FormHelperText> // [this generates the error]
        )
      ) : null}
    </span>
  );
};
let multipleTextareaXVFactory = factoryToMultiple(textareaXVFactory);

let multipleTextareaXFactory = factoryToMultiple(textareaXFactory);
/********************************************
 ********************************************
 **********                        **********
 **********   Text Field (Multi)   **********
 **********                        **********
 ********************************************
 ********************************************/

let multipleTextFieldFactory = factoryToMultiple(textFieldFactory);

/********************************************
 ********************************************
 *******                              *******
 *******   TextareaXV Field (Multi)   *******
 *******                              *******
 ********************************************
 ********************************************/

const ArrayItemContent = ({ props, provided, snapshot }) => {
  return (
    <span
      className={classNames(
        "flex w-full flex-col",
        props.rowClassName &&
          (typeof props.rowClassName == "function"
            ? props.rowClassName(props.index)
            : props.rowClassName)
      )}
    >
      <span
        className={classNames(
          "flex w-full flex-row items-start bg-white rounded-lg",
          props.RowProps && props.RowProps.className,
          props.mainProps.classes.showOnHoverParent
        )}
        key={props.index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          // some basic styles to make the items look a bit nicer
          userSelect: "none",

          // styles we need to apply on draggables
          ...provided.draggableProps.style,

          // change background colour if dragging
          opacity:
            snapshot.isDragging && !snapshot.isDropAnimating ? "0.5" : "1",
        }}
      >
        {props.withHandle && (
          <IconButton
            aria-label="Handle"
            size="small"
            variant="outlined"
            className={classNames(
              "pointer-events-none mt-6",
              props.arrayDragDisabled && "opacity-50",
              props.HandleProps && props.HandleProps.className
            )}
          >
            <Icon>drag_indicator</Icon>
          </IconButton>
        )}

        <span
          key="item_wrapper"
          {...props.LineContentWrapperProps}
          className={classNames(
            "flex flex-1",
            props.LineContentWrapperProps &&
              props.LineContentWrapperProps.className &&
              (typeof props.LineContentWrapperProps.className == "function"
                ? props.LineContentWrapperProps.className(props.index)
                : props.LineContentWrapperProps.className)
          )}
        >
          <props.Factory
            key="item"
            {...props}
            name={
              props.prefixName +
              "." +
              props.index +
              (props.name ? "." + props.name : "")
            }
          />
        </span>

        <span
          className={classNames(
            props.RightButtonWrapperProps &&
              props.RightButtonWrapperProps.className,
            props.RightButtonWrapperProps &&
              props.RightButtonWrapperProps.showOnHover &&
              props.mainProps.classes.showOnHover
          )}
        >
          <IconButton
            key="-1"
            aria-label="Delete"
            size="small"
            variant="outlined"
            disabled={props.readOnly || props.arrayButtonsDisabled}
            {...props.DeleteButtonProps}
            className={classNames(
              props.DeleteButtonProps && props.DeleteButtonProps.className,
              props.DeleteButtonProps &&
                props.DeleteButtonProps.showOnHover &&
                props.mainProps.classes.showOnHover
            )}
          >
            <Icon {...props.DeleteButtonProps.iconProps}>
              {props.DeleteButtonProps && props.DeleteButtonProps.icon
                ? props.DeleteButtonProps.icon
                : "delete"}
            </Icon>
          </IconButton>
          {props.RightButtonWrapperProps &&
            props.RightButtonWrapperProps.extraButtons &&
            props.RightButtonWrapperProps.extraButtons.map((Comp, ind) => (
              <Comp key={ind} {...props} {...props.RightButtonWrapperProps} />
            ))}
        </span>
      </span>
      {props.rowExtraRenderer && props.rowExtraRenderer(props.index)}
    </span>
  );
};

const ArrayItem = (props) => {
  return (
    <Draggable
      isDragDisabled={props.arrayDragDisabled}
      draggableId={"id-" + props.index}
      index={props.index}
    >
      {(provided, snapshot) => (
        <ArrayItemContent
          props={props}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  );
};

class FactoryToArray extends Component {
  constructor(props) {
    super(props);
    const { mainProps, prefixName, value: value_, passOnProps } = props;
    const { defaultValue } = passOnProps;
    const { form, hdlForm, classes } = mainProps;
    const values = value_ || _.get(form, prefixName) || [];
    this.values = values;

    this.pushForm = this.pushForm.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.addRow = this.addRow.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onChange = this.onChange.bind(this);

    if (!Array.isArray(values)) {
      console.error("[ERROR] FactoryToArray: value received is not an array", {
        values,
      });
    }

    if (!defaultValue) throw "array type must contains props `defaultValue`";
  }

  componentDidMount() {
    this.onChange(this.values);
  }

  pushForm(value) {
    const { validations, validationErrors } = this.props;
    const validation_result = handleValidation(
      value,
      validations,
      validationErrors,
      null
    );

    this.errorMessage =
      validation_result &&
      !validation_result.valid &&
      Object.values(validation_result.errors)[0];

    this.setState({});

    if (this.props.mainProps.hdlForm)
      this.props.mainProps.hdlForm(
        value,
        this.props.prefixName,
        validation_result
      );
    if (this.props.onChange)
      this.props.onChange(value, this.props.prefixName, validation_result);
  }

  removeRow(idx) {
    const current =
      _.get(this.props.mainProps.form, this.props.prefixName) || [];
    const updated = current.filter((v, i) => i != idx);
    this.onChange(updated);
    if (this.props.onChange)
      this.props.onChange(updated, this.props.prefixName);
    this.setState({});
  }

  addRow() {
    const current =
      _.get(this.props.mainProps.form, this.props.prefixName) || [];
    const updated = Array.from(current);
    updated.push(_.cloneDeep(this.props.passOnProps.defaultValue));
    this.onChange(updated);
    if (this.props.onChange)
      this.props.onChange(updated, this.props.prefixName);
    this.setState({});
  }

  onChange(updated) {
    this.values = updated;
    this.pushForm(updated);
  }

  onDragStart(result) {
    document.activeElement.blur();
  }

  onDragEnd(result) {
    if (!result.destination) return;

    if (result.destination.index === result.source.index) return;

    const current =
      _.get(this.props.mainProps.form, this.props.prefixName) || [];
    const updated = Array.from(current);
    const [removed] = updated.splice(result.source.index, 1);
    updated.splice(result.destination.index, 0, removed);

    debug("FactoryToArray dragend", {
      values: this.values,
      updated,
      active: document.activeElement,
    });

    this.onChange(updated);
    if (this.props.onChange)
      this.props.onChange(updated, this.props.prefixName);

    this.setState({});
  }

  getPassOnProps(value, idx) {
    const {
      mainProps,
      Factory,
      extraProps,
      DeleteButtonProps,
      RightButtonWrapperProps,
      HandleProps,
      LineContentWrapperProps,
      RowProps,
      rowExtraRenderer,
      withHandle = true,
      prefixName,
      readOnly,
      passOnProps,
    } = this.props;
    const passOnProps_ = _.omit(passOnProps, []);

    if (extraProps)
      Object.keys(extraProps).forEach((k) => (passOnProps_[k] = extraProps[k]));
    passOnProps_.mainProps = mainProps;

    return {
      HandleProps,
      withHandle,
      ...passOnProps_,
      DeleteButtonProps: {
        ...DeleteButtonProps,
        onClick: memobind(this, "removeRow", idx),
        readOnly: readOnly,
      },
      RightButtonWrapperProps,
      RowProps,
      LineContentWrapperProps,
      prefixName,
      index: idx,
      rowExtraRenderer,
      Factory: Factory,
      readOnly,
    };
  }

  render() {
    const props = this.props;
    const {
      mainProps,
      lazyFormIndex,
      AddRowProps,
      WrapperProps,
      AddButtonProps,
      prefixName,
      validations,
      validationErrors,
      validationStyle,
      validationIcon,
      arrayButtonsDisabled,
      readOnly,
    } = props;
    const { form, hdlForm, classes } = mainProps;
    const { values } = this;
    const errorStyle = validationStyle
      ? validationStyle
      : "message mb-8 text-red";

    const errorMessage = this.errorMessage;

    debug("FactoryToArray render", {
      values,
      props,
      prefixName,
      form,
      errorMessage,
    });

    return (
      <span
        {...WrapperProps}
        key={lazyFormIndex}
        className={classNames(
          "flex w-full flex-col",
          WrapperProps && WrapperProps.className
        )}
      >
        <DragDropContext
          key="DragDropContext"
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <Droppable
            key="Droppable"
            droppableId="list"
            renderClone={(provided, snapshot, rubric) => (
              <ArrayItemContent
                key="ArrayItemContent"
                props={this.getPassOnProps(
                  values[rubric.source.index],
                  rubric.source.index
                )}
                provided={provided}
                snapshot={snapshot}
                rubric={rubric}
              />
            )}
          >
            {(provided) => (
              <div
                key="DroppableContent"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {values.map((value, idx) => (
                  <ArrayItem
                    key={"ArrayItem" + idx}
                    {...this.getPassOnProps(value, idx)}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <span
          {...AddRowProps}
          className={classNames(
            "flex w-full flex flex-row-reverse",
            AddRowProps && AddRowProps.className
          )}
        >
          <IconButton
            key="add"
            aria-label="Add"
            size="small"
            variant="outlined"
            {...AddButtonProps}
            disabled={readOnly || arrayButtonsDisabled}
            onClick={this.addRow}
          >
            <Icon>
              {AddButtonProps && AddButtonProps.icon
                ? AddButtonProps.icon
                : "add"}
            </Icon>
          </IconButton>
          {AddRowProps && AddRowProps.children}
        </span>

        {errorMessage ? (
          validationIcon ? (
            <Tooltip title={errorMessage} placement="top">
              <Icon className={errorStyle}>warning</Icon>
            </Tooltip>
          ) : (
            <FormHelperText className={errorStyle}>
              {errorMessage}
            </FormHelperText>
          )
        ) : null}
      </span>
    );
  }
}

// FactoryToArray = _.memoize(FactoryToArray);

const arrayFactoryModifiers = {
  textMulti: {
    DeleteButtonProps: {
      className: "mt-12",
    },
    HandleProps: {
      className: "mt-12",
    },
  },
  textareaXVMulti: {
    DeleteButtonProps: {
      className: "mt-12",
    },
    HandleProps: {
      className: "mt-12",
    },
  },
};

let arrayFactory = (props) => {
  /**
     * 
     * Example Usage:
     * 

{
    type: "array",
    value: [],
    props: {
      type: "textMulti",

      name: `fallbacks.${i}`,
      className: "flex-1",
      persistent: "multiTextFieldLang",
      placeholder: "Placeholder",
      multiline: true,
      rowsMax: 4,
      tabs: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "Chinese",
          value: "zh-TW",
        },
      ],
    }
}

     * 
     */
  const {
    props: passOnProps,
    className,
    name,
    mainProps,
    lazyFormIndex,
    disabled,
    validations,
    validationErrors,
    validationStyle,
    validationIcon,
  } = props;
  const { classes } = mainProps;

  const Factory = fieldTypes[passOnProps.type];
  if (!Factory) throw `Unknown type of field: ${passOnProps.type})`;
  const extra = arrayFactoryModifiers[passOnProps.type] || {};

  return (
    <span
      key={lazyFormIndex}
      {..._.omit(props, [
        "mainProps",
        "lazyFormIndex",
        "AddRowProps",
        "WrapperProps",
        "extraProps",
        "arrayButtonsDisabled",
        "arrayDragDisabled",
        "AddButtonProps",
        "HandleProps",
        "DeleteButtonProps",
        "RightButtonWrapperProps",
      ])}
      className={
        className && typeof className != "string"
          ? className
          : classNames(classes.arrayWrapper, className || "")
      }
    >
      <FactoryToArray
        Factory={Factory}
        key="InnerFactory"
        arrayButtonsDisabled={disabled}
        arrayDragDisabled={disabled}
        passOnProps={passOnProps}
        validations={validations}
        validationErrors={validationErrors}
        validationStyle={validationStyle}
        prefixName={name}
        mainProps={mainProps}
        DeleteButtonProps={_.merge(
          extra.DeleteButtonProps,
          props.DeleteButtonProps
        )}
        WrapperProps={_.merge(extra.WrapperProps, props.WrapperProps)}
        AddRowProps={props.AddRowProps}
        RightButtonWrapperProps={_.merge(
          extra.RightButtonWrapperProps,
          props.RightButtonWrapperProps
        )}
        AddButtonProps={_.merge(extra.AddButtonProps, props.AddButtonProps)}
        HandleProps={_.merge(extra.HandleProps, props.HandleProps)}
        LineContentWrapperProps={_.merge(
          extra.LineContentWrapperProps,
          props.LineContentWrapperProps
        )}
        RowProps={_.merge(extra.RowProps, props.RowProps)}
        withHandle={props.withHandle}
        rowExtraRenderer={props.rowExtraRenderer}
      />
    </span>
  );
};

let rowFactory = (props) => {
  /**
     * Example Usage:
     * 

        {
          type: "row",
          items: [
            {
                type: "label",
                label: "Name",
            },
            {
                type: "label",
                className: "text-black",
                label: "Peter",
            },
            {
                type: "text",
                name: "name",
                placeholder: "Your Name"
            },
          ]
        }


     * 
     */

  const { mainProps, items, name } = props;

  if (items.some((item) => item.name == "row")) {
    console.error(items);
    throw "cannot have a nested rows";
  }

  return (
    <div
      key="rowFactory"
      {..._.pick(props, ["id", "style"])}
      className={classNames("flex items-start", props && props.className)}
    >
      {items.map((item, lazyFormIndex) => {
        const Factory = fieldTypes[item.type];
        if (!Factory) throw `Unknown type of field: ${item.type})`;
        const passOnProps = _.omit(item, ["rowItemProps"]);
        passOnProps.lazyFormIndex = lazyFormIndex;
        passOnProps.mainProps = mainProps;
        if (name) {
          if (passOnProps.name.indexOf(".") > -1)
            throw 'nested fields should not have "." in the name';
          passOnProps.name = name + "." + passOnProps.name;
        }

        return (
          <span
            key={"rowFactoryItem-" + lazyFormIndex}
            {...item.rowItemProps}
            className={classNames(
              lazyFormIndex == items.length - 1 ? "" : "mr-8",
              item.rowItemProps && item.rowItemProps.className
            )}
          >
            <Factory {...passOnProps} />
          </span>
        );
      })}
    </div>
  );
};

/********************************************
 ********************************************
 ********************************************
 ********************************************/

const fieldTypes = {
  row: rowFactory,
  label: labelFactory,
  text: textFieldFactory,
  select: selectFactory,
  textMulti: multipleTextFieldFactory,
  tags: multipleSelectFactory,
  textareaX: textareaXFactory,
  textareaXV: textareaXVFactory,
  textareaXVMulti: multipleTextareaXVFactory,
  textareaXMulti: multipleTextareaXFactory,
  array: arrayFactory,
  switch: switchFactory,
};

const extendLazyForm = (key, factory) => {
  fieldTypes[key] = factory;
};

const emptyEntity = {};

const nestedAnyNull = (obj) => {
  return Object.keys(obj).some((key) => {
    if (typeof obj[key] === "object") return nestedAnyNull(obj[key]);
    return !!obj[key];
  });
};

class LazyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      isInvalid: true,
    };

    this.hdlForm = this.hdlForm.bind(this);

    this.valids = {};

    this.form = props.entity
      ? props.entityFields
        ? _.pick(props.entity, props.entityFields)
        : _.cloneDeep(props.entity)
      : _.cloneDeep(emptyEntity);
  }

  /*
  shouldComponentUpdate(nextProps, nextState) {
    console.error("LazyForm shouldComponentUpdate", {
      props_changed: !_.isEqual(this.props, nextProps),
      entity_changed: !_.isEqual(this.props.entity, nextProps.entity),
      entity: this.props.entity,
      next_entity: nextProps.entity,
    });
    if (!_.isEqual(this.props.entity, nextProps.entity)) {
      return true;
    }
    return false;
  }*/

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.entity, prevProps.entity)) {
      this.form = this.props.entity
        ? this.props.entityFields
          ? _.pick(this.props.entity, this.props.entityFields)
          : _.cloneDeep(this.props.entity)
        : _.cloneDeep(emptyEntity);
      this.forceUpdate();
    } else if (!_.isEqual(this.props, prevProps)) {
      this.forceUpdate();
    }
  }

  hdlForm(value, name, validation_result, callback) {
    if (!name) {
      console.error({
        props: this.props,
      });
      throw "name must be passed to hdlForm";
    }
    // if (_.isEqual(_.get(this.form, name), value)) return;

    _.set(this.form, name, value);

    if (callback) callback(this.form);

    debug("hdlForm", {
      entity: this.form,
      name,
      value,
    });

    _.set(
      this.valids,
      name,
      validation_result === undefined ||
        validation_result === true ||
        (validation_result !== false && validation_result.valid)
    );

    const valid = nestedAnyNull(this.valids);

    if (this.props.onChange !== undefined)
      this.props.onChange(this.form, this.props.name, valid);

    if (valid) {
      if (this.props.onValid) this.props.onValid(true);
    } else {
      if (this.props.onInvalid) this.props.onInvalid(true);
    }
  }

  render() {
    let { fields, className, t: ttt } = this.props;

    const mainProps = {
      ...this.props,
      form: this.form,
      hdlForm: this.hdlForm,
      ttt,
    };
    fields.mainProps = mainProps;
    fields.lazyFormIndex = 0;

    const Factory = fieldTypes[fields.type];
    if (!Factory) {
      console.error({
        fields,
        fieldTypes,
      });
      throw `Unknown type of field: ${fields.type}`;
    }

    return (
      <Fragment>
        <div className={classNames("flex flex-col w-full", className)}>
          <Factory {...fields} />
        </div>
        {this.props.children}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage,
      setGlobalVariables,
    },
    dispatch
  );
}

function mapStateToProps({ company, globals, flowEditorApp }) {
  return {
    company,
    globals,
    system_variable_to_name:
      (globals && globals.variables.system_variable_to_name) || {},
    user_variable_to_name:
      (flowEditorApp && flowEditorApp.template.user_variable_to_name) || {},
  };
}

const _styles = (theme) => styles;
LazyForm = connect(mapStateToProps, mapDispatchToProps)(LazyForm);
LazyForm = withStyles(_styles, { withTheme: true })(LazyForm);
LazyForm = withTranslation()(LazyForm);
export { LazyForm, extendLazyForm };
