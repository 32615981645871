import Debug from "debug";

import { asyncThunk } from "app/utils/common";

import { REST } from "app/client_proxied";

const debug = Debug("pfe:store:company:action:emergency");

export const GET_AUTO_REPLY = "[EMERGENCY] GET AUTO REPLY";
export const UPDATE_AUTO_REPLY = "[EMERGENCY] UPDATE AUTO REPLY";

export function getAutoReply() {
  return asyncThunk(async (dispatch, getState) => {
    const raw = (await REST.AutoReplyApi.autoReplyGet()).data.Result,
      { status: auto_reply } = raw;
    // norm = normalize(raw, getSubsResult),
    // subscription = Object.values(norm.entities.subscription || {})[0]
    debug("getSubscription - norm", auto_reply);

    return dispatch({
      type: GET_AUTO_REPLY,
      auto_reply,
    });
  });
}

export function updateAutoReply(auto_reply) {
  return asyncThunk(async (dispatch, getState) => {
    /*
    const access_token = auth0Service.getAccessToken(),
          api = new AutoReplyApi({
            accessToken: access_token,
          })
          ;
          */

    const raw = (await REST.AutoReplyApi.autoReplyPatch({ status: auto_reply }))
      .data.Result;
    // norm = normalize(raw, getSubsResult),
    // subscription = Object.values(norm.entities.subscription || {})[0]
    debug("getSubscription - norm", raw);

    return dispatch({
      type: GET_AUTO_REPLY,
      auto_reply,
    });
  });
}
