import { normalize, schema } from "normalizr";
import Debug from "debug";

import { isEmpty, asyncThunk } from "app/utils/common";

import { REST } from "app/client_proxied";

export const GET_FOLDERS = "[COMPANY][FOLDERS] GET";

const debug = Debug("pfe:store:folders.actions");

const FolderSchema = new schema.Entity(
  "conv_types",
  {},
  {
    idAttribute: "folder_id",
    processStrategy: (value, parent, key) => ({
      ...value,
      id: value.folder_id,
      styles: `bg-${value.color || "yellow"} text-black`,

      // NOTES: Removed unused fields
      folder_id: undefined,
    }),
  }
);

export function getFolders() {
  return asyncThunk(async (dispatch, getState) => {
    const folders_raw = (await REST.FoldersApi.foldersGet()).data,
      folders_norm = normalize(folders_raw, new schema.Array(FolderSchema)),
      folders = folders_norm.entities.conv_types;
    debug("folders", folders_norm, folders);

    if (!isEmpty(folders))
      dispatch({
        type: GET_FOLDERS,
        folders,
      });
  });
}
