import React from "react";
import { withStyles } from "@material-ui/core";
import { FusePageSimple } from "@fuse";

const styles = (theme) => {
  return {};
};

let component = ({ children }) => {
  return <FusePageSimple content={children} />;
};

component = withStyles(styles, { withTheme: true })(component);
export const BlankPageForDialogs = component;
