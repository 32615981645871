import _ from "@lodash";
import { normalize, schema } from "normalizr";
import Debug from "debug";

import { asyncThunk } from "app/utils/common";
import { toPBE } from "app/utils/entities/plfacc";

import { REST } from "app/client_proxied";

export const GET_PLFACCS = "[SETTINGS][PLFACCS] GET";
export const UPDATE_PLFACC = "[SETTINGS][PLFACCS] UPDATE";
export const DELETE_PLFACC = "[SETTINGS][PLFACCS] DELETE";
// export const SET_PW = '[SETTINGS][COMPANY] SET STAFF PW';
export const SET_SUBWIN_HANDLE = "[SETTINGS][PLFACCS] SET HANDLE";

const debug = Debug("pfe:store:company:action:plfacc");

const PASchema = new schema.Entity(
  "plfaccs",
  {},
  {
    idAttribute: "plfacc_id",

    processStrategy: (value, parent, key) => {
      const result = {
        ...value,
        id: value.plfacc_id,
      };

      result.preferences = Object.assign(
        {
          // Defaults here
          auto_reply: false,
          hide_staff_name: false,
          hide_channel_name: false,
          timeout: 0,
          auto_get_started: false,
          stt_enabled: false,
          tts_enabled: false,
          tts_button_enabled: false,
          placeholder: "",
          security_option: null,
          password: "",
          avatar: "",
        },
        _.pick(value.preferences, [
          "auto_reply",
          "hide_staff_name",
          "hide_channel_name",
          "timeout",
          "auto_get_started",
          "stt_enabled",
          "tts_enabled",
          "tts_button_enabled",
          "placeholder",
          "security_option",
          "password",
          "avatar",
        ])
      );

      // NOTES: Removed unused fields
      delete result.plfacc_id;

      return result;
    },
  }
);

export function setSubWindowHandle(handle) {
  return (dispatch) =>
    dispatch({
      type: SET_SUBWIN_HANDLE,
      handle: handle,
    });
}

export function getAllPlfaccs(company_id) {
  return asyncThunk(async (dispatch, getState) => {
    const pa_raw = (await REST.PlatformAccountsApi.plfaccsGet()).data,
      pa_norm = normalize(pa_raw, new schema.Array(PASchema)),
      plfaccs = pa_norm.entities.plfaccs;
    if (plfaccs)
      dispatch({
        type: GET_PLFACCS,
        plfaccs,
      });
  });
}

export function updatePA(plfacc) {
  debug("updatePA", plfacc);

  return asyncThunk(async (dispatch) => {
    const plfacc_to_update = toPBE(plfacc);
    await REST.PlatformAccountsApi.plfaccsPlfaccIdPatch(
      plfacc.id,
      plfacc_to_update
    );

    return dispatch({
      type: UPDATE_PLFACC,
      plfacc,
    });
  });
}

export function deletePA(plfacc) {
  debug("deletePA", plfacc);
  return asyncThunk(async (dispatch) => {
    await REST.PlatformAccountsApi.plfaccsPlfaccIdDelete(plfacc.id);

    return dispatch({
      type: DELETE_PLFACC,
      plfacc_id: plfacc.id,
    });
  });
}
