import React from "react";
import {
  Card,
  CardContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ld from "lodash";
import Debug from "debug";

import { PaymentMethod, i18nNamespaces } from "constants.js";
import { getPaymentMethod, displayPrice, ts2Date } from "app/utils/entities";

const debug = Debug("pfe:settings:subscription:SubscriptionCard");

const styles = (theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  cardCaption: {},
  learnMoreExp: {
    boxShadow: "none",
    "&:before": {
      backgroundColor: "transparent",
    },
  },
  glowing: {
    outline: "none",
    borderColor: "#9ecaed",
    boxShadow: "0 0 10px 3px #9ecaed",
  },
});

const PaymentFields = (props) => {
  const { ttt } = props,
    { subscription, descLabel, valLabel } = props,
    { paypal } = subscription;
  debug("paypal", paypal, subscription);

  return (
    <React.Fragment>
      {/* PayPal Subscription */}
      {paypal && (
        <React.Fragment>
          <Typography className={descLabel}>
            {ttt("form-fields:subscription.payment.paypal.subscription_id")}
          </Typography>
          <Typography className={valLabel}>{paypal.subscription_id}</Typography>

          <Typography className={descLabel}>
            {ttt("form-fields:subscription.next_billing_date")}
          </Typography>
          <Typography className={valLabel}>
            {ts2Date(subscription.next_billing_day)}
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const SubscriptionCard = (props) => {
  const { classes, className: pClassName, company, subscription, plan } = props,
    { t: ttt } = useTranslation();
  const currentMethod = getPaymentMethod(subscription) || {};
  debug("currentMethod", currentMethod);

  let plan_contents = ttt(`plans:plan-${plan.id}.contents`, {
    returnObjects: true,
  });
  if (!ld.isArray(plan_contents)) plan_contents = [];

  let card_inline_style = {
      outline: "none",
      borderColor: plan.color,
      // boxShadow: `0 0 10px 3px`,
    },
    card_caption_inline_style = {
      backgroundColor: plan.color,
    };

  const descLabel = "text-18 font-200 mb-4",
    valLabel = "text-16 font-600 mb-16";
  return (
    <Card style={card_inline_style} className={classNames(pClassName)}>
      <div
        style={card_caption_inline_style}
        className={classNames("min-h-128 flex items-center pl-32 py-16")}
      >
        <div>
          <Typography className="text-white font-bold pt-16 pb-8 text-36">
            {ttt(`plans:plan-${plan.id}.name`)}
          </Typography>

          {subscription.change ? (
            false && subscription.change.downgrade ? (
              <React.Fragment>
                <Typography className="text-white" variant="h6">
                  {ttt(
                    "settings-subscription:will downgrade",
                    subscription.change
                  )}
                </Typography>
                <Typography className="text-white" variant="body">
                  {ttt(
                    "settings-subscription:will downgrade desc",
                    subscription.change
                  )}
                </Typography>
              </React.Fragment>
            ) : (
              <Typography className="text-white" variant="h6">
                {ttt("settings-subscription:will change", subscription.change)}
              </Typography>
            )
          ) : null}
        </div>
      </div>

      <CardContent className={classNames("flex")}>
        <div className="w-1/2 pr-12">
          <Typography className="text-18">{displayPrice(plan)}</Typography>
          <ExpansionPanel className={classNames(classes.learnMoreExp)}>
            <ExpansionPanelSummary
              style={{ padding: 0 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className="font-200">
                {ttt("general:Learn More")} ...
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="flex flex-col md:flex-row">
              <ul>
                {plan_contents.map((str, ind) => (
                  <li key={ind} className="my-4">
                    {str}
                  </li>
                ))}
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

        <div className="">
          <Typography className={descLabel}>
            {ttt("form-fields:subscription.payment.method")}
          </Typography>
          <Typography className={valLabel}>
            {ttt(
              `form-fields:subscription.payment.${subscription.payment_method}.method_name`
            )}
          </Typography>
          {subscription.payment_method !== PaymentMethod.none && (
            <PaymentFields
              subscription={subscription}
              plan={plan}
              company={company}
              ttt={ttt}
              descLabel={descLabel}
              valLabel={valLabel}
            />
          )}

          {/* <div className={classNames()}>
            <FormLabel className={classNames(smallForm, labelText, 'mb-8 sm:mb-0 mr-16')}>{fttt('next_billing_date')}</FormLabel>
            <FormLabel className={classNames(smallForm, labelText, 'text-black')}>{timestampToStr(subscription.next_billing_date, company.timezone)}</FormLabel>
          </div> */}
        </div>
      </CardContent>
    </Card>
  );
};

let myComp = withStyles(styles, { withTheme: true })(SubscriptionCard);
export default myComp;
