/** @module userDisplay */

import ld from "lodash";
import moment from "moment-timezone";

import { PFEDefault } from "constants.js";
import store from "app/store";
import Locales, { LOCALE_UI_DEF } from "app/data/locales.js";
import fnKeeper from "app/utils/fnKeeper";

/**
 *
 * Convert user object to the shape of PFE.
 *
 * @param {object} user User object from PBE REST API
 * @return {object} user object as in redux store.
 */
export function fromPBE(user) {
  const ret = ld.pick(user, [
    "gender",
    "salutation",
    "job_title",
    "phone_number",
    "preferences",
    "user_type",
  ]);

  if (ret.preferences === null) delete ret.preferences;

  return ret;
}

/**
 * Get Profile Picture URL of user.
 *
 * @param {object} user user object as in redux state.
 */
export function userProfilePic(user) {
  return ld.get(user, "profile_pic.small");
}

/**
 * Get User ID of current user.
 * @return {string} User ID.
 */
export function myUserID() {
  const state = store.getState();
  return state.auth.user.user_id;
}

/**
 * Get the full name of a given user or staff. The result name will
 * be formatted according to
 * <ul>
 * <li>Current display language;</li>
 * <li>Corresponding name formatting;</li>
 * </ul>
 *
 * @param {object} user User object as in redux store (auth.user or
 * company.staff)
 * @return {string} Full name to be displayed
 */
export function userFullName(user) {
  const state = store.getState(),
    { ui_language } = state.auth.user,
    jp = (lang) => `${lang}.formatter.fullname.main`,
    fn_fullname = ld.get(Locales, jp(ui_language)),
    fn_fullname_def = ld.get(Locales, jp(LOCALE_UI_DEF));
  return fn_fullname ? fn_fullname(user) : fn_fullname_def(user);
}

//
// Personalized Display
//

/**
 *
 * Display timestamp as String of Date. Format of display is based on the
 * language preference of user.
 *
 * @param {number} timestamp
 * @return {string} date.
 */
export function ts2Date(timestamp) {
  const state = store.getState(),
    ui_language = state.auth.user.ui_language,
    tz = state.company.info.timezone,
    tz_def = PFEDefault.timezone,
    timezone = tz || tz_def,
    jp = (lang) => `${lang}.formatter.date`,
    fmt_date = ld.get(Locales, jp(ui_language)),
    fmt_date_def = ld.get(Locales, jp(LOCALE_UI_DEF)),
    fmt = fmt_date || fmt_date_def;
  return moment(timestamp)
    .tz(timezone)
    .format(fmt);
}
ld.set(fnKeeper, "formatter.date", ts2Date);

/**
 * Display timestamp as string. Format of display is based on the language
 * preference of user.
 *
 * @param {number} timestamp
 * @return {string} date.
 */
const dt_formatter = (dt, now, formatter) => {
  const { years: y1, months: m1, date: d1 } = dt.toObject();
  const { years: y2 } = now.toObject();
  const s = ~~(now.diff(dt) / 1000);
  if (s < 0) return formatter.year(y1, m1 + 1, d1);
  if (s < 60) return formatter.seconds(s);
  const mins = ~~(s / 60);
  if (mins < 60) return formatter.minutes(mins);
  const hrs = ~~(s / 60 / 60);
  if (hrs < 24) return formatter.hours(hrs);
  const days = ~~(s / 60 / 60 / 24);
  if (days <= 7) return formatter.days(days);
  if (y2 == y1) return formatter.month(m1 + 1, d1);
  return formatter.year(y1, m1 + 1, d1);
};
const en_months = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const dt_formatters = {
  en: {
    seconds: (s) => `Just now`,
    minutes: (mins) => `${mins} minutes ago`,
    hours: (hrs) => `${hrs} hours ago`,
    days: (days) => `${days} days ago`,
    month: (month, day) => `${en_months[month]} ${day}`,
    year: (year, month, day) => `${en_months[month]} ${day},  ${year}`,
  },
  "zh-TW": {
    seconds: (s) => `剛剛`,
    minutes: (mins) => `${mins} 分鐘前`,
    hours: (hrs) => `${hrs} 小時前`,
    days: (days) => `${days} 日前`,
    month: (month, day) => `${month} 月 ${day} 日`,
    year: (year, month, day) => `${year} 年 ${month} 月 ${day} 日`,
  },
  "zh-HK": {
    seconds: (s) => `剛剛`,
    minutes: (mins) => `${mins} 分鐘前`,
    hours: (hrs) => `${hrs} 小時前`,
    days: (days) => `${days} 日前`,
    month: (month, day) => `${month} 月 ${day} 日`,
    year: (year, month, day) => `${year} 年 ${month} 月 ${day} 日`,
  },
  "zh-CN": {
    seconds: (s) => `刚刚`,
    minutes: (mins) => `${mins} 分钟前`,
    hours: (hrs) => `${hrs} 小时前`,
    days: (days) => `${days} 日前`,
    month: (month, day) => `${month} 月 ${day} 日`,
    year: (year, month, day) => `${year} 年 ${month} 月 ${day} 日`,
  },
};

export function ts2String(timestamp) {
  if (!timestamp || timestamp === "") return "";

  const state = store.getState(),
    ui_language = state.auth.user.preferences.ui_language,
    tz = state.company.info.timezone,
    tz_def = PFEDefault.timezone,
    timezone = tz || tz_def,
    jp = (lang) => `${lang}.formatter.timestamp`,
    fmt_date = ld.get(Locales, jp(ui_language)),
    fmt_date_def = ld.get(Locales, jp(LOCALE_UI_DEF)),
    fmt = fmt_date || fmt_date_def;

  if (!isNaN(timestamp) && timestamp < 1e10) timestamp *= 1000;
  return dt_formatter(
    moment(timestamp).tz(timezone),
    moment().tz(timezone),
    dt_formatters[ui_language] || dt_formatters.en
  );
  //.fromNow(); //.format(fmt);
}
ld.set(fnKeeper, "formatter.timestamp", ts2String);

/**
 * Get the full name of current user.
 *
 * @return {string} Full name to be displayed
 */
export function fullname() {
  const state = store.getState();
  return userFullName(state.auth.user);
}
