import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, CircularProgress, withStyles } from "@material-ui/core";
import classNames from "classnames";

import { i18nNamespaces } from "constants.js";

const styles = (theme) => ({
  progress: {
    color: "white",
  },
  curt: {
    backdropFilter: "blur(6px)",
    position: "relative",
    top: "-100%",
    height: "100%",
  },
});

const Curtain = (props) => {
  const { classes } = props,
    { t: ttt } = useTranslation();
  return (
    <div
      className={classNames(
        classes.curt,
        "curtain flex items-center justify-center"
      )}
    >
      <CircularProgress classes={{ colorPrimary: classes.progress }} />
      <Typography variant="h4" className="text-white px-24">
        {ttt("general:Loading")} ...
      </Typography>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Curtain);
