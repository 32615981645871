import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Debug from "debug";
// import * as imupdate from 'object-path-immutable';
import { PayPalButton } from "react-paypal-button-v2";

import { i18nNamespaces, PayPal, PriceModel } from "constants.js";
// import Platforms from 'app/data/platforms';
import PlanCard from "./PlanCard";

const debug = Debug("pfe:settings:subscription:ChoosePlanDialog");

// const PaymentMethod = ld.get(SubsAndPlans, 'payment.method')

const styles = (theme) => ({
  unsetWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "unset !important",
    },
  },
});

const { client_id: PAYPAL_CLIENT_ID } = PayPal;

const ConfirmDialog = (props) => {
  const { plan } = props,
    { onGoPrevious, onCancel, onComplete, onPaypayError } = props,
    { t: ttt } = useTranslation();
  // [choice, setChoice] = useState(null)
  // const onCardClicked = code => {
  //   console.log('choose', code);
  //   setChoice(code);
  // }

  debug("plan", plan);

  // Price Model
  let creation = {},
    completion = {},
    no_payment = true;
  switch (plan.price_model) {
    case PriceModel.one_off: {
      no_payment = false;
      creation.createOrder = async (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: plan.currency,
                value: plan.price,
              },
            },
          ],
        });
      };
      completion.onSuccess = onComplete;
      break;
    }

    case PriceModel.recurrent: {
      no_payment = false;
      creation.createSubscription = async (data, actions) => {
        const { plan_id } = plan.paypal;
        debug("PPPP", plan_id);
        return await actions.subscription.create({
          plan_id,
          // plan_id: "P-1C598694RR5917132LXTWQLA"
        });
      };
      completion.onApprove = async (data, actions) => {
        const details = await actions.subscription.get();
        return onComplete(details, data);
      };
      break;
    }

    default:
      break;

    // } else if (false) {
    //   // NOTES: one-off payment
    //     no_payment = false;
    //     creation.createOrder = async(data, actions) => {
    //       return actions.order.create({
    //         purchase_units: [{
    //           amount: {
    //             currency_code: plan.currency,
    //             value: plan.price
    //           }
    //         }]
    //       });
    //     }
    //     completion.onSuccess = onComplete;
  }

  // Payment Method and Confirm Button
  let confirm_button;
  if (no_payment) {
    confirm_button = (
      <Button
        className="min-w-128"
        aria-label="OK"
        // color="primary"
        onClick={() => onComplete()}
        variant="contained"
      >
        {ttt("general:button.ok")}
      </Button>
    );
  } else {
    confirm_button = (
      <PayPalButton
        options={{
          clientId: PAYPAL_CLIENT_ID,
          vault: true,
        }}
        style={{
          size: "small",
          label: "checkout",
          color: "blue",
          tagline: false,
          // layout: 'horizontal',
        }}
        {...creation}
        {...completion}
        onError={onPaypayError}
      />
    );
  }

  // Main Component
  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      maxWidth="sm"
      open={true}
      onClose={onCancel}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
    >
      <DialogTitle>
        {ttt("settings-subscription:page header.title")}
      </DialogTitle>

      <DialogContent>
        {/* <Typography
            color="textSecondary"
            className="pb-24"
        >
          {ttt('settings-subscription:confirm dialog.desc')}
        </Typography> */}
        <Typography color="textPrimary" className="pb-24">
          {ttt(
            no_payment
              ? "settings-subscription:confirm dialog.action desc - no payment"
              : "settings-subscription:confirm dialog.action desc - paypal"
          )}
        </Typography>
        <div
          className={classNames(
            "flex overflow-y-auto py-16 items-center justify-around"
          )}
        >
          <PlanCard
            key={plan.id}
            plan={plan}
            chosen={true}
            showDescription={false}
            className="h-full rounded-8 mx-16 min-w-1/3 max-w-224"
          />
          {confirm_button}
        </div>
      </DialogContent>

      <DialogActions>
        <Button aria-label="CANCEL" color="primary" onClick={onCancel}>
          {ttt("general:button.cancel")}
        </Button>

        <Button
          aria-label="CANCEL"
          color="primary"
          onClick={onGoPrevious.bind(null, plan)}
        >
          {ttt("settings-subscription:confirm dialog.button return to choose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

let myComp = withStyles(styles, { withTheme: true })(ConfirmDialog);
export default myComp;
