/** @module */
import React from "react";

import _ from "@lodash";
import qs from "qs";

import { withRouter } from "react-router-dom";
import classNames from "classnames";

import {
  withStyles,
  Icon,
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import { AgGridReact, Events } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import withEnhancedRouter from "app/parami-layouts/withEnhancedRouter";

const styles = {
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  typography: {
    fontFamily:
      "Comfortaa, cwTeXYen, Muli, Roboto, Helvetica Neue, Arial, sans-serif ",
  },
};

class AgGrid_ extends React.Component {
  constructor(props) {
    super(props);
    const { location, do_not_use_query } = props;
    let queries = do_not_use_query
      ? {}
      : qs.parse(location.search, { ignoreQueryPrefix: true });
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
    this.state = {
      data: props.data,
      order: queries.order || "asc",
      orderBy: queries.orderBy || "id",

      rowsPerPage: parseInt(queries.rowsPerPage) || -1,
      page: parseInt(queries.page) || 1,
      viewItemStart: 1,
      viewItemEnd: 1,
    };
  }

  getOptionsFromQuery(location) {
    if (this.props.do_not_use_query) return this.state;
    let queries = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (!queries.order) queries.order = this.props.order || "asc";
    if (!queries.orderBy) queries.orderBy = this.props.orderBy || "id";
    queries.page = parseInt(queries.page) || 1;
    queries.rowsPerPage = parseInt(queries.rowsPerPage) || -1;

    return queries;
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, location, orderBy, order, do_not_use_query } = this.props;
    let queries = do_not_use_query
      ? this.state
      : qs.parse(location.search, { ignoreQueryPrefix: true });

    let new_page = queries.page && parseInt(queries.page);
    let new_rowsPerPage = queries.rowsPerPage && parseInt(queries.rowsPerPage);

    if (!_.isEqual(this.props.data, prevProps.data)) {
      this.setState({ data: this.props.data });
    }
    if (new_page && new_page !== prevState.page)
      this.updateState({ page: new_page, no_update_location: true });

    if (new_rowsPerPage && new_rowsPerPage !== prevState.rowsPerPage)
      this.updateState({
        rowsPerPage: new_rowsPerPage,
        no_update_location: true,
      });

    if (
      this.gridApi &&
      this.state.rowsPerPage == -1 &&
      this.state.rowsPerPage !== prevState.rowsPerPage
    ) {
      this.gridApi.dispatchEvent({ type: "bodyHeightChanged" });
    }
  }

  async componentDidMount() {
    const {
      t: ttt,
      location,
      resetApp,
      fetchIntentions,
      do_not_use_query,
    } = this.props;
    this.setState(this.getOptionsFromQuery(location));
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.props.gridApiRef) this.props.gridApiRef.current = params.api;
    if (this.props.gridColumnApi)
      this.props.gridColumnApi.current = params.columnApi;
    const { page } = this.state;
    this.gridApi.paginationGoToPage(page - 1);
  }

  nextPage() {
    this.updateState({ page: this.state.page + 1 });
  }

  prevPage() {
    this.updateState({ page: this.state.page - 1 });
  }

  lastPage() {
    if (this.gridApi.paginationGetTotalPages() !== 0)
      this.updateState({ page: this.gridApi.paginationGetTotalPages() });
  }

  firstPage() {
    this.updateState({ page: 1 });
  }

  updateState({ page, rowsPerPage, no_update_location }) {
    if (this.gridApi) {
      const {
        history,
        location,
        orderBy,
        order,
        do_not_use_query,
      } = this.props;
      const num_pages = this.gridApi.paginationGetTotalPages();
      let queries = qs.parse(location.search, { ignoreQueryPrefix: true });

      const updates = {};

      if (page > num_pages) page = num_pages;

      if (page < 1) page = 1;

      if (page !== undefined && page != this.state.page) {
        updates.page = page;
        this.gridApi.paginationGoToPage(page - 1);
      }
      if (rowsPerPage !== undefined && rowsPerPage != this.state.rowsPerPage) {
        if (rowsPerPage > 0) this.gridApi.paginationSetPageSize(rowsPerPage);
        updates.rowsPerPage = rowsPerPage;
      }

      if (Object.keys(updates).length > 0) {
        if (!no_update_location && !do_not_use_query) {
          let new_queries = _.cloneDeep(queries);
          new_queries.order = order;
          new_queries.orderBy = orderBy;
          new_queries.page = updates.page || this.state.page;
          new_queries.rowsPerPage =
            updates.rowsPerPage || this.state.rowsPerPage;

          this.props.goTo({
            pathname: location.pathname,
            search: qs.stringify(new_queries),
            state: { from: location.pathname },
          });
        }
        this.setState(updates);
      }
    }
  }

  onPageSizeChanged(event) {
    if (this.gridApi) {
      const value = parseInt(event.target.value);
      this.updateState({ rowsPerPage: value });
    }
  }

  onPaginationChanged() {
    if (this.gridApi) {
      let current_page = this.gridApi.paginationGetCurrentPage();
      if (current_page != this.state.page) {
        this.gridApi.paginationGoToPage(this.state.page - 1);
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      columnDefs,
      defaultColDef,
      frameworkComponents,
      data,
      onRowClicked,
    } = this.props;
    const num_pages = this.gridApi ? this.gridApi.paginationGetTotalPages() : 1;
    const { page } = this.state;
    const rowsPerPage = this.gridApi ? this.gridApi.paginationGetPageSize() : 1;

    let maxItemNum = this.props.data.length;

    let viewItemStart = (page - 1) * rowsPerPage;
    let viewItemEnd = viewItemStart + rowsPerPage;

    if (viewItemStart > maxItemNum) viewItemStart = maxItemNum;

    if (viewItemEnd > maxItemNum) viewItemEnd = maxItemNum;

    viewItemStart = viewItemStart + 1;

    //handle exceptional case if the page is not exist
    if (this.gridApi && (page > num_pages || page <= 0)) this.lastPage();

    return (
      <div className={classNames("ag-theme-material", classes.root)}>
        <AgGridReact
          className={classes.typography}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          frameworkComponents={frameworkComponents}
          onGridReady={this.onGridReady.bind(this)}
          onPaginationChanged={this.onPaginationChanged.bind(this)}
          rowData={data}
          headerHeight={32}
          paginationPageSize={this.state.rowsPerPage == -1 ? -1 : 10}
          paginationAutoPageSize={this.state.rowsPerPage == -1}
          pagination={true}
          suppressCellSelection={true}
          onRowClicked={onRowClicked}
          suppressPaginationPanel={true}
          suppressScrollOnNewData={true}
          rowStyle={{ borderWidth: "0 0 1px 0" }}
        ></AgGridReact>

        <div className={classNames(classes.typography)}>
          <div className="h-48 flex justify-end text-xs">
            <span className="self-center">Rows per page:</span>

            <Select
              className="mx-16"
              style={{ fontSize: 13 }}
              value={this.state.rowsPerPage}
              onChange={this.onPageSizeChanged.bind(this)}
              onFocus={(e) => e.stopPropagation()}
              MenuProps={{ keepMounted: true, disablePortal: true }}
            >
              <MenuItem key="-1" value="-1">
                Auto
              </MenuItem>
              <MenuItem key="0" value="10">
                10
              </MenuItem>
              <MenuItem key="1" value="25">
                25
              </MenuItem>
              <MenuItem key="2" value="50">
                50
              </MenuItem>
              <MenuItem key="3" value="100">
                100
              </MenuItem>
            </Select>
            <IconButton
              aria-label="Go to first page"
              className="p-4 self-center"
              disabled={page == 1}
              onClick={this.firstPage}
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon fontSize="inherit">first_page</Icon>
            </IconButton>
            <IconButton
              aria-label="Go to previous page"
              className="p-4 self-center"
              disabled={page == 1}
              onClick={this.prevPage}
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon fontSize="inherit">navigate_before</Icon>
            </IconButton>
            {this.props.data.length > 0 ? (
              <>
                <span className="ml-8 mr-4 self-center">{viewItemStart}</span>
                <span className="self-center">−</span>
                <span className="ml-4 mr-8 self-center">{viewItemEnd}</span>
                <span className="self-center">/</span>
                <span className="mx-8 self-center">
                  {this.props.data.length}
                </span>
              </>
            ) : null}

            <IconButton
              aria-label="Go to next page"
              className="p-4 self-center"
              disabled={page == num_pages}
              onClick={this.nextPage}
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon fontSize="inherit">navigate_next</Icon>
            </IconButton>
            <IconButton
              aria-label="Go to last page"
              className="p-4 self-center"
              disabled={page == num_pages}
              onClick={this.lastPage}
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon fontSize="inherit">last_page</Icon>
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}
let AgGrid = withStyles((theme) => styles, { withTheme: true })(AgGrid_);

AgGrid = withEnhancedRouter(AgGrid);

export default AgGrid;
