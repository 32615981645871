import Debug from "debug";

import { GeneralApi, UsersApi, RegisterUtilsApi } from "app/client";
import { auth0Service } from "app/services/auth0Service";
import ParamiError from "app/utils/ParamiError";
import { fromPBE } from "app/utils/entities/user";

import { REST } from "app/client_proxied";

const debug = Debug("pfe:service:authPBE");

/**
 * @since v1, PRMF-25
 * @param {object} userOfPBE User state from react/redux store
 * @return {object} An object with the following: pfe_instance_id;
 */
export async function addUser(userOfPBE) {
  try {
    // console.log('addUser started...');

    // Login in to PBE RESTFul API Server

    const { pfe_instance_id } = (await REST.UsersApi.usersPost(userOfPBE)).data;
    // console.log('REST login OK', pfe_instance_id);
    return { pfe_instance_id };
    /*
    const access_token = auth0Service.getAccessToken();
    if (!access_token) 
      return [new Error('Access Token not available in PFE')];

    const api = new UsersApi({
      accessToken: access_token,
    });
    const { pfe_instance_id } = (await api.usersPost(userOfPBE)).data;
    
    return { pfe_instance_id };
    */
  } catch (err) {
    console.log("login Failed.", err);
    throw err;
  }
}

/**
 * Login to PBE REST and get the user details other than those
 * in auth0 token from PBE.
 *
 * @since v1, PRMF-22
 * @param {object} user User state from react/redux store
 * @return {object} user object as in redux store (auth.user);
 */
export async function loginREST(user) {
  await REST.GeneralApi.loginGet();

  const user_raw = (await REST.UsersApi.usersUserIdGet(user.user_id)).data,
    user_for_pfe = fromPBE(user_raw);

  return user_for_pfe;
  /*
  const access_token = auth0Service.getAccessToken();
  if (!access_token) 
    return [new Error('Access Token not available in PFE')];

  const api = new GeneralApi({
    accessToken: access_token,
  }), uapi = new UsersApi({
    accessToken: access_token,
  });
  console.log('login PBE REST started...',  user.id, user);
      
  // Login in to PBE RESTFul API Server
  await api.loginGet();
  
  // Get User Info. Staff need this while owner cannot get before
  // company is created.
  const user_raw = (await uapi.usersUserIdGet(user.user_id)).data,
        user_for_pfe = fromPBE(user_raw)
        ;

  return user_for_pfe;

  */
}

/**
 * @sicne v1, PRMF-23
 * @return {ParamiError} The list of errors encountered during the process. The
 * original error was loaded at field "payload";
 */
export async function logout() {
  let all_err = [];
  debug("logout started...");

  // TODO: Close all connection to MsgBroker

  // Logout from PBE RESTFul API Server

  try {
    await REST.GeneralApi.logoutGet();
    /*
    const access_token = auth0Service.getAccessToken();
    const api = new GeneralApi({
      accessToken: access_token,
    });

    await api.logoutGet();
    debug('REST logout OK');    
    */
  } catch (err) {
    all_err.push(err);
  }

  if (all_err.length > 0) throw new ParamiError("Error during logout", all_err);
}

export async function resendVerify() {
  await REST.RegisterUtilsApi.resendVerifyPost();
  /*
  const access_token = auth0Service.getAccessToken(),
          api = new RegisterUtilsApi({
            accessToken: access_token,
          })
          ;
  
  const raw = (await api.resendVerifyPost()).data;      
  debug('resendVerify - norm', raw);
  */
}

//
// Routines
//
