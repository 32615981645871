import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Tooltip,
  ListItem,
  OutlinedInput,
  Input,
  InputAdornment,
  IconButton,
  Icon,
  List,
} from "@material-ui/core";
import classNames from "classnames";
import _ from "@lodash";
import { lightGreen, red } from "@material-ui/core/colors";

class LabelModel {
  id;
  name;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.is_negative = data.is_negative || false;
  }
}

class LabelsList extends Component {
  state = {
    labels: this.props.labels,
    newLabelText: "",
    focusedLabel: null,
    disabled: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_.isEqual(prevProps.labels, this.props.labels) &&
      !_.isEqual(this.state.labels, this.props.labels)
    ) {
      this.setState({ labels: this.props.labels });
    }
  }

  handleLabelChange = (event, label) => {
    const updatedLabel = new LabelModel(
      _.setIn(label, event.target.name, event.target.value)
    );
    this.setState({
      labels: this.state.labels.map((item) =>
        item.id === updatedLabel.id ? updatedLabel : item
      ),
    });
  };

  toggleLabelNegative = (event, label) => {
    const updatedLabel = new LabelModel(
      _.setIn(label, label.name, label.is_negative)
    );
    updatedLabel.is_negative =
      updatedLabel.is_negative == 0 || !updatedLabel.is_negative ? true : false;

    this.setState({
      labels: this.state.labels.map((item) =>
        item.id === updatedLabel.id ? updatedLabel : item
      ),
    });
    // Update callback
    this.props.onUpdate(updatedLabel);
  };

  backupLabel = (label) => {
    // Backup value
    this.setState({ focusedLabel: _.cloneDeep(label) });
  };

  commitLabel = (label) => {
    // Update backup value
    this.backupLabel(label);

    // Update callback
    this.props.onUpdate(label);
  };

  restoreLabel = (label) => {
    const { focusedLabel } = this.state;

    // Restore backed up value
    this.setState({
      labels: this.state.labels.map((item) =>
        item.id === focusedLabel.id ? focusedLabel : item
      ),
    });
  };

  deleteLabel = (label) => {
    this.props.onDelete(label);
  };

  handleNewLabelChange = (event) => {
    this.setState({ newLabelText: event.target.value });
  };

  handleNewLabel = (ev) => {
    ev.preventDefault();
    const newLabel = new LabelModel({ name: this.state.newLabelText });
    if (this.props.onCreate(newLabel)) {
      //clear on create success
      this.setState({ newLabelText: "" });
    }
  };

  checkEnterKey = (ev, label) => {
    if (ev.keyCode == 13 && label && label.name && label.name.trim())
      this.commitLabel(label);
  };

  render() {
    const { labels, newLabelText, focusedLabel } = this.state;
    const { t: ttt, placeholder, disabled } = this.props;

    return (
      <List dense>
        <form onSubmit={this.handleNewLabel}>
          <ListItem className="p-0 mb-16" dense>
            <Icon className="list-item-icon text-16" color="action">
              add
            </Icon>
            <Input
              className={classNames("flex flex-1 mx-8")}
              name="name"
              value={newLabelText}
              onChange={this.handleNewLabelChange}
              placeholder={placeholder}
              disabled={disabled}
            />
            <Tooltip
              title={ttt(`Detail.Examples.Save`)}
              placement="bottom"
              enterDelay={300}
            >
              <IconButton
                className="w-32 h-32 mx-4 p-0"
                aria-label="Create"
                onClick={this.handleNewLabel}
                disabled={disabled || newLabelText === ""}
              >
                <Icon fontSize="small">check</Icon>
              </IconButton>
            </Tooltip>
          </ListItem>
        </form>
        {Object.entries(labels).map(([key, label]) => (
          <ListItem className="p-0 mb-8" key={label.id} dense>
            <Icon className="list-item-icon text-16" color="action">
              label
            </Icon>
            <OutlinedInput
              className={classNames("flex flex-1 mx-8 pr-0")}
              name="name"
              value={label.name}
              onChange={(event) => this.handleLabelChange(event, label)}
              onFocus={(event) => this.backupLabel(label)}
              onBlur={(event) => this.restoreLabel(label)}
              onKeyUp={(event) => {
                if (
                  !(
                    disabled ||
                    !focusedLabel ||
                    focusedLabel.id !== label.id ||
                    focusedLabel.name === label.name
                  )
                )
                  this.checkEnterKey(event, label);
              }}
              disabled={disabled}
              labelWidth={0}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      label.is_negative
                        ? ttt(`Detail.Examples.Negative`)
                        : ttt(`Detail.Examples.Positive`)
                    }
                    placement="top"
                    enterDelay={300}
                  >
                    <IconButton
                      className="-mr-12"
                      aria-label="Submit"
                      onClick={(event) => {
                        this.toggleLabelNegative(event, label);
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {label.is_negative == 0 || !label.is_negative ? (
                        <Icon style={{ color: "#48c78e" }} fontSize="small">
                          check_circle
                        </Icon>
                      ) : (
                        <Icon style={{ color: "red" }} fontSize="small">
                          cancel
                        </Icon>
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={ttt(`Detail.Examples.Restore`)}
                    placement="bottom"
                    enterDelay={300}
                  >
                    <IconButton
                      className="-mr-12"
                      aria-label="Reset"
                      onClick={(event) => this.restoreLabel(label)}
                      disabled={
                        disabled ||
                        !focusedLabel ||
                        focusedLabel.id !== label.id ||
                        focusedLabel.name === label.name
                      }
                      onMouseDown={(e) => {
                        // prevent unwanted onBlur event on <OutlinedInput/>
                        // ref: https://stackoverflow.com/a/57630197
                        e.preventDefault();
                      }}
                    >
                      <Icon fontSize="small">restore</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={ttt(`Detail.Examples.Save`)}
                    placement="bottom"
                    enterDelay={300}
                  >
                    <IconButton
                      aria-label="Submit"
                      onClick={(event) => this.commitLabel(label)}
                      disabled={
                        disabled ||
                        !focusedLabel ||
                        focusedLabel.id !== label.id ||
                        focusedLabel.name === label.name
                      }
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <Icon fontSize="small">check</Icon>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
            />
            <Tooltip
              title={ttt(`Detail.Examples.Delete`)}
              placement="bottom"
              enterDelay={300}
            >
              <IconButton
                className="w-32 h-32 mx-4 p-0"
                aria-label="Delete"
                onClick={(e) => this.deleteLabel(label)}
                disabled={disabled}
              >
                <Icon fontSize="small">delete</Icon>
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withTranslation("settings-intention")(LabelsList);
