import React from "react";
import { AppBar, Hidden, Icon, withStyles } from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import classNames from "classnames";

import NavbarStayButton from "app/fuse-layouts/shared-components/NavbarStayButton";
import UserNavbarHeader from "app/fuse-layouts/shared-components/UserNavbarHeader";
import Logo from "app/fuse-layouts/shared-components/Logo";
// import NavbarFoldedToggleButton from 'app/fuse-layouts/shared-components/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import Navigation from "app/fuse-layouts/shared-components/Navigation";

import LanguageMenu from "app/fuse-layouts/shared-components/LanguageMenu";
import { connect } from "react-redux";

const styles = (theme) => ({
  content: {
    overflowX: "visible",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 40px, 100% 10px",
    backgroundAttachment: "local, scroll",
  },
  bgWhite: {
    background: "#fafafa",
  },
  barHover: {
    "&:hover button.inFolded": {
      color: "#9067b9 !important",
    },
    "& button.inFolded": {
      marginLeft: "-24px",
    },
  },
});

const NavbarLayout4 = ({ classes, navigation, className, folded }) => {
  return (
    <div
      className={classNames(
        "flex flex-col h-full",
        classes.barHover,
        className
      )}
    >
      <AppBar
        color="default"
        position="static"
        elevation={0}
        className={classNames(
          "flex flex-row items-center flex-shrink h-64 min-h-64 pr-12",
          folded ? "pl-12" : "pl-20",
          classes.bgWhite
        )}
      >
        <div className="flex flex-1 pr-8">
          <Logo hideText={false} />
        </div>

        <Hidden xsDown>
          <NavbarStayButton className="w-30 h-30 p-0 ml-4" />
        </Hidden>

        <Hidden xsUp>
          <NavbarMobileToggleButton className="w-40 h-40 p-0">
            <Icon>arrow_back</Icon>
          </NavbarMobileToggleButton>
        </Hidden>
      </AppBar>

      <UserNavbarHeader />
      <FuseScrollbars
        className={classNames("overflow-hidden", classes.content)}
      >
        <Navigation layout="vertical" />
      </FuseScrollbars>
    </div>
  );
};
function mapStateToProps({ fuse }) {
  return {
    folded: fuse.settings.current.layout.config.navbar.folded,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(NavbarLayout4)
);
