import React, { Component } from "react";
import {
  Snackbar,
  IconButton,
  withStyles,
  Icon,
  SnackbarContent,
} from "@material-ui/core";
import { green, amber, blue, grey } from "@material-ui/core/colors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import * as Actions from "app/store/actions";

const styles = (theme) => ({
  root: {
    width: "fit-content",
    margin: "24px auto",
    backgroundColor: "transparent",
    left: "50%",
    right: "auto",
    transform: "translateX(-50%)",
    "& > *": {
      borderRadius: "1rem",
    },
  },
  success: {
    backgroundColor: green[600],
    color: "#ffffff",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: blue[600],
    color: "#ffffff",
  },
  warning: {
    backgroundColor: amber[600],
    color: "#ffffff",
  },
  forModal: {
    backgroundColor: grey[300],
    color: theme.palette.getContrastText(grey[300]),
  },
  default: {
    backgroundColor: "#ffffff",
    color: theme.palette.getContrastText(grey[300]),
  },
});

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};

class FuseMessage extends Component {
  static defaultProps = {
    theme: "secondary",
    label: "Button Text",
  };
  render() {
    const { classes, options } = this.props;
    return (
      <Snackbar
        id="snack-bar"
        {...options}
        open={this.props.state}
        onClose={this.props.hideMessage}
        classes={{
          root: classes.root,
        }}
        ContentProps={{
          variant: "body2",
          headlineMapping: {
            body1: "div",
            body2: "div",
          },
        }}
      >
        <SnackbarContent
          id="snack-bar-content"
          className={classNames(classes[options.variant])}
          message={
            <div className="flex items-center">
              {variantIcon[options.variant] && (
                <Icon className="mr-8" color="inherit">
                  {variantIcon[options.variant]}
                </Icon>
              )}
              {options.message}
            </div>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="p-0"
              onClick={this.props.hideMessage}
            >
              <Icon>close</Icon>
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideMessage: Actions.hideMessage,
    },
    dispatch
  );
}

function mapStateToProps({ fuse }) {
  return {
    state: fuse.message.state,
    options: fuse.message.options,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FuseMessage)
);
