/** @module */
import React from "react";
import { withStyles, Icon, IconButton } from "@material-ui/core";

const styles = {
  sectionHeader: {
    padding: "2px 4px",
    margin: "4px",
    marginTop: "1.2rem",
    marginBottom: "1.2rem",
    marginLeft: 0,
    display: "block",
    fontSize: "1.2rem",
    borderBottom: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    fontWeight: "800",
    userSelect: "none",
    width: "fit-content",
  },
};

let SectionTitle = (props) => {
  return <span className={props.classes.sectionHeader}>{props.children}</span>;
};

SectionTitle = withStyles((theme) => styles, { withTheme: true })(SectionTitle);

export default SectionTitle;
