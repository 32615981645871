import history from "history.js";
import * as Actions from "app/store/actions";
import { setDefaultSettings, setInitialSettings } from "app/store/actions/fuse";

import _ from "@lodash";
import i18n from "i18n.js";

import { Roles } from "constants.js";
import store from "app/store";
import firebase from "firebase/app";
import firebaseService from "app/services/firebaseService";
import { auth0Service } from "app/services/auth0Service";
import {
  loginREST,
  logout as logoutPBE,
  isOwner,
} from "app/services/paramiService";
import { LOGIN_ERROR, LOGIN_SUCCESS } from "./login.actions";
import { getCompany } from "app/store/actions/company/info.actions";
import { getConvTypes, getFolders } from "app/store/actions/company";

// import { REGISTER_SUCCESS, REGISTER_ERROR } from './register.actions';
import jwtService from "app/services/jwtService";
import { asyncThunk } from "app/utils/common";
import { REST } from "app/client_proxied";

import { get as ld_get, isNil as ld_is_nil } from "lodash";
import Debug from "debug";

export const SET_USER_DATA = "[USER] SET DATA";
// export const UPDATE_USER = '[USER] UPDATE';
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const EMAIL_NOT_VERIFIED = "[USER] EMAIL NOT VERIFIED";

const debug = Debug("pfe:auth:actions:user");

const ttt = i18n.t.bind(i18n);

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData) {
  let user = {
    role: ld_get(tokenData, "app_metadata.roles.0", Roles.guest),
    from: "auth0",
    data: {
      displayName: tokenData.name,
      photoURL: tokenData.picture,
      email: tokenData.email || null,
      // settings    : (tokenData.user_metadata && tokenData.user_metadata.settings) ? tokenData.user_metadata.settings : {},
      shortcuts:
        tokenData.user_metadata && tokenData.user_metadata.shortcuts
          ? tokenData.user_metadata.shortcuts
          : [],
    },
    // parami specific
    user_id: tokenData.user_id,
    username: tokenData.username,
    current_company_id: ld_get(tokenData, "app_metadata.current_company_id"),
    last_name: tokenData.given_name,
    first_name: tokenData.family_name,
    email_verified: tokenData.email_verified,
  };

  return setUserData(user);
}

export function PFELogin(options = {}) {
  const { location } = options || {},
    redir = _.get(location, "state.redirectLocation");
  let user = store.getState().auth.user,
    is_owner = isOwner(user),
    jump_to = redir ? redir : null;
  // Need email verification only for owner. Staff does not has email.
  if (is_owner && !user.email_verified) {
    history.push({ pathname: "/error/email-not-verified" });
    return {
      type: EMAIL_NOT_VERIFIED,
      payload: { from: "auth0" },
    };
  }

  // Check if company created. If not, redirect user to create one.
  if (ld_is_nil(user.current_company_id)) {
    if (is_owner) {
      history.push({ pathname: "/create-company" });
      return (dispatch) => {};
    } else {
      return logoutUser({ UIFlow: false });
    }
  }

  return asyncThunk(async (dispatch) => {
    let act_to_disp, msg_to_disp;

    try {
      await dispatch(LoginToPBE());

      // NOTES: jump_to is not set here so to allow direct
      // access to page after the login proccess.

      msg_to_disp = "general:login.ok";
      act_to_disp = {
        type: LOGIN_SUCCESS,
      };
    } catch (err) {
      debug("Error", err.stack);

      // Notes: Remove all tokens. If not, some endpoints within the
      // wall may be reachable.
      dispatch(logoutUser({ UIFlow: false }));

      jump_to = { pathname: "/login" };
      msg_to_disp = "general:login.failed";
      act_to_disp = {
        type: LOGIN_ERROR,
        payload: err,
      };
    } finally {
      if (jump_to) history.replace(jump_to);

      dispatch(act_to_disp);

      dispatch(Actions.showMessage({ message: ttt(msg_to_disp) }));
    }
  });
}

export function LoginToPBE() {
  let user_in_store = store.getState().auth.user;

  return asyncThunk(async (dispatch, getState) => {
    // Login to PBE REST
    try {
      const user_for_pfe = await loginREST(user_in_store);
      debug("LoginToPBE", user_for_pfe);
      dispatch(setUserData(user_for_pfe));
    } catch (err) {}

    user_in_store = getState().auth.user;

    // Get Company and related info after login.
    dispatch(getCompany());
    dispatch(Actions.getAsangaPlans());
    dispatch(getConvTypes());
    dispatch(getFolders());
    dispatch(Actions.getAllStaff());
    dispatch(Actions.getAllPlfaccs());
    dispatch(Actions.getFlowTemplates());
    dispatch(Actions.getGlobals());
  });
}

// /**
//  *
//  * @param {*} user  User as shape in state.
//  * @param {*} company Company Info as shape in state.
//  */
// export function createUser(user, company, password, pOptions)
// {
//     const options = {
//         servant: false,
//         message: {
//             success: sttt("create success"),
//             failed: sttt("create failed"),
//         },
//         ...pOptions
//     }
//     const user_for_pbe = {
//         company_id: company.company_id,
//         password,
//         preferences: {
//             ui_language: user.ui_language,
//         },
//         roles: [
//             user.role
//         ],
//         ...ld_pick(user, [
//             'username', 'last_name', 'first_name',
//             'job_title', 'phone_number', 'email_address',
//             'ui_language'
//         ])
//     };

//   return asyncThunk(async dispatch => {
//     let msg = { message: options.message.success };

//     try {
//         const access_token = auth0Service.getAccessToken();
//         const uapi = new UsersApi({
//             accessToken: access_token,
//         });
//         let user_returned = (await uapi.usersPost(user_for_pbe)).data;
//         user_returned = {
//             ...user_for_pbe, ...user_returned
//         }
//         dispatch({
//            type   : UPDATE_USER, user_returned
//         });

//     } catch (err) {
//         msg = { message: options.message.failed };
//         if (options.servant)
//             throw err;

//     } finally {
//         if (!options.servant)
//             dispatch(Actions.showMessage(msg));
//     }
//   });
// }

export function updateUser(user) {
  return asyncThunk(async (dispatch) => {
    const uid = user.user_id;
    delete user.user_id;
    const retRaw = (await REST.UsersApi.usersUserIdPatch(uid, user)).data;
    debug("retRaw", retRaw);
    return dispatch(setUserData(user));

    /*
    const access_token = auth0Service.getAccessToken()
          ;
    const api = new UsersApi({
      accessToken: access_token,
    });
    const uid = user.user_id;
    delete user.user_id;
    const ret = (await api.usersUserIdPatch(uid, user)).data;
    debug('updateUser: ret', ret);

    return dispatch(setUserData(user));
    */

    // dispatch({
    //     type: UPDATE_USER, user
    // });
  });
}

/**
 * Set user data from Firebase data
 */
export function setUserDataFirebase(user, authUser) {
  if (
    user &&
    user.data &&
    user.data.settings &&
    user.data.settings.theme &&
    user.data.settings.layout &&
    user.data.settings.layout.style
  ) {
    // Set user data but do not update
    return setUserData(user);
  } else {
    // Create missing user settings
    return createUserSettingsFirebase(authUser);
  }
}

/**
 * Create User Settings with Firebase data
 */
export function createUserSettingsFirebase(authUser) {
  return (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const fuseDefaultSettings = getState().fuse.settings.defaults;
    const currentUser = firebase.auth().currentUser;

    /**
     * Merge with current Settings
     */
    const user = _.merge({}, guestUser, {
      uid: authUser.uid,
      from: "firebase",
      role: "admin",
      data: {
        displayName: authUser.displayName,
        email: authUser.email,
        settings: { ...fuseDefaultSettings },
      },
    });
    currentUser.updateProfile(user.data);

    updateUserData(user);
    return dispatch(setUserData(user));
  };
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return (dispatch, getState) => {
    let user_orig = getState().auth.user,
      jp_lang = "preferences.ui_language";
    /*
            Set User Settings
         */
    if (user.data) dispatch(setDefaultSettings(user.data.settings));

    // Change Language
    const lang_new = _.get(user, jp_lang),
      lang_orig = _.get(user_orig, jp_lang);
    if (lang_new && lang_new !== lang_orig) i18n.changeLanguage(lang_new);

    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

export function setLanguage(value) {
  debug("setLanguage", value);
  return (dispatch) =>
    dispatch(
      setUserData({
        preferences: { ui_language: value },
      })
    );
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    updateUserData(user);

    return dispatch(setUserData(user));
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    updateUserData(newUser);

    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser(opts = {}) {
  const { UIFlow = true, message = ttt("general:login.logged_out") } = opts;

  return (dispatch, getState) => {
    const user = getState().auth.user;

    // PRMF-23
    // Notes: Logout should proceed regardless the error from PBE.
    return logoutPBE()
      .catch((errors) => {
        debug("err", errors.payload);
      })
      .finally(() => {
        switch (user.from) {
          case "firebase": {
            firebaseService.signOut();
            break;
          }
          case "auth0": {
            auth0Service.logout();
            break;
          }
          default: {
            jwtService.logout();
          }
        }

        dispatch(setInitialSettings());

        if (UIFlow) {
          dispatch(Actions.showMessage({ message }));

          dispatch({
            type: USER_LOGGED_OUT,
          });

          history.push({
            pathname: "/login",
            //   search: '?fake=dish',
          });
        }
      });
  };
}

/**
 * Update User Data
 */
function updateUserData(user) {
  if (user.role === "guest") {
    return;
  }

  switch (user.from) {
    case "firebase": {
      firebaseService
        .updateUserData(user)
        .then(() => {
          store.dispatch(
            Actions.showMessage({ message: "User data saved to firebase" })
          );
        })
        .catch((error) => {
          store.dispatch(Actions.showMessage({ message: error.message }));
        });
      break;
    }
    case "auth0": {
      auth0Service
        .updateUserData({
          settings: user.data.settings,
          shortcuts: user.data.shortcuts,
        })
        .then(() => {
          store.dispatch(
            Actions.showMessage({ message: "User data saved to auth0" })
          );
        })
        .catch((error) => {
          store.dispatch(Actions.showMessage({ message: error.message }));
        });
      break;
    }
    default: {
      jwtService
        .updateUserData(user)
        .then(() => {
          store.dispatch(
            Actions.showMessage({ message: "User data saved with api" })
          );
        })
        .catch((error) => {
          store.dispatch(Actions.showMessage({ message: error.message }));
        });
      break;
    }
  }
}
