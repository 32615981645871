import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ld from "lodash";
import Debug from "debug";
import Masonry from "react-masonry-css";
// import * as imupdate from 'object-path-immutable';

import { i18nNamespaces, PARAMI_PLAN_NONE } from "constants.js";
// import Platforms from 'app/data/platforms';
import PlanCard from "./PlanCard";

const debug = Debug("pfe:settings:subscription:ChoosePlanDialog");

const styles = (theme) => ({
  unsetWidth: {
    [theme.breakpoints.down("xs")]: {},
  },
});

function allowOKButton(choice, currentPlan) {
  if (!choice) return false;

  if (currentPlan) {
    // Allow only when choice is different from current plan
    return choice.id !== currentPlan.id;
  }
  // Default to allow OK button when choice is made
  return true;
}

const ChoosePlanDialog = (props) => {
  const { classes, theme, plans, currentPlan, wantedPlan } = props,
    { onCancel, onNext } = props,
    { t: ttt } = useTranslation(),
    [choice, setChoice] = useState(wantedPlan || currentPlan);
  const onCardClicked = (pl) => {
    setChoice(pl);
  };
  debug("choice", choice);

  debug("choice", choice, currentPlan);

  return (
    <Dialog
      classes={{
        paper: "m-24",
      }}
      maxWidth="lg"
      open={true}
      onClose={onCancel}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
    >
      <DialogTitle>
        {ttt("settings-subscription:choose plan dialog.title")}
      </DialogTitle>

      <DialogContent>
        <Typography color="textSecondary" className="pb-24">
          {ttt("settings-subscription:choose plan dialog.desc")}
        </Typography>
        <Typography color="textPrimary" className="pb-24">
          {ttt("settings-subscription:choose plan dialog.action desc")}
        </Typography>
        <div
          className={classNames(
            "h-256 md:h-auto overflow-y-auto pl-12 pr-24 sm:px-8"
          )}
        >
          <Masonry
            breakpointCols={{
              default: plans.length,
              [theme.breakpoints.values.xs]: 1,
            }}
            className="my-masonry-grid flex justify-center"
            columnClassName={classNames(
              classes.unsetWidth,
              "my-masonry-grid_column flex flex-col p-8 items-stretch"
            )}
          >
            {
              // Notes: Lodash map used - since mapping from map to array.
              ld
                .chain(plans)
                .filter((plan) => plan.id !== PARAMI_PLAN_NONE)
                .map((plan) => (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    chosen={choice && plan.id === choice.id}
                    className="h-full flex-1 sm:min-w-160 max-w-320 w-full rounded-8 mb-16 mx-8"
                    onClick={onCardClicked.bind(null, plan)}
                  />
                ))
                .value()
            }
          </Masonry>
        </div>
      </DialogContent>
      <DialogActions>
        <Button aria-label="CANCEL" color="primary" onClick={onCancel}>
          {ttt("general:button.cancel")}
        </Button>

        <Button
          type="submit"
          aria-label="SAVE"
          disabled={!allowOKButton(choice, currentPlan)}
          onClick={onNext.bind(null, choice)}
          color="primary"
        >
          {ttt("general:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

let myComp = withStyles(styles, { withTheme: true })(ChoosePlanDialog);
export default myComp;
