import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core";

let LoadingOverlay = (props) => {
  const { onClose, loading, classes } = props;

  return (
    <Modal
      onClose={onClose}
      className={classes.overlay}
      open={loading}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
        style: {
          background: "rgba(255,255,255, 0.5)",
        },
      }}
    >
      <Fade in={loading}>
        <CircularProgress color="primary" />
      </Fade>
    </Modal>
  );
};

LoadingOverlay = withStyles(
  {
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  { withTheme: true }
)(LoadingOverlay);

export default LoadingOverlay;
