import ld from "lodash";
import moment from "moment-timezone";
import uniqID from "uniqid";

import { last, genTS, split_by } from "app/utils/common";
import { gen_msgs_by_distribution } from "./message_gen";

const toTS = (str) => moment(str).valueOf();

const convs = {
  "parami chatroom default": {
    _id: "parami chatroom default",
    me: "social_page_yyy001",
    conv_code: "default",
    messages: [],
  },
  "2725a680b8d240c011dd2243": {
    _id: "2725a680b8d240c011dd2243",
    me: "social_page_yyy002",
    messages: [
      {
        id: uniqID(),
        sender: "5725a680606588342058356d",
        body: "Quickly come to the meeting room 1B, we have a big server issue",
        datetime: toTS("2017-04-22T01:00:00.299Z"),
        read: 0,
      },
      {
        id: uniqID(),
        sender: "5725a6802d10e277a0f35724",
        body: "I’m having breakfast right now, can’t you wait for 10 minutes?",
        datetime: toTS("2017-04-22T01:05:00.299Z"),
        read: 0,
      },
      {
        id: uniqID(),
        sender: "5725a680606588342058356d",
        body: "We are losing money! Quick!",
        datetime: toTS("2017-04-22T01:10:00.299Z"),
        read: 0,
      },
    ],
  },
  "3725a6809413bf8a0a5272b4": {
    _id: "3725a6809413bf8a0a5272b4",
    me: "social_page_yyy003",
    messages: [
      {
        id: uniqID(),
        sender: "5725a68009e20d0a9e9acf2a",
        body:
          "Quickly come ftp://www.cuhk.edu.hk to the meeting room www.google.com 1B,  we have a big server www.github issue",
        datetime: toTS("2017-04-22T02:10:00.299Z"),
        attachments: [
          {
            url: "http://localhost:3000/assets/images/calendar/autumn.jpg",
            filename: "aut_umn_name.jpg",
            mimetype: "image/jpeg",
          },
        ],
        read: 0,
      },
      {
        id: uniqID(),
        sender: "5725a68009e20d0a9e9acf2a",
        title: "Attachment Test 2",
        body: "See enclosed.",
        datetime: toTS("2017-04-22T02:10:00.299Z"),
        attachments: [
          {
            url: "http://www.wecl.com.hk/downloads/pdf/28-20-0700.pdf",
            mimetype: "application/pdf",
          },
        ],
        read: 0,
      },
      {
        id: uniqID(),
        sender: "5725a68009e20d0a9e9acf2a",
        title: "My awsome www.google.com work",
        body: "See enclosed.",
        datetime: toTS("2017-04-22T02:10:00.299Z"),
        attachments: [
          {
            url:
              "http://localhost:3000/assets/images/ecommerce/morain-lake.jpg",
            mimetype: "image/jpeg",
          },
        ],
        read: 0,
      },
      {
        id: uniqID(),
        sender: "5725a68009e20d0a9e9acf2a",
        title: "Attachment Test 3",
        body: "See enclosed.",
        datetime: toTS("2017-04-22T02:10:00.299Z"),
        attachments: [
          {
            url: "http://localhost:3000/assets/docs/testing1.pdf",
            mimetype: "application/pdf",
          },
        ],
        read: 0,
      },
    ],
  },
};

// Prepare messages
const prep_messages = gen_msgs_by_distribution(moment().valueOf(), [
  // [-3, 'd'],
  [0, "m"],
  // [1, 'd']
]);
const prep_time = moment().valueOf(),
  spt = split_by(prep_messages, (msg) => msg.datetime <= prep_time);
convs["parami chatroom default"].messages = spt[0];
convs["parami chatroom default"].prepared_messages = spt[1];

// Unify fields
const convs_data_ts = genTS();
ld.map(convs, (conv) => {
  conv.session_id = null;
  conv.last_modified = convs_data_ts;
  conv.staff_in_charge = null;
  conv.conv_type = null;
  conv.folder = "New";
  conv.created_at = convs_data_ts;
  conv.last_msg_at = convs_data_ts;
  conv.last_message = last(conv.messages);
});

export default convs;
