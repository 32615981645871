import { authRoles } from "app/auth";

import { SubsPagePath, Subscription } from "./Subscription";

export const SubsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acc_manage,
  routes: [
    // Notes: Beware of order of path matching
    {
      path: [`${SubsPagePath}/:action`],
      component: Subscription,
    },
    {
      path: [SubsPagePath],
      component: Subscription,
    },
  ],
};
