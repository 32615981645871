import { combineReducers } from "redux";
import userPreferences from "./userPreferences.reducer";
import asangaPlans from "./asangaPlans.reducer";
import validations from "./validations.reducer";

const paramiReducers = combineReducers({
  asangaPlans,
  userPreferences,
  validations,
});

export default paramiReducers;
