import React from "react";
import * as QS from "query-string";
import { mapValues as ld_map } from "lodash";
import { Route } from "react-router-dom";

import * as AuthAction from "app/auth/store/actions";
import store from "app/store";

const CYDispatch = (props) => {
  const parms = QS.parse(props.location.search);
  const toExec = AuthAction[parms.name],
    input = parms.input ? JSON.parse(parms.input) : null,
    storage = parms.storage ? JSON.parse(parms.storage) : null;
  if (storage)
    ld_map(storage, (val, kk) => {
      window.localStorage.setItem(kk, val);
      console.log(`Set Storage: ${kk} = ${val}`);
    });

  console.log(`Dispatching: ${parms.name}, Input: ${JSON.stringify(input)}`);
  if (parms.name === "login") {
    store.dispatch(AuthAction.setUserDataAuth0(input));
    store.dispatch(AuthAction.PFELogin({ jump_to: "/" }));
  } else {
    store.dispatch(toExec(input));
  }
  // return <div><h1>This is a hole</h1></div>;
  return null;
};

const RoutedCYDispatch = (props) => {
  return <Route exact path="/cyDispatch" component={CYDispatch} />;
};

export { RoutedCYDispatch };

// const CYLocalStorage = (props) => {
//   const parms = QS.parse(props.location.search)
//         ;
//   ld_map(parms, (val, kk) => {
//       window.localStorage.setItem(kk, val);
//       console.log(`cyLocalStorage: ${kk} = ${val}`);
//   });
//   return null
// }
