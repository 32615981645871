import * as Actions from "app/store/actions/parami";

const initialState = {};

const plans = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ASANGA_PLANS: {
      return action.plans;
    }

    default: {
      return state;
    }
  }
};

export default plans;
