import { CY_TRIGGER } from "app/store/actions/test/cypress.actions";

const initialState = {
  trigger: {
    label: null,
    params: null,
  },
};

const cypress = function (state = initialState, action) {
  switch (action.type) {
    case CY_TRIGGER: {
      const { label, params } = action;
      return {
        ...state,
        trigger: { label, params },
      };
    }

    default: {
      return state;
    }
  }
};

export default cypress;
