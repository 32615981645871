import { combineReducers } from "redux";
import variables from "./variables.reducer";
import formSubmissions from "./formSubmissions.reducer";

const globalsReducers = combineReducers({
  variables,
  formSubmissions,
});

export default globalsReducers;
