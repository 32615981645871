import Debug from "debug";
import { normalize, schema } from "normalizr";

import { PaymentMethod } from "constants.js";
import { asyncThunk } from "app/utils/common";
import { isPaidPlan, isDowngrading } from "app/utils/entities/plan";
import ParamiError from "app/utils/ParamiError";
import { REST } from "app/client_proxied";

const debug = Debug("pfe:store:company:action:plfacc");

export const GET_SUBSCRIPTION = "[SUBSCRIPTION] GET";

// Schema
const SubsSchema = new schema.Entity(
  "subscription",
  {},
  {
    idAttribute: "id",
    processStrategy: (value, parent, key) => ({
      id: value.id,
      amount: value.curr_billing_amount,
      currency: value.curr_billing_currency,
      plan_id: value.curr_plan_id,
      next_billing_day: value.next_billing_day,
      change:
        value.curr_plan_id === value.next_plan_id
          ? null
          : {
              downgrade: isDowngrading(value.curr_plan_id, value.next_plan_id),
              plan_id: value.next_plan_id,
              effective_from: value.next_billing_day,
            },
      ...setPaymentMethod(value),
    }),
  }
);

function setPaymentMethod(subs_raw) {
  if (subs_raw.subscription_id)
    return {
      payment_method: PaymentMethod.paypal,
      paypal: {
        subscription_id: subs_raw.subscription_id,
      },
    };
  else
    return {
      payment_method: PaymentMethod.none,
    };
}

const getSubsResult = new schema.Entity("getSubsResult", {
  Result: SubsSchema,
});

export function getSubscription() {
  return asyncThunk(async (dispatch, getState) => {
    const raw = (await REST.SubscriptionsApi.subscriptionsGet()).data,
      norm = normalize(raw, getSubsResult),
      subscription = Object.values(norm.entities.subscription || {})[0];
    debug("getSubscription - norm", norm);

    return dispatch({
      type: GET_SUBSCRIPTION,
      subscription,
    });
  });
}

export function updateSubscription(newPlan, pmDetails) {
  let subsInfo = {
    plan_id: newPlan.id, // Parami's Plan ID
  };
  if (isPaidPlan(newPlan)) {
    const isSubs = pmDetails.subscriber !== undefined;

    if (pmDetails.intent === "CAPTURE" && pmDetails.status !== "COMPLETED")
      throw new ParamiError(
        "From Paypal: transaction capturing not completed.",
        subsInfo
      );

    subsInfo.subscription_id = isSubs ? pmDetails.id : undefined;
  } else {
    subsInfo.subscription_id = null;
  }
  debug("updateSubscription - subsInfo", subsInfo);

  return asyncThunk(async (dispatch, getState) => {
    const retRaw = (
      await REST.SubscriptionsApi.subscriptionsPatch(
        subsInfo.plan_id,
        subsInfo.subscription_id
      )
    ).data;
    debug("retRaw", retRaw);

    return dispatch(getSubscription());
  });
}
