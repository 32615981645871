import { FuseLoadable } from "@fuse";
import { authRoles } from "app/auth";

export const PFEChatAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acc_usage,
  routes: [
    {
      path: "/chats",
      component: FuseLoadable({
        loader: () => import("./PFEChatApp"),
      }),
    },
  ],
};
