import Dashboard from "./Dashboard";
import { authRoles } from "app/auth";

export const DashboardConfig = {
  settings: {
    layout: {
      style: "layout4",
    },
  },
  auth: authRoles.acc_usage,
  routes: [
    {
      path: "/dashboard",
      component: Dashboard,
    },
  ],
};
