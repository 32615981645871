/** @module userDisplay */
// import Debug from 'debug';

import store from "app/store";
import {
  myUserID,
  fullname as myFullName,
  userProfilePic,
  userFullName,
} from "./user";

// const debug = Debug('pfe:entity:staff');

/**
 * Get Profile Picture URL of Staff.
 *
 * @param {object} staff Staff object as in redux state
 * @return {string} URL of the profile pic, if available.
 */
export const profilePic = (staff) => userProfilePic(staff);

/**
 *
 * Display the full name of user. This function will honour the current display
 * language settings in redux store, as well as format specified in "locale.js".
 *
 * @param {object|string} staff Staff object as in redux state, or user ID of
 * the staff as string;
 * @param {object} opts Options
 * @param {string} opts.alt Alternate field of Staff object to be used
 * if name is not available.
 * @return {string} Fullname of the staff.
 */
export function fullname(pStaff, opts = {}) {
  const { my_name, alt } = opts,
    myid = myUserID(),
    staff = typeof pStaff === "string" ? { user_id: pStaff } : pStaff;
  if (staff.user_id === myid) {
    return my_name || myFullName();
  }
  const alt_disp = staff[alt];
  return userFullName(staff) || alt_disp;
}

/**
 *
 * @param {object} staff Staff object as in redux state
 * @return {boolean} true if the staff is Company owner.
 */
export function isOwner(staff) {
  return staff.user_type === "owner";
}

/**
 *
 * Get Staff object with ID given.
 *
 * @param {object} staff Staff object as in redux store.
 */
export function getStaffObj(user_id) {
  const state = store.getState();
  return state.company.staff[user_id];
}
