import { GET_SUBSCRIPTION } from "app/store/actions/company/subscription.actions";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION: {
      return {
        ...state,
        ...action.subscription,
      };
    }

    default:
      return state;
  }
}
