/**
 * @module
 */

import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import * as imupdate from "object-path-immutable";
import ld from "lodash";
import Debug from "debug";

import { DISP_LANG_ORIG } from "constants.js";
import Locales from "app/data/locales";
import ParamiError from "app/utils/ParamiError";
import fnKeeper from "app/utils/fnKeeper";

const debug = Debug("pfe:utils:common");

/**
 * Wrapper for using "async/await" keywords in redux actions. By using
 * "redux-thunk" package and putting * your codes inside the wrapper, 
 * you can using these ES6 keywords in a normal redux action.
 * <p/>
 * Note: If you dispatch such redux action and wait for the return, 
 * you still have to use the "await" keyword, like this:
 ```
    function redux_action_A () {
      return asyncThunk(dispatch => {
        // Some async actions here, and the return is async itself.
        // It should be handled by redux-thunk, but waiting is your
        // choice.
      });
    }

    function redux_action_B () {
      ...
      // So here you wait if one action dispatch one using asyncThunk.
      await dispatch(redux_action_A);
    }
 ```
 * 
 * @param {*} asyncFn 
 */
export function asyncThunk(asyncFn) {
  return function(dispatch, getState) {
    return asyncFn(dispatch, getState).catch((err) => {
      // console.log('asyncThunk', err.message);
      throw err;
    });
  };
}

// React and Components
/**
 *
 * Generate a event handler function for forms in React. The generated function
 * can set the value to the target object created by useState.
 *
 * @param {function} pSetter the function to store generated values. Refer to
 * React's "useState" function;
 * @param {object} pObj the original object to be modified. Refer to React's
 * "useState" function;
 * @returns {function} handler function to be put in the event handler of
 * components, like "onChange"
 */
export function genFormHandler(pSetter, pObj) {
  return (event) => {
    const { name, type, checked, value } = event.target;
    debug("genFormHandler", {
      trace: new Error().stack,
      name,
      type,
      checked,
      value,
    });
    pSetter(imupdate.set(pObj, name, type === "checkbox" ? checked : value));
  };
}

// NOTES: getBearer has to be function, not constant to keep loading
// the current token.
// NOTES: Consider move it away as calling store in "common.js" may cause
// problem on call stack (i18n > common > redux > i18n again )

// export function getBearer() {
//   const token = auth0Service.getAccessToken();
//   if (!token)
//     throw new Error('Access Token not available in PFE');
//   return `Bearer ${token}`;
// }

//
// Paths
//
export function inAsset(relaPath) {
  return `${process.env.PUBLIC_URL}/assets/${relaPath}`;
}

//
// Array
//
export function last(arr, defVal = null) {
  return arr && arr.length > 0 ? arr[arr.length - 1] : null;
}

export function first(arr) {
  return arr && arr.length > 0 ? arr[0] : null;
}

//
// General
//
export function isEmpty(item) {
  return ld.isEmpty(item);
}

/**
 * Return the length of the item, no matter it is null or
 * undefined. Now support String and Array.
 *
 * @param {*} item Given item to measure length.
 */
export function safeLength(item) {
  return item ? item.length : 0;
}

//
// String Formatting
//
export function timestampToStr(timestamp, timezone) {
  if (!timestamp || timestamp === "") return "";
  const fmt = ld.get(Locales, `en.formatter.timestamp`, "lllll");
  return moment(timestamp)
    .tz(timezone)
    .format(fmt);
}

export function listLang() {
  return Object.keys(Locales);
}

export function getInitials(string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

//
// Array and Objects
//
export function asArray(items) {
  if (items) return items.map ? items : [items];
  else return [];
}

export function asMap(items, keyFieldName) {
  if (!keyFieldName) throw new Error("asMap: Missing keyFieldName.");
  return items.hasOwnProperty(keyFieldName)
    ? {
        [items[keyFieldName]]: items,
      }
    : items;
}

export function cleanUD(obj, opts) {
  return ld.pickBy(obj, (val) => val !== undefined);
}

export function split_by(arr, fn) {
  let pos;
  arr.every((msg, ind) => {
    // if (msg.datetime <= ts_now)
    if (fn(msg)) {
      return true;
    } else {
      pos = ind;
      return false;
    }
  });
  const first_half = arr.slice(0, pos),
    second_half = arr.slice(pos);
  return [first_half, second_half];
}

export function hasField(pObj, fieldName) {
  return pObj.hasOwnProperty(fieldName);
}

//
// Time
//
export function genTS() {
  return Date.now();
}

export function shortTimestamp(pTS) {
  if (!pTS) return "";
  const ts = moment(pTS),
    today = moment(),
    diff = ts.diff(today, "days"),
    diff_by_months = ts.diff(today, "months");
  let result;
  if (diff > -1) result = ts.format("HH:mm");
  else if (diff > -7) result = ts.format("ddd");
  else if (diff_by_months > -11) result = ts.format("MMM DD");
  else result = ts.format("YYYY-MM-DD");

  return result;
}

//
// Chat Messages
//
export function getMsgContent(message, display_lang) {
  let lang = DISP_LANG_ORIG;
  if (display_lang && message.translation && message.translation[display_lang])
    lang = display_lang;
  else if (
    message.display_language &&
    message.translation &&
    message.translation[message.display_language]
  )
    lang = message.display_language;

  try {
    return {
      language: lang,
      ...((message.translation && message.translation[lang]) || {}),
    };
  } catch (err) {
    console.log("ERRER", err.stack, message);
  }
}

//
// Error Message
//

export function produceErrorMsg(err, ttt, opts) {
  const ettt = ld.get(fnKeeper, "ttt.error");
  const { label = "produceErrorMsg", i18n_opts, i18n_keys_root } = opts || {},
    status = ld.get(err, "response.status"),
    ret = ld.get(err, "response.data", {});
  let message = ttt([i18n_keys_root, `others`].join("."), i18n_opts);
  if (ret.sub_error_code) message = ettt(ret.errorCode, i18n_opts);
  else if (httpError(status))
    message = ttt(
      [`HTTP_${status}`, `others`].map((str) =>
        [i18n_keys_root, str].join(".")
      ),
      i18n_opts
    );
  else if (err instanceof ParamiError) message = ettt(err.message);

  debug(label, ret, err.stack);
  // console.log(inspect(err));
  return message;
}

//
// Status and Response
//
export function httpOK(code) {
  return code >= 200 && code < 300;
}

export function httpError(code) {
  return httpClientError(code) || httpServerError(code);
}

export function httpClientError(code) {
  return code >= 400 && code < 500;
}

export function httpServerError(code) {
  return code >= 500 && code < 600;
}

//
// Debug
//
const debug_funcs = {};

/**
 * Create handy debug print functions. The debug function return will take
 * two parameters: An identifier, which print in the debug prefix (i.e.
 * "pfe:<name>:<identifier>"), and the parameters to be passed to normal
 * debug function.
 *
 * @param {*} name
 * @param {*} prefix
 * @return {function} a function that performs debug printing;
 */
export function define_debug_prints(name, prefix) {
  if (!debug_funcs[name]) {
    debug_funcs[name] = {
      _prefix: prefix,
      _debug_fn: (debug_fn_id, ...parms) => {
        const debug_def = debug_funcs[name];
        let dfn = debug_def[debug_fn_id];

        if (typeof dfn === "function") dfn(...parms);
        else {
          const str = `${prefix}:${debug_fn_id}`;
          debug_def[debug_fn_id] = Debug(str);
          debug(`define_debug_prints - created debug print ${str}`);
        }
      },
    };
  } else {
    debug(`define_debug_prints - already defined: ${name}`);
  }

  return debug_funcs[name]._debug_fn;
}

//
// hash
//

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {boolean} [asString=false] set to true to return the hash value as
 *     8-digit hex string instead of an integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
export function hashFnv32a(str, asString, seed) {
  /*jshint bitwise:false */
  var i,
    l,
    hval = seed === undefined ? 0x811c9dc5 : seed;

  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval +=
      (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  if (asString) {
    // Convert to 8 digit hex string
    return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
  }
  return hval >>> 0;
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function() {
      console.log("Async: Copying to clipboard was successful!");
    },
    function(err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const flatten = (obj, prefix = "", res = {}) =>
  obj === undefined || obj === null
    ? obj
    : Object.entries(obj).reduce((r, [key, val]) => {
        const k = `${prefix}${key}`;
        if (typeof val === "object") {
          flatten(val, `${k}.`, r);
        } else {
          res[k] = val;
        }
        return r;
      }, res);

export const nestedUpdate = (obj, updates) => {
  if (!updates) return obj;
  let fargs = flatten(updates);
  for (var key in fargs) {
    ld.set(obj, key, fargs[key]);
  }
  return obj;
};
