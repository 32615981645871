export const SIOC_MISSING_SOCKET = "SIOC_MISSING_SOCKET";
export const SIOC_EMIT_TIMEOUT = "SIOC_EMIT_TIMEOUT";

export const DISALLOW_UPDATE_STAFF = "DISALLOW_UPDATE_STAFF";
export const DISALLOW_CREATE_STAFF = "DISALLOW_CREATE_STAFF";
export const DISALLOW_DELETE_STAFF = "DISALLOW_DELETE_STAFF";

export const CHAT_EMPTY_RETURN = "CHAT_EMPTY_RETURN";
export const CHAT_SEND_MSG_FAILED = "CHAT_SEND_MSG_FAILED";
export const CHAT_FAILED_MODIFY_CONV = "CHAT_FAILED_MODIFY_CONV";
export const CHAT_FAILED_CHANGE_CONV_TYPE = "CHAT_FAILED_CHANGE_CONV_TYPE";
export const CREATE_COMPANY_CREATE_FAILED = "CREATE_COMPANY_CREATE_FAILED";
export const CREATE_COMPANY_REFRESH_TOKEN_FAILED =
  "CREATE_COMPANY_REFRESH_TOKEN_FAILED";

export const DASHBOARD_FILTER_SELECT_TOO_MANY =
  "DASHBOARD_FILTER_SELECT_TOO_MANY";

export default class ParamiError extends Error {
  constructor(message, payload) {
    super(message);
    this._payload = payload;
  }

  get payload() {
    return this._payload;
  }
}
