import React, { useState, useEffect } from "react";
import { FuseAnimate, FusePageCarded, SelectFormsy } from "@fuse";
import {
  Button,
  FormLabel,
  withStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Formsy from "formsy-react";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import ld from "lodash";
import classNames from "classnames";
import { connect } from "react-redux";
import Debug from "debug";

import { i18nNamespaces, Salutation, Gender } from "constants.js";
import { listLang, genFormHandler } from "app/utils/common";
import { showMessage } from "app/store/actions";
import { updateUser } from "app/auth/store/actions/user.actions";
import { withPFEValidators } from "app/services/paramiService";
import { TextFieldPFE, HeaderTitle } from "app/parami-layouts";

const debug = Debug("pfe:settings:user:main");
const langs = listLang();

const styles = (theme) => ({
  labelFont: {
    fontSize: "14px",
  },
});

const User = (props) => {
  const { user: pUser, classes, validators } = props,
    { updateUser, showMessage } = props,
    // Notes: Use Internal state to allow update to redux only on save
    [user, setUser] = useState(pUser),
    [canSub, setSubmit] = useState(false),
    { t: ttt } = useTranslation();
  debug("validators", validators);
  const hdlForm = genFormHandler(setUser, user);

  const disableButton = () => {
    setSubmit(false);
  };

  const enableButton = () => {
    setSubmit(true);
  };

  const onValidSubmit = async (updates) => {
    try {
      const user_update_at_pfe = ld.pick({ ...user, ...updates }, [
        "user_id",
        // 'email_address',
        "first_name",
        "gender",
        "job_title",
        "last_name",
        "phone_number",
        "salutation",
        "preferences",
      ]);
      debug("updates", updates, "user_update_at_pfe", user_update_at_pfe);
      debug("user_update_at_pfe", user_update_at_pfe);

      await updateUser(user_update_at_pfe);
      showMessage({ message: ttt("settings-user:update success") });
    } catch (err) {
      debug("EEE", err.stack);
      showMessage({ message: ttt("settings-user:actions.update failed") });
    }
  };

  // Notes: Wait for loading from PBE. Since during the time component loaded,
  // data from PBE may not be ready.
  useEffect(() => {
    setUser(pUser);
  }, [pUser]);

  // Styles
  const smallForm = "block sm:flex",
    formRow = classNames(smallForm, "mt-8 mb-10");
  return (
    <FusePageCarded
      classes={{
        content: "flex",
      }}
      header={
        <HeaderTitle
          key="SettingsHeader"
          text={ttt("settings-user:page header.title")}
          icon="person"
          button={{
            text: ttt("general:button.save"),
            type: "submit",
            className: "whitespace-no-wrap",
            variant: "contained",
            form: "user-form",
            "aria-label": "SAVE",
            disabled: !canSub,
          }}
        ></HeaderTitle>
      }
      content={
        <div className="flex w-full pt-20">
          <Formsy
            id="user-form"
            onValidSubmit={onValidSubmit}
            onValid={enableButton}
            onInvalid={disableButton}
            // ref={(form) => form = form}
            className="flex flex-col w-full mx-16"
          >
            <div className={classNames(formRow)}>
              <FormLabel
                className={classNames(
                  classes.labelFont,
                  smallForm,
                  "text-14, w-1/4 pl-8 mb-16 sm:mb-0"
                )}
              >
                {ttt("form-fields:user.username")}
              </FormLabel>
              <FormLabel
                className={classNames(
                  smallForm,
                  "text-16 w-3/4 pl-8 sm:px-0 text-black"
                )}
              >
                {user.username}
              </FormLabel>
            </div>

            <div className={classNames(formRow)}>
              <SelectFormsy
                label={ttt("form-fields:user.salutation")}
                name="salutation"
                value={user.salutation || ""}
                // required
                className="w-2/5 sm:w-2/5 md:w-1/5 sm:mb-0 sm:mr-16"
                // validations="isExisty"
                // validationError="Choose one"
                variant="outlined"
                onChange={hdlForm}
              >
                {ld.map(Salutation, (val) => (
                  <MenuItem name="ui_salutation" key={val} value={val}>
                    {ttt(`form-fields:salutations.${val}`)}
                  </MenuItem>
                ))}
              </SelectFormsy>

              <TextFieldPFE
                className="w-3/5 pl-8 sm:px-0 mb-16 sm:mb-0 sm:mr-16"
                autoFocus
                name="first_name"
                fullWidth
                label={ttt("form-fields:user.first_name")}
                value={user.first_name}
                pfeValidType="user.first_name"
                onChange={hdlForm}
              />

              <TextFieldPFE
                className="w-full sm:w-2/5"
                autoFocus
                name="last_name"
                fullWidth
                label={ttt("form-fields:user.last_name")}
                value={user.last_name}
                pfeValidType="user.last_name"
                onChange={hdlForm}
              />
            </div>

            <div className={classNames(formRow)}>
              <SelectFormsy
                label={ttt("form-fields:user.gender")}
                name="gender"
                value={user.gender || ""}
                // required
                className="w-2/5 sm:w-2/5 md:w-1/5 sm:mb-0 sm:mr-16"
                // validations="isExisty"
                // validationError="Choose one"
                variant="outlined"
                onChange={hdlForm}
              >
                {ld.map(Gender, (val) => (
                  <MenuItem name="ui_gender" key={val} value={val}>
                    {ttt(`form-fields:genders.${val}`)}
                  </MenuItem>
                ))}
              </SelectFormsy>

              <TextFieldPFE
                className="w-3/4 pl-8 sm:px-0"
                autoFocus
                name="job_title"
                label={ttt("form-fields:user.job_title")}
                fullWidth
                value={user.job_title}
                pfeValidType="user.job_title"
                onChange={hdlForm}
              />
            </div>

            <div className={classNames(formRow)}>
              <SelectFormsy
                label={ttt("form-fields:user.language")}
                name="preferences.ui_language"
                value={user.preferences.ui_language || ""}
                className="w-full sm:w-1/2 mb-16 sm:mb-0 mr-16"
                required
                variant="outlined"
                onChange={hdlForm}
              >
                {langs.map((lang, ind) => (
                  <MenuItem
                    name="ui_language"
                    value={lang}
                    key={`mi_ui_${ind}`}
                  >
                    {ttt(`general:language.${lang}`)}
                  </MenuItem>
                ))}
              </SelectFormsy>

              <SelectFormsy
                label={ttt("form-fields:user.translate_to_language")}
                name="preferences.translate_to_language"
                value={user.preferences.translate_to_language || ""}
                className="w-full sm:w-1/2"
                required
                variant="outlined"
                onChange={hdlForm}
              >
                {langs.map((lang, ind) => (
                  <MenuItem value={lang} key={`mi_tt_${ind}`}>
                    {ttt(`general:language.${lang}`)}
                  </MenuItem>
                ))}
              </SelectFormsy>
            </div>

            <div className={classNames(formRow)}>
              <TextFieldPFE
                className=""
                autoFocus
                name="phone_number"
                label={ttt("form-fields:user.phone_number")}
                fullWidth
                value={user.phone_number}
                pfeValidType="general.phone_number"
                onChange={hdlForm}
              />
            </div>

            <div className={classNames(formRow)}>
              <TextFieldPFE
                className=""
                autoFocus
                name="email_address"
                label={ttt("form-fields:user.email_address")}
                fullWidth
                value={user.email_address}
                pfeValidType="general.email_address"
                onChange={hdlForm}
              />
            </div>
          </Formsy>
        </div>
      }
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUser,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ auth, parami }) {
  return {
    user: auth.user,
    validators: parami.validations.user,
  };
}

let myComp = connect(mapStateToProps, mapDispatchToProps)(User);
myComp = withStyles(styles, { withTheme: true })(myComp);
myComp = withPFEValidators(myComp, ["user", "general"]);
// myComp = withLoadingSpinner(waitForList, {})(myComp);
export default myComp;
