/** @module */
import React from "react";
import { Avatar } from "@material-ui/core";
import ld from "@lodash";
// import Debug from 'debug';

import { assets } from "app/AppConst";
import { getInitials } from "app/utils/common";

// const debug = Debug('pfe:layout:pavatar');

const Pavatar = (props) => {
  const {
      src: pSrc_,
      fallback,
      className,
      alt: pAlt,
      useInitials = true,
      white,
    } = props,
    importedProps = ld.pickBy(
      props,
      (val, key) => !["src", "fallback", "className", "alt"].includes(key)
    );
  const pSrc =
    (pSrc_ && pSrc_.small && typeof pSrc_.small === "string" && pSrc_.small) ||
    pSrc_;
  // NOTES: Order of display, src > alt > fallback = true.
  let content = props.children,
    src = pSrc && (white ? pSrc.replace(".svg", "-white.svg") : pSrc),
    useFallback = false;
  if (!pSrc) {
    if (!ld.isEmpty(pAlt)) {
      const alt_disp = useInitials ? getInitials(pAlt) : pAlt;
      content = <h1>{alt_disp}</h1>;
    } else {
      if (fallback === true) {
        src = assets.avatar_default_fallback.path;
        useFallback = true;
      }
    }
  }

  // debug('pavatar', 'src=', src, 'content=', content);

  return (
    <Avatar
      {...importedProps}
      src={src}
      className={className}
      style={{
        borderRadius:
          src && src.indexOf && src.indexOf("/assets/images/") > -1
            ? "0"
            : "100em",
      }}
    >
      {content}
    </Avatar>
  );
};

export default Pavatar;
