import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import ld from "@lodash";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

import { i18nNamespaces } from "constants.js";
import * as Actions from "app/store/actions";
import { connect } from "react-redux";

const jpath = "layout.config.navbar.folded";

const NavbarStayButton = ({
  settings,
  setDefaultSettings,
  children,
  className,
}) => {
  const { t: ttt } = useTranslation(),
    folded = ld.get(settings, jpath),
    icon_name = folded ? "keyboard_arrow_right" : "keyboard_arrow_left",
    tip_title = ttt(
      folded
        ? `general:change navbar to static`
        : `general:change navbar to overlapping`
    );
  return (
    <Tooltip title={tip_title} placement="right">
      <IconButton
        size="small"
        className={(className, folded ? "inFolded" : "")}
        onClick={() => setDefaultSettings(ld.set({}, jpath, !folded))}
        style={{
          color: folded ? "transparent" : "inherit",
          marginLeft: folded ? "" : "-0.7em",
        }}
        color="inherit"
      >
        <Icon
          fontSize="small"
          style={{
            color: folded ? "" : "#8051b0",
            transition: "all 333ms ease",
          }}
        >
          {icon_name}
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDefaultSettings: Actions.setDefaultSettings,
    },
    dispatch
  );
}

function mapStateToProps({ fuse }) {
  return {
    settings: fuse.settings.current,
  };
}

// NavbarFoldedToggleButton.defaultProps = {
//     children: <Icon>vertical_split</Icon>
// };

export default connect(mapStateToProps, mapDispatchToProps)(NavbarStayButton);
