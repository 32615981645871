// import {authRoles} from 'app/auth';
import CreateCompany from "./CreateCompany";

export const CreateCompanyConfig = {
  settings: {
    layout: {
      style: "layout4",
      config: {
        scroll: "content",
        navbar: {
          display: false,
          folded: true,
          // position: 'left'
        },
        toolbar: {
          display: false,
          style: "fixed",
          position: "below",
        },
        footer: {
          display: false,
          // style   : 'fixed',
          // position: 'below'
        },
        rightSidePanel: {
          display: false,
        },
        mode: "fullwidth",
      },
    },
  },
  // auth: authRoles.acc_owner_only,
  routes: [
    {
      path: "/create-company",
      component: CreateCompany,
    },
  ],
};

/**
 * Lazy load Owner
 */
/*
import FuseLoadable from '@fuse/components/FuseLoadable/FuseLoadable';

export const OwnerConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: FuseLoadable({
                loader: () => import('./Owner')
            })
        }
    ]
};
*/
