import * as imupdate from "object-path-immutable";

import { GET_AUTO_REPLY } from "app/store/actions/company/emergency.actions";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_AUTO_REPLY: {
      return imupdate.set(state, "auto_reply", action.auto_reply);
    }

    default:
      return state;
  }
}
