/**
 * @module
 */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  MuiThemeProvider,
  Typography,
  Card,
  CardContent,
  withStyles,
} from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { Line } from "react-chartjs-2";
import classNames from "classnames";
import * as imupdate from "object-path-immutable";
import ld from "@lodash";
import Debug from "debug";

import { i18nNamespaces, Dashboard as DBConst } from "constants.js";
import FilterSelect from "./FilterSelect";
import Curtain from "./Curtain";
import { getLineChart, showMessage } from "app/store/actions";
import { ColorGen } from "app/utils";

// const debug = Debug('pfe:dashboard:LineChart');
const buttonGroupWidth = 40;

const PLFACCS_ALL = DBConst.ALL;
const styles = (theme) => ({
  root: {},
  graphWrapper: {
    height: "100%",
    display: "flex",
  },
  container: {
    width: `calc(100% - ${buttonGroupWidth}px)`,
    "& #chart-button-group-vert": {
      width: buttonGroupWidth,
      "& button": {
        minWidth: "unset",
        width: buttonGroupWidth,
      },
    },
  },
  progress: {
    color: "#222",
  },
  buttonContained: {
    backgroundColor: "green",
  },
});

const default_graph_options = (conf) => ({
  spanGaps: false,
  legend: {
    display: true,
    labels: {
      fontColor: "#222",
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    position: "nearest",
    mode: "index",
    intersect: false,
  },
  layout: {
    padding: {
      left: 24,
      right: 32,
    },
  },
  elements: {
    point: {
      radius: 4,
      borderWidth: 2,
      hoverRadius: 4,
      hoverBorderWidth: 2,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: "#222",
          // fontColor: 'rgba(0,0,0,0.54)'
        },
      },
    ],
    yAxes: [
      {
        stacked: conf.stacked || false,
        gridLines: {
          tickMarkLength: 16,
        },
        ticks: {
          // stepSize: 1,
          fontColor: "#222",
        },
      },
    ],
  },
  plugins: {
    filler: {
      propagate: false,
    },
  },
});

function update_graph(conf, linesData, filters, cg, opts) {
  const { stacked, custom_ds_names } = conf,
    {
      ttt, //debug
    } = opts;
  // debug('update_graph', linesData);
  return (
    ld
      .chain(linesData)
      .map((dset) => {
        // debug('dset', dset);
        let label = dset.label;
        if (custom_ds_names) label = ttt(`data sets.${dset.label}`);
        else if (dset.label === PLFACCS_ALL) label = ttt(PLFACCS_ALL);

        const palette = "primary",
          color_main = cg.getByID(label);
        return {
          ...dset,
          label: label,
          fill: stacked || false,
          borderColor: color_main,
          backgroundColor: stacked ? color_main : undefined,
          pointBackgroundColor: palette.dark,
          pointHoverBackgroundColor: color_main,
          pointBorderColor: palette.contrastText,
          pointHoverBorderColor: palette.contrastText,
        };
      })
      // .flatten()
      .value()
  );
}

/**
 * Line Chart Component
 *
 * @param {object} props React properties to pass into;
 * @param {object} props.filters Filter object for Chart;
 * @param {number} props.filters.limit Number of items allowed to be chosen at
 * most;
 */
const LineChart = (props) => {
  const {
      widgetTheme,
      widgetConfig,
      name,
      data: dataRaw,
      className: cns,
      filters,
      scales,
      classes,
    } = props,
    { getLineChart, showMessage } = props,
    graphOptions = default_graph_options(widgetConfig),
    [colorGen] = useState(
      new ColorGen(widgetConfig.name, {
        range: ld.get(widgetConfig, "lines.color_range", ["909090", "F0F0F0"]),
      })
    ),
    [choices, setChoices] = useState({
      scale: scales[0],
      plfacc_ids: [PLFACCS_ALL],
    }),
    [graphData, setGraph] = useState([]),
    [showCurtain, setShowCurtain] = useState(false),
    debug = useRef(Debug(`pfe:dashboard:${name}`)),
    { t: ttt } = useTranslation();
  // debug.current('LineChart', dataRaw, choices);

  const changeScale = (scale) => {
    update_chart(imupdate.set(choices, "scale", scale));
  };

  const onFilterClose = (plfacc_ids) => {
    let pfids = plfacc_ids;
    const new_choices = imupdate.set(choices, "plfacc_ids", pfids);
    update_chart(new_choices);
  };

  const update_chart = async (newChoices) => {
    setShowCurtain(true);
    // debug.current('update_chart - choices', newChoices);

    // Notes: Only pass "All" when loading data from PBE
    let pfids = newChoices.plfacc_ids;
    if (pfids.includes(PLFACCS_ALL)) pfids = [PLFACCS_ALL];

    try {
      await getLineChart(name, {
        plfaccs: pfids,
        duration: newChoices.scale,
      });
    } catch (err) {
      debug.current("update_chart: err", err.stack);
      showMessage({
        message: ttt("dashboard:load chart failed", { chartName: name }),
      });
    } finally {
      setChoices(newChoices);
    }

    setShowCurtain(false);
  };

  useEffect(() => {
    update_chart(choices);
  }, []);

  useEffect(() => {
    if (!dataRaw) return;

    const lines = dataRaw.datasets || {};
    // debug.current('useEffect dataRaw', choices, lines);
    setGraph(
      update_graph(widgetConfig, lines, filters.plfaccs, colorGen, {
        debug: debug.current,
        ttt: (str, opts) => ttt(`dashboard:charts.${name}.${str}`, opts),
      })
    );
  }, [dataRaw]);

  return (
    <MuiThemeProvider theme={{ widgetTheme }}>
      <Card
        style={{
          background: widgetConfig.background,
        }}
        className={classNames(classes.root, cns)}
      >
        <CardContent className={classNames()}>
          <div className="container relative pb-16 pl-16 sm:pl-24 flex flex-row justify-between items-baseline">
            <FuseAnimate delay={100}>
              <div className="flex items-center">
                <Typography className="h2">
                  {ttt(`dashboard:charts.${name}.title`)}
                </Typography>
                {/* <Typography className="h5 mx-8" color="textSecondary">{ttt('monthly unique visitors')}</Typography> */}
              </div>
            </FuseAnimate>

            {!ld.isEmpty(filters.plfaccs) && (
              <FilterSelect
                filters={filters.plfaccs}
                limit={filters.limit}
                initSelection={choices.plfacc_ids}
                ttt={(str, opts) =>
                  ttt(`dashboard:charts.${name}.filter.${str}`, opts)
                }
                clearMenu
                allMenu={{
                  display_me_only: true,
                }}
                onClose={onFilterClose}
                enable={!showCurtain}
              />
            )}
          </div>

          <div
            className={classNames(classes.container, "relative h-200 sm:h-256")}
          >
            <div className={classes.graphWrapper}>
              {dataRaw && (
                <Line
                  data={{
                    labels: dataRaw.labels,
                    datasets: graphData,
                  }}
                  options={graphOptions}
                />
              )}
              <div id="chart-button-group-vert">
                {scales.map((scale) => (
                  <Button
                    key={scale}
                    // classes={{
                    //   contained: classes.buttonContained
                    // }}
                    variant={scale === choices.scale ? "contained" : null}
                    onClick={() => changeScale(scale)}
                    disabled={showCurtain}
                  >
                    {ttt(`dashboard:charts.${name}.scales.${scale}`)}
                  </Button>
                ))}
              </div>
            </div>
            {showCurtain && <Curtain />}
          </div>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLineChart,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ fuse, dashboard }, { name }) {
  const widgetTheme = ld.merge({}, fuse.settings.mainThemeLight, {
    palette: {
      type: "light",
      primary: {
        light: "#EEEEEE",
        main: "#a391b9",
        dark: "#7C51AB",
        contrastText: "#000",
      },
    },
  });
  const data = ld.get(dashboard, `charts.${name}`);
  return { widgetTheme, data };
}

let myComp = connect(mapStateToProps, mapDispatchToProps)(LineChart);
export default withStyles(styles, { withTheme: true })(myComp);
