/** @module */
import ld from "lodash";

// import { } from 'app/utils/common';

export const preferences = [];

/**
 * Convert plfacc object to shape of PBE;
 *
 * @param {object} plfacc Platform Account object as in redux store
 * (company.plfacc)
 * @return {object} Platform Account object for updating to PBE
 */
export function toPBE(plfacc) {
  const result = ld.pick(plfacc, ["name", "template_id", "preferences"]);
  return result;
}

/**
 * Get the icon url.
 *
 * @param {object} plfacc Platform Account object as in redux store
 * (company.plfacc)
 * @param {string} [size='small] Size of icon to be used.
 * @return {string} URL of the icon
 */
export function getIcon(plfacc, size = "small") {
  return ld.get(plfacc, `icon.${size}`);
}

/**
 * Produce a plfacc_id - name pair for each plfacc.
 *
 * @param {Object} plfaccs The key-value mapping of plfacc objects as stored in
 * redux store, i.e. structure of state "company.plfaccs".
 */
export function nameList(plfaccs) {
  if (!plfaccs) return null;

  const result = {};
  Object.keys(plfaccs).map((key) => {
    result[key] = plfaccs[key].name;
    return null;
  });
  return result;
}
