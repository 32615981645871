/** @module */
import React from "react";
import { FuseAnimate } from "@fuse";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { setGlobalVariables } from "app/store/actions";

import { Hidden, Typography } from "@material-ui/core";
import {
  withStyles,
  Paper,
  Input,
  MuiThemeProvider,
  Button,
  Icon,
  IconButton,
} from "@material-ui/core";
import withEnhancedRouter from "./withEnhancedRouter.js";

const styles = (theme) => ({
  searchBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 400,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 0,
    height: 30,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4,
      height: "100%",
    },
  },
});

let HeaderTitle = (props) => {
  const {
    classes,
    children,
    text,
    prefix: _prefix,
    icon,
    buttons,
    button,
    backButton,
    t: ttt,
    globals,
    search,
    mainTheme,
    setGlobalVariables,
  } = props;
  const prefix = _prefix && (typeof _prefix === "string" ? [_prefix] : _prefix);
  const use_search = search !== undefined;
  /*
    buttons = {
        text: "Click me",
        icon: <AddIcon />,
        onClick: "",
    }

  */
  const buttonComp = (b, variant) => (
    <Button
      {...b}
      className={variant == "flat" ? "min-w-32 mr-8" : ""}
      variant={variant}
    >
      {b.icon &&
        (React.isValidElement(b.icon) ? b.icon : <Icon>{b.icon}</Icon>)}
      {React.isValidElement(b.text) ? (
        b.text
      ) : (
        <span
          style={{
            whiteSpace: "nowrap",
          }}
          className={b.icon ? "hidden ml-4 sm:flex" : "ml-4 flex"}
        >
          {b.text}
        </span>
      )}
    </Button>
  );

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      {backButton !== undefined &&
        (backButton === true ? (
          <FuseAnimate key="1" animation="transition.slideRightIn" delay={0}>
            <IconButton
              className="flex items-center -ml-8 -mr-6"
              aria-label="Back"
              onClick={() => props.goBack()}
            >
              <Icon className="text-20">arrow_back</Icon>
            </IconButton>
          </FuseAnimate>
        ) : (
          <FuseAnimate key="1" animation="transition.slideRightIn" delay={0}>
            {React.isValidElement(backButton) ? (
              backButton
            ) : (
              <IconButton
                className="flex items-center -ml-8 -mr-6"
                aria-label="Back"
                onClick={() => props.goBack()}
                {...backButton}
              >
                <Icon className="text-20">
                  {backButton.icon || "arrow_back"}
                </Icon>
              </IconButton>
            )}
          </FuseAnimate>
        ))}

      {icon && (
        <FuseAnimate key="2" animation="transition.expandIn" delay={0}>
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <Icon className="mx-16">{icon}</Icon>
          )}
        </FuseAnimate>
      )}

      <FuseAnimate key="3" animation="transition.slideLeftIn" delay={0}>
        <span className={"flex flex-1 items-center" + (prefix ? " mt-10" : "")}>
          {(prefix && (
            <>
              <span className=" flex items-center absolute transform -translate-y-6">
                {prefix.map((p) => (
                  <>
                    <Typography
                      key="1"
                      className=" ml-4 lg:text-12 text-10 "
                      variant="h5"
                    >
                      {p}
                    </Typography>
                    <Icon key="2" className="mx-4 lg:text-16 text-12">
                      keyboard_arrow_right
                    </Icon>
                  </>
                ))}
              </span>
              <Typography
                key="3"
                className="mt-8 lg:text-20 text-16 flex-1"
                variant="h4"
              >
                {text}
              </Typography>
            </>
          )) || (
            <Typography
              key="3"
              className={"lg:text-20 text-16 flex-1"}
              variant="h4"
            >
              {text}
            </Typography>
          )}
        </span>
      </FuseAnimate>
      {children}

      {use_search && (
        <div
          key="search"
          className="flex flex-1 items-center justify-center pr-0 pl-6 sm:px-16 mr-8"
        >
          <MuiThemeProvider theme={mainTheme}>
            <FuseAnimate animation="transition.fadeIn" delay={100}>
              <Paper className={classes.searchBar} elevation={1}>
                <Icon className="mr-8 hidden sm:flex" color="action">
                  search
                </Icon>

                <Input
                  placeholder={ttt("Search")}
                  className="flex flex-1"
                  disableUnderline
                  fullWidth
                  value={globals.variables[search]}
                  inputProps={{
                    "aria-label": "Search",
                  }}
                  onChange={(e) => {
                    setGlobalVariables({
                      [search]: e.target.value,
                    });
                  }}
                />
              </Paper>
            </FuseAnimate>
          </MuiThemeProvider>
        </div>
      )}

      {((button ? [button] : buttons) || []).map((b, i) => (
        <FuseAnimate key={4 + i} animation="transition.slideRightIn" delay={0}>
          {React.isValidElement(b) ? (
            b
          ) : (
            <span>
              <Hidden xsDown>{buttonComp(b, "contained")}</Hidden>
              <Hidden smUp>{buttonComp(b, "flat")}</Hidden>
            </span>
          )}
        </FuseAnimate>
      ))}
    </div>
  );
};
HeaderTitle = withStyles(styles, { withTheme: true })(HeaderTitle);
HeaderTitle = withTranslation()(HeaderTitle);

const mapDispatchToProps = {
  setGlobalVariables,
};

function mapStateToProps({ globals, fuse }) {
  return {
    globals,
    mainTheme: fuse.settings.mainTheme,
  };
}
HeaderTitle = connect(mapStateToProps, mapDispatchToProps)(HeaderTitle);

HeaderTitle.defaultProps = {};

export default withEnhancedRouter(HeaderTitle);
