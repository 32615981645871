import mock from "./mock";
import "./db/analytics-dashboard-db";
// import './db/project-dashboard-db';
// import './db/user-manage-db';
// import './db/chat-db';
// import './db/users';
// import './db/plfaccs';
// import './db/plans';

mock.onAny().passThrough();
