import React, { useState, useEffect } from "react";
import { FusePageCarded } from "@fuse";
import connect from "react-redux/es/connect/connect";
import { ListSubheader } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import ld from "lodash";
import * as imupdate from "object-path-immutable";
import Debug from "debug";
import Formsy from "formsy-react";
import { TextFieldPFE } from "app/parami-layouts";

import { REST } from "app/client_proxied";

import { i18nNamespaces } from "constants.js";
import PATable from "./PATable";
import PlatformAccountsHeader from "./PlatformAccountsHeader";
import PAInfoDialog from "./PAInfoDialog";
import ChoosePlatformDialog from "./ChoosePlatformDialog";
import OpenSubWindow from "./OpenSubWindow";
import Platforms from "app/data/platforms";
import { showMessage } from "app/store/actions/fuse";
import { LoadingOverlay, AccountItem } from "app/parami-layouts";
import {
  getAllPlfaccs,
  getFlowTemplates,
  updatePA,
  deletePA,
  // setPassword
} from "app/store/actions/company";
import { withPFEValidators } from "app/services/paramiService";
import { SingleButtonDialog, DoubleButtonDialog } from "app/parami-layouts";

const debug = Debug("pfe:settings:plfacc:main");

// Notes: variable "screenFlow" out of react function.
// ===================================
// As "screenFlow" will be called by actionListener inside, and
// that action handler needs the up-to-date state to run correctly.
// if screenFlow is defined inside the react function, it will refer
// to the states created at the same "round".
//
// By moving it out, variable "screenFlow" will always refer to
// the latest content, including the states binded to it.
let screenFlow;

const Scenes = {
  CREATE_CHOOSE: "create_choose",
  CREATE_CHOSEN: "create_chosen",
  UPDATE: "update",
  DELETE: "delete",
  SET_PW: "set pw",
  SET_PW_FAILED: "set pw failed",
};

const Action = {
  RETURNED: "returned",
  CONFIRMED: "confirmed",
  CONFIRMED_ASANGA_WEB: "confirmed_asanga_web",
  CONFIRMED_WHATSAPP: "confirmed_whatsapp",
  CANCEL: "cancel",
};

const PlatformAccounts = (props) => {
  const { in_plfacc_list, company } = props,
    {
      getAllPlfaccs,
      getFlowTemplates,
      updatePA,
      deletePA,
      // setPassword,
      showMessage,
    } = props,
    [records, setRecords] = useState([]),
    [idPreview, setIdPreview] = useState(""),
    [loading, setLoading] = useState(false),
    [flow, setScreenFlow] = useState({
      plfacc: null,
      platform: null,
      scene: null,
      from: null,
      message: null,
    }),
    // [ staff_to_delete, setDeleteDlg ] = useState(null),
    [order, setOrder] = useState({
      dir: "asc",
      byCol: "username",
    }),
    [simple_access_token_valid, set_simple_access_token_valid] = useState([]),
    [selections, setSelected] = useState({}),
    { t: ttt } = useTranslation();
  debug("company", {
    flow_templates: company.flow_templates,
    company,
    in_plfacc_list,
  });

  // Actions per row
  const whenSelect = (event, pStaffID) => {
    if (selections[pStaffID]) setSelected(imupdate.del(selections, pStaffID));
    else setSelected(imupdate.set(selections, pStaffID, true));
  };

  // Actions per table
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const new_sel = {};
      // eslint-disable-next-line array-callback-return
      Object.keys(records).map((user_id) => {
        new_sel[user_id] = true;
      });
      setSelected(new_sel);
      return;
    }
    setSelected({});
  };

  const handleRequestSort = (event, rowDefID) => {
    const new_order = {
      dir:
        rowDefID === order.byCol
          ? order.dir === "desc"
            ? "asc"
            : "desc"
          : order.dir,
      byCol: rowDefID,
    };
    // debug('handleRequestSort', order, new_order);
    sort_records(records, new_order);

    // Preserve order so for compare usage next time
    setOrder(new_order);
  };

  function sort_records(pStaffList, pOrder = order) {
    let iteratee;
    switch (pOrder.byCol) {
      case "fullname":
        iteratee = (item) => item.first_name + " " + item.last_name;
        break;

      default:
        iteratee = pOrder.byCol;
        break;
    }

    const sorted = ld.orderBy(pStaffList, [iteratee], [pOrder.dir]);
    // debug('sorted!', order);
    setRecords(sorted);
  }

  //
  // Screen Flow Handling
  //
  screenFlow = async (action, data) => {
    debug(`screenFlow flow=${flow.scene} action=${action}`);
    switch (flow.scene) {
      case Scenes.CREATE_CHOOSE: {
        if (action === Action.CHOOSE) {
          const platform = data,
            { origin_magic_html } = Platforms[platform] || {};
          debug(`Chosen ${platform}`, {
            "flow.platform": flow.platform,
            origin_magic_html: origin_magic_html,
            "Platforms[flow.platform]": Platforms[flow.platform],
            Platforms: Platforms,
          });

          const chk_connect_result = function(e) {
            debug("e.origin", e);
            if (e.origin.includes(origin_magic_html)) {
              // if (e.origin.includes('parami.ai')) {
              // debug('Buddy', e.origin, e.data);
              if (e.data.pfe_action === "ADD_PLFACC") {
                // NOTES: debug display of "magic HTML"
                debug("chk_connect_result: return", e.data);
                screenFlow(Action.RETURNED, e.data);
                window.removeEventListener(
                  "message",
                  chk_connect_result,
                  false
                );
              }
            }
          };
          window.addEventListener("message", chk_connect_result, false);

          setScreenFlow({
            plfacc: null,
            platform,
            scene: Scenes.CREATE_CHOSEN,
            from: Scenes.CREATE_CHOOSE,
          });
        } else if (action === "cancel") {
          closePAInfoDialog();
        }
        break;
      }

      case Scenes.CREATE_CHOSEN: {
        let disp,
          autoHideDuration = 6000;
        debug("At chosen", data);
        if (action === Action.RETURNED) {
          if (data.success) {
            try {
              await getAllPlfaccs();
              disp = ttt("settings-plfaccs:create success");
            } catch (err) {
              disp = ttt(
                "settings-plfaccs:create success but get plfacc failed"
              );
            }
          } else {
            const { payload } = data;
            const items_with_unknown_problem = [];
            const items_connected_by_others = [];
            let problematic_count = 0;
            autoHideDuration = 10000000;

            debug({
              payload,
            });
            payload.forEach((pl) => {
              const { status_code, page } = pl;
              switch (status_code) {
                case 409:
                  problematic_count += 1;
                  items_connected_by_others.push(page);

                  break;
                case 200:
                  break;
                default:
                  problematic_count += 1;
                  items_with_unknown_problem.push(page);
              }
            });
            if (problematic_count == 0)
              disp = ttt("settings-plfaccs:create failed");
            else {
              const list_view = (items) => (
                <ul>
                  {items.map((item) => {
                    return (
                      <AccountItem
                        account={{
                          id: item.id,
                          platform: flow.platform,
                          icon: item.icon,
                          name: item.name,
                        }}
                      />
                    );
                  })}
                </ul>
              );
              disp = (
                <span>
                  {items_with_unknown_problem.length > 0 && (
                    <ListSubheader>
                      {ttt("settings-plfaccs:create failed with items")}
                    </ListSubheader>
                  )}
                  {list_view(items_with_unknown_problem)}
                  {items_connected_by_others.length > 0 && (
                    <ListSubheader>
                      {ttt(
                        "settings-plfaccs:create failed with items because owned"
                      )}
                    </ListSubheader>
                  )}
                  {list_view(items_connected_by_others)}
                </span>
              );
            }
          }
        } else if (action === Action.CONFIRMED_ASANGA_WEB) {
          setLoading(true);
          try {
            await REST.PlatformAccountsApi.plfaccsPost({
              platform: "asangaweb",
              name: data.ID,
              plfacc_code: data.ID,
            });
          } catch (error) {
            console.error(error);
            setLoading(false);
            showMessage({ message: ttt("settings-plfaccs:conflict") });
            break;
          }
          setLoading(false);
          await getAllPlfaccs();
          disp = ttt("settings-plfaccs:create success");
        } else if (action === "cancel") {
          disp = ttt("settings-plfaccs:create canceled");
        } else if (action == Action.CONFIRMED_WHATSAPP) {
          setLoading(true);
          try {
            await REST.PlatformAccountsApi.plfaccsPost({
              platform: "whatsapp1",
              name: "whatsapp-" + data.identifier,
              identifier: data.identifier,
              access_token: data.access_token,
            });
          } catch (error) {
            console.error(error);
            setLoading(false);
            showMessage({ message: ttt("settings-plfaccs:conflict") });
            break;
          }
          setLoading(false);
          await getAllPlfaccs();
          disp = ttt("settings-plfaccs:create success");
        }
        closePAInfoDialog();
        showMessage({ message: disp, autoHideDuration });
        break;
      }

      case Scenes.UPDATE: {
        if (action === "save") {
          debug("DDD", data);
          const updates = data,
            plfacc_new = { ...flow.plfacc, ...updates };
          let disp = {
            message: ttt("settings-plfaccs:update ok", {
              name: flow.plfacc.name,
            }),
          };
          try {
            await updatePA(plfacc_new);
          } catch (err) {
            disp = {
              message: ttt("settings-plfaccs:update failed", {
                name: flow.plfacc.name,
              }),
            };
          }
          closePAInfoDialog();
          showMessage(disp);
        } else if (action === Scenes.DELETE) {
          setScreenFlow(imupdate.set(flow, "scene", Scenes.DELETE));
        } else {
          closePAInfoDialog();
        }
        break;
      }

      case Scenes.DELETE: {
        if (action === Action.CONFIRMED) {
          const pf_name = flow.plfacc.name;
          let disp = {
            message: ttt("settings-plfaccs:delete ok", {
              name: pf_name,
            }),
          };
          try {
            debug("Delete plfacc...", data);
            await deletePA(flow.plfacc);
          } catch (err) {
            disp = {
              message: ttt("settings-plfaccs:delete failed", {
                name: pf_name,
              }),
            };
          }
          closePAInfoDialog();
          showMessage(disp);
        } else {
          // return to update info dialog;
          setScreenFlow(imupdate.set(flow, "scene", Scenes.UPDATE));
        }

        break;
      }

      default: {
        const plfacc = data;
        debug(`To ${action}`, data);
        setScreenFlow({
          plfacc: plfacc,
          scene: action,
          from: null,
        });
        break;
      }
    }
  };

  const changeIdPreview = (data) => {
    setIdPreview(data);
  };

  // const returnToPrevious = () => {
  //   setScreenFlow(imupdate.set(flow, 'scene', flow.from));
  // }

  const closePAInfoDialog = (message) => {
    setScreenFlow({
      plfacc: null,
      scene: null,
      from: null,
      message,
    });
  };

  //
  // Component to display
  //
  let showDlg, subWindow;
  switch (flow.scene) {
    case Scenes.CREATE_CHOOSE:
      showDlg = (
        <ChoosePlatformDialog
          onCancel={screenFlow.bind(null, "cancel")}
          onNext={(platform) => {
            debug("PPP", platform);
            screenFlow(Action.CHOOSE, platform);
          }}
        />
      );
      break;

    case Scenes.CREATE_CHOSEN: {
      const options = { state: { company_id: company.company_id } },
        platform = Platforms[flow.platform],
        { simple_access_token, simple_id } = platform;
      var myJump = platform.oauth_url;

      const is_asangaweb = platform.code === "asangaweb";
      const url_title = `${
        company.alias_code ? company.alias_code : company.company_code
      }/`;
      const url_for_asangaweb =
        is_asangaweb &&
        (process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_PBE_PROTOCOL
          ? `https://${window.location.host}/chatbot/${url_title}`
          : `http://self.parami.ai:7000/api/v1/chatbot/${url_title}`);

      if (simple_id) {
        showDlg = (
          <DoubleButtonDialog
            open={true}
            title={ttt("settings-plfaccs:create asanga web.title", {
              platform: platform.name,
            })}
            content={
              <Formsy
                id="simple-id"
                onValidSubmit={screenFlow.bind(
                  null,
                  Action.CONFIRMED_ASANGA_WEB
                )}
                // onValid={() => {simple_access_token_valid(true)}}
                // onInvalid={() => {simple_access_token_valid(false)}}
                className="flex flex-col justify-center"
              >
                {/* <TextFieldPFE
                  type="text"
                  className="my-8"
                  name="name"
                  label={ttt("general:name")}
                  variant="outlined"
                  required
                  fullWidth
                /> */}
                <TextFieldPFE
                  type="text"
                  className="my-8"
                  name="ID"
                  label={ttt("general:name")}
                  variant="outlined"
                  required
                  fullWidth
                  pfeValidType="plfacc.name"
                  onChange={(e, v) => changeIdPreview(v)}
                />
                <p className="inline-block max-w-full relative break-words whitespace-pre-wrap bg-grey-lighter text-grey p-12 rounded-md">
                  {ttt("settings-plfaccs:link preview")}
                  <br />
                  {is_asangaweb ? url_for_asangaweb : ""}
                  <span className="text-purple-light">{idPreview}</span>
                </p>
              </Formsy>
            }
            onClose={screenFlow.bind(null, "cancel")}
            closeButtonCaption={[ttt("general:cancel")]}
            disableEscapeKeyDown={true}
            rightButtonCaption={ttt("general:button.create")}
            onLeft={screenFlow.bind(null, Action.CANCEL)}
            onRight={() =>
              document
                .getElementById("simple-id")
                .dispatchEvent(new Event("submit"))
            }
          />
        );

        break;
      } else if (simple_access_token) {
        showDlg = (
          <DoubleButtonDialog
            open={true}
            title={ttt("settings-plfaccs:connect to access token.title", {
              platform: platform.name,
            })}
            messages={[ttt("settings-plfaccs:connect to access token.desc")]}
            content={
              <Formsy
                id="simple-access-token-form"
                onValidSubmit={screenFlow.bind(null, Action.CONFIRMED_WHATSAPP)}
                // onValid={() => {simple_access_token_valid(true)}}
                // onInvalid={() => {simple_access_token_valid(false)}}
                className="flex flex-col justify-center"
              >
                <TextFieldPFE
                  type="text"
                  className="my-8"
                  name="access_token"
                  label={ttt(
                    "settings-plfaccs:connect to access token.access token"
                  )}
                  placeholder={ttt(
                    "settings-plfaccs:connect to access token.access token eg"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  pfeValidType="plfacc.access_token"
                  variant="outlined"
                  required
                  fullWidth
                />
                <TextFieldPFE
                  type="text"
                  name="identifier"
                  label={ttt(
                    "settings-plfaccs:connect to access token.identifier"
                  )}
                  placeholder={ttt(
                    "settings-plfaccs:connect to access token.identifier eg"
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  pfeValidType="plfacc.identifier"
                  variant="outlined"
                  required
                  fullWidth
                />
              </Formsy>
            }
            onClose={screenFlow.bind(null, "cancel")}
            closeButtonCaption={[ttt("general:cancel")]}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            rightButtonCaption={ttt("general:button.connect")}
            onLeft={screenFlow.bind(null, Action.CANCEL)}
            onRight={() =>
              document
                .getElementById("simple-access-token-form")
                .dispatchEvent(new Event("submit"))
            }
          />
        );

        break;
      }

      myJump = myJump(options);
      debug("HHHH", myJump);
      subWindow = <OpenSubWindow href={myJump}></OpenSubWindow>;
      showDlg = (
        <SingleButtonDialog
          open={true}
          title={ttt("settings-plfaccs:wait connection dialog.title")}
          messages={[
            ttt("settings-plfaccs:wait connection dialog.desc"),
            ttt("settings-plfaccs:wait connection dialog.cancel desc"),
          ]}
          onClose={screenFlow.bind(null, "cancel")}
          closeButtonCaption={[ttt("general:cancel")]}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        />
      );
      break;
    }

    case Scenes.UPDATE:
      showDlg = (
        <PAInfoDialog
          plfacc={flow.plfacc}
          flow_templates={company.flow_templates}
          onCancel={screenFlow.bind(null, "cancel")}
          onOK={async (platform) => screenFlow("save", platform)}
          onDelete={screenFlow.bind(null, Scenes.DELETE)}
        />
      );
      break;

    case Scenes.DELETE:
      showDlg = (
        <DoubleButtonDialog
          title={ttt("settings-plfaccs:delete dialog.title", {
            name: flow.plfacc.name,
          })}
          messages={[ttt("settings-plfaccs:delete dialog.desc")]}
          rightButtonCaption={ttt("general:button.delete")}
          onLeft={screenFlow.bind(null, Action.CANCEL)}
          onRight={screenFlow.bind(null, Action.CONFIRMED)}
        />
      );
      break;

    // case Scenes.SET_PW_FAILED:
    //   showDlg = <
    //     SingleButtonDialog
    //   open = {
    //     true
    //   }
    //   title = {
    //     yttt('title')
    //   }
    //   messages = {
    //     [yttt('desc', {
    //       fullname: fmt(flow.plfacc)
    //     })]
    //   }
    //   onClose = {
    //     screenFlow.bind(null, 'ok')
    //   }
    //   // closeButtonCaption={[ttt('message')]}
    //   />
    //   break;

    default:
      showDlg = null;
      break;
  }

  // Run at component mount time
  useEffect(() => {
    // NOTES: Not using await or then here for actions.
    getAllPlfaccs();
    getFlowTemplates();
    // setScreenFlow({
    //   plfacc: null,
    //   scene: Scenes.CREATE_CHOOSE,
    //   from: null,
    // });
  }, []);

  // Run on state change
  useEffect(() => {
    sort_records(in_plfacc_list);
  }, [in_plfacc_list]);

  return (
    <React.Fragment>
      {subWindow}
      {showDlg}
      <FusePageCarded
        classes={{
          content: "flex",
        }}
        header={
          <PlatformAccountsHeader
            onAddNewStaff={screenFlow.bind(null, Scenes.CREATE_CHOOSE)}
          />
        }
        content={
          <PATable
            order={order}
            selections={selections}
            plfacc_list={records}
            onEditInfo={async (event, pStaff) =>
              screenFlow(Scenes.UPDATE, pStaff)
            }
            onSetPassword={async (event, pStaff) =>
              screenFlow(Scenes.SET_PW, pStaff)
            }
            whenSelect={whenSelect}
            handleSelectAllClick={handleSelectAllClick}
            handleRequestSort={handleRequestSort}
            showMessage={showMessage}
            ttt={ttt}
          />
        }
        innerScroll
      />
      <LoadingOverlay loading={loading} />
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllPlfaccs,
      getFlowTemplates,
      updatePA,
      deletePA,
      // setPassword,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ company }) {
  return {
    in_plfacc_list: company.plfaccs,
    company: company.info,
    subWinHandle: company.handle,
  };
}

let comp = connect(mapStateToProps, mapDispatchToProps)(PlatformAccounts);
comp = withPFEValidators(comp, ["plfacc"]);
export { comp as PlatformAccounts, Scenes };
