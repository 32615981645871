import { createMuiTheme } from "@material-ui/core";
import { fuseDark } from "@fuse/fuse-colors";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import qs from "qs";
import _ from "@lodash";

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
  customScrollbars: true,
  theme: {
    main: "parami",
    navbar: "parami",
    toolbar: "mainThemeLight",
    footer: "parami",
  },
};

export function getParsedQuerySettings() {
  const parsedQueryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  if (parsedQueryString && parsedQueryString.defaultSettings) {
    return JSON.parse(parsedQueryString.defaultSettings);
  }
  return {};

  // Generating route params from settings
  /*const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings);*/
}

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      "Comfortaa",
      "cwTeXYen",
      "Muli",
      "Roboto",
      '"Helvetica"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
};

const defaultTheme = createMuiTheme();

export const mustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "rgb(0 0 0 / 0.2)",
      },
    },
    MuiTooltip: {
      tooltipPlacementTop: {
        margin: "6px 0",
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiTableCell: {
      head: {
        [defaultTheme.breakpoints.down("xs")]: {
          padding: "4px 0px 4px 6px",
        },
        whiteSpace: "nowrap",
        padding: "4px 0px 4px 24px",
      },
      body: {
        [defaultTheme.breakpoints.down("xs")]: {
          padding: "4px 0px 4px 6px",
        },
        whiteSpace: "nowrap",
        padding: "4px 0px 4px 24px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 15px) scale(1)",
      },
      shrink: {
        color: "rgb(0 0 0 / 0.4)",
        background: "white",
        padding: "0 4px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "13.5px 14px",
      },
    },
    MuiInputBase: {
      root: {
        cursor: "auto",
      },
      input: {
        "&$disabled": {
          opacity: 0.3,
        },
      },
    },
    MuiSelect: {
      select: {
        paddingRight: 24,
      },
    },
    MuiSnackbarContent: {
      action: {
        maxHeight: 50,
      },
      root: {
        alignItems: "unset",
      },
    },
  },
};

export const defaultThemes = {
  default: {
    palette: {
      type: "light",
      primary: fuseDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700],
      },
      error: red,
    },
    status: {
      danger: "orange",
    },
  },
  defaultDark: {
    palette: {
      type: "dark",
      primary: fuseDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700],
      },
      error: red,
    },
    status: {
      danger: "orange",
    },
  },
};

export function extendThemeWithMixins(obj) {
  const theme = createMuiTheme(obj);
  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
    }),
  };
}

export function mainThemeVariations(theme) {
  return {
    mainThemeDark: createMuiTheme(
      _.merge({}, defaultThemeOptions, theme, {
        palette: { type: "dark" },
        ...mustHaveThemeOptions,
      })
    ),
    mainThemeLight: createMuiTheme(
      _.merge({}, defaultThemeOptions, theme, {
        palette: { type: "light" },
        ...mustHaveThemeOptions,
      })
    ),
  };
}
