import React, { useState } from "react";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { Tab, Tabs, Button, withStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { i18nNamespaces } from "constants.js";
import { HeaderTitle } from "app/parami-layouts";
import { withPFEValidators } from "app/services/paramiService";

import GeneralTab from "./GeneralTab";
import AddressTab from "./AddressTab";

const styles = (theme) => ({
  footerBar: {
    backgroundColor: theme.palette.grey[100],
  },
});

const Company = (props) => {
  const { classes } = props,
    [tabValue, setTab] = useState(0),
    [canSubGen, setSubmitGen] = useState(false),
    [canSubAddr, setSubmitAddr] = useState(false),
    [form, setForm] = useState(),
    formEl = React.useRef(),
    { t: ttt } = useTranslation();
  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: "flex",
      }}
      header={
        <HeaderTitle
          key="AccountHeader"
          text={ttt("settings-company:page header.title")}
          icon="business"
          button={{
            text:
              tabValue === 0
                ? ttt("settings-company:save general tab")
                : ttt("settings-company:save address tab"),
            type: "submit",
            className: "whitespace-no-wrap",
            form: form,
            "aria-label": "SAVE",
            disabled:
              !(tabValue === 0 && canSubGen) && !(tabValue === 1 && canSubAddr),
          }}
        ></HeaderTitle>
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          onChange={(event, value) => setTab(value)}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          classes={{ root: "w-full" }}
        >
          <Tab
            className="normal-case"
            label={ttt("settings-company:tabs.general")}
          />
          <Tab
            className="normal-case"
            label={ttt("settings-company:tabs.address")}
          />
        </Tabs>
      }
      content={
        (tabValue === 0 && (
          <GeneralTab
            formEl={(el) => {
              if (!el) return formEl;
              setForm(el.props.id);
              formEl.current = el;
            }}
            setSubmitButton={setSubmitGen}
          />
        )) ||
        (tabValue === 1 && (
          <AddressTab
            formEl={(el) => {
              if (!el) return formEl;
              setForm(el.props.id);
              formEl.current = el;
            }}
            setSubmitButton={setSubmitAddr}
          />
        ))
      }
    />
  );
};

let myComp = withStyles(styles, { withTheme: true })(Company);
myComp = withPFEValidators(myComp, ["company", "general"]);
export default myComp;
