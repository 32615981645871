import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ld from "lodash";
import Masonry from "react-masonry-css";
// import * as imupdate from 'object-path-immutable';

import { i18nNamespaces } from "constants.js";
import Platforms from "app/data/platforms";
import PlatformCard from "./PlatformCard";

const styles = (theme) => ({});

const all_platforms = ld
  .chain(Platforms)
  .pickBy(
    (platform) =>
      platform.oauth_url || platform.simple_access_token || platform.simple_id
  )
  .map((platform) => ({ ...platform, chosen: false }))
  .value();
const ChoosePlatformDialog = (props) => {
  const { onCancel, onNext } = props,
    { t: ttt } = useTranslation(),
    [choice, setChoice] = useState(null);
  const onCardClicked = (code) => {
    onNext(code);
  };

  return (
    <Dialog
      id="choosePlatformDialog"
      classes={{
        paper: "m-24",
      }}
      open={true}
      onClose={onCancel}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className="text-center">
        {ttt("settings-plfaccs:choose platform dialog.title")}
      </DialogTitle>

      <DialogContent>
        <Masonry
          breakpointCols={{
            default: 1,
            600: 1,
            480: 1,
          }}
          className="my-masonry-grid w-full"
          columnClassName="my-masonry-grid_column p-8 text-center"
        >
          {// Notes: Lodash map used - since mapping from map to array.
          all_platforms.map((platform) => (
            <PlatformCard
              key={platform.code}
              platform={platform}
              chosen={platform.code === choice}
              className={
                platform.code == "asangaweb" ? "mb-8" : "rounded-full mb-8"
              }
              onClick={onCardClicked}
            />
          ))}
        </Masonry>
      </DialogContent>
    </Dialog>
  );
};
/*
        <Typography color="textSecondary" className="pb-24">
          {ttt("settings-plfaccs:choose platform dialog.desc")}
        </Typography>
        <Typography color="textPrimary" className="pb-24">
          {ttt("settings-plfaccs:choose platform dialog.action desc")}
        </Typography>

      <DialogActions>
        <Button aria-label="CANCEL" color="primary" onClick={onCancel}>
          {ttt("general:button.cancel")}
        </Button>
        <Button
          type="submit"
          aria-label="SAVE"
          disabled={!choice}
          onClick={onNext.bind(null, choice)}
          color="primary"
        >
          {ttt("general:button.ok")}
        </Button>
      </DialogActions>

*/
let myComp = withStyles(styles, { withTheme: true })(ChoosePlatformDialog);
export default myComp;
