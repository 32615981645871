import React from "react";
import {
  AppBar,
  Hidden,
  MuiThemeProvider,
  Toolbar,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";

const styles = (theme) => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
});

const ToolbarLayout4 = ({ classes, settings, toolbarTheme }) => {
  const layoutConfig = settings.layout.config;

  return (
    <MuiThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className="flex z-10 absolute h-auto rounded-full"
        color="default"
        style={{
          left: "10px",
          top: "90%",
          opacity: "0.8",
          width: "fit-content",
        }}
      >
        <Toolbar variant="dense" className="p-0">
          {layoutConfig.navbar.display &&
            layoutConfig.navbar.position === "left" && (
              <Hidden lgUp>
                <NavbarMobileToggleButton className="w-48 h-48 p-0" />
              </Hidden>
            )}

          {layoutConfig.navbar.display &&
            layoutConfig.navbar.position === "right" && (
              <Hidden lgUp>
                <NavbarMobileToggleButton />
              </Hidden>
            )}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

function mapStateToProps({ fuse }) {
  return {
    settings: fuse.settings.current,
    toolbarTheme: fuse.settings.toolbarTheme,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps)(ToolbarLayout4))
);
