// import Debug from 'debug';

import { Dashboard as DBConst } from "constants.js";
import { asyncThunk } from "app/utils/common";
import { REST } from "app/client_proxied";

// const debug =  Debug('pfe:store:dashboard:actions');

export const GET_CHARTS = "[DASHBOARD] GET CHARTS";

export function getTable(name, filters) {
  const { plfaccs, duration } = filters;

  return asyncThunk(async (dispatch) => {
    /*
    const access_token = auth0Service.getAccessToken(),
          api = new DashboardApi({
            accessToken: access_token,
          })
          ;
          */

    // debug('getTable', plfaccs, duration);
    const stat_raw = (
      await REST.DashboardApi.dashboardTablePost({
        data_type: name,
        date_range: duration || "year",
        // NOTES: If plfaccs not given, PBE gives stats of all plfaccs;
        plfaccs: plfaccs === DBConst.ALL ? undefined : plfaccs,
      })
    ).data.Result;
    // debug('getTable - stat_raw', stat_raw);

    dispatch({
      type: GET_CHARTS,
      data: {
        [name]: { rows: stat_raw },
      },
    });
  });
}

export function getLineChart(name, filters) {
  const { plfaccs, duration } = filters;
  // debug('getLineChart', plfaccs, duration);

  return asyncThunk(async (dispatch) => {
    /*
    const access_token = auth0Service.getAccessToken(),
          api = new DashboardApi({
            accessToken: access_token,
          })
          ;
          */

    // debug('getLineChart', plfaccs, duration);
    const stat_raw = (
      await REST.DashboardApi.dashboardLineChartPost({
        data_type: name,
        date_range: duration,
        // NOTES: If plfaccs not given, PBE gives stats of all plfaccs;
        plfaccs: plfaccs === DBConst.ALL ? undefined : plfaccs,
      })
    ).data.Result;
    // debug('getLineChart - stat_raw', stat_raw);

    const chartData = {
      chartType: "line",
      labels: stat_raw.label,
      datasets: stat_raw.lines.map((ds) => ({
        label: ds.name,
        data: ds.data,
        fill: "start",
      })),
    };

    dispatch({
      type: GET_CHARTS,
      data: {
        [name]: chartData,
      },
    });
  });
}
