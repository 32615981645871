/** @module */
import React from "react";
import { withStyles, FormControlLabel, Switch } from "@material-ui/core";

const styles = {
  label: {
    width: "100%",
  },
};

let SwitchField = (props) => {
  return (
    <FormControlLabel
      className="flex px-4 mx-0"
      control={<Switch {...props} />}
      labelPlacement="start"
      classes={{
        label: props.classes.label,
      }}
      label={props.label}
    />
  );
};

SwitchField = withStyles((theme) => styles, { withTheme: true })(SwitchField);

export default SwitchField;
