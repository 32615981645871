import * as imupdate from "object-path-immutable";
import {
  GET_STAFF,
  UPDATE_STAFF,
  DELETE_STAFF,
} from "app/store/actions/company/staff.actions";

const staff = function (state = {}, action) {
  switch (action.type) {
    case GET_STAFF:
      return action.staff;

    case UPDATE_STAFF:
      const { staff } = action,
        curr_staff = state[staff.user_id];
      return imupdate.set(state, staff.user_id, {
        ...curr_staff,
        ...staff,
      });

    case DELETE_STAFF: {
      const { user_id } = action;
      return imupdate.del(state, user_id);
    }

    default: {
      return state;
    }
  }
};

export default staff;
