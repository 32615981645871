import React, { useState, useEffect } from "react";
import clsx from "clsx";
// import AsyncSelect from 'react-select/lib/Async';
import Select from "react-select";
import * as ld from "lodash";
import classNames from "classnames";
import { withFormsy } from "formsy-react";
import Debug from "debug";
import { useTranslation } from "react-i18next";

import {
  withStyles,
  TextField,
  Paper,
  MenuItem,
  Typography,
  FormHelperText,
  IconButton,
  Chip,
} from "@material-ui/core";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { i18nNamespaces } from "constants.js";

// const someAPI = searchText =>
//   new Promise(resolve => {
//     setTimeout(() => {
//       resolve(
//         teams.filter(
//           team =>
//             searchText &&
//             team.label
//               .toLowerCase()
//               .includes(searchText.toLowerCase())
//         )
//       );
//     }, 10);
//   });

const debug = Debug("pfe:ui:AutoComplete");

const marginLeft = 16;

const styles = (theme) => ({
  root: {},
  input: {
    display: "flex",
    padding: "4px 0",
    height: "4.8rem",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    marginLeft: marginLeft,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: marginLeft,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing.unit * 1,
    left: 0,
    right: 0,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#F8F8F8",
    opacity: 1,
  },
  chip: {
    margin: `${(theme.spacing.unit * 1) / 2}px ${(theme.spacing.unit * 1) /
      4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  ClearIndicator: {
    padding: "7px 7px",
  },
  DropdownIndicator: {
    padding: "7px 7px",
  },
  helperText: {
    margin: "8px 12px",
  },
});

const NoOptionsMessage = (props) => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = (props) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },
    }}
    variant="outlined"
    {...props.selectProps.textFieldProps}
  />
);

const Option = (props) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = (props) => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SingleValue = (props) => (
  <Typography
    className={props.selectProps.classes.singleValue}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const ValueContainer = (props) => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);

const Menu = (props) => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
);

const IndicatorSeparator = () => null;

const ClearIndicator = (props) => (
  <IconButton
    className={props.selectProps.classes.ClearIndicator}
    {...props.innerProps}
  >
    <CancelIcon />
  </IconButton>
);

const DropdownIndicator = (props) => (
  <IconButton
    className={props.selectProps.classes.DropdownIndicator}
    {...props.innerProps}
  >
    <ArrowDropDownIcon />
  </IconButton>
);

const MultiValue = (props) => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={clsx(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
  />
);

// const LoadingIndicator = () => <CircularProgress size={20} />;

// const LoadingMessage = props => (
//   <Typography
//     color="textSecondary"
//     className={props.selectProps.classes.noOptionsMessage}
//     {...props.innerProps}
//   >
//     {props.children}
//   </Typography>
// );

function set_intValue_by_match_options(
  pOptions,
  pValue,
  pPure,
  pSetter,
  pCheckError
) {
  const testVal = pPure ? pValue : pValue.value,
    emptyResult = pPure ? null : { label: null, value: null };
  let result = pOptions.filter((opt) => opt.value === testVal);
  result = result.length === 1 ? result[0] : emptyResult;
  // debug('result', result, pOptions, pValue, pPure);
  pSetter(result);
  pCheckError(result);

  return result;
}

const Autocomplete = (props) => {
  const {
      classes,
      value: pVal,
      options: pOptions,
      name,
      isPureValue,
      className,
      required,
      error: pError = false,
      getErrorMessage,
      isDisabled,
    } = props,
    { onChange: pOnChange, setValue } = props,
    { t: ttt } = useTranslation(),
    [error, setError] = useState(pError);
  const [intValue, setIntValue] = useState();

  // debug('Autocomplete', error);

  const check_error = (tuple) => {
    const missing_required =
      required &&
      (isPureValue
        ? ld.isEmpty(tuple) || ld.isNil(tuple.value)
        : ld.isEmpty(tuple));
    // debug('check_error', missing_required);
    let msg = getErrorMessage();
    if (missing_required) msg = ttt("form-fields:validations.required");
    else if (pError) msg = pError;

    setError(msg);
  };

  const onChange = (tuple) => {
    debug("onChange", tuple);
    if (pOnChange) {
      isPureValue
        ? pOnChange(
            {
              target: {
                name,
                type: "AutoComplete",
                value: tuple ? tuple.value : null,
              },
            },
            Boolean(error)
          )
        : pOnChange(tuple);
    }
    setValue(isPureValue ? tuple && tuple.value : tuple);
    setIntValue(tuple);
    check_error(tuple);
  };

  // Notes: Make component react to "options" change. When options are
  // changed, the label or value pairs are different, so the internal
  // value should be updated.
  if (isPureValue) {
    useEffect(() => {
      set_intValue_by_match_options(
        pOptions,
        pVal,
        isPureValue,
        setIntValue,
        check_error
      );
    }, [pVal, pOptions]);
  } else {
    useEffect(() => {
      // Notes: Allow to keep value if options are not available,
      // specially at the early loading time.
      if (!pOptions || pOptions.length === 0) return;

      const result = set_intValue_by_match_options(
        pOptions,
        pVal,
        isPureValue,
        setIntValue,
        check_error
      );
      // Notes: Update the value outside too since the options change
      // may lead to a matched value, but different label.
      pOnChange(result);
    }, [pOptions]);

    useEffect(() => {
      setIntValue(pVal);
    }, [pVal]);
  }

  // Filter props
  const propsFiltered = ld.pickBy(
    props,
    (val, key) =>
      !["isPureValue", "value", "onChange", "label", "className"].includes(key)
  );

  // return (
  //   <div className={classes.root}>
  //     <AsyncSelect
  //       value={value}
  //       onChange={value => setValue(value)}
  //       textFieldProps={{
  //         label: 'Team',
  //         InputLabelProps: {
  //           shrink: true
  //         }
  //       }}
  //       {...{ ...props, classes }}
  //     />
  //   </div>
  // );

  return (
    <div className={classNames(classes.root, className)}>
      <Select
        style={{
          singleValue: (provided, state) => {
            const color = state.isDisabled
              ? "rgba(0, 0, 0, 0.38)"
              : "currentColor";

            return { ...provided, color };
          },
        }}
        value={intValue}
        onChange={onChange}
        className={classNames("w-full")}
        textFieldProps={{
          error: Boolean(error),
          label: props.label,
          InputLabelProps: {
            shrink: true,
          },
          disabled: isDisabled,
        }}
        placeholder={ttt("general:Select")}
        isDisabled={isDisabled}
        {...{ ...propsFiltered, classes }}
      />
      {Boolean(error) && (
        <FormHelperText className={classes.helperText} error>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

Autocomplete.defaultProps = {
  cacheOptions: true,
  defaultOptions: true,
  // loadOptions: someAPI,
  isMulti: false,
  isClearable: true,
  isPureValue: false,
  // options: teams,
  components: {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    MultiValue,
    ValueContainer,
    IndicatorSeparator,
    ClearIndicator,
    DropdownIndicator,
    // LoadingIndicator,
    // LoadingMessage
  },
};

let myComp = withStyles(styles, { withTheme: true })(Autocomplete);
myComp = withFormsy(myComp);
export default myComp;
