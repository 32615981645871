import ld from "@lodash";

import { GET_VALIDATIONS } from "app/store/actions/parami";

const initialState = {};

const user_pref = function (state = initialState, action) {
  switch (action.type) {
    case GET_VALIDATIONS: {
      const { validations } = action;
      ld.mapValues(validations, (vals, ns) => {
        state[ns] = vals;
      });
      return state;
    }

    default: {
      return state;
    }
  }
};

export default user_pref;
