import * as imupdate from "object-path-immutable";
import {
  GET_PLFACCS,
  UPDATE_PLFACC,
  DELETE_PLFACC,
} from "app/store/actions/company/platformAccounts.actions";

const plfaccs = function (state = {}, action) {
  switch (action.type) {
    case GET_PLFACCS:
      const { plfaccs } = action;
      return plfaccs;

    case UPDATE_PLFACC: {
      const { plfacc } = action,
        orig = state[plfacc.id];
      return {
        ...state,
        [plfacc.id]: { ...orig, ...plfacc },
      };
    }

    case DELETE_PLFACC: {
      const { plfacc_id } = action;
      return imupdate.del(state, plfacc_id);
    }

    default: {
      return state;
    }
  }
};

export default plfaccs;
