import { asyncThunk } from "app/utils/common";
import { REST } from "app/client_proxied";
import _ from "lodash";
import Debug from "debug";
const debug = Debug("pfe:varaibles-actions");

export const SET_GLOBALS = "[GLOBALS] SET VARIABLES";

export function getGlobals() {
  return asyncThunk(async (dispatch) => {
    debug("getGlobals");

    const unwrap = (x) => x.then((r) => r.data.Result);

    const [fallback_types, system_variables] = await Promise.all([
      unwrap(REST.FallbacksApi.fallbacksGet({})),
      unwrap(REST.SystemVariablesApi.systemVariablesGet({})),
    ]);

    const data = {
      fallback_types: _.sortBy(fallback_types, ["id"]).map((fallback) => ({
        ...fallback,
        examples: fallback.examples.reduce(function(acc, ex) {
          acc[ex.language_id] = ex.example;
          return acc;
        }, {}),
      })),
      system_variables,
      system_variable_to_name: system_variables.reduce((acc, v) => {
        acc[`a.${v.id}`] = v.variable_name;
        return acc;
      }, {}),
    };
    debug("getGlobals", data);

    dispatch({
      type: SET_GLOBALS,
      data: data,
    });
  });
}

export function setGlobalVariables(data) {
  return {
    type: SET_GLOBALS,
    data,
  };
}
