import io from "socket.io-client";
import { Test } from "constants.js";
import uniqID from "uniqid";

const event_hdls_for_client = {},
  event_hdls_for_server = {};
const session_id = uniqID();

function genLogging() {
  return function (...args) {
    const { logging: toLog = true } = this.options;
    if (toLog) console.log(...args);
  };
}

function gen_on(queue) {
  return function (event, func) {
    if (queue[event]) queue[event].push(func);
    else queue[event] = [func];
    // this.logging('on event', event, 'finished.');
  };
}

function gen_once(queue) {
  return function (event, func) {
    if (queue[event]) queue[event].push(func);
    else queue[event] = [func];
  };
}

function gen_emit(queue, name) {
  return function (event, ...args) {
    this.logging(`${name}`, event);
    const hdls = queue[event];
    if (!hdls || hdls.length === 0) {
      this.logging(`${name} No handlers registered for event:`, event);
      return;
    }
    queue[event].forEach((func) => func(...args));
  };
}

function gen_remove_listener(queue, name) {
  return function (event, func, ...args) {
    let arr = queue[event];
    arr = arr.filter((fn) => fn !== func);
    queue[event] = arr;
  };
}

const client_socket = {
  options: {
    logging: false,
  },
  id: session_id,
  logging: genLogging(),
  on: gen_on(event_hdls_for_client),
  once: gen_once(event_hdls_for_client),
  emit: gen_emit(event_hdls_for_server, "[client_socket.emit]"),
  removeListener: gen_remove_listener(
    event_hdls_for_client,
    "[client_socket.removeListener]"
  ),
};

let server_socket;
export function createServerSocket(name) {
  server_socket = {
    options: {
      logging: false,
    },
    getClientSessionID: () => session_id,
    logging: genLogging(),
    on: gen_on(event_hdls_for_server),
    once: gen_once(event_hdls_for_server),
    emit: gen_emit(event_hdls_for_client, "[server_socket.emit]"),
    removeListener: gen_remove_listener(
      event_hdls_for_server,
      "[server_socket.removeListener]"
    ),
  };
  return server_socket;
}

// Client
const fakeClient = {
  connect() {
    if (server_socket) {
      console.log("Fake IO:connect");
      setTimeout(() => {
        server_socket.emit("connect");
      }, 1000);
      return client_socket;
    } else {
      console.log("Fake IO: Server not ready");
    }
  },
};

export const client = Test.sio_test_mode ? fakeClient : io;

export function getCurrServer() {
  return server_socket;
}

export function useServer(name, serverCreateFunc) {
  console.log("[useServer]");
  serverCreateFunc(server_socket);
}

export function removeServer() {
  console.log("[removeServer]");
  Object.keys(event_hdls_for_server).map((kk) => {
    delete event_hdls_for_server[kk];
    return null;
  });
}
