import { normalize, schema } from "normalizr";
import Debug from "debug";

import { REST } from "app/client_proxied";
import { asyncThunk } from "app/utils/common";
import {
  allowed,
  AA_UPDATE_STAFF,
  AA_CREATE_STAFF,
  AA_DELETE_STAFF,
} from "app/services/paramiService/authority";
import ParamiError, {
  DISALLOW_UPDATE_STAFF,
  DISALLOW_CREATE_STAFF,
  DISALLOW_DELETE_STAFF,
} from "app/utils/ParamiError";

const debug = Debug("pfe:settings:staff:main");

export const GET_STAFF = "[SETTINGS][COMPANY] GET STAFF";
export const UPDATE_STAFF = "[SETTINGS][COMPANY] UPDATE STAFF";
export const DELETE_STAFF = "[SETTINGS][COMPANY] DELETE STAFF";
export const SET_PW = "[SETTINGS][COMPANY] SET STAFF PW";

const UserSchema = new schema.Entity(
  "users",
  {},
  {
    idAttribute: "user_id",
    processStrategy: (value, parent, key) => {
      const result = {
        ...value,

        // NOTES: Removed unused fields
        _id: undefined,
        uid: undefined,
      };

      delete result._id;
      delete result.uid;

      return result;
    },
  }
);

export function getAllStaff() {
  // const request = axios.get(`/api/company/staff`);

  return asyncThunk(async (dispatch, getState) => {
    const users_raw = (await REST.UsersApi.usersGet()).data,
      users_norm = normalize(users_raw, new schema.Array(UserSchema)),
      staff = users_norm.entities.users;
    if (staff)
      dispatch({
        type: GET_STAFF,
        staff,
      });
  });
}

export function createStaff(staff) {
  if (!allowed(AA_CREATE_STAFF, staff)) {
    throw new ParamiError(DISALLOW_CREATE_STAFF);
  }

  return asyncThunk(async (dispatch, getState) => {
    const staff_from_pbe = (await REST.UsersApi.usersPost(staff)).data;

    if (staff_from_pbe)
      dispatch({
        type: UPDATE_STAFF,
        staff: staff_from_pbe,
      });
  });
}

export function updateStaff(staff) {
  if (!allowed(AA_UPDATE_STAFF, staff)) {
    throw new ParamiError(DISALLOW_UPDATE_STAFF);
  }

  return asyncThunk(async (dispatch) => {
    const staff_id = staff.user_id,
      staff_to_submit = {
        ...staff,
        user_id: undefined,
        user_type: undefined,
      };

    // TOOD: Error handling
    await REST.UsersApi.usersUserIdPatch(staff_id, staff_to_submit);

    // Remove password if exist AFTER submission
    delete staff.password;

    return dispatch({
      type: UPDATE_STAFF,
      staff,
    });
  });
}

export function setPassword(staff, password) {
  if (!allowed(AA_UPDATE_STAFF, staff)) {
    throw new ParamiError(DISALLOW_UPDATE_STAFF);
  }

  return asyncThunk(async (dispatch) => {
    await REST.UsersApi.usersUserIdPatch(staff.user_id, {
      password: password,
    });

    return dispatch({
      type: SET_PW,
    });
  });
}

export function deleteStaff(staff) {
  if (!allowed(AA_DELETE_STAFF, staff)) {
    throw new ParamiError(DISALLOW_DELETE_STAFF);
  }

  return asyncThunk(async (dispatch) => {
    const ret = (await REST.UsersApi.usersUserIdDelete(staff.user_id)).data;
    debug("deleteStaff", ret.data);

    return dispatch({
      type: DELETE_STAFF,
      user_id: staff.user_id,
    });
  });
}
