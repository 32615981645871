import React from "react";
import {
  AppBar,
  Avatar,
  Typography,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import classNames from "classnames";

import { inAsset } from "app/utils/common";
import { fullname } from "app/utils/entities/user";
import history from "history.js";

import withEnhancedRouter from "app/parami-layouts/withEnhancedRouter";

const styles = (theme) => ({
  root: {
    "& .user": {
      "& .username, & .email": {
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
    background: "#fafafa",
  },
  avatarWrapper: {
    width: 48,
    height: 48,
    position: "absolute",
    top: "2.0em",
    boxSizing: "content-box",
    left: "50%",
    transform: "translateX(-50%)",
  },
  avatar: {
    width: 48,
    height: 48,
    padding: 8,
    boxSizing: "content-box",
    "& > img": {
      borderRadius: "50%",
    },
  },
});

const UserNavbarHeader = ({ user, fullname, classes, goTo }) => {
  const { t: ttt } = useTranslation();

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      classes={{ root: classes.root }}
      className="user relative flex flex-col items-center justify-center pb-40 pt-12 mb-40 z-3"
    >
      <Typography
        className="username text-16 whitespace-no-wrap"
        color="inherit"
      >
        {fullname || fullname}
      </Typography>
      <IconButton
        className={classNames(classes.avatarWrapper, "avatar-wrapper")}
        onClick={() => {
          goTo("/settings");
        }}
      >
        <Avatar
          className={classNames(classes.avatar, "avatar")}
          alt={ttt("general:user photo")}
          src={
            user.data.photoURL && user.data.photoURL !== ""
              ? user.data.photoURL
              : inAsset("images/avatars/profile.jpg")
          }
        />
      </IconButton>
    </AppBar>
  );
};

function mapStateToProps({ fuse, auth }) {
  return {
    user: auth.user,
    fullname: fullname(),
  };
}

const NavbarHeader = withStyles(styles, { withTheme: true })(
  withEnhancedRouter(connect(mapStateToProps)(UserNavbarHeader))
);

export default NavbarHeader;
