import * as _client from "../client";

// const api_names = Object.keys(_client).filter(e=>e.endsWith('Api'));

import { auth0Service } from "app/services/auth0Service";

import { Purpose } from "constants.js";
import { BASE_PATH } from "app/client/base.js";
import { asyncThunk } from "app/utils/common";
import store from "app/store";
import ld from "@lodash";
import { showMessage } from "app/store/actions/fuse";

import Debug from "debug";

import i18n from "i18n.js";
const ttt = i18n.t.bind(i18n);
const debug = Debug("pfe:rest-proxied");
var console_event_header_css =
  "padding:0.3em 0.7em;background: #222; color: #bada55;font-weight:900;";
const hlog = (msg, ...args) => {
  debug("%c" + msg.replace(/%/g, "%%"), console_event_header_css);
};

/**
 * This attempts to simplify and add error handling to  API calls.
 * 
 * @summary Sample standard API calls:
 * 
    const access_token = auth0Service.getAccessToken();
    const api = new UsersApi({
      accessToken: access_token,
    });
    const ret = (await api.usersUserIdPatch(...)).data;
 * 
 * 
 * @summary  Sample API calls *after* modification:
 * 
    const ret = (await REST.UsersApi.usersUserIdPatch()).data
 * 
 * 
 */

const simple_handler = (key_prefix, fallback) => {
  return async (error) => {
    let { data } = error.response,
      { sub_err_code, err_msg } = data,
      key = `${key_prefix}.${sub_err_code}`,
      message = ttt(key),
      fallback_message = ttt(fallback);
    if (message === key) message = err_msg || fallback_message;

    store.dispatch(showMessage({ message, variant: "default" }));
  };
};

const handlers = {
  "/users": {
    post: {
      422: simple_handler(
        `settings-user:errors`,
        `settings-user:actions.update failed`
      ),
      409: simple_handler(
        `settings-user:errors`,
        `settings-user:actions.update failed`
      ),
    },
  },
};

const getter = (unwrapper) =>
  function(client, apiName) {
    if (!(apiName in client)) throw "Access to non-existent API";

    const proxied_api_call = new Proxy(
      {},
      {
        get: function(_, callName) {
          return async function(...args) {
            let retry = 0;
            const call = async () => {
              try {
                hlog("Calling " + callName + "...");
                if (args.length) debug(args);

                const access_token = auth0Service.getAccessToken();
                const api_cls = client[apiName];
                const api = new api_cls({
                  accessToken: access_token,
                });

                // await REST.UsersAPI.usersUserIdPatch(...).data
                //            ^^^^^^^^  = api (object)

                // await REST.UsersAPI.usersUserIdPatch(...).data
                //                     ^^^^^^^^^^^^^^^^  = proxied_api_call (function)

                const res = await api[callName](...args);
                if (unwrapper !== undefined) return unwrapper(res);
                return res;
              } catch (error) {
                const ret = await handle_error(error);
                if (ret !== undefined) return ret;
                throw error;
              }
            };
            const handle_error = async (error) => {
              retry += 1;
              if (Purpose.notProduction) {
                window.last_error = error;
                console.error(error);
              }
              if (error.response) {
                const status_code = error.response.status,
                  method = error.config.method,
                  endpoint = error.request.responseURL.replace(BASE_PATH, "");
                console.error("Caught REST API error", {
                  status_code,
                  method,
                  endpoint,
                });
                const handler_path = `${endpoint}.${method}.${status_code}`;
                const handler = ld.get(handlers, handler_path);
                if (handler) {
                  console.error(`handler exists at ${handler_path}`);
                  try {
                    await handler(error);
                  } catch (error2) {
                    if (Purpose.notProduction) {
                      window.last_error = error;
                      console.error(error);
                    }
                    const message = ttt(`general:Something went wrong`);
                    store.dispatch(
                      showMessage({ message, variant: "default" })
                    );
                  }
                } else {
                  let error_msg = "";
                  switch (status_code) {
                    case 401: // Unauthorized
                      if (!error_msg) error_msg = "Unauthorized";
                    case 408: // Timeout
                      if (!error_msg) error_msg = "Timeout";
                      if (retry <= 1) {
                        console.error("Refreshing session...");
                        // Refresh token before retry
                        await auth0Service.refreshSession();
                        // Retry once
                        return await call();
                      } else {
                        store.dispatch(
                          showMessage({
                            message: ttt(`general:${error_msg}`),
                            variant: "default",
                          })
                        );
                      }
                    default:
                    // Do nothing if no corresponding handler
                  }
                }
              } else {
                const message = ttt(`general:Something went wrong`);
                store.dispatch(showMessage({ message, variant: "default" }));
              }
            };
            return await call();
          };
        },
      }
    );

    return proxied_api_call;
  };

export const REST = new Proxy(_client, {
  get: getter((e) => e),
});

// Unwraps parami server response for easy consumption
const API_WRAP = (res) => {
  if (res.data.msg) {
    res.data = res.data.Result;
  }
  return res;
};

export const REST2 = new Proxy(_client, {
  get: getter(API_WRAP),
});
