import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  MuiThemeProvider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createMuiTheme,
  withStyles,
} from "@material-ui/core";
import * as imupdate from "object-path-immutable";
import classNames from "classnames";
import Debug from "debug";
import ld from "@lodash";

import { i18nNamespaces, Dashboard as DBConst } from "constants.js";
import FilterSelect from "./FilterSelect";
import Curtain from "./Curtain";
import { getTable, showMessage } from "app/store/actions";

// const debug = Debug('pfe:dashboard:StatTable');

const PLFACCS_ALL = DBConst.ALL;

const styles = (theme) => ({
  root: {
    "& #chart-button-group-vert button": {
      margin: "0px 2px",
      padding: "4px 2px",
      minWidth: "unset",
      width: 40,
      height: 40,
    },
  },
  filterSelect: {
    width: "calc(100% - 250px)",
  },
  tableRow: {
    fontSize: 27,
  },
  cardContent: {
    // NOTES: Height is needed for Curtain to cover correctly.
    height: 400,
  },
});

const widgetTheme = (origTheme) =>
  createMuiTheme({
    // Notes: To extend from outter theme, the original theme has to be used.
    ...origTheme,
    palette: {
      type: "light",
      primary: {
        light: "#EEEEEE",
        main: "#a391b9",
        dark: "#7C51AB",
        contrastText: "#222",
      },
      secondary: {
        light: "#B2E7ED",
        main: "#00BDD6",
        dark: "#325474",
      },
    },
    overrides: {
      MuiTableCell: {
        head: {
          fontSize: 20,
          borderBottom: "none",
          // borderBottom: '1px solid white',
        },
        body: {
          fontSize: 16,
          borderBottom: "none",
        },
      },
    },
  });

const tableConfig = {
  id_column: "flow_name",
  columns: ["runs"],
};

const StatTable = (props) => {
  const { theme, data, filters, scales, className: cns, name, classes } = props,
    { getTable, showMessage } = props,
    rows = ld.get(data, "rows"),
    [choices, setChoices] = useState({
      scale: scales[0],
      plfacc_ids: [PLFACCS_ALL],
    }),
    [showCurtain, setShowCurtain] = useState(false),
    { t: ttt } = useTranslation(),
    debug = useRef(Debug(`pfe:dashboard:${name}`));
  const changeScale = (scale) => {
    update_table(imupdate.set(choices, "scale", scale));
  };

  const onFilterClose = (plfacc_ids) => {
    update_table(imupdate.set(choices, "plfacc_ids", plfacc_ids));
  };

  const update_table = async (newChoices) => {
    setShowCurtain(true);
    // debug.current('choices', newChoices);

    // Notes: Only pass "All" when loading data from PBE
    let pfids = newChoices.plfacc_ids;
    if (pfids.includes(PLFACCS_ALL)) pfids = [PLFACCS_ALL];

    try {
      await getTable(name, {
        plfaccs: pfids,
        duration: newChoices.scale,
      });
    } catch (err) {
      debug.current("update_table: err", err.stack);
      showMessage({
        message: ttt("dashboard:load table failed", { tableName: name }),
      });
    } finally {
      setChoices(newChoices);
    }

    setShowCurtain(false);
  };

  useEffect(() => {
    update_table(choices);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiThemeProvider theme={widgetTheme}>
        <Card className={classNames(classes.root, cns)}>
          <CardHeader title={ttt(`dashboard:tables.${name}.title`)} />
          <CardContent className={classes.cardContent}>
            <div className="flex flex-row items-center justify-between">
              <FilterSelect
                className={classes.filterSelect}
                filters={filters.plfaccs}
                limit={filters.limit}
                initSelection={choices.plfacc_ids}
                ttt={(str, opts) =>
                  ttt(`dashboard:tables.${name}.filter.${str}`, opts)
                }
                clearMenu
                allMenu={{ display_me_only: true }}
                onClose={onFilterClose}
                enable={!showCurtain}
              />
              <div id="chart-button-group-vert">
                {scales.map((scale) => (
                  <Button
                    key={scale}
                    variant={scale === choices.scale ? "contained" : null}
                    onClick={() => changeScale(scale)}
                    disabled={showCurtain}
                  >
                    {ttt(`dashboard:tables.${name}.scales.${scale}`)}
                  </Button>
                ))}
              </div>
            </div>
            <Table className={"w-512 mx-auto mt-16"}>
              <TableHead>
                <TableRow className="h-48">
                  <TableCell>
                    {ttt(
                      `dashboard:tables.${name}.labels.${tableConfig.id_column}`
                    )}
                  </TableCell>
                  {tableConfig.columns.map((colName) => (
                    <TableCell key={`title-${colName}`} className="text-right">
                      {ttt(`dashboard:tables.${name}.labels.${colName}`)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, ind) => (
                    <TableRow key={ind} className={classes.tableRow}>
                      <TableCell key={row.title} id={row.title}>
                        {row[tableConfig.id_column]}
                      </TableCell>
                      {tableConfig.columns.map((colName) => {
                        return (
                          <TableCell
                            key={`data-${row.title}-${colName}`}
                            className="text-right"
                          >
                            {row[colName]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {showCurtain && <Curtain />}
          </CardContent>
        </Card>
      </MuiThemeProvider>
    </MuiThemeProvider>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTable,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ fuse, dashboard }, { name }) {
  const data = ld.get(dashboard, `charts.${name}`);
  return {
    theme: fuse.settings.mainThemeLight,
    data,
  };
}

let myComp = connect(mapStateToProps, mapDispatchToProps)(StatTable);
export default withStyles(styles, { withTheme: true })(myComp);
