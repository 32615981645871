import React, { Component } from "react";
import { withStyles, Drawer, Typography } from "@material-ui/core/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as Actions from "./store/actions/index";

const styles = (theme) => ({
  root: {
    width: 280,
    padding: 24,
  },
});

class QuickPanel extends Component {
  render() {
    const { classes, state, toggleQuickPanel, t: ttt } = this.props;
    return (
      <Drawer
        classes={{ paper: classes.root }}
        open={state}
        anchor="right"
        onClose={() => toggleQuickPanel(false)}
      >
        <Typography>{ttt("Quick Panel")}</Typography>
      </Drawer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleQuickPanel: Actions.toggleQuickPanel,
    },
    dispatch
  );
}

function mapStateToProps({ quickPanel }) {
  return {
    state: quickPanel.state,
  };
}

let myQuickPanel = withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(QuickPanel)
);
myQuickPanel = withTranslation("general")(myQuickPanel);
export default myQuickPanel;
