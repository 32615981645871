import { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSubWindowHandle } from "app/store/actions/company/platformAccounts.actions";

const styles = (theme) => ({});

// https://medium.com/hackernoon/using-a-react-16-portal-to-do-something-cool-2a2d627b0202

// Detect sub-window close:
// https://stackoverflow.com/questions/781770/can-the-parent-window-be-notified-when-a-child-window-closes-on-a-diff-domain

// https://stackoverflow.com/questions/25098573/how-to-call-parent-window-function-from-child-window-jquery

// 1. PFE call PBE for connect platform request: /plfacc/connect?request_id=[random id];
// 2. PBE returns link to facebook "dialog/oauth", with state = [random id];
// 3. PFE receives that link and open in sub-window;
// 4. PFE main windows becomes modal and wait for sub-window finished;
// 5. At the same time user continue the process at sub-window;
// 6. After Auth-code flow completes between PBE and FB, PBE response to sub-window by redirecting it to a page with connection result;
// 7. Main windows discovered that sub-window is closed, then invoke sub-window handle to get the connection result and close this sub-window;
// OR 7. ...thus it ask PBE again with the request-id;

const OpenSubWindow = (props) => {
  const { href } = props,
    { setSubWindowHandle } = props,
    containerEl = document.createElement("div");
  let externalWindow = null;

  useEffect(() => {
    externalWindow = window.open(
      href,
      "",
      "width=800,height=500,left=200,top=200"
    );
    externalWindow.document.body.appendChild(containerEl);
    // localStorage.setItem('fucking', 'sake');
    setSubWindowHandle(externalWindow);
  }, []);

  return ReactDOM.createPortal(props.children, containerEl);
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSubWindowHandle,
    },
    dispatch
  );
}

let comp = connect(null, mapDispatchToProps)(OpenSubWindow);
comp = withStyles(styles, { withTheme: true })(comp);
export default comp;
