import * as Actions from "../../actions";

const initialState = null;

const chartsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CHARTS: {
      const { data } = action;
      return { ...state, ...data };
    }

    default:
      return state;
  }
};

export default chartsReducer;
