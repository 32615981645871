import _ from "lodash";
import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import {
  ClickAwayListener,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import RelativePortal from "react-relative-portal";
import { InfiniteLoader } from "react-virtualized";

class ReactHoverObserver extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.setIsHovering = this.setIsHovering.bind(this);
    this.unsetIsHovering = this.unsetIsHovering.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);

    this.timerIds = [];
  }

  static displayName = "ReactHoverObserver";

  static defaultProps = {
    hoverDelayInMs: 0,
    hoverOffDelayInMs: 0,
    onHoverChanged: () => {},
    onMouseEnter: ({ setIsHovering }) => setIsHovering(),
    onMouseLeave: ({ unsetIsHovering }) => unsetIsHovering(),
    onMouseOver: () => {},
    onMouseOut: () => {},
    shouldDecorateChildren: true,
  };

  static propTypes = {
    className: PropTypes.string,
    hoverDelayInMs: PropTypes.number,
    hoverOffDelayInMs: PropTypes.number,
    onHoverChanged: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    shouldDecorateChildren: PropTypes.bool,
  };

  onMouseEnter(e) {
    this.props.onMouseEnter({
      e,
      setIsHovering: this.setIsHovering,
      unsetIsHovering: this.unsetIsHovering,
    });
  }

  onMouseLeave(e) {
    this.props.onMouseLeave({
      e,
      setIsHovering: this.setIsHovering,
      unsetIsHovering: this.unsetIsHovering,
    });
  }

  onMouseOver(e) {
    this.props.onMouseOver({
      e,
      setIsHovering: this.setIsHovering,
      unsetIsHovering: this.unsetIsHovering,
    });
  }

  onMouseOut(e) {
    this.props.onMouseOut({
      e,
      setIsHovering: this.setIsHovering,
      unsetIsHovering: this.unsetIsHovering,
    });
  }

  componentWillUnmount() {
    this.clearTimers();
  }

  setIsHovering() {
    this.clearTimers();

    const hoverScheduleId = setTimeout(() => {
      const newState = { isHovering: true };
      this.setState(newState, () => {
        this.props.onHoverChanged(newState);
      });
    }, this.props.hoverDelayInMs);

    this.timerIds.push(hoverScheduleId);
  }

  unsetIsHovering() {
    this.clearTimers();

    const hoverOffScheduleId = setTimeout(() => {
      const newState = { isHovering: false };
      this.setState(newState, () => {
        this.props.onHoverChanged(newState);
      });
    }, this.props.hoverOffDelayInMs);

    this.timerIds.push(hoverOffScheduleId);
  }

  clearTimers() {
    const ids = this.timerIds;
    while (ids.length) {
      clearTimeout(ids.pop());
    }
  }

  getIsReactComponent(reactElement) {
    return typeof reactElement.type === "function";
  }

  shouldDecorateChild(child) {
    return (
      !!child &&
      this.getIsReactComponent(child) &&
      this.props.shouldDecorateChildren
    );
  }

  decorateChild(child, props) {
    return cloneElement(child, props);
  }

  renderChildrenWithProps(children, props) {
    if (typeof children === "function") {
      return children(props);
    }

    return Children.map(children, (child) => {
      return this.shouldDecorateChild(child)
        ? this.decorateChild(child, props)
        : child;
    });
  }

  render() {
    const { children, className } = this.props;
    const childProps = _.assign(
      {},
      { isHovering: this.state.isHovering },
      _.omit(this.props, [
        "children",
        "className",
        "hoverDelayInMs",
        "hoverOffDelayInMs",
        "onHoverChanged",
        "onMouseEnter",
        "onMouseLeave",
        "onMouseOver",
        "onMouseOut",
        "shouldDecorateChildren",
      ])
    );

    return (
      <div
        {...{
          className,
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
          onMouseOver: this.onMouseOver,
          onMouseOut: this.onMouseOut,
        }}
      >
        {this.renderChildrenWithProps(children, childProps)}
      </div>
    );
  }
}

const refTranslateUpwardsIfBelowScreen = (elem) => {
  if (!elem) return;
  const rect = elem.getBoundingClientRect();
  const overflow = rect.y + rect.height - window.innerHeight;
  if (overflow > 0) elem.style.transform = `translateY(-${overflow + 10}px)`;
};

const is_descendant_of_class = (a, cls) => {
  // a is the element we are checking
  if (!a) return false;
  while ((a = a.parentNode)) {
    if (a.classList && a.classList.contains(cls)) return a;
  }
  return false;
};

const AsyncFunction = (async () => {}).constructor;

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

function isAsyncFunction(functionToCheck) {
  return functionToCheck instanceof AsyncFunction === true;
}

// to stop looping once the option item is found
let optionFound = false;

// function to extract the hierarchial family structure of the selected option item
const findParentStructure = (
  options,
  selectedOption,
  optionValue,
  tree,
  path,
  parent,
  callback,
  found = false
) => {
  optionFound = found;
  for (let i = 0; i <= tree.length - 1; i += 1) {
    // for each main loop currentPath is set as new array
    // with slice else currentPath will be overrided with previous values
    const currentPath = path.slice();

    if (tree[i].value === optionValue) {
      // condition for the objects which dont have options in the top level.
      if (currentPath.length === 0 && parent === undefined) {
        callback({ value: tree[i].value, label: tree[i].label });
        break;
      }

      // checking whether the options parent and the currentPath last object
      // value is same, bcz some of the options values might be same but have different parent.
      if (
        currentPath.length &&
        currentPath[currentPath.length - 1].value === parent
      ) {
        optionFound = true;
        callback(
          findHierarchyAddSelectedOption(currentPath, options, selectedOption)
        );
        break;
      }
    } else {
      // dont continue looping once the option item is found
      if (optionFound) {
        break;
      }
      if (tree[i].options) {
        currentPath.push({
          value: tree[i].value,
          label: tree[i].label,
          options: [],
        });
        findParentStructure(
          options,
          selectedOption,
          optionValue,
          tree[i].options,
          currentPath,
          parent,
          callback,
          optionFound
        );
      }
    }
  }
};

// currentPath is an array of objects having the hierarchial parenting structure of
// the selected option. once the top to bottom level hierachy is found we arrange it
// in nested structure as the original options data hierachy structure.

const findHierarchyAddSelectedOption = (
  currentPath,
  options,
  selectedOption
) => {
  let option = {};
  let temp = {};

  // find the option object from the selected options which matches the top level value
  const optionsData = options.find(
    (item) => item.value === currentPath[0].value
  );

  // when optionsData is available
  if (optionsData !== undefined) {
    return addSelectedOption([optionsData], currentPath, selectedOption);
  }

  // when optionsData is undefined we add the selectedOption in the hierarchial pattern
  // and return the updated object
  for (let i = currentPath.length - 1; i >= 0; i -= 1) {
    if (i === currentPath.length - 1)
      option = { ...currentPath[i], options: [selectedOption] };
    if (i > 0) {
      temp = { ...currentPath[i - 1], options: [option] };
      option = temp;
    }
  }
  return option;
};

// this functions adds the new selected option along with the previous selected options data

const addSelectedOption = (
  optionsSelectedData,
  currentPath,
  selectedOption
) => {
  let options = {};
  let temp = {};

  // this functions returns the array of objects Each having the parent and its options
  // property having previous selected data
  const current = currentPathInHierarchy(optionsSelectedData, currentPath);

  for (let i = current.length - 1; i >= 0; i -= 1) {
    if (i === current.length - 1) {
      // append the selectedOption along with the options already available
      options = {
        ...current[i],
        options: [selectedOption, ...current[i].options],
      };
    }

    if (i > 0) {
      temp = {
        ...current[i - 1],
        options: [...current[i - 1].options, options],
      };
      options = temp;
    }
  }
  return options;
};

// this function takes two input one optionsData which is array of already
// selected options and currentPath which is array of objects with top to
// bottom level parenting of the selected option
const currentPathInHierarchy = (optionsData, currentPath) => {
  for (let i = 0; i <= optionsData.length - 1; i += 1) {
    for (let j = 0; j <= currentPath.length - 1; j += 1) {
      if (optionsData[i].value === currentPath[j].value) {
        if (optionsData[i].options) {
          // to remove the duplicate option created while looping the data
          currentPath[j].options = optionsData[i].options.filter((item) => {
            if (j < currentPath.length - 1)
              return item.value !== currentPath[j + 1].value;
            return item;
          });
          currentPathInHierarchy(optionsData[i].options, currentPath);
        }
      }
    }
  }
  return currentPath;
};

const suffixedClassName = (className, suffix) => {
  const classNames = {
    "options-selected-container": `${
      className ? `${className}-options-selected-container` : ""
    }`,
    "options-group": `${className ? `${className}-options-group` : ""}`,
    "options-value": `${className ? `${className}-options-value` : ""}`,
    "or-separator": `${className ? `${className}-or-separator` : ""}`,
    "remove-group": `${className ? `${className}-remove-group` : ""}`,
    "multi-selector-container": `${
      className ? `${className}-multi-selector-container` : ""
    }`,
    active: `${className ? `${className}-active` : ""}`,
    "multi-level-options-container": `${
      className ? `${className}-multi-level-options-container` : ""
    }`,
    "menu-open": `${className ? `${className}-menu-open` : ""}`,
    "menu-close": `${className ? `${className}-menu-close` : ""}`,
    "options-label": `${className ? `${className}-options-label` : ""}`,
    "options-sub-menu-container": `${
      className ? `${className}-options-sub-menu-container` : ""
    }`,
    "options-sub-menu-header": `${
      className ? `${className}-options-sub-menu-header` : ""
    }`,
    "options-sub-menu": `${className ? `${className}-options-sub-menu` : ""}`,
    "arrow-up": `${className ? `${className}-arrow-up` : ""}`,
    "arrow-right": `${className ? `${className}-arrow-right` : ""}`,
    "arrow-down": `${className ? `${className}-arrow-down` : ""}`,
    "multi-selector-placeholder": `${
      className ? `${className}-multi-selector-placeholder` : ""
    }`,
  };

  return classNames[suffix];
};

class MultiLevelSelect extends React.Component {
  constructor(props) {
    super();
    this.state = {
      values: [],
      search: [],
      isMenuOpen: props.isMenuOpen || false,
    };
  }

  getClassName = (suffix) => {
    return suffixedClassName("", suffix);
  };

  onOptionsChange = (updates) => {
    const { onChange } = this.props;
    const { values } = this.state;
    onChange(values, updates);
  };

  removeSelectedGroup = ({ value }) => {
    const { values } = this.state;
    this.setState(
      { values: values.filter((data) => data.value !== value) },
      this.onOptionsChange
    );
  };

  handleClickOutside = (ev) => {
    const { isMenuOpen } = this.state;
    if (is_descendant_of_class(ev.target, "multi-level-selector-container"))
      return;

    return isMenuOpen && this.setState({ isMenuOpen: false });
  };

  toggleMenu = () => {
    const { isMenuOpen } = this.state;
    this.setState({ isMenuOpen: !isMenuOpen });
  };

  selectOption = (data, parent, event, noTrigger) => {
    const { values } = this.state;
    const { value, checked } = event.target;
    if (checked) {
      const parentValue = data.value;
      const updatedOption = data;
      const isOptionAvailable = values.findIndex(
        (option) => option.value === parentValue
      );

      if (isOptionAvailable === -1) {
        return this.setState(
          { values: [...values, updatedOption] },
          !noTrigger
            ? this.onOptionsChange.bind(this, {
                value,
                data,
                checked,
              })
            : undefined
        );
      }

      const updatedOptionsData = values.map((item) => {
        if (item.value === parentValue) return updatedOption;
        return item;
      });

      return this.setState(
        { values: updatedOptionsData },
        !noTrigger
          ? this.onOptionsChange.bind(this, {
              value,
              data,
              checked,
            })
          : undefined
      );
    }

    const uncheckedOption = this.removeOption(values, parent, value, parent);
    return this.setState(
      { values: uncheckedOption },
      !noTrigger
        ? this.onOptionsChange.bind(this, {
            value,
            data,
            checked,
          })
        : undefined
    );
  };

  // remove options
  removeOption = (values, optionParent, removeOption, removeOptionParent) =>
    values.filter((item) => {
      if (item.value.includes(removeOption)) {
        // checks if parent are undefined bcz level 1 menu dont have parents
        if (removeOptionParent !== undefined && optionParent !== undefined) {
          // if the parents match then only the particular child
          // is removed from the options array
          if (optionParent === removeOptionParent) return false;
        }
        // this condition is satisfied for level 1 options
        if (removeOptionParent === optionParent) return false;
      }
      if (item.options) {
        return (item.options = this.removeOption(
          item.options,
          item.value,
          removeOption,
          removeOptionParent
        )).length;
      }
      return item;
    });

  isOptionChecked = (values, optionValue, parent) => {
    if (parent) {
      return values.some((e) => {
        if (e.value === parent) {
          return e.options.some((item) => item.value === optionValue);
        }
        if (e.options)
          return this.isOptionChecked(e.options, optionValue, parent);
        return false;
      });
    }

    return values.some((e) => e.value === optionValue);
  };

  renderOptionsSelected = (values) =>
    values.map((item, i) => (
      <div
        key={i}
        className={`options-selected-container`}
        onClick={(event) => event.stopPropagation()}
      >
        {this.renderSubOptionsSelected([item])}
      </div>
    ));
  /*
        <div
          onClick={() => this.removeSelectedGroup(item)}
          className={`remove-group`}
        >
          &#10005;
        </div>

    */

  renderSubOptionsSelected = (data, counter = 0) =>
    data.map((item, index) => (
      <React.Fragment key={`${item.value}-${index}`}>
        {item.options && (
          <div>
            {counter === 0 ? (
              <span className={`options-group`}>{` ${item.label}`}</span>
            ) : data.length > 1 && index !== 0 ? (
              `, ${item.label}`
            ) : (
              ` ${item.label}`
            )}
            <span className={`options-group`}>{" ->"}</span>
            &nbsp;
          </div>
        )}
        {!item.options && (
          <div className={`options-value`}>
            {data.length > 1 && index !== 0 ? (
              `, ${item.label}`
            ) : counter === 0 ? (
              <span className={`options-group`}>{item.label}</span>
            ) : (
              `${item.label}`
            )}
            &nbsp;
          </div>
        )}
        {item.options &&
          this.renderSubOptionsSelected(item.options, (counter += 1))}
      </React.Fragment>
    ));

  renderCaretButton = () => {
    const { isMenuOpen } = this.state;

    return (
      <div className="multi-selector-button" onClick={this.toggleMenu}>
        <div className={isMenuOpen ? `arrow-up` : `arrow-down`} />
      </div>
    );
  };

  renderPlaceholder = () => {
    const { placeholder } = this.props;

    return (
      <div className={`multi-selector-placeholder`}>
        {placeholder || "Select"}
      </div>
    );
  };

  renderOptionsMenu = (options, parent = {}, level = 0, dry = false) =>
    options.map((item, i) => {
      if (
        this.state.search &&
        this.state.search[level] &&
        item.label.indexOf(this.state.search[level]) == -1
      )
        return <span key={`${item.value}-${i}`}></span>;

      if (item.options) {
        if (dry) this.renderSubMenu(item, parent, false, level);
        return (
          <ReactHoverObserver key={`${item.value}-${i}`}>
            {({ isHovering }) => {
              return (
                <div className="options-container">
                  <div key="label" className={`options-label`}>
                    {item.label}
                  </div>
                  {this.renderSubMenu(item, parent, isHovering, level)}
                </div>
              );
            }}
          </ReactHoverObserver>
        );
      }
      return (
        <React.Fragment key={`${item.value}-${i}`}>
          {this.renderSubMenu(item, parent, false, level)}
        </React.Fragment>
      );
    });

  renderSubMenu = (item, parent = {}, isHovering, level = 0) => {
    const { values } = this.state;
    const { options } = this.props;

    if (item.options) {
      let content = <></>;
      let key = 0;
      if (isHovering) {
        if (isFunction(item.options)) {
          content = <CircularProgress className="m-16" />;
          item.options();
          key = 1;
        } else if (isAsyncFunction(item.options)) {
          content = <CircularProgress className="m-16" />;
          item.options.then((result) => {});
          key = 2;
        } else {
          if (item.options.length > 0)
            content = this.renderOptionsMenu(item.options, item, level + 1);
          else
            content = (
              <div className="options-sub-menu-no-options">
                {this.props.noOptionsText}
              </div>
            );
          key = 3;
        }
      } else {
        if (!isFunction(item.options) && !isAsyncFunction(item.options)) {
          this.renderOptionsMenu(item.options, item, level + 1, true);
        }
      }
      return (
        <>
          <div key="arrow" className={`arrow-right`} />
          <RelativePortal
            className="z-10 absolute left-0 top-0 multi-level-selector-container"
            component="div"
            left={6}
            top={-20}
          >
            {isHovering && (
              <div
                key={key}
                ref={refTranslateUpwardsIfBelowScreen}
                className={`options-sub-menu-container`}
              >
                <div className={`options-sub-menu-header`}>{item.value}</div>
                {!this.props.hideSearch && this.searchBar(level + 1)}
                {content}
              </div>
            )}
          </RelativePortal>
        </>
      );
    }
    let checked = this.isOptionChecked(values, item.value, parent.value);

    const handleChange = (event) => {
      if (!checked) {
        findParentStructure(
          values,
          item,
          item.value,
          options,
          [],
          parent.value,
          (data) => {
            this.selectOption(data, parent.value, event, event.noTrigger);
          }
        );
      } else {
        this.selectOption({}, parent.value, event, event.noTrigger);
      }
    };

    if (item.checked !== undefined && checked != item.checked) {
      item.checked = undefined;
      handleChange({
        target: {
          value: item.value,
          checked: !checked,
        },
        noTrigger: true,
      });
      checked = !checked;
    }

    return (
      <label key="label">
        <div key="options" className={`options-sub-menu`}>
          <input
            type="checkbox"
            value={item.value}
            checked={checked}
            name={item.label}
            onChange={handleChange}
          />
          <div key="checkbox" className="checkbox">
            <span className="checkmark" />
          </div>
          <div key="label" className={`options-label`}>
            {item.label}
          </div>
        </div>
      </label>
    );
  };

  searchBar(ind) {
    return (
      <div className="options-search w-full px-12 py-8 flex flex-col">
        <TextField
          onChange={(event) => {
            let new_search = this.state.search;
            new_search[ind] = event.target.value;

            this.setState({
              search: new_search,
            });
          }}
          value={this.state.search[ind] || ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }

  render() {
    const { values, isMenuOpen } = this.state;
    const { options, className, hideChosen } = this.props;
    const menu = this.renderOptionsMenu(options, {}, 0, !isMenuOpen);
    return (
      <ClickAwayListener onClickAway={this.handleClickOutside}>
        <div className={"multi-level-selector-container " + className}>
          <div
            className={`multi-selector-container ${
              isMenuOpen ? `active` : "inactive"
            }`}
          >
            <div className="multi-selector" onClick={this.toggleMenu}>
              {(!values.length || hideChosen) && this.renderPlaceholder()}
              {!hideChosen && this.renderOptionsSelected(values)}
            </div>
            {this.renderCaretButton()}
          </div>
          {isMenuOpen && (
            <div
              key="options"
              ref={refTranslateUpwardsIfBelowScreen}
              className={`multi-level-options-container ${
                isMenuOpen ? `menu-open` : `menu-close`
              }`}
            >
              {!this.props.hideSearch && this.searchBar(0)}
              <div className="options-main-menu">
                <div>{menu}</div>
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

MultiLevelSelect.propTypes = {
  noOptionsText: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          })
        ),
        PropTypes.func,
      ]),
    })
  ),
  className: PropTypes.string,
};

MultiLevelSelect.defaultProps = {
  noOptionsText: "No options",
  placeholder: "",
  options: [],
  onChange: () => {},
  className: "",
};

export { MultiLevelSelect };
