import { Roles } from "constants.js";

/**
 * @since v1, PRMF-25
 * @param {object} user User as in redux state "auth.user"
 * @return {boolean} true if the user is an Owner.
 */
export function isOwner(user) {
  return user.role === Roles.companyOwner;
}
