export default {
  first_name: {
    maxLength: [256, "maxLength character"],
  },
  last_name: {
    maxLength: [256, "maxLength character"],
  },
  name: {
    maxLength: [256, "maxLength character"],
  },
  username_prefix: {
    minLength: [2, "minLength character"],
    maxLength: [15, "maxLength character"],
    matchRegexp: [/^[0-9a-zA-Z_\-.]+$/, "only company code character"],
  },
  password: {
    minLength: [8, "minLength character"],
    maxLength: [16, "maxLength character"],
    matchRegexp: [
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]$/,
      "only password character",
    ],
  },
  gender: {
    matchRegexp: [/^(male|female|not_specified)$/, "only available option"],
  },
  job_title: {
    maxLength: [256, "maxLength character"],
  },
  salutation: {
    matchRegexp: [
      /^(mr|mrs|ms|dr|prof|sir|madam|rev)$/,
      "only available salutation",
    ],
  },
};
