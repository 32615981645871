import URL from "url";
import Debug from "debug";

import {
  api as API,
  apiOrigin as APIOrigin,
  Facebook,
  eBay,
} from "constants.js";
import { inAsset } from "app/utils/common";

const debug = Debug("pfe:data:platforms");

const getPath = (fname) => inAsset(`images/platforms/${fname}`);

const ebay_scopes = [
  "",
  "/buy.order.readonly",
  "/buy.guest.order",
  "/sell.marketing.readonly",
  "/sell.marketing",
  "/sell.inventory.readonly",
  "/sell.inventory",
  "/sell.account.readonly",
  "/sell.account",
  "/sell.fulfillment.readonly",
  "/sell.fulfillment",
  "/sell.analytics.readonly",
  "/sell.marketplace.insights.readonly",
  "/commerce.catalog.readonly",
  "/buy.shopping.cart",
  "/buy.offer.auction",
  "/commerce.identity.readonly",
  "/commerce.identity.email.readonly",
  "/commerce.identity.phone.readonly",
  "/commerce.identity.address.readonly",
  "/commerce.identity.name.readonly",
  "/commerce.identity.status.readonly",
  "/sell.finances",
  "/sell.item.draft",
  "/sell.payment.dispute",
  "/sell.item",
].map((str) => `https://api.ebay.com/oauth/api_scope${str}`);

const host_info = APIOrigin.host ? APIOrigin : API,
  fb_redirect_url = URL.format({
    protocol: host_info.protocol,
    hostname: host_info.host,
    port: host_info.port == 443 ? undefined : host_info.port,
    pathname: "/auth/fb/app",
  }),
  origin_magic_html = URL.format({
    protocol: host_info.protocol,
    hostname: host_info.host,
    port: host_info.port == 443 ? undefined : host_info.port,
  });
debug("origin_magic_html", origin_magic_html);

const getState = (content, options) => {
  const { base64 } = options || {};
  let payload = encodeURI(JSON.stringify(content));
  if (base64) payload = Buffer.from(JSON.stringify(content)).toString("base64");

  return `&state=${payload}`;
};

export default {
  google: {
    code: "google",
    name: "Google",
    icon: getPath("google.svg"),
    color: {
      background: "#34ab53",
      text: "white",
    },
  },
  facebook: {
    code: "facebook",
    name: "Facebook",
    icon: getPath("facebook.svg"),
    oauth_url: (options) =>
      `https://www.facebook.com/v5.0/dialog/oauth?` +
      `client_id=${Facebook.app_id}` +
      `&redirect_uri=${fb_redirect_url}` +
      `&scope=${["manage_pages", "pages_show_list", "pages_messaging"].join(
        ","
      )}` +
      getState(options.state),
    origin_magic_html,
    color: {
      background: "#3b5998",
      text: "white",
    },
  },
  ebay: {
    code: "ebay",
    name: "eBay",
    icon: getPath("ebay.svg"),
    oauth_url: (options) =>
      `https://auth.sandbox.ebay.com/oauth2/authorize?` +
      `client_id=${eBay.app_id}` +
      `&redirect_uri=${eBay.ru_name}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent(ebay_scopes.join(" "))}` +
      getState(options.state, { base64: true }),
    origin_magic_html,
    color: {
      background: "#f5af02",
      text: "black",
    },
  },
  taobao: {
    code: "taobao",
    name: "Taobao",
    icon: getPath("taobao.svg"),
    color: {
      background: "#ff4200",
      text: "white",
    },
  },
  whatsapp1: {
    code: "whatsapp1",
    name: "Whatsapp",
    simple_access_token: true,
    icon: getPath("whatsapp.svg"),
    color: {
      background: "#25d366",
      text: "white",
    },
  },
  whatsapp: {
    code: "whatsapp",
    name: "Whatsapp",
    icon: getPath("whatsapp.svg"),
    color: {
      background: "#25d366",
      text: "white",
    },
  },
  parami: {
    code: "parami",
    name: "Parami",
    icon: getPath("asanga.svg"),
    color: {
      background: "#7C51AB",
      text: "white",
    },
  },
  asangaweb: {
    code: "asangaweb",
    name: "Asanga (web)",
    simple_id: true,
    icon: getPath("asanga-web.svg"),
    color: {
      background: "#7C51AB",
      text: "white",
    },
  },
};
