import React from "react";
import { withStyles } from "@material-ui/core";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";

import { BlankPageForDialogs, DoubleButtonDialog } from "app/parami-layouts";
import { resendVerify } from "app/services/paramiService";
import { updateCompany } from "app/store/actions/company/info.actions";
import { logoutUser } from "app/auth/store/actions/user.actions";
import { showMessage } from "app/store/actions/fuse";
import Debug from "debug";

const styles = (theme) => {
  return {};
};

const EmailNotVerify = ({
  classes,
  updateCompany,
  logoutUser,
  showMessage,
}) => {
  const { t: ttt } = useTranslation();
  const handleClose = () => {
    logoutUser();
  };

  const handleResend = async () => {
    try {
      await resendVerify();
      showMessage({ message: ttt("register:verify_email_dialog.Success") });
    } catch (err) {
      const debug = Debug("pfe:store:company:action:resend_verify");
      debug("resendVerify - err", err.stack);
      showMessage({ message: ttt("register:verify_email_dialog.Failure") });
      return false;
    }
  };

  const messages = [
    ttt("register:verify_email_dialog.paragraph"),
    ttt("register:verify_email_dialog.logout"),
  ];

  return (
    <BlankPageForDialogs>
      <DoubleButtonDialog
        title={ttt("register:verify_email_dialog.title")}
        messages={messages}
        open={true}
        leftButtonCaption={ttt(
          "register:verify_email_dialog.resend_verify_button"
        )}
        rightButtonCaption={ttt("register:verify_email_dialog.close_button")}
        onLeft={handleResend}
        onRight={handleClose}
      />
    </BlankPageForDialogs>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCompany,
      logoutUser,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ company }) {
  return {
    company: company.info,
  };
}

let myComp = withStyles(styles, { withTheme: true })(EmailNotVerify);
export default connect(mapStateToProps, mapDispatchToProps)(myComp);
