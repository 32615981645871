import React from "react";
import Platforms from "app/data/platforms";
import Pavatar from "app/parami-layouts/Pavatar";

import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

export default class AccountItem extends React.Component {
  render() {
    const { account } = this.props;
    return (
      <ListItem key={account.id}>
        <ListItemAvatar>
          <Pavatar src={account.icon || Platforms[account.platform].icon} />
        </ListItemAvatar>
        <ListItemText primary={account.name} />
      </ListItem>
    );
  }
}
AccountItem.defaultProps = {
  account: {},
};
