import React from "react";
import { withStyles } from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Typography } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    minHeight: "0px !important",
  },
})(MuiExpansionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: "24px",
  },
  contentInColumns: {
    flexDirection: "rows",
  },
}))(MuiExpansionPanelDetails);

let lastId = 0;

export default function SimpleAccordions({
  content,
  contentInColumns = false,
  openFirst = false,
}) {
  const [expanded, setExpanded] = React.useState(openFirst ? "panel0" : "");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  /*
  
  content: [
    [
      "something" || <Typography></Typography>,
      "something" || <Typography></Typography>
    ]
  ]
  
  */

  lastId += 1;
  return (
    <div>
      {content.map((e, i) => (
        <Accordion
          square
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}
        >
          <AccordionSummary
            aria-controls={"panel" + i + "d-content--" + lastId}
            id={"panel" + i + "d-header--" + lastId}
          >
            {React.isValidElement(e[0]) ? (
              e[0]
            ) : (
              <Typography>{e[0]}</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails className={contentInColumns ? "flex-row" : ""}>
            {React.isValidElement(e[1]) ? (
              e[1]
            ) : (
              <Typography>{e[1]}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
