// import { GET_STAFF, CREATE_STAFF, UPDATE_STAFF, DELETE_STAFF } from 'app/store/actions/company/staff.actions';
import Debug from "debug";

import {
  GET_COMPANY,
  UPDATE_COMPANY,
  GET_FLOW_TEMPS,
} from "app/store/actions/company";

const debug = Debug("pfe:store:company:reducer:info");

// export const initState = {
//   company_id: 'sssss',
//   company_code: 'ccccccc',
//   isPersonal: false,
//   name: 'Test Company',
//   fullname: 'Test Company for the Good of all mankind',
//   address: {
//     unit: "Flat 1, 2/F, Block A",
//     building: "Wing Kut Industrial Building",
//     address_line_1: "608 Castle Peak Road",
//     address_line_2: "Cheung Sha Wan, Kln",
//     postal_code: '12345',
//     region: 'HK',
//   },
//   phone_number: '17171717',
//   email_address: 'info@t-dim',
//   timezone: 'Asia/Tokyo',
//   preferences: {
//     receive_news_letter: false
//   }
// }

export const initState = {
  company_id: null,
  company_code: null,
  alias_code: null,
  isPersonal: false,
  name: null,
  fullname: null,
  address: {
    unit: null,
    building: null,
    address_line_1: null,
    address_line_2: null,
    postal_code: null,
    region: null,
  },
  phone_number: null,
  email_address: null,
  timezone: null,
  preferences: {
    receive_emails: false,
  },
  flow_templates: {},
  conversation_types: null,
  last_modified: null,
  created_at: null,
};

export default function(state = initState, action) {
  switch (action.type) {
    case GET_COMPANY:
    case UPDATE_COMPANY: {
      const { company } = action;
      // isPersonal: false,
      const newState = {
        ...state,
        ...company,
      };
      debug("newState", newState, company);
      return newState;
    }

    case GET_FLOW_TEMPS: {
      const { flow_templates } = action;
      return {
        ...state,
        flow_templates,
      };
    }

    default:
      return state;
  }
}
