import React, { useEffect, useState } from "react";
import querystring from "querystring";
import axios from "axios";

import { auth0Service } from "app/services/auth0Service";
import { useDropzone } from "react-dropzone";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from "@material-ui/core";

import { AutofitImage } from "app/parami-layouts";

import { inAsset, usePrevious } from "app/utils/common";

import * as _ from "lodash";
import classNames from "classnames";

import Debug from "debug";
import { showMessage } from "app/store/actions";

const debug = Debug("pfe:UploadWithPreviews");

//test
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "100%",
  padding: 4,
  boxSizing: "border-box",
  margin: "auto",
};

const thumbInner = {
  display: "flex",
  width: "100%",
  overflow: "hidden",
  position: "relative",
};

const { REACT_APP_PBE_HOST, REACT_APP_PBE_DATA_HOST } = process.env;
let REACT_APP_DATA_HOST;

const use_self = REACT_APP_PBE_HOST.indexOf("self.") > -1;
const use_sit = REACT_APP_PBE_HOST.indexOf("sit.") > -1;

if (use_self && REACT_APP_PBE_DATA_HOST) {
  REACT_APP_DATA_HOST = REACT_APP_PBE_DATA_HOST;
} else {
  REACT_APP_DATA_HOST =
    (use_sit || use_self ? "sit-" : "") +
    "data." +
    REACT_APP_PBE_HOST.replace("self.", "").replace("sit.", "");
}

let UploadWithPreviews = (props) => {
  const {
    onChange,
    language,
    t: ttt,
    node_id,
    value,
    showMessage,
    disabled,
    frameWidth,
    frameHeight,
    maxImages,
    maxFrameWidth,
    maxFrameHeight,
  } = props;
  const [files, setFiles] = useState(value || []);
  const [file_urls, setFileUrls] = useState(value || []);
  const prevProps = usePrevious({ value });
  const maxFiles = maxImages || 1;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: 50 * 1024 * 1024,
    maxFiles: maxFiles,
    accept: ".png,.jpg,.jpeg,.webp,.gif,.pdf, .mp4",
    onDrop: (acceptedFiles_) => {
      const limit = maxFiles - files.length;
      const acceptedFiles = acceptedFiles_.slice(0, limit);
      if (acceptedFiles_.length != acceptedFiles.length)
        showMessage({
          message: ttt("form-fields:validations.at most n items", {
            args: maxFiles,
          }),
        });

      const new_files = [];
      const new_file_urls = [];
      let i = 0;
      const access_token = auth0Service.getAccessToken();
      debug("acceptedFiles", {
        acceptedFiles,
      });

      const upload = () => {
        if (i < acceptedFiles.length) {
          let file = acceptedFiles[i];
          let preview;
          if (file.type.startsWith("image/"))
            preview = URL.createObjectURL(file);
          else if (file.type == "application/pdf") {
            preview = inAsset("images/icons/pdf.svg");
          }
          const formData = new FormData();
          formData.append("file", file);
          axios
            .post(
              "https://" +
                REACT_APP_DATA_HOST +
                "/upload" +
                "?" +
                querystring.stringify({ method: "0", language, node_id }),
              formData,
              {
                onUploadProgress: ({ total, loaded }) => {
                  debug("onUploadProgress", {
                    total,
                    loaded,
                  });
                },
                headers: {
                  Authorization: "Bearer " + access_token,
                },
              }
            )
            .then((res) => res.data)
            .then((data) => {
              i += 1;
              const new_file = Object.assign(file, {
                preview: data,
              });
              debug("uploaded " + i + " / " + acceptedFiles.length, {
                data,
                new_file,
              });
              new_files.push(new_file);
              new_file_urls.push({
                name: new_file.name,
                size: new_file.size,
                preview: new_file.preview,
                lastModified: new_file.lastModified,
                type: new_file.type,
                url: data,
              });
              upload();
            })
            .catch((err) => {
              if (err.response)
                switch (err.response.status) {
                  case 401:
                    auth0Service.refreshSession();
                  default:
                    showMessage({ message: "The file is not accepted" });
                    break;
                }
              console.error({
                err,
              });
            });
        } else {
          // Finish All

          debug("finish uploading", {
            files,
            new_files,
            new_file_urls,
          });

          let updated_file_urls = _.concat(file_urls, new_file_urls);
          setFiles(_.concat(files, new_files));
          setFileUrls(updated_file_urls);
          onChange(updated_file_urls);
        }
      };

      upload();
    },
  });

  const thumbs =
    files &&
    files.map((file, i) => (
      <div style={thumb} key={i}>
        <div style={thumbInner}>
          <AutofitImage
            maxFrameWidth={maxFrameWidth || undefined}
            maxFrameHeight={maxFrameHeight || undefined}
            frameWidth={frameWidth || undefined}
            frameHeight={frameHeight || "256px"}
            imgSrc={file.preview || file.url || file}
          />
          <span
            className="flex right-0 absolute text-sm cursor-pointer rounded-full p-4"
            style={{
              background: "#fff8",
            }}
            onClick={() => {
              var new_files = files.filter((e, j) => {
                return j != i;
              });
              var new_file_urls = file_urls.filter((e, j) => {
                return j != i;
              });
              setFiles(new_files);
              setFileUrls(new_file_urls);
              onChange(new_file_urls);
            }}
          >
            <Icon>close</Icon>
          </span>
        </div>
      </div>
    ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files &&
        files.forEach(
          (file) => file.preview && URL.revokeObjectURL(file.preview)
        );
    },
    [files]
  );

  useEffect(() => {
    if (prevProps && !_.isEqual(prevProps.value, value)) {
      prevProps.value = value;
      setFiles(value || []);
      setFileUrls(value || []);
    }
  }, [value]);

  return (
    <div>
      <div
        {...getRootProps({
          className: classNames(
            "dropzone",
            (disabled || files.length >= maxFiles) &&
              "pointer-events-none opacity-50"
          ),
        })}
      >
        <input {...getInputProps()} />
        <div
          style={{
            transition: "all 0.333s ease-in-out",
            borderColor:
              (isDragReject && "#ff1744") ||
              (isDragAccept && "#00e676") ||
              (isDragActive && "#2196f3") ||
              "#eeeeee",
          }}
          className={classNames(
            "h-96 mt-4 text-center flex m-8 border-3 border-dashed rounded-8 box-shadow"
          )}
        >
          <p className="m-auto select-none">
            Drag and drop a file here, or click
          </p>
        </div>
      </div>
      <aside
        className={classNames(disabled && "pointer-events-none opacity-50")}
        style={thumbsContainer}
      >
        {thumbs}
      </aside>
    </div>
  );
};

UploadWithPreviews = connect(
  ({}) => {
    return {};
  },
  (dispatch) => {
    return bindActionCreators(
      {
        showMessage,
      },
      dispatch
    );
  }
)(UploadWithPreviews);
UploadWithPreviews = withTranslation()(UploadWithPreviews);

export { UploadWithPreviews };
