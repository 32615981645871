import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Formsy from "formsy-react";
import ld from "lodash";

import { i18nNamespaces } from "constants.js";
import Locales from "app/data/locales.js";
import { PasswordField } from "app/parami-layouts";

const fmt = ld.get(Locales, `en.formatter.fullname.main`);

const styles = (theme) => ({});

const SetPasswordDialog = (props) => {
  const { staff } = props,
    { toCancel, toSubmit } = props,
    { t: ttt } = useTranslation(),
    [canSub, setSubmit] = useState(false),
    [showPW, setShowPW] = useState(false);
  const disableButton = () => {
    setSubmit(false);
  };

  const enableButton = () => {
    setSubmit(true);
  };

  return (
    <Dialog
      id="staff-info-dialog"
      classes={{
        paper: "m-24",
      }}
      open={true}
      onClose={toCancel}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
    >
      <DialogTitle>
        {ttt("settings-staff:set password dialog.title", {
          fullname: fmt(staff),
        })}
      </DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          {ttt("settings-staff:set password dialog.desc")}
        </Typography>
        <Formsy
          id="set-pw-form"
          onValidSubmit={toSubmit}
          onValid={enableButton}
          onInvalid={disableButton}
          className="flex flex-col justify-center"
        >
          <PasswordField required className="mt-16" name="password" />
        </Formsy>
      </DialogContent>
      <DialogActions>
        <Button aria-label="CANCEL" color="primary" onClick={toCancel}>
          {ttt("general:button.cancel")}
        </Button>
        <Button
          type="submit"
          form="set-pw-form"
          aria-label="SAVE"
          disabled={!canSub}
          color="primary"
        >
          {ttt("general:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

let myComp = withStyles(styles, { withTheme: true })(SetPasswordDialog);
export default myComp;
