import Debug from "debug";
const debug = Debug("pfe:formSubmission-actions");

export const INIT_FORM_SUBMISSION = "[GLOBALS] INIT FORM SUBMISSION";
export const SET_FORM_SUBMISSION = "[GLOBALS] SET FORM SUBMISSION";
export const CLEAR_FORM_SUBMISSION = "[GLOBALS] CLEAR FORM SUBMISSION";

export function initFormSubmission(id, num_forms) {
  debug("initFormSubmission", { id, num_forms });
  return {
    type: INIT_FORM_SUBMISSION,
    id,
    num_forms,
  };
}

export function setFormSubmission(id, index, changes) {
  debug("setFormSubmission", { id, index, changes });
  return {
    type: SET_FORM_SUBMISSION,
    id,
    index,
    changes,
  };
}

export function clearFormSubmission() {
  debug("clearFormSubmission");
  return {
    type: CLEAR_FORM_SUBMISSION,
  };
}
