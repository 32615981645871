//[Fix Problem] : Nodes Validation
export default {
  node_name: {
    minLength: [1, "minLength character"],
    maxLength: [100, "maxLength character"],
  },
  node_return_value: {
    minLength: [1, "minLength character"],
    maxLength: [45, "maxLength character"],
  },
  node_output: {
    minLength: [1, "minLength character"],
    maxLength: [1000, "maxLength character"],
  },
  node_right: {
    minLength: [1, "minLength character"],
    maxLength: [200, "maxLength character"],
  },
  string: {
    minLength: [1, "minLength character"],
    maxLength: [1000, "maxLength character"],
    isNotBlankString: [true, "not blank"],
  },
  integer: {
    minLength: [1, "minLength character"],
    maxLength: [10, "maxLength character"],
    matchRegexp: [/^[-]?(?:\d+)((\d{1,3})*([, ]\d{3})*)$/, "only integers"],
  },
  float: {
    minLength: [1, "minLength character"],
    maxLength: [10, "maxLength character"],
    matchRegexp: [
      /^[-]?(?:\d+)((\d{1,3})*([, ]\d{3})*)(.\d+)?$/,
      "only numbers",
    ],
  },
  name: {
    minLength: [1, "minLength character"],
    maxLength: [100, "maxLength character"],
    matchRegexp: [
      /^[a-zA-Z一-龥À-ž]+(([',. -][a-zA-Z 一-龥À-ž])?[a-zA-Z一-龥À-ž]*)*$/,
      "only name format",
    ],
  },
  email: {
    maxLength: [256, "maxLength character"],
    matchRegexp: [
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/imu,
      "only email format",
    ],
  },
  datetime: {
    matchRegexp: [
      /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
      "datetime format",
    ],
  },
  date: {
    matchRegexp: [
      /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
      "date format",
    ],
  },
  time: {
    matchRegexp: [/^(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/, "time format"],
  },
  phone: {
    minLength: [4, "minLength character"],
    maxLength: [20, "maxLength character"],
    matchRegexp: [/^(\(?\+?[0-9]*\)?)?[0-9\-\(\)]*$/, "only phone number"],
  },
  boolean: {
    matchRegexp: [/^(True|False|true|false)$/, "only boolean"],
  },
  variable: {
    isNotBlankString: [true, "required"],
  },
  process: {
    isNotBlankString: [true, "required"],
  },
};
