import React, { Component } from "react";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/AppContext";

import withEnhancedRouter from "app/parami-layouts/withEnhancedRouter";

import Debug from "debug";

const debug = Debug("pfe:fuse:authorization");

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute(this.props);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, user } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];

    const accessGranted =
      matched && matched.route.auth && matched.route.auth.length > 0
        ? matched.route.auth.includes(user.role)
        : true;

    return {
      accessGranted,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  redirectRoute(props) {
    const { location, user, history } = props;
    const { state } = location;

    debug("location", location);
    /*
        User is guest
        Redirect to Login Page
        */
    if (user.role === "guest") {
      // NOTE: Avoid cyclic reference
      if (state) delete state.redirectLocation;
      this.props.replace({
        pathname: "/login",
        state: { redirectLocation: location },
      });
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectLocation
        */
      const redir =
        state && state.redirectLocation
          ? state.redirectLocation
          : { pathname: "/" };
      this.props.replace(redir);
    }
  }

  render() {
    const { children } = this.props;
    const { accessGranted } = this.state;
    // console.info('Fuse Authorization rendered', accessGranted);
    return accessGranted ? <React.Fragment>{children}</React.Fragment> : null;
  }
}

function mapStateToProps({ fuse, auth }) {
  return {
    user: auth.user,
  };
}

FuseAuthorization.contextType = AppContext;

export default withEnhancedRouter(connect(mapStateToProps)(FuseAuthorization));
