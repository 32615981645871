import { flatten as ld_flatten } from "lodash";
import { normalize, schema } from "normalizr";

import { isEmpty, asyncThunk } from "app/utils/common";

import { REST } from "app/client_proxied";

import Debug from "debug";

export const GET_CUSTOMERS = "[CUST] GET CUSTOMERS";

const debug = Debug("pfe:store:customers.actions");

const CustomerSchema = new schema.Entity(
  "customers",
  {},
  {
    idAttribute: "cust_id",
    processStrategy: (value, parent, key) => {
      const result = {
        ...value,
        id: value.cust_id,
      };

      delete result.cust_id;

      return result;
    },
  }
);

export function get_customers(customer_ids) {
  return asyncThunk(async (dispatch, getState) => {
    try {
      const params = customer_ids.map((cid) => [cid]),
        { result: custs_raw, failed } = await bulk_request(
          REST.CustomersApi.customersCustIdGet,
          params
        ),
        custs_norm = normalize(custs_raw, new schema.Array(CustomerSchema)),
        customers = custs_norm.entities.customers;
      if (!isEmpty(failed)) {
        failed.map((tuple) =>
          debug("Failed for %o, %s", tuple.params, tuple.err.stack)
        );
      }
      // debug(customers, custs_raw, failed);

      dispatch(update_customers_in_store(customers));
    } catch (err) {
      debug(err.stack);
    }
  });
}

async function bulk_request(req, params_list) {
  const failed = [];
  const ret_bulk = await Promise.all(
    params_list.map((params) => {
      return req(...params).catch((err) => {
        failed.push({ params, err });
        return {};
      });
    })
  );

  const result = ld_flatten(
    ret_bulk.map((ret) => ret.data).filter((data) => data)
  );
  // debug('result', result);
  return { result, failed };
}

export function update_customers_in_store(customers) {
  return {
    type: GET_CUSTOMERS,
    customers,
  };
}
