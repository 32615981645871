import URL from "url";

import { api as PBERest } from "../constants";

const MockAdapter = require("axios-mock-adapter");
const axios = require("axios");
const mock = new MockAdapter(axios);

mock.atPBE = (str) => {
  if (PBERest.host && PBERest.port)
    return URL.format({
      protocol: PBERest.protocol,
      hostname: PBERest.host,
      port: PBERest.port,
      pathname: str,
    });
  else return str;
};
// mock.restore();
export default mock;
