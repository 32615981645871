import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  withStyles,
  Button,
  Typography,
  FormLabel,
  Tab,
  Tabs,
  IconButton,
  Icon,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import {
  FuseScrollbars,
  TextFieldFormsy,
  CheckboxFormsy,
  FuseChipSelect,
  FusePageCarded,
} from "@fuse";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Formsy from "formsy-react";
import ld, { get as ld_get } from "lodash";
import * as imupdate from "object-path-immutable";
import Debug from "debug";

import { UploadWithPreviews } from "app/parami-layouts/UploadWithPreviews";

import { i18nNamespaces } from "constants.js";
import Platforms from "app/data/platforms";
import { preferences } from "app/utils/entities/plfacc";
import { Pavatar } from "app/parami-layouts";
import AutoComplete from "app/parami-layouts/AutoComplete";
import { getIcon } from "app/utils/entities/plfacc";
import { SwitchField } from "app/parami-layouts";

const debug = Debug("pfe:settings:ui:PAInfoDialog");

const styles = (theme) => ({
  deleteIcon: {
    marginLeft: theme.spacing.unit,
  },
  button: {
    margin: `${theme.spacing.unit}px`,
  },
  delete: {
    minWidth: "350px",
  },
  actionBar: {
    backgroundColor: theme.palette.background.default,
    margin: 0,
  },
  groupBorder: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: theme.palette.grey[400],
  },
  textField: {
    flexBasis: 80,
  },
  dialogContent: {
    minHeight: 300,
  },
  dialogContentWrapper: {
    width: "calc(100vw - 50px)",
    height: "calc(100vh - 220px)",
    maxWidth: 600,
  },
});

function fallback_template_id(dlgValue, flow_temps) {
  if (!flow_temps) return [];
  const flow_temps_map = flow_temps.reduce((acc, item) => {
    acc[item.value] = item;
    return acc;
  }, {});

  if (Array.isArray(dlgValue.template_id)) {
    const values = [];
    dlgValue.template_id.forEach(function(id) {
      const found = flow_temps_map[id];
      if (found !== undefined) values.push(found);
    });
    return values;
  }
  const found = flow_temps_map[dlgValue.template_id];
  if (found !== undefined) return [found];
}

const PAInfoDialog = (props) => {
  const { plfacc: inPlfacc, flow_templates, validators = {}, classes } = props,
    { onCancel, onOK, onDelete } = props,
    { t: ttt } = useTranslation(),
    formRef = useRef(null),
    [canSub, setSubmit] = useState(false),
    [dlgValue, setValues] = useState(inPlfacc),
    [flow_temps, setFlowTemps] = useState(),
    [tabValue, setTabValue] = useState(0),
    [isPasswordHidden, setPasswordHidden] = useState(false);
  let plfacc = inPlfacc || {};

  debug("PAInfoDialog", {
    dlgValue,
  });

  const disableButton = () => {
    setSubmit(false);
  };

  const enableButton = () => {
    setSubmit(true);
  };

  const handleForm = (event) => {
    const { name, type, checked, value: tValue } = event.target,
      value =
        type === "checkbox"
          ? checked
          : type === "number"
          ? parseFloat(tValue)
          : tValue;
    setValues(imupdate.set(dlgValue, name, value));
    debug("handleForm", event, event.target);
  };

  const handlePlatform = (platforms) => {
    const value = platforms.map((x) => x.value);
    setValues(imupdate.set(dlgValue, "template_id", value));
    debug("handleForm", platforms);
  };

  const handleSecurity = (option) => {
    setValues(
      imupdate.set(dlgValue, "preferences.security_option", option.value)
    );
  };

  const handlePasswordHidden = () => {
    setPasswordHidden(!isPasswordHidden);
  };

  const securityOptions = [
    {
      value: null,
      label: ttt("settings-plfaccs:plfacc info dialog.security.options.none"),
    },
    {
      value: "password",
      label: ttt(
        "settings-plfaccs:plfacc info dialog.security.options.password"
      ),
    },
    /*
    {
      value: "recaptcha",
      label: ttt(
        "settings-plfaccs:plfacc info dialog.security.options.recaptcha"
      ),
    },
    */
  ];

  //
  let onValidSubmit;
  onValidSubmit = (updates) => {
    return onOK(dlgValue);
  };

  useEffect(() => {
    // NOTES: Filtering - Only published flow templates will be shown.
    const filtered = ld
      .chain(flow_templates)
      .map((ft) => ({
        label: ft.template_name,
        value: ft.id,
        isDisabled: ld.isNil(ft.publish_time),
        disabledSuffix: ld.isNil(ft.publish_time)
          ? ttt("Not Yet Published")
          : undefined,
      }))
      .value();
    debug("flow_temps filtered", filtered);
    setFlowTemps(filtered);
  }, [flow_templates]);

  return (
    <Dialog
      // id="staff-info-dialog"
      classes={{
        paper: "m-24",
      }}
      open={true}
      onClose={onCancel}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <AppBar position="static" elevation={1}>
        <div className="flex flex-row items-center px-24 pt-16 pb-8">
          <div className="flex">
            <Pavatar
              className="w-48 h-48 mr-4"
              alt="contact avatar"
              src={getIcon(plfacc)}
              // fallback={ava(plfacc)}
            />
            <Pavatar
              className="w-24 h-24 -ml-16 mt-auto"
              alt="contact avatar"
              src={Platforms[plfacc.platform].icon}
              fallback={plfacc.platform}
              white
            />
          </div>
          <Typography variant="h6" color="inherit" className="ml-16">
            {inPlfacc.name}
          </Typography>
        </div>
      </AppBar>

      <DialogContent
        className={classNames("pt-0", classes.dialogContentWrapper)}
      >
        <FuseScrollbars>
          <Formsy
            id="plfacc-info-form"
            onValidSubmit={onValidSubmit}
            onValid={enableButton}
            onInvalid={disableButton}
            ref={formRef}
            className={classNames(classes.dialogContent, "flex flex-col")}
          >
            <Tabs
              value={tabValue}
              indicatorColor="secondary"
              textColor="secondary"
              classes={{
                flexContainer: "justify-evenly",
              }}
            >
              <Tab
                className="normal-case"
                label="General"
                onClick={() => setTabValue(0)}
              />
              <Tab
                className="normal-case"
                label="Security"
                onClick={() => setTabValue(1)}
              />
            </Tabs>
            <span className={tabValue === 0 ? "flex flex-col" : "hidden"}>
              <SwitchField
                checked={dlgValue.preferences.auto_reply}
                name="preferences.auto_reply"
                label={ttt("settings-flow-template:Detail.General.auto_reply")}
                onChange={handleForm}
              />

              <TextFieldFormsy
                className="mt-16 mb-16"
                type="text"
                name="name"
                label={ttt("settings-plfaccs:plfacc info dialog.name")}
                validations={ld_get(validators, "name.validations")}
                validationErrors={ld_get(validators, "name.validationErrors")}
                value={plfacc.name}
                onChange={handleForm}
                variant="outlined"
                required
              />

              {preferences.map((pref) => (
                <CheckboxFormsy
                  key={`preferences.${pref}`}
                  name={`preferences.${pref}`}
                  label={ttt(
                    `settings-plfaccs:plfacc info dialog.preferences.${pref}`
                  )}
                  onChange={handleForm}
                  value={dlgValue.preferences[pref]}
                  className="my-8"
                />
              ))}

              <FuseChipSelect
                id="template_id"
                name="template_id"
                className="mt-8 mb-12"
                onChange={handlePlatform}
                value={fallback_template_id(dlgValue, flow_temps)}
                options={flow_temps}
                placeholder={ttt(
                  "settings-plfaccs:plfacc info dialog.choose template"
                )}
                textFieldProps={{
                  label: ttt(
                    "settings-plfaccs:plfacc info dialog.choose template"
                  ),
                  InputLabelProps: {
                    shrink: true,
                  },
                  variant: "outlined",
                }}
                variant="outlined"
                isMulti
              />

              {dlgValue.platform == "asangaweb" && (
                <TextField
                  id="timeout"
                  name="preferences.timeout"
                  type="number"
                  className="mt-8 mb-16 w-full"
                  onChange={handleForm}
                  value={dlgValue.preferences.timeout || 0}
                  label={ttt("settings-flow-template:Detail.General.Timeout")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      max: 10080,
                      min: 0,
                    },
                  }}
                  variant="outlined"
                />
              )}

              {dlgValue.platform == "asangaweb" && (
                <>
                  <span
                    style={{
                      paddingLeft: "0.4rem",
                      height: 48,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body2">
                      {ttt("settings-flow-template:Detail.General.avatar")}
                    </Typography>
                  </span>

                  <UploadWithPreviews
                    maxFrameWidth="256px"
                    maxFrameHeight="256px"
                    value={
                      (dlgValue.preferences.avatar &&
                        dlgValue.preferences.avatar.split(",").map((url) => ({
                          url,
                          preview: url,
                        }))) ||
                      []
                    }
                    node_id={dlgValue.plfacc_code}
                    language="plfacc_avatar"
                    maxImages={2}
                    onChange={(files) => {
                      console.log(files);
                      const concated_url = files
                        .map((file) => file.url)
                        .join(",");

                      setValues(
                        imupdate.set(
                          dlgValue,
                          "preferences.avatar",
                          concated_url
                        )
                      );
                    }}
                  />
                  <SwitchField
                    checked={dlgValue.preferences.auto_get_started}
                    name="preferences.auto_get_started"
                    label={ttt(
                      "settings-flow-template:Detail.General.AutoGetStated"
                    )}
                    onChange={handleForm}
                  />
                  <SwitchField
                    checked={dlgValue.preferences.stt_enabled}
                    name="preferences.stt_enabled"
                    label={ttt(
                      "settings-flow-template:Detail.General.stt_enabled"
                    )}
                    onChange={handleForm}
                  />
                  <SwitchField
                    checked={dlgValue.preferences.tts_button_enabled}
                    name="preferences.tts_button_enabled"
                    label={ttt(
                      "settings-flow-template:Detail.General.tts_button_enabled"
                    )}
                    onChange={handleForm}
                  />
                  <SwitchField
                    checked={dlgValue.preferences.tts_enabled}
                    name="preferences.tts_enabled"
                    label={ttt(
                      "settings-flow-template:Detail.General.tts_enabled"
                    )}
                    onChange={handleForm}
                  />

                  <TextField
                    id="timeout"
                    name="preferences.placeholder"
                    type="text"
                    className="mt-8 mb-16 w-full"
                    onChange={handleForm}
                    value={dlgValue.preferences.placeholder || ""}
                    label={ttt("settings-flow-template:Placeholder for input box")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        max: 10080,
                        min: 0,
                      },
                    }}
                    variant="outlined"
                  />
                </>
              )}

              <div className={classNames(classes.delete, "flex items-center")}>
                <FormLabel className="text-14 w-1/3">
                  {ttt("settings-plfaccs:plfacc info dialog.delete plfacc")}
                </FormLabel>
                <Button
                  variant="contained"
                  className={classNames(classes.button, "w-2/3")}
                  onClick={onDelete.bind(null, plfacc)}
                >
                  {ttt("general:delete")}
                  <DeleteIcon className={classes.deleteIcon} />
                </Button>
              </div>
            </span>
            <span className={tabValue === 1 ? "flex flex-col" : "hidden"}>
              <FuseChipSelect
                id="security_option"
                name="preferences.security_option"
                className="mt-12 mb-12"
                onChange={handleSecurity}
                value={
                  (dlgValue.preferences &&
                    securityOptions.find(
                      (element) =>
                        element.value == dlgValue.preferences.security_option
                    )) ||
                  securityOptions[0]
                }
                options={securityOptions}
                placeholder={ttt(
                  "settings-plfaccs:plfacc info dialog.security.choose security"
                )}
                textFieldProps={{
                  label: ttt(
                    "settings-plfaccs:plfacc info dialog.security.choose security"
                  ),
                  InputLabelProps: {
                    shrink: true,
                  },
                  variant: "outlined",
                }}
                variant="fixed"
              />
              {dlgValue.preferences &&
                dlgValue.preferences.security_option == "password" && (
                  <span className="flex relative">
                    <TextField
                      id="password"
                      name="preferences.password"
                      type={!isPasswordHidden ? "password" : "text"}
                      className="mt-8 mb-16 w-full"
                      onChange={handleForm}
                      value={
                        (dlgValue.preferences &&
                          dlgValue.preferences.password) ||
                        ""
                      }
                      label={ttt(
                        "settings-plfaccs:plfacc info dialog.security.options.password"
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                    <IconButton
                      className="mt-8 right-0 absolute float-right -translate-x-full"
                      onClick={handlePasswordHidden}
                    >
                      {isPasswordHidden ? (
                        <Icon>visibility</Icon>
                      ) : (
                        <Icon>visibility_off</Icon>
                      )}
                    </IconButton>
                  </span>
                )}
              {/*dlgValue.preferences &&
                dlgValue.preferences.security_option == "recaptcha" && (
                  <span className="flex">
                    <SwitchField
                      id="recaptcha"
                      name="preferences.recaptcha"
                      checked={
                        dlgValue.preferences && dlgValue.preferences.recaptcha
                      }
                      onChange={handleForm}
                      label={ttt(
                        "settings-plfaccs:plfacc info dialog.security.options.recaptcha"
                      )}
                    />
                  </span>
                )*/}
            </span>
          </Formsy>
        </FuseScrollbars>
      </DialogContent>

      <DialogActions className={classNames(classes.actionBar)}>
        <Button aria-label="CLOSE" color="primary" onClick={onCancel}>
          {ttt("general:button.cancel")}
        </Button>
        <Button
          type="submit"
          form="plfacc-info-form"
          aria-label="SAVE"
          disabled={!canSub}
          color="primary"
        >
          {ttt("general:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ parami }) {
  return {
    validators: parami.validations.plfacc,
  };
}

let myComp = connect(mapStateToProps)(PAInfoDialog);
myComp = withStyles(styles, { withTheme: true })(myComp);

export default myComp;
