import ld from "lodash";
import { Gender } from "constants.js";
import * as Actions from "../actions";
import { inAsset } from "app/utils/common";
import { LOCALE_UI_DEF } from "app/data/locales";

export const initialState = {
  role: "guest",
  data: {
    displayName: "",
    photoURL: inAsset("images/avatars/Velazquez.jpg"),
    email: "",
    shortcuts: ["calendar", "mail", "contacts", "todo"],
  },
  // parami specific
  user_id: "",
  from: null,
  username: null,
  last_name: "",
  first_name: "",
  gender: Gender["not specified"],
  job_title: "",
  phone_number: "",
  preferences: {
    ui_language: LOCALE_UI_DEF,
    translate_to_language: LOCALE_UI_DEF,
  },
  rights: {},
};

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.EMAIL_NOT_VERIFIED:
    case Actions.SET_USER_DATA: {
      return ld.merge({}, state, action.payload);
    }

    // case Actions.UPDATE_USER:
    // {
    //     const { user } = action;
    //     return {
    //         ...state, ...user
    //     };
    // }

    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      };
    }

    case Actions.LOGIN_ERROR:
    case Actions.USER_LOGGED_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default user;
