import { SET_SUBWIN_HANDLE } from "app/store/actions/company/platformAccounts.actions";

export default function (state = null, action) {
  switch (action.type) {
    case SET_SUBWIN_HANDLE:
      const { handle } = action;
      return handle;

    default:
      return state;
  }
}
