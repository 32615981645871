import React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select, MenuItem } from "@material-ui/core";
import { setGlobalVariables } from "app/store/actions";
import { withTranslation } from "react-i18next";

let LangSelector = (props) => {
  const { globals, template, t: ttt, setGlobalVariables } = props;
  const { multiTextFieldLang: lang } = globals.variables;
  const tabs = ((template || {}).languages_with_defaults || []).map((lang) => ({
    value: lang,
    label: ttt(`languages:${lang}`),
  }));
  const lang_in_use_ = lang || (tabs && tabs[0] && tabs[0].value) || "default";
  const lang_in_use = tabs.some((tab) => tab.value == lang_in_use_)
    ? lang_in_use_
    : tabs[0].value;
  return (
    <Select
      {..._.omit(props, ["children", "tabs"])}
      displayEmpty
      MenuProps={{
        disableEnforceFocus: true,
      }}
      value={lang_in_use}
      key={0}
      onChange={(event) => {
        setGlobalVariables({ multiTextFieldLang: event.target.value });
        if (props.onChange) props.onChange(event);
      }}
    >
      {tabs.map((item, i) => (
        <MenuItem key={i} {...item}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

LangSelector = connect(
  ({ globals, flowEditorApp }) => {
    return {
      template:
        flowEditorApp &&
        flowEditorApp.template &&
        flowEditorApp.template.currentFlowTemplate,
      globals,
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        setGlobalVariables,
      },
      dispatch
    );
  }
)(LangSelector);

LangSelector = withTranslation()(LangSelector);
export default LangSelector;
