import { cloneDeep, merge, mapValues } from "lodash";

export const LOCALE_UI_DEF = "en";

// NOTES: Customize the default for locales here.
const locale_default = {
  auth0: "en",
  icon: "lock",
  formatter: {
    fullname: {
      main: (ent) =>
        ent
          ? [ent.first_name, ent.last_name].filter((str) => str).join(" ") ||
            ent.fullname ||
            ent.name ||
            ent.username
          : "",
    },
    nameInitial: (ent) =>
      ent
        ? (ent.first_name ? ent.first_name[0] : "") +
          (ent.last_name ? ent.last_name[0] : "")
        : "",
    timestamp: "llll",
    date: `ll`,
  },
};

const oriental_initials = (ent) => (ent ? ent.last_name[0] : "");

// NOTES: Add locales here
const locales = {
  en: {},
  "zh-TW": {
    auth0: "zh-tw",
    icon: "lock",
    formatter: {
      fullname: {
        main: (record) =>
          record ? `${record.last_name}${record.first_name}` : "",
      },
      nameInitial: oriental_initials,
    },
  },
  "zh-CN": {
    auth0: "zh",
    icon: "nice",
    formatter: {
      fullname: {
        main: (record) =>
          record ? `${record.last_name}${record.first_name}` : "",
      },
      nameInitial: oriental_initials,
    },
  },
};

const locs_base = mapValues(locales, () => cloneDeep(locale_default)),
  to_export = merge(locs_base, locales);
export default to_export;
