import { Roles } from "constants.js";

/**
 * Authorization Roles
 */
// TS Notes: Mapping is <authority>: [ role_name_1, role_name_2, ...]
const authRoles = {
  sys_admin_only: [Roles.sysAdmin],
  sys_maint: [Roles.sysAdmin, Roles.sysOps],
  acc_owner_only: [Roles.companyOwner],
  acc_manage: [Roles.companyOwner, Roles.companyManager],
  acc_usage: [Roles.companyOwner, Roles.companyManager, Roles.companyStaff],
  onlyGuest: [Roles.guest],
};

// TODO: Export Roles name as constants

export default authRoles;
