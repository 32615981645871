import { GET_FOLDERS } from "app/store/actions/company";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_FOLDERS:
      const { folders } = action;
      return { ...state, ...folders };

    default:
      return state;
  }
}
