/**
 * @module FilterSelect
 */
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ld from "lodash";
// import Debug from 'debug';

import { i18nNamespaces, Dashboard as DBConst } from "constants.js";
import { showMessage } from "app/store/actions";
import ParamiError, {
  DASHBOARD_FILTER_SELECT_TOO_MANY,
} from "app/utils/ParamiError";

// const debug = Debug('pfe:dashboard:FilterSelect');

const PLFACCS_ALL = DBConst.ALL;

const ITEM_HEIGHT = 48,
  ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme) => ({
  miWithCheckBox: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  cbInMenuItem: {
    padding: 4,
  },
  miText: {
    padding: "0 4px",
  },
});

function isSelected(id, selected, all_items) {
  if (all_items[0] === sel_all) return true;
  return selected.indexOf(id) > -1;
}

function isDisabled(id, selected) {
  return selected.includes(PLFACCS_ALL);
}

const sel_all = "__sel_all";

/**
 * Component for selecting multiple filters.
 *
 * @param {boolean} props.clearMenu If true, a menu for clearing all selections
 * will appear;
 * @param {boolean} props.selectAllMenu If true, a menu for selecting all
 * items will appear;
 * @param {object|boolean} props.allMenu If true, or an object is given,
 * a menu item "All" will appear. This option provides alternatives for a single
 * item named "All" besides the meaning "all items checked";
 * @param {boolean} props.allMenu.display_me_only If true, and when "all" menu
 * is chosen, only the label for "all" menu will be displayed in the
 * FilterSelect after the menu is close;
 */
const FilterSelect = (props) => {
  const {
      filters = {},
      initSelection,
      limit,
      className: cns,
      clearMenu,
      selectAllMenu,
      allMenu,
      ttt: dttt,
      classes,
    } = props,
    { onChange, onClose } = props,
    { showMessage } = props,
    { display_me_only = false } = allMenu || {},
    [selected, setSelected] = useState(
      initSelection[0] === sel_all ? Object.keys(filters) : initSelection
    ),
    [selAll, setSelAll] = useState(
      initSelection.length === ld.size(filters) || initSelection[0] === sel_all
    ),
    { t: ttt } = useTranslation();
  // debug('FitlerSelect', initSelection, selected, selAll, filters);
  const handleChange = (event) => {
    let val = event.target.value;

    try {
      if (val.includes("__clear")) {
        val = [];
      } else if (val.includes(sel_all)) {
        val = Object.keys(filters);
      } else if (limit !== undefined && val.length >= limit) {
        const arr = ld.difference(val, [PLFACCS_ALL]);
        if (arr.length > limit)
          throw new ParamiError(DASHBOARD_FILTER_SELECT_TOO_MANY);
      }

      // Check if all items are selected
      let newSelAll = false;
      if (val.length >= ld.size(filters)) {
        newSelAll = val
          .sort()
          .join(",")
          .includes(
            Object.keys(filters)
              .sort()
              .join(",")
          );
      }

      // debug('handleChange', newSelAll, val);

      setSelected(val);
      setSelAll(newSelAll);

      if (onChange) onChange(newSelAll ? [sel_all] : val);
    } catch (err) {
      let msg;
      if (err.message === DASHBOARD_FILTER_SELECT_TOO_MANY)
        msg = {
          message: ttt("components:filter select.error.over limit", { limit }),
        };
      showMessage(msg);
    }
  };

  const handleClose = (event) => {
    if (!onClose) return;

    if (selectAllMenu && selAll) onClose([sel_all], event);
    else onClose(selected, event);
  };

  let filtersN = filters;
  if (allMenu) filtersN = { ...filters, [PLFACCS_ALL]: dttt("all") };

  const mitems = ld.map(filtersN, (name, plfacc_id) => {
    const disabled =
      plfacc_id === PLFACCS_ALL ? false : isDisabled(plfacc_id, selected);
    return (
      <MenuItem
        key={plfacc_id}
        value={plfacc_id}
        disabled={disabled}
        classes={{
          root: classes.miWithCheckBox,
        }}
      >
        <Checkbox
          checked={isSelected(plfacc_id, selected, filters)}
          classes={{
            root: classes.cbInMenuItem,
          }}
        />
        <ListItemText
          primary={name}
          classes={{
            root: classes.miText,
          }}
        />
      </MenuItem>
    );
  });

  if (clearMenu)
    mitems.push(
      <MenuItem key="clear" value="__clear">
        <ListItemText primary="Clear Selected" />
      </MenuItem>
    );

  if (selectAllMenu)
    mitems.push(
      <MenuItem key={sel_all} value={sel_all}>
        <ListItemText primary="Select All" />
      </MenuItem>
    );

  return (
    <FormControl className={classNames("w-256 mx-8", cns)}>
      <InputLabel>{dttt("title")}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => {
          let arr = ld
            .chain(filters)
            .pick(selected)
            .map((name) => name)
            .value();
          if (selected.includes(PLFACCS_ALL)) {
            const tx_all = dttt("all");
            if (display_me_only) arr = [tx_all];
            else arr.unshift(tx_all);
          }
          return arr.join(", ");
        }}
        MenuProps={{
          ...MenuProps,
          onExited: handleClose,
        }}
      >
        {mitems}
      </Select>
    </FormControl>
  );
};

FilterSelect.sel_all = sel_all;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage,
    },
    dispatch
  );
}

let myComp = connect(null, mapDispatchToProps)(FilterSelect);
export default withStyles(styles, { withTheme: true })(myComp);
