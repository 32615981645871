import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles, Typography } from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
// import {FuseAnimate} from '@fuse';
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import ld from "@lodash";
import Debug from "debug";

// import JWTLoginTab from './tabs/JWTLoginTab';
// import FirebaseLoginTab from './tabs/FirebaseLoginTab';
// import Auth0LoginTab from './tabs/Auth0LoginTab';
import history from "history.js";
import { asanga_app_url } from "constants.js";
import { onAuth0Events } from "app/auth";
import * as Actions from "app/store/actions";
import * as userActions from "app/auth/store/actions";
import { assets } from "app/AppConst";
import { auth0Service } from "app/services/auth0Service";

const debug = Debug("pfe:login");

const styles = (theme) => ({
  root: {
    background: "linear-gradient(30deg, #a274ae 0%, #05a9c4 100%)",
    color: theme.palette.primary.contrastText,
  },
});

let _close_dialog = true;
class Login extends Component {
  state = {
    tabValue: 0,
  };

  showDialog = () => {
    const redir = ld.get(this.props, "location.state.redirectLocation"),
      { location } = this.props;
    auth0Service.login({
      onClose: (details) => {
        let leave_app = false;
        if (details.is_manual_close) {
          leave_app = true;
          _close_dialog = false;
        }
        debug("redir", redir, leave_app);
        if (leave_app) window.location.assign(asanga_app_url);
        else history.replace(redir);
      },
    });
    _close_dialog = true;
    // debug('location.search', this.props.location.search);

    // NOTES: Need to call the Auth0 events checking
    // in Login Components as Auth.js checkings would
    // not help if screen is not refreshed (i.e. Auth.
    // js component mounted again)
    onAuth0Events.call(this, "Login.js", { location });
  };

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  componentDidMount() {
    this.showDialog();
  }

  componentWillUnmount() {
    debug("componentWillUnmount", _close_dialog);
    if (_close_dialog) auth0Service.closeLoginDialog();
  }

  render() {
    const { classes, t: ttt } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          "flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0"
        )}
      >
        <div className="flex flex-col flex-no-grow items-center text-white p-16 text-center md:p-128 md:items-start md:flex-no-shrink md:flex-1 md:text-left"></div>
      </div>
    );
  }
}
/*
                    <img className="w-128 mb-32" src={assets.logo_dark.path} alt={ttt('general:logo')}/>
                    <Typography variant="h3" color="inherit" className="font-light">
                        {ttt('general:Welcome to the Parami')}
                    </Typography>
                    
                    <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16">
                        {ttt('general:Login logged')}
                    </Typography>                    

*/

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserDataAuth0: userActions.setUserDataAuth0,
      PFELogin: userActions.PFELogin,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
    },
    dispatch
  );
}

let myLogin = connect(null, mapDispatchToProps)(Login);
myLogin = withStyles(styles, { withTheme: true })(withRouter(myLogin));
myLogin = withTranslation()(myLogin);
export default myLogin;
