import ld from "lodash";
import Debug from "debug";

import { Rights } from "constants.js";
import { isOwner } from "app/utils/entities/staff";
import store from "app/store";

const debug = Debug("pfe:service:authority");

const matrix = {
  staff: {
    create: {
      rights: [Rights.CREATE_STAFF],
      target: () => {
        const state = store.getState(),
          user = state.auth.user;
        const result = isOwner(user);
        debug("staff.create", result, isOwner(user));
        return result;
      },
    },
    update: {
      rights: [Rights.UPDATE_STAFF],
      target: (staff) => {
        const state = store.getState(),
          user = state.auth.user;
        let staffObj = staff;
        // debug('staffObj', staffObj);
        const result = staffObj.user_type && (isOwner(user) || !isOwner(staff));
        debug(
          "staff.update",
          result,
          staffObj.user_type,
          isOwner(user),
          isOwner(staff)
        );
        return result;
      },
    },
    delete: {
      rights: [Rights.DELETE_STAFF],
      target: (staff) => {
        const result = staff.user_type && !isOwner(staff);
        debug("staff.delete", result, staff.user_type, isOwner(staff));
        return result;
      },
    },
  },
};

export const AA_CREATE_STAFF = "staff.create";
export const AA_UPDATE_STAFF = "staff.update";
export const AA_DELETE_STAFF = "staff.delete";

/**
 *
 * Check current user is allowed to do the specified actions. This is a client
 * side checking only.
 *
 * @param {object|string} staff Staff object as in redux state, or staff's user
 * ID.
 * @return {boolean} true if update is allowed.
 */
export function allowed(action, target) {
  const { rights: rights_needed, target: allowed_target } = ld.get(
      matrix,
      action
    ),
    authorized = check_authority(rights_needed);
  return authorized && allowed_target(target);
}

/**
 * Check if current user has the rights needed.
 *
 * @param {string[]} rights The rights needed.
 * @return {boolean} true if the current user has ANY of the right(s)
 * among the given list.
 */
function check_authority(rights_needed) {
  const state = store.getState(),
    user = state.auth.user,
    rights_of_user = ld.get(user, "rights");
  // debug('rights', rights_needed);
  const matched_rights = rights_needed.some((rname) => {
      return rights_of_user[rname];
    }),
    ret = isOwner(user) || matched_rights;
  debug("check_authority", ret);
  return ret;
}
