import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import Locales from "app/data/locales.js";
import ld from "lodash";

import Pavatar from "app/parami-layouts/Pavatar";
import { fullname, profilePic } from "app/utils/entities/staff";
import { FlexWrapper } from "app/parami-layouts";
import { useTranslation } from "react-i18next";

const fmt = ld.get(Locales, `en.formatter.fullname.main`);

// Notes:
// ===========================
// (1) "width" is the upper bound in this case, not "max-width". Seems
// "width" controls the maximum width, but not a fix width value;
// (2) While "maxWidth" controls the maximum width of string display within
// the item, not the object itself;

export const ColumnDefinition = [
  // {
  //   id            : 'checkers',
  //   align         : 'center',
  //   disablePadding: true,
  //   label         : '',
  //   sort          : false,
  //   field         : null,
  //   fieldType     : 'checkbox',
  //   // display       : () => <Icon className="text-20">check_box</Icon>,
  //   maxWidth      : 40,
  //   classNames : ['cell-wrap-text', 'px-0'],
  //   headerClassNames : ['hd-wrap-text', 'px-0'],
  // },
  {
    id: "username",
    align: "left",
    disablePadding: false,
    label: "table.header.username",
    sort: true,
    width: 200,
    maxWidth: 180,
    classNames: ["cell-wrap-text"],
    headerClassNames: ["hd-wrap-text"],
  },
  {
    id: "fullname",
    align: "left",
    disablePadding: false,
    label: "table.header.user",
    sort: true,
    display: (staff) => (
      <div className="flex">
        <Pavatar
          className=""
          src={profilePic(staff)}
          alt={fullname(staff)}
          fallback
        />
        <div className="pl-8 flex items-center">{fmt(staff)}</div>
      </div>
    ),
    classNames: ["cell-wrap-text"],
    headerClassNames: ["hd-wrap-text"],
  },
  {
    id: "suspended",
    align: "center",
    disablePadding: false,
    label: "table.header.status",
    sort: true,
    fieldType: "picture",
    display: (staff) => {
      return (
        <FlexWrapper>
          {staff.suspended ? (
            <Icon className="text-red text-20">pause_circle_filled</Icon>
          ) : (
            <Icon className="text-green text-20">check_circle</Icon>
          )}
        </FlexWrapper>
      );
    },
    width: 100,
    maxWidth: 25,
    classNames: ["cell-wrap-text"],
    headerClassNames: ["hd-wrap-text"],
  },
  // TODO: Display rights in chips
  // {
  //   id            : 'rights',
  //   align         : 'left',
  //   disablePadding: false,
  //   label         : 'rights',
  //   sort          : true
  // },
  {
    id: "staff_info",
    align: "center",
    disablePadding: false,
    label: "table.header.staff_info",
    sort: false,
    fieldType: "button",
    display: (staff, props) => {
      const { t: ttt } = useTranslation();
      if (props.allowed)
        return (
          <Tooltip title={ttt(`general:edit`)}>
            <IconButton
              className="text-20"
              onClick={(event) => props.onEditInfo(event, staff)}
            >
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        );
    },
    width: 60,
    maxWidth: 25,
    classNames: ["cell-wrap-text", "px-4"],
    headerClassNames: ["hd-wrap-text", "px-4"],
  },
  {
    id: "password",
    align: "center",
    disablePadding: false,
    label: "table.header.password",
    sort: false,
    fieldType: "button",
    display: (staff, props) => {
      const { t: ttt } = useTranslation();
      if (props.allowed)
        return (
          <Tooltip title={ttt(`settings-staff:staff info dialog.set password`)}>
            <IconButton
              className="text-20"
              onClick={(event) => props.onSetPassword(event, staff)}
            >
              <Icon>vpn_key</Icon>
            </IconButton>
          </Tooltip>
        );
    },
    width: 60,
    maxWidth: 25,
    classNames: ["cell-wrap-text", "px-4"],
    headerClassNames: ["hd-wrap-text", "px-4"],
  },
];

export function genDisplayClasses(
  columnDef,
  classes,
  fieldName = "classNames"
) {
  let class_list = [];

  if (columnDef[fieldName]) {
    let tmp_arr = [];
    if (typeof columnDef[fieldName] === "string")
      tmp_arr.push(columnDef[fieldName]);
    else tmp_arr = columnDef[fieldName];

    tmp_arr.map((name) => {
      if (classes[name]) class_list.push(classes[name]);
      else class_list.push(name);
      return null;
    });
  }

  // if (columnDef.width) {
  //   class_list.push(`w-${columnDef.width}`, `max-w-${columnDef.width}`);
  // }

  return class_list;
}

export function genExtraStyles(columnDef) {
  return {
    width: columnDef.width,
    maxWidth: columnDef.maxWidth,
  };
}
