import { combineReducers } from "redux";
import staff from "./staff.reducer";
import info from "./info.reducer";
import plfaccs from "./platformAccounts.reducer";
import handle from "./handle.reducer";
import subscription from "./subscription.reducer";
import customers from "./customers.reducer";
import convTypes from "./convTypes.reducer";
import folders from "./folders.reducer";
import emergency from "./emergency.reducer";

const comapnyReducers = combineReducers({
  info,
  staff,
  plfaccs,
  handle,
  subscription,
  customers,
  convTypes,
  folders,
  emergency,
});

export default comapnyReducers;
