import * as Actions from "app/store/actions/globals";
import _ from "@lodash";

const initialState = {
  fallback_types: [],
  system_variables: [],
  system_variable_to_name: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_GLOBALS: {
      let obj = Object.assign({}, state);
      for (var key in action.data) _.set(obj, key, action.data[key]);
      return obj;
    }

    default: {
      return state;
    }
  }
}
