import React, { useState, useEffect } from "react";
import { FusePageCarded } from "@fuse";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import * as imupdate from "object-path-immutable";
import ld from "lodash";
import Debug from "debug";
import { HeaderTitle } from "app/parami-layouts";

import { i18nNamespaces, Roles, Salutation, Gender } from "constants.js";
import StaffTable from "./StaffTable";
import StaffInfoDialog from "./StaffInfoDialog";
import SetPasswordDialog from "./SetPasswordDialog";
import { produceErrorMsg } from "app/utils/common";
import Locales from "app/data/locales.js";
import { showMessage } from "app/store/actions/fuse";
import {
  getAllStaff,
  createStaff,
  updateStaff,
  setPassword,
  deleteStaff,
} from "app/store/actions/company/staff.actions";
import {
  SingleButtonDialog,
  DoubleButtonDialog,
} from "app/parami-layouts/dialogs";
import {
  allowed,
  AA_CREATE_STAFF,
  AA_DELETE_STAFF,
} from "app/services/paramiService/authority";
import { withPFEValidators } from "app/services/paramiService";

const debug = Debug("pfe:settings:staff:main"),
  fmt = ld.get(Locales, `en.formatter.fullname.main`);
const DialogModes = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  SET_PW: "set pw",
  SET_PW_FAILED: "set pw failed",
};

const DialogActions = {
  CREATE: "create staff",
  SAVE: "save",
  CONFIRMED: "confirmed",
  CANCEL: "cancel",
  CANCELED: "canceled",
  DELETE: "delete",
  SET_PW: "set pw",
};

const Staff = (props) => {
  const { staffList, company } = props,
    {
      showMessage,
      getAllStaff,
      createStaff,
      updateStaff,
      setPassword,
      deleteStaff,
    } = props,
    [staff_list, setStaffList] = useState([]),
    [flowContext, setContext] = useState({
      staff: null,
      mode: null,
      from: null,
      errorMessage: null,
    }),
    // [ staff_to_delete, setDeleteDlg ] = useState(null),
    [order, setOrder] = useState({
      dir: "asc",
      byCol: "username",
    }),
    [selections, setSelected] = useState({}),
    [okButton, setOKButton] = useState(true),
    { t: ttt } = useTranslation();
  debug("staffList - main", okButton, staffList);

  // Actions per row
  const whenSelect = (event, pStaffID) => {
    if (selections[pStaffID]) setSelected(imupdate.del(selections, pStaffID));
    else setSelected(imupdate.set(selections, pStaffID, true));
  };

  // Actions per table
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const new_sel = {};
      Object.keys(staff_list).map((user_id) => {
        new_sel[user_id] = true;
        return null;
      });
      setSelected(new_sel);
      return;
    }
    setSelected({});
  };

  const handleRequestSort = (event, rowDefID) => {
    const new_order = {
      dir:
        rowDefID === order.byCol
          ? order.dir === "desc"
            ? "asc"
            : "desc"
          : order.dir,
      byCol: rowDefID,
    };
    // debug('handleRequestSort', order, new_order);
    sort_staff(staff_list, new_order);

    // Preserve order so for compare usage next time
    setOrder(new_order);
  };

  function sort_staff(pStaffList, pOrder = order) {
    let iteratee;
    switch (pOrder.byCol) {
      case "fullname":
        iteratee = (item) => item.first_name + " " + item.last_name;
        break;

      default:
        iteratee = pOrder.byCol;
        break;
    }

    const sorted = ld.orderBy(pStaffList, [iteratee], [pOrder.dir]);
    debug("sorted!", order);
    setStaffList(sorted);
  }

  //
  // Screen Flow Handling
  //
  const screenFlow = async (action, data) => {
    debug("screenFlow", flowContext, flowContext.mode, action);
    switch (flowContext.mode) {
      case DialogModes.CREATE: {
        // const stf = data;
        let close_dialog = true;
        setOKButton(false);

        if (action === DialogActions.SAVE) {
          try {
            const { updates: partialStaff } = data,
              new_staff = {
                ...partialStaff,
                username: `${partialStaff.name}`,
                preferences: {
                  ui_language: Locales.en.auth0,
                },
                roles: [Roles.companyStaff],
              };
            await createStaff(new_staff);
            showMessage({ message: ttt("settings-staff:create staff ok") });
          } catch (err) {
            close_dialog = false;
          }
          setOKButton(true);
        }
        if (close_dialog) closeStaffInfoDialog();
        break;
      }

      case DialogModes.UPDATE: {
        if (action === DialogActions.SAVE) {
          let close_dialog = true;
          setOKButton(false);

          const { staff: stf, updates: pUpdates } = data,
            patched_staff = ld.pick({ ...stf, ...pUpdates }, [
              "user_id",
              "user_type",
              "salutation",
              "first_name",
              "last_name",
              "gender",
              "job_title",
              "phone_number",
              "salutation",
              "suspended",
              "salutation",
            ]);

          try {
            await updateStaff(patched_staff);
            showMessage({ message: ttt("settings-staff:update staff ok") });
          } catch (err) {
            const message = produceErrorMsg(err, ttt, {
              i18n_keys_root: "error.update staff",
            });
            showMessage({
              message,
            });
            close_dialog = false;
          }

          setOKButton(true);
          if (close_dialog) closeStaffInfoDialog();
        } else if (action === DialogModes.DELETE) {
          setContext(imupdate.set(flowContext, "mode", DialogModes.DELETE));
        } else if (action === DialogModes.SET_PW) {
          setContext(
            imupdate
              .wrap(flowContext)
              .set("mode", DialogModes.SET_PW)
              .set("from", flowContext.mode)
              .value()
          );
        } else {
          closeStaffInfoDialog();
        }
        break;
      }

      case DialogModes.DELETE: {
        if (action === DialogActions.CONFIRMED) {
          const staff_user_name = flowContext.staff.username;
          debug("Delete Staff...", flowContext.staff);
          let msg_to_disp;
          try {
            await deleteStaff(flowContext.staff);
            msg_to_disp = {
              message: ttt("settings-staff:delete staff ok", {
                name: staff_user_name,
              }),
            };
          } catch (err) {
            msg_to_disp = {
              message: produceErrorMsg(err, ttt, {
                i18n_keys_root: "error.delete staff",
                i18n_opts: {
                  name: staff_user_name,
                },
              }),
            };
          }
          closeStaffInfoDialog();
          showMessage(msg_to_disp);
        } else {
          // return to update info dialog;
          setContext(imupdate.set(flowContext, "mode", DialogModes.UPDATE));
        }
        break;
      }

      case DialogModes.SET_PW: {
        if (action === DialogActions.CONFIRMED) {
          debug("Update Password...", flowContext.staff);
          try {
            await setPassword(flowContext.staff, data.password);
            returnToPrevious();
            showMessage({
              message: ttt("settings-staff:set password ok", {
                name: flowContext.staff.name,
              }),
            });
          } catch (err) {
            let errorMessage = null;
            if (err.response && err.response.data) {
              if (err.response.data.message) {
                errorMessage = err.response.data.message;
              }
            }
            setContext(
              imupdate
                .wrap(flowContext)
                .set("mode", DialogModes.SET_PW_FAILED)
                .set("errorMessage", errorMessage)
                .value()
            );
          }
        } else {
          returnToPrevious();
        }
        break;
      }

      case DialogModes.SET_PW_FAILED:
        returnToPrevious();
        break;

      default: {
        const stf = data;
        debug(`To ${action} ${stf ? fmt(stf) : "new"}`, data);
        if (action === DialogActions.CREATE) {
          setContext({
            staff: {
              salutation: Salutation.mr,
              gender: Gender["not specified"],
            },
            mode: DialogModes.CREATE,
            from: null,
          });
        } else {
          setContext({
            staff: stf,
            mode: action,
            from: null,
          });
        }
        break;
      }
    }
  };

  const closeStaffInfoDialog = () => {
    setContext({ staff: null, mode: null, from: null });
    setOKButton(true);
  };

  const returnToPrevious = () => {
    setContext(imupdate.set(flowContext, "mode", flowContext.from));
  };

  //
  // Component to display
  //
  let showDlg;
  switch (flowContext.mode) {
    case DialogModes.CREATE:
      showDlg = (
        <StaffInfoDialog
          staff={flowContext.staff}
          enableOKButton={okButton}
          company_code={company.company_code}
          open={true}
          dlgMode={DialogModes.CREATE}
          onCloseStaffInfoDialog={screenFlow.bind(null, DialogActions.CANCEL)}
          saveStaffInfo={async (pStaff, pUpdates) =>
            screenFlow(DialogActions.SAVE, {
              staff: pStaff,
              updates: pUpdates,
            })
          }
          deleteStaff={screenFlow.bind(null, DialogActions.DELETE)}
        />
      );
      break;

    case DialogModes.UPDATE:
      showDlg = (
        <StaffInfoDialog
          staff={flowContext.staff}
          enableOKButton={okButton}
          enableDeleteButton={allowed(AA_DELETE_STAFF, flowContext.staff)}
          open={true}
          dlgMode={DialogModes.UPDATE}
          onCloseStaffInfoDialog={screenFlow.bind(null, DialogActions.CANCEL)}
          saveStaffInfo={async (pStaff, pUpdates) =>
            screenFlow(DialogActions.SAVE, {
              staff: pStaff,
              updates: pUpdates,
            })
          }
          deleteStaff={screenFlow.bind(null, DialogActions.DELETE)}
          toSetPassword={screenFlow.bind(null, DialogActions.SET_PW)}
        />
      );
      break;

    case DialogModes.DELETE:
      showDlg = (
        <DoubleButtonDialog
          title={ttt("settings-staff:delete dialog.title", {
            fullname: fmt(flowContext.staff),
          })}
          rightButtonCaption={ttt("general:button.delete")}
          onLeft={screenFlow.bind(null, DialogActions.CANCELED)}
          onRight={screenFlow.bind(null, DialogActions.CONFIRMED)}
        />
      );
      break;

    case DialogModes.SET_PW:
      showDlg = (
        <SetPasswordDialog
          staff={flowContext.staff}
          open={true}
          toCancel={screenFlow.bind(null, DialogActions.CANCELED)}
          toSubmit={screenFlow.bind(null, DialogActions.CONFIRMED)}
        />
      );
      break;

    case DialogModes.SET_PW_FAILED:
      showDlg = (
        <SingleButtonDialog
          open={true}
          title={ttt("settings-staff:set password failed dialog.title")}
          messages={[
            flowContext.errorMessage ||
              ttt("settings-staff:set password failed dialog.desc", {
                fullname: fmt(flowContext.staff),
              }),
          ]}
          onClose={screenFlow.bind(null, "ok")}
          // closeButtonCaption={[ttt('message')]}
        />
      );
      break;

    default:
      showDlg = null;
      break;
  }

  useEffect(() => {
    getAllStaff();
  }, []);

  // Run on state change
  useEffect(() => {
    sort_staff(staffList);
  }, [staffList]);

  return (
    <React.Fragment>
      {showDlg}
      <FusePageCarded
        classes={{
          content: "flex",
        }}
        header={
          <HeaderTitle
            key="UsersHeader"
            text={ttt("settings-staff:page header.title")}
            icon="people_alt"
            button={{
              text: ttt("settings-staff:page header.new staff"),
              icon: "add",
              onClick: screenFlow.bind(null, DialogActions.CREATE),
              disabled: !allowed(AA_CREATE_STAFF, flowContext.staff),
            }}
          ></HeaderTitle>
        }
        content={
          <StaffTable
            order={order}
            selections={selections}
            staff_list={staff_list}
            onEditInfo={async (event, pStaff) =>
              screenFlow(DialogModes.UPDATE, pStaff)
            }
            onSetPassword={async (event, pStaff) =>
              screenFlow(DialogModes.SET_PW, pStaff)
            }
            whenSelect={whenSelect}
            handleSelectAllClick={handleSelectAllClick}
            handleRequestSort={handleRequestSort}
            ttt={ttt}
          />
        }
        innerScroll
      />
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage,
      getAllStaff,
      createStaff,
      updateStaff,
      setPassword,
      deleteStaff,
    },
    dispatch
  );
}

function mapStateToProps({ company }) {
  return {
    staffList: company.staff,
    company: company.info,
  };
}

let comp = connect(mapStateToProps, mapDispatchToProps)(Staff);
comp = withPFEValidators(comp, ["general", "user"]);
export { comp as Staff, DialogModes };

// export default withReducer('eCommerceApp', reducer)(Staff);
