import { GET_CUSTOMERS } from "app/store/actions/company/customers.actions";

const initState = {};

export default function (state = initState, action) {
  switch (action.type) {
    case GET_CUSTOMERS:
      const { customers } = action;
      return { ...state, ...customers };

    default:
      return state;
  }
}
