import React from "react";
import { Button, Typography, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FuseAnimate } from "@fuse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { HeaderTitle } from "app/parami-layouts";

import { i18nNamespaces } from "constants.js";

const styles = (theme) => ({});

const PlatformAccountsHeader = (props) => {
  const { onAddNewStaff } = props,
    { t: ttt } = useTranslation();
  return (
    <HeaderTitle
      key="ChannelHeader"
      text={ttt("settings-plfaccs:page header.title")}
      icon="router"
      button={{
        text: ttt("settings-plfaccs:page header.new plfacc"),
        icon: "add",
        onClick: onAddNewStaff,
      }}
    ></HeaderTitle>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // setSearchText: Actions.setProductsSearchText
    },
    dispatch
  );
}

function mapStateToProps({ eCommerceApp, fuse }) {
  return {
    // searchText: eCommerceApp.products.searchText,
    mainTheme: fuse.settings.mainTheme,
  };
}

let myComp = withStyles(styles, { withTheme: true })(PlatformAccountsHeader);
export default connect(mapStateToProps, mapDispatchToProps)(myComp);
