import URL from "url";

export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_PFE_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_PFE_AUTH0_CLIENT_ID,
  // http://web.parami_sit:3000/app/callback
  callbackUrl: URL.format({
    protocol:
      process.env.REACT_APP_PBE_PROTOCOL_ || process.env.REACT_APP_PBE_PROTOCOL,
    hostname:
      process.env.REACT_APP_PBE_HOST_ || process.env.REACT_APP_PBE_HOST,
    port: process.env.REACT_APP_PBE_PORT_ || process.env.REACT_APP_PBE_PORT,
    pathname: "/app/callback",
  }),
  authExt: {
    url: process.env.REACT_APP_PFE_AUTH0_AUTHEXT_URL,
    // client_id: process.env.REACT_APP_PFE_AUTH0_AUTHEXT_CLIENT_ID,
    // client_secret: process.env.REACT_APP_PFE_AUTH0_AUTHEXT_CLIENT_SECRET,
  },
};
