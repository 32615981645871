import { inAsset } from "app/utils/common";

export const assets = {
  logo: {
    // Notes: For index.html, you need to manually change the logo path.
    path: inAsset("images/logos/asanga_logo.svg"),
    // path: inAsset("images/logos/fuse.svg'
  },
  logo_dark: {
    // Notes: For index.html, you need to manually change the logo path.
    path: inAsset("images/logos/asanga_logo.svg"),
    // path: inAsset("images/logos/fuse.svg'
  },
  login_logo: {
    // Notes: For index.html, you need to manually change the logo path.
    path: inAsset("images/logos/asanga_logo.svg"),
    // path: inAsset("images/logos/fuse.svg'
  },
  avatar_default_fallback: {
    // Notes: For index.html, you need to manually change the logo path.
    path: inAsset("images/platforms/asanga.svg"),
    // path: inAsset("images/logos/fuse.svg'
  },
};

export const names = {
  company: "Parami",
  chatbot: "Asanga",
};
