/**
 * @module fnKeeper
 *
 * @description
 * We use it as a storage for some useful functions created in
 * other modules. This is because for some cases, when one code
 * file require another, it will invoke the codes inside. while
 * this may be not appropriate to modules with dependencies.
 *
 * For example, "i18n.js" starts early in PFE and by that time
 * redux store may not be available. If i18n.js wants to include
 * a function that related to redux store or actions, that may
 * cause problem.
 *
 * Instead, i18n.js request the content here, like a placeholder,
 * and when the redux-related routine is ready, it will be placed
 * here for i18n.js to use - by that time, init stage of PFE is
 * completed, and calling to the wanted routine will be safe.
 *
 */

/**
 * Storage of functions
 */
const fnKeeper = {
  formatter: {
    date: (str) => str,
    timestamp: (str) => str,
  },
  ttt: {
    error: (str) => str,
  },
};

export default fnKeeper;
