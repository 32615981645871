import React from "react";
import { assets } from "app/AppConst";

import i18n from "i18n.js";
const ttt = i18n.t.bind(i18n);

const FuseSplashScreen = () => {
  return (
    <div id="fuse-splash-screen">
      <div className="center">
        <div className="logo">
          <img width="128" src={assets.logo.path} alt={ttt("general:logo")} />
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuseSplashScreen;
