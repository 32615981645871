import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const fromProps = (props, handleClose) => {
  return (
    <>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        {(props.buttons || []).map((button) => (
          <Button
            onClick={button.onClick(handleClose)}
            color="primary"
            autoFocus={button.autoFocus}
          >
            {button.text}
          </Button>
        ))}
      </DialogActions>
    </>
  );
};

let usePopup = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getInner = () => {
    if (!props) return;
    if (React.isValidElement(props))
      return <props handleClose={handleClose}></props>;
    else if (typeof props === "function") return props(handleClose);
    else return fromProps(props);
  };

  const comp = (
    <Dialog open={open} onClose={handleClose}>
      {open && getInner()}
    </Dialog>
  );
  return [comp, setOpen];
};

export default usePopup;
