import React, { useState, useEffect } from "react";
import { CheckboxFormsy } from "@fuse";
import { FormLabel, FormGroup, withStyles } from "@material-ui/core";
import Formsy from "formsy-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Debug from "debug";

import { i18nNamespaces, TimeZones } from "constants.js";
import AutoComplete from "app/parami-layouts/AutoComplete";
import { TextFieldPFE } from "app/parami-layouts";
import { updateCompany } from "app/store/actions/company/info.actions";
import { showMessage } from "app/store/actions";

const debug = Debug("pfe:settings:company:general");

const styles = (theme) => ({
  groupBorder: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: theme.palette.grey[400],
  },
  preferences: {
    zIndex: 1,
  },
});

const GeneralTab = (props) => {
  const { formEl, classes, company: pCompany } = props,
    { updateCompany, setSubmitButton, showMessage } = props,
    // Notes: Use Internal state to allow update to redux only on save
    [company, setInfo] = useState(pCompany),
    { t: ttt } = useTranslation();
  const onValidSubmit = async () => {
    const to_update = formEl().current.getModel();
    to_update.company_id = company.company_id;
    debug("onValidSubmit", to_update);
    try {
      await updateCompany(to_update);
      showMessage({ message: ttt("settings-company:save general success") });
    } catch (err) {
      debug("err", err.stack, { err });
      let message = ttt("settings-company:save general failed");
      if (err.response)
        switch (err.response.status) {
          case 422:
            message = ttt("settings-company:invalid form data");
            break;
        }
      showMessage({ message });
    }
  };

  const disableButton = () => {
    setSubmitButton(false);
  };

  const enableButton = () => {
    setSubmitButton(true);
  };

  // Notes: Wait for loading from PBE. Since during the time component loaded,
  // data from PBE may not be ready.
  useEffect(() => {
    setInfo(pCompany);
  }, [pCompany]);

  // Styles
  const smallForm = "block sm:flex",
    labelText = "text-16",
    formRow = classNames(smallForm, "mt-8 mb-8");
  return (
    <div className="flex w-full pt-16">
      <Formsy
        id="company-form"
        onValidSubmit={onValidSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formEl}
        className="flex flex-col w-full mx-16"
      >
        <div className="flex ml-4 mt-8 mb-8 flex-col sm:flex-row">
          <div className="flex flex-1 text-16 mb-16 sm:mb-0">
            <FormLabel className={classNames(smallForm, "mb-8 sm:mb-0 mr-16")}>
              {ttt("form-fields:business.company_code")}
            </FormLabel>
            <FormLabel className={classNames(smallForm, "text-black")}>
              {company.company_code}
            </FormLabel>
          </div>

          <div className="flex flex-1 text-16">
            <FormLabel
              autoFocus
              className={classNames(smallForm, labelText, "mb-8 sm:mb-0 mr-16")}
            >
              {ttt("form-fields:business.type")}
            </FormLabel>
            <FormLabel
              className={classNames(smallForm, labelText, "text-black")}
            >
              {ttt(
                `${
                  company.isPersonal
                    ? "form-fields:business.personal"
                    : "form-fields:business.business"
                }`
              )}
            </FormLabel>
          </div>
        </div>

        <div className={classNames(formRow)}>
          <TextFieldPFE
            className="mt-8 mb-16 sm:mb-0"
            name="alias_code"
            variant="outlined"
            fullWidth
            label={ttt("form-fields:business.alias_code")}
            value={company.alias_code}
            pfeValidType="company.alias_code"
          />
        </div>

        <div className={classNames(formRow)}>
          <TextFieldPFE
            className="mt-8 mb-16 sm:mb-0"
            name="fullname"
            variant="outlined"
            fullWidth
            label={ttt("form-fields:business.fullname")}
            value={company.fullname}
            pfeValidType="company.fullname"
          />
        </div>

        <AutoComplete
          name="timezone"
          className={classNames("block mt-8 mb-8 flex-no-grow")}
          options={TimeZones}
          label={ttt("form-fields:business.timezone")}
          value={company.timezone}
          isPureValue={true}
          placeholder={ttt("settings-company:choose timezone")}
          // onChange={e => handleForm(e, 'timezone')}
          variant="outlined"
        />

        <div className={classNames(formRow)}>
          <TextFieldPFE
            name="phone_number"
            variant="outlined"
            fullWidth
            label={ttt("form-fields:business.phone_number")}
            value={company.phone_number}
            pfeValidType="general.phone_number"
          />
        </div>

        <div className={classNames(formRow)}>
          <TextFieldPFE
            name="email_address"
            label={ttt("form-fields:business.email_address")}
            variant="outlined"
            fullWidth
            value={company.email_address}
            pfeValidType="general.email_address"
          />
        </div>
        <FormLabel component="legend" className="text-14 pt-12 pb-4 ml-4">
          {ttt("form-fields:business.preferences.title")}
        </FormLabel>
        <FormGroup
          className={classNames(
            classes.groupBorder,
            formRow,
            classes.preferences,
            "py-4 px-12 mb-8"
          )}
        >
          <CheckboxFormsy
            className={classNames(formRow)}
            name="preferences.receive_emails"
            value={company.preferences.receive_emails}
            label={ttt("form-fields:business.preferences.receive_emails")}
          />
        </FormGroup>

        {/* Placeholder */}
        <div className="mb-32"></div>
      </Formsy>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCompany,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ company }) {
  return {
    company: company.info,
  };
}

let myComp = connect(mapStateToProps, mapDispatchToProps)(GeneralTab);
myComp = withStyles(styles, { withTheme: true })(myComp);
export default myComp;
