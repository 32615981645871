import { GET_CONV_TYPES } from "app/store/actions/company";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_CONV_TYPES:
      const { convTypes } = action;
      return { ...state, ...convTypes };

    default:
      return state;
  }
}
