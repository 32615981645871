import Auth0Lock from "auth0-lock";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Debug from "debug";
import URL from "url";

import { AUTH_CONFIG } from "./auth0ServiceConfig";
import { assets } from "app/AppConst";
import { currAuth0Lang } from "app/auth/utils";

const debug = Debug("pfe:service:auth0Service");

class auth0Service {
  sdk = { auth0Manage: null };

  init() {
    this.lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
      autoclose: true,
      socialButtonStyle: "big",
      closable: false,
      auth: {
        // redirect: false,
        redirectUrl: URL.format({
          protocol: window.location.protocol,
          hostname: window.location.hostname,
          port: window.location.port,
          pathname: "/app/callback",
        }),
        responseType: "token id_token",
        audience: `https://${AUTH_CONFIG.domain}/api/v2/`,
        params: {
          state: "is_owner_reg:true",
          scope:
            "openid profile email user_metadata app_metadata picture update:current_user_metadata create:current_user_metadata read:current_user",
        },
      },
      languageDictionary: {
        emailInputPlaceholder: "something@youremail.com",
        title: "Login to Parami",
        error: {
          login: {
            "lock.invalid_email_password": "Email/Password is incorrect.",
            "lock.network":
              "A network error occurs. Please check your connection",
            "lock.unauthorized": "Unauthorized.",
            too_many_attempts:
              "Too many failed login attempts. Please try later.",
          },
          signUp: {
            invalid_password: "Password is invalid",
            user_exists: "This email/username has already been registered.",
          },
        },
      },
      theme: {
        logo: assets.login_logo.path,
        primaryColor: "rgb(184 159 210)",
      },
    });
    this.handleAuthentication();
  }

  login = (options) => {
    if (!this.lock) {
      return false;
    }
    // Call the show method to display the widget.
    const { onClose } = options || {},
      mylang = currAuth0Lang();
    // Detect if manuall close dialog
    this.lock.on("show", () => {
      document.body.classList.add("auth0-lock-shown");
      const ele = document.querySelector("span.auth0-lock-close-button");
      if (ele)
        ele.addEventListener(
          "mousedown",
          () => {
            this._is_manual_close = true;
          },
          false
        );
    });

    this.lock.show({
      language: mylang,
      popup: false,
    });

    this.lock.on("hide", () => {
      document.body.classList.remove("auth0-lock-shown");
      debug("onClose event", this._is_manual_close);
      if (onClose) onClose({ is_manual_close: this._is_manual_close });
    });
  };

  closeLoginDialog = (options) => {
    if (!this.lock) {
      return false;
    }
    this.lock.hide();
  };

  register = () => {
    if (!this.lock) {
      return false;
    }

    const mylang = currAuth0Lang();
    this.lock.show({
      language: mylang,
      initialScreen: "signUp",
    });
  };

  handleAuthentication = () => {
    if (!this.lock) {
      return false;
    }

    // Add a callback for Lock's `authenticated` event
    this.lock.on("authenticated", this.setSession);
    // Add a callback for Lock's `authorization_error` event
    this.lock.on("authorization_error", (err) => {
      console.warn(
        `Error: ${err.error}. Check the console for further details.`
      );
    });
  };

  onAuthenticated = (callback) => {
    if (!this.lock) {
      return false;
    }
    this.lock.on("authenticated", callback);
  };

  onAuthFailed = (callback) => {
    if (!this.lock) {
      return false;
    }
    this.lock.on("authorization_error", callback);
  };

  setSession = (authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set the time that the access token will expire at
      let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      );
      localStorage.setItem("access_token", authResult.accessToken);
      localStorage.setItem("id_token", authResult.idToken);
      localStorage.setItem("expires_at", expiresAt);
    }
  };

  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("auth0.ssodata");
  };

  isAuthenticated = () => {
    if (!this.lock) {
      return false;
    }
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    const isNotExpired = new Date().getTime() < expiresAt;
    if (isNotExpired) {
      return true;
    } else {
      this.logout();
      return false;
    }
  };

  getUserData = () => {
    return new Promise((resolve, reject) => {
      const tokenData = this.getTokenData();
      const { sub: userId } = tokenData;

      const auth0UserUrl =
        "https://" + AUTH_CONFIG.domain + "/api/v2/users/" + userId;

      axios
        .get(auth0UserUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAccessToken(),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.warn("Cannot retrieve user data", error);
          reject(error);
        });
    });
  };

  updateUserData = (user_metadata) => {
    const tokenData = this.getTokenData();
    const { sub: userId } = tokenData;

    const auth0UserUrl =
      "https://" + AUTH_CONFIG.domain + "/api/v2/users/" + userId;
    const dataObj = JSON.stringify({ user_metadata });

    return axios.patch(auth0UserUrl, dataObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken(),
      },
    });
  };

  getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  getIdToken = () => {
    return window.localStorage.getItem("id_token");
  };

  getTokenData = () => {
    const token = this.getIdToken();
    if (!token) return null;
    const decoded = jwtDecode(token);
    if (!decoded) {
      return null;
    }
    return decoded;
  };

  refreshSession = async () => {
    const that = this;
    if (!this.refreshingQueue) this.refreshingQueue = [];
    const queue = this.refreshingQueue;
    if (this.refreshing)
      return new Promise((resv, rej) => {
        if (!that.refreshing) return resv();
        queue.push([resv, rej]);
      });

    this.refreshing = true;
    const setSession = this.setSession.bind(this),
      myLock = this.lock;
    return new Promise((resv, rej) => {
      // WORKING: In case checkSession failed, advice user to
      // re-login again to get through this;
      myLock.checkSession({}, function(err, authResult) {
        // handle error or new tokens
        if (err) {
          debug("Auth0 Token refresh failed");
          rej(err);
        } else {
          debug("Auth0 Token refreshed");
          setSession(authResult);
          resv();
          let current;
          while ((current = queue.pop())) current[0]();
        }
      });
    })
      .catch((error) => {
        console.error(error);
        let current;
        while ((current = queue.pop())) current[1]();
      })
      .finally(() => {
        that.refreshing = false;
      });

    // const target = 'https://' + AUTH_CONFIG.domain + '/authorize';

    // return axios.get(target, {
    //     params: {
    //         response_type: `id_token token`,
    //         client_id: AUTH_CONFIG.clientId,
    //         redirect_uri: AUTH_CONFIG.callbackUrl,
    //         // state: '',
    //         // scope: '',
    //         nonce: 'xxxxx',
    //         // audience=...&
    //         // response_mode=...&
    //         prompt: 'none',
    //     },
    //     headers: {
    //         'Content-Type' : 'application/json',
    //         'Authorization': 'Bearer ' + this.getAccessToken(),
    //     }
    // });
  };
}

const instance = new auth0Service();
if (process.env.REACT_APP_ENV !== "production") window.auth0Service = instance;
export default instance;
