/** @module */

import ld from "lodash";
import * as cc from "color-convert";
// import Debug from 'debug';

// const debug = Debug('pfe:utils:color');
const all_colors = {};

/**
 *
 * Generate a random color value in Hex format. This value can be retrieved
 * for use again by the given scope + ident.
 *
 * @example <caption>Basic usage</caption>
 * // Return a random color code in hex, e.g. '6B8399'
 * getColor('name-your-scope', 'user_1);
 *
 * @example <caption>Use with range</caption>
 * // Return a random color code that value will be within given range. For
 * // example, values like '444444' should not be generated.
 * getColor('name-your-scope', 'user_1, { range: ['555555', 'A0A0A0' ]});
 *
 * @param {string} scope A self-defined scope of the color.
 * @param {string} ident Identitty to retrieve the same color values.
 * @param {object} opts Options for the routine.
 * @param {string[]} opts.range Array of color codes that represent the allowed
 * range for random generation. Size of array must be 2;
 * @return {string} Color code generated, in Hex format. Example: "A2B3FF".
 * Pound sign is NOT included.
 */
export function getColor(scope, ident, opts = {}) {
  const jp = [scope, ident].join("."),
    { range = ["000000", "FFFFFF"] } = opts;
  let val = ld.get(all_colors, jp);

  if (!val) {
    val = "#" + genRandomColor(range[0], range[1]);
    ld.set(all_colors, jp, val);
  }
  return val;
}

/**
 * The internal generator for randome color code.
 *
 * @param {string} pColor1 Color Code in Hex, no pound sign.
 * @param {string} pColor2 Color Code in Hex, no pound sign.
 */
function genRandomColor(pColor1, pColor2) {
  const r1 = cc.hex.rgb(pColor1),
    r2 = cc.hex.rgb(pColor2),
    mr = r2[0] - r1[0],
    mg = r2[1] - r1[1],
    mb = r2[2] - r1[2],
    seed_r = ((Math.random() * mr + r1[0]) << 0).toString(16),
    seed_g = ((Math.random() * mg + r1[1]) << 0).toString(16),
    seed_b = ((Math.random() * mb + r1[2]) << 0).toString(16),
    seed = seed_r + seed_g + seed_b,
    color = ("00000" + seed).substr(-6);
  return color;
}

function convertToBinary(x, fill = 3) {
  let bin = 0,
    rem,
    i = 1;
  let result = [];
  while (x !== 0) {
    rem = x % 2;
    x = parseInt(x / 2);
    bin = bin + rem * i;
    i = i * 10;
    result.unshift(rem);
  }

  if (result.length < fill) {
    const to_fill = fill - result.length;
    for (let jjj = 0; jjj < to_fill; jjj++) {
      result.unshift(0);
    }
  }
  // debug('convertToBinary', result);
  return result;
}

export class ColorGen {
  constructor(scope, opts = {}) {
    this.scope = scope;
    this.range = opts.range || ["000000", "FFFFFF"];
    this.colors = {};
    this.last_color = null;
  }

  getByID(ident) {
    const { range, scope, colors } = this,
      jp = [scope, ident].join(".");
    let ccode = ld.get(colors, jp);

    if (!ccode) {
      const val = genNextRandColor(this.last_color, range[0], range[1]);
      this.last_color = val;
      ccode = "#" + val;
      ld.set(colors, jp, ccode);
    }
    return ccode;
  }
}

let last_rand;
function genNextRandColor(pLast, pColor1, pColor2) {
  let dir,
    found = false;
  do {
    dir = Math.floor(Math.random() * 8);
    if (dir !== last_rand) {
      last_rand = dir;
      found = true;
    }
  } while (!found);

  const r0 = cc.hex.rgb(pLast || pColor1),
    r1 = cc.hex.rgb(pColor1),
    r2 = cc.hex.rgb(pColor2),
    binArr = convertToBinary(dir),
    mr = binArr[0] === 1 ? r2[0] - r0[0] : r1[0] - r0[0],
    mg = binArr[1] === 1 ? r2[1] - r0[1] : r1[1] - r0[1],
    mb = binArr[2] === 1 ? r2[2] - r0[2] : r1[2] - r0[2],
    seed_r = (magnitude(mr, r0[0]) << 0).toString(16),
    seed_g = (magnitude(mg, r0[1]) << 0).toString(16),
    seed_b = (magnitude(mb, r0[2]) << 0).toString(16),
    seed = seed_r + seed_g + seed_b,
    color = ("00000" + seed).substr(-6);
  // debug('genNextRandColor', pLast, binArr, mr, mg, mb);
  return color;
}

function magnitude(mag, orig) {
  const m = Math.floor(Math.random() * (mag / 2)),
    m2 = Math.floor(mag / 2) + m;
  return orig + m2;
}
