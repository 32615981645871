import React from "react";
import {
  Avatar,
  withStyles,
  TableCell,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import classNames from "classnames";

import {
  genDisplayClasses,
  genExtraStyles,
  ColumnDefinition,
} from "./StaffTableDef";

const styles = (theme) => ({
  "cell-wrap-text": {
    // width: '100px',
    maxWidth: "150px",
    overflow: "hidden",
    //textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const StaffTableRow = (props) => {
  return (
    <TableRow>
      {ColumnDefinition.map((tuple, ind) => {
        const { content, class_list, extra_styles } = generateDisplay(
          tuple,
          props
        );
        return (
          <TableCell
            key={`row-cell-${ind}`}
            component="th"
            scope="row"
            align={tuple.align}
            className={classNames(...class_list)}
            style={extra_styles}
          >
            {content}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

function generateDisplay(columnDef, props) {
  const { staff, classes, selected, whenSelect } = props;
  let content = "",
    field_to_use = columnDef.field ? columnDef.field : columnDef.id;

  if (columnDef.display) content = columnDef.display(staff, props);
  else {
    if (columnDef.fieldType === "checkbox") {
      content = (
        <Checkbox
          id={staff.user_id}
          checked={selected}
          onClick={(event) => whenSelect(event, staff.user_id)}
        />
      );
    } else if (columnDef.fieldType === "picture") {
      content = (
        <Avatar
          className=""
          // alt={ttt('user photo')}
          src={staff[field_to_use]}
        />
      );
    } else {
      content = staff[field_to_use];
    }
  }
  const extra_styles = genExtraStyles(columnDef);
  const class_list = genDisplayClasses(columnDef, classes);
  return { content, class_list, extra_styles };
}

let myComp = withStyles(styles, { withTheme: true })(StaffTableRow);
// export default connect(mapStateToProps, mapDispatchToProps)(myComp);

export { myComp as StaffTableRow, ColumnDefinition, genDisplayClasses };
