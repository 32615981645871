import { combineReducers } from "redux";
import fuse from "./fuse";
import auth from "app/auth/store/reducers";
import parami from "./parami";
import company from "./company";
import globals from "./globals";
import test from "./test";
import quickPanel from "app/fuse-layouts/shared-components/quickPanel/store/reducers";
import dashboard from "./dashboard";

function lastAction(state = null, action) {
  return action;
}

let myTest = window.Cypress ? test : null;

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    fuse,
    parami,
    company,
    dashboard,
    test: myTest,
    globals,
    quickPanel,
    ...asyncReducers,
    lastAction,
  });

export default createReducer;
