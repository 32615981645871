import { bindActionCreators } from "redux";
import Debug from "debug";

import i18n from "i18n.js";
import { i18nNamespaces } from "constants.js";
import store from "app/store";
import * as Actions from "app/store/actions";
import * as AuthActions from "app/auth/store/actions";
import { auth0Service } from "app/services/auth0Service";

const ttt = i18n.t.bind(i18n);

const debug = Debug("pfe:auth:core");

const binded = bindActionCreators(
  {
    logoutUser: AuthActions.logoutUser,
    setUserDataAuth0: AuthActions.setUserDataAuth0,
    PFELogin: AuthActions.PFELogin,
    showMessage: Actions.showMessage,
  },
  store.dispatch
);

/**
 *
 * @param {*} props
 * @param {*} opts
 */
export function whenAuthOK(opts) {
  const { setUserDataAuth0, PFELogin, showMessage } = binded,
    { label, location } = opts || {};
  showMessage({ message: ttt("general:login.progress") });

  debug("whenAuthOK", label, location);
  auth0Service
    .getUserData()
    .then((tokenData) => {
      debug("whenAuthOK - then", label);
      setUserDataAuth0(tokenData);
      PFELogin({ location });
      showMessage({ message: ttt("general:login.ok") });
    })
    .catch((err) => {
      debug("whenAuthOK - err", err.stack);
    });
}

export function whenAuthFailed(opts) {
  const { logoutUser } = binded;
  logoutUser();
}

// NOTES: interimFn
// ========================================
// For adding event handler of Auth0, since there is no function to remove
// listener for lock.js, we need to use an interimFn to allow changing/replacing
// the event handler when needed.

let interimFnOK,
  interimFnFailed,
  added_event_handler = false;
export function onAuth0Events(label, opts) {
  debug("onAuth", label);
  const { location } = opts || {};

  interimFnOK = whenAuthOK.bind(null, { label, location });
  interimFnFailed = whenAuthFailed.bind(null, { label });

  if (!added_event_handler) {
    auth0Service.onAuthenticated(() => {
      if (interimFnOK) {
        debug("call interim: OK");
        interimFnOK();
      }
    });

    auth0Service.onAuthFailed((err) => {
      debug("onAuthFailed", err.stack);
      if (interimFnFailed) {
        debug("call interim: failed");
        interimFnFailed();
      }
    });
    added_event_handler = true;
  } else {
    debug("onAuth0Events - Already added event handlers");
  }
}
