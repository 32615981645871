import React from "react";
import {
  withStyles,
  Table,
  TableBody,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import ld from "lodash";
import Debug from "debug";

import StaffTableHeader from "./StaffTableHeader";
import { StaffTableRow } from "./StaffTableRow";
import { allowed, AA_UPDATE_STAFF } from "app/services/paramiService/authority";

import i18n from "i18n.js";
import Pavatar from "app/parami-layouts/Pavatar";
import Locales from "app/data/locales.js";
import { fullname, profilePic } from "app/utils/entities/staff";
import { FlexWrapper } from "app/parami-layouts";

import { AgGrid } from "app/parami-layouts";

const ttt = i18n.getFixedT(null, "general");

const fmt = ld.get(Locales, `en.formatter.fullname.main`);

const debug = Debug("pfe:settings:staff:StaffTable");

const styles = (theme) => {};

const UserNameRenderer = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Pavatar
        className=""
        src={profilePic(props.data)}
        alt={fullname(props.data)}
        fallback
      />
      <div className="pl-8 flex items-center">{fmt(props.data)}</div>
    </div>
  );
};

const StaffStatusRenderer = (props) => {
  return (
    <div className="w-full flex justify-center items-center">
      {props.data.suspended ? (
        <Icon className="text-red text-20">pause_circle_filled</Icon>
      ) : (
        <Icon className="text-green text-20">check_circle</Icon>
      )}
    </div>
  );
};

const StaffActionRenderer = (props) => {
  const { ttt } = props.colDef;
  const allow = allowed(AA_UPDATE_STAFF, props.data);
  if (allow)
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={ttt(`general:edit`)}>
          <IconButton
            className="text-20"
            onClick={(event) => props.colDef.onEditInfo(event, props.data)}
          >
            <Icon>edit</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title={ttt(`settings-staff:staff info dialog.set password`)}>
          <IconButton
            className="text-20"
            onClick={(event) => props.colDef.onSetPassword(event, props.data)}
          >
            <Icon>vpn_key</Icon>
          </IconButton>
        </Tooltip>
      </div>
    );
  return <span></span>;
};

const frameworkComponents = {
  userNameRenderer: UserNameRenderer,
  staffStatusRenderer: StaffStatusRenderer,
  staffActionRenderer: StaffActionRenderer,
};

const StaffTable = (props) => {
  const { order, selections, staff_list, ttt } = props;
  const {
    onEditInfo,
    onSetPassword,
    handleSelectAllClick,
    handleRequestSort,
    whenSelect,
  } = props;

  debug("staff_list", staff_list);

  let staffTable_list = staff_list.filter((e) => e.user_type != "owner");

  const columnDefs = [
    {
      headerName: ttt("settings-staff:table.header.username"),
      field: "username",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: ttt("settings-staff:table.header.user"),
      field: "fullname",
      sortable: true,
      flex: 3,
      minWidth: 200,
      cellRenderer: "userNameRenderer",
    },
    {
      headerName: ttt("settings-staff:table.header.status"),
      field: "suspended",
      sortable: true,
      width: 100,
      cellRenderer: "staffStatusRenderer",
    },
    {
      headerName: "",
      field: "id",
      sortable: false,
      width: 150,
      cellRenderer: "staffActionRenderer",
      onSetPassword,
      onEditInfo,
      ttt,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AgGrid
        columnDefs={columnDefs}
        defaultColDef={{ autoHeight: true }}
        frameworkComponents={frameworkComponents}
        data={staffTable_list}
      />
    </div>
    // <FuseScrollbars className="flex-grow overflow-x-auto">
    //   <Table aria-labelledby="tableTitle">
    //     <StaffTableHeader
    //       sortDir={order.dir}
    //       sortCol={order.byCol}
    //       selections={selections}
    //       rowCount={ld.size(staff_list)}
    //       numSelected={ld.size(selections)}
    //       onSelectAllClick={handleSelectAllClick}
    //       onRequestSort={handleRequestSort}
    //     />

    //     <TableBody>
    //       {staff_list
    //         .filter((e) => e.user_type != "owner")
    //         .map((staff, ind) => {
    //           return (
    //             <StaffTableRow
    //               key={`staff-row-${ind}`}
    //               staff={staff}
    //               allowed={allowed(AA_UPDATE_STAFF, staff)}
    //               selected={selections[staff.user_id] || false}
    //               whenSelect={whenSelect}
    //               onEditInfo={onEditInfo}
    //               onSetPassword={onSetPassword}
    //             />
    //           );
    //         })}
    //     </TableBody>
    //   </Table>
    // </FuseScrollbars>
  );
};

let myComp = withStyles(styles, { withTheme: true })(StaffTable);
// export default connect(mapStateToProps)(myComp);
export default myComp;
