import React, { useState, useEffect } from "react";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { Button, withStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ld from "lodash";
import Debug from "debug";
import classNames from "classnames";

import { isActive } from "app/utils/entities/plan";
// import { SubsPagePath } from './SubsPageConfig';
import SubscriptionCard from "./SubscriptionCard";
import ChoosePlanDialog from "./ChoosePlanDialog";
import ConfirmDialog from "./ConfirmDialog";
import { i18nNamespaces } from "constants.js";
import { showMessage } from "app/store/actions";
import {
  getSubscription,
  updateSubscription,
} from "app/store/actions/company/subscription.actions";
import { getAsangaPlans } from "app/store/actions/parami/asangaPlans.actions";
import { SingleButtonDialog, HeaderTitle } from "app/parami-layouts";

// import GeneralTab from './GeneralTab';
// import AddressTab from './AddressTab';

const isCy = window.Cypress;
const debug = Debug("pfe:settings:subscription:main");
const SubsPagePath = "/subscription";

const styles = (theme) => ({
  footerBar: {
    backgroundColor: theme.palette.grey[100],
  },
});

const Scenes = {
  MAIN_SCREEN: "scene.main_screen",
  CHOOSE: "scene.choose",
  CONFIRM: "scene.confirm",
  CHOOSE_COMPLETE: "scene.choose_complete",
  PAYMENT_ERROR: "scene.payment_error",
  POST_PAYMENT_ERROR: "scene.post_payment_error",
};

const Acts = {
  GO_CHOOSE: "act.go_choose",
  CHOOSE_OK: "act.choose_ok",
  CHOOSE_CANCEL: "act.choose_cancel",
  CONFIRM_GO_PREV: "act.confirm_go_prev",
  CONFIRM_PAYMENT_OK: "act.confirm_payment_ok",
  CONFIRM_POST_PAYMENT_ERROR: "act.confirm_post_payment_error",
};

const Subscription = (props) => {
  const { company, plans, subscription, match } = props,
    {
      getSubscription,
      getAsangaPlans,
      showMessage,
      updateSubscription,
    } = props,
    { action } = match.params,
    [newerPlan, setNewPlan] = useState({}),
    all_plans = ld.pickBy(plans, isActive),
    [flow, setScreenFlow] = useState({
      scene: Scenes.MAIN_SCREEN,
    }),
    { t: ttt } = useTranslation();
  // debug('match', match);

  // Note: currentPlan may be null since all_plans has to be loaded from PBE.
  const currentPlan = all_plans[subscription.plan_id];
  debug("currentPlan", currentPlan, all_plans, subscription);

  // Styles
  const smallForm = "block md:flex",
    formRow = classNames(smallForm, "items-start"),
    formCol = "mr-16 md:min-w-192";
  const update_subscription = async (details, data) => {
    let tuple;
    try {
      // Capture the funds from the transaction
      debug("update_subscription - details", details);
      debug("update_subscription - data", data);
      debug("update_subscription - newerPlan", newerPlan);

      await updateSubscription(newerPlan, details);
      screenFlow(Acts.CONFIRM_PAYMENT_OK);

      tuple = {
        message: ttt("settings-subscription:change plan success"),
      };
    } catch (error) {
      let status_code;
      if (error.response) status_code = error.response.status;

      screenFlow(Acts.CONFIRM_POST_PAYMENT_ERROR, {
        error,
        status_code,
        payload: details
          ? {
              email_address: details.subscriber.email_address,
              payer_id: details.subscriber.payer_id,
              // order_id: null,
              subscription_id: details.id,
            }
          : null,
      });

      tuple = {
        message: ttt("settings-subscription:change plan failed"),
      };
    } finally {
      showMessage(tuple);
    }
  };

  const handle_payment_error = (error) => {
    screenFlow(Acts.CONFIRM_PAYMENT_ERROR, { error });
  };

  // When Component Mount
  useEffect(() => {
    getAsangaPlans()
      .then(() => {
        return getSubscription();
      })
      .then(() => {
        if (action === "choose") {
          setScreenFlow({
            scene: Scenes.CHOOSE,
          });
        }
      });
  }, []);

  // Cypress Testing
  // if (isCy) {
  //   useEffect(() => {
  //     if (trigger.label === 'update_subscription') {
  //       const { details, data } = trigger.params;
  //       update_subscription(details, data);
  //     }
  //   }, [trigger]);
  // }

  // Screen Flow Handling
  const screenFlow = async (action, data) => {
    debug(`screenFlow flow=${flow.scene} action=${action}`);
    switch (flow.scene) {
      case Scenes.MAIN_SCREEN: {
        if (action === Acts.GO_CHOOSE) {
          setNewPlan(null);
          setScreenFlow({
            scene: Scenes.CHOOSE,
          });
        }
        break;
      }

      case Scenes.CHOOSE: {
        if (action === Acts.CHOOSE_OK) {
          const plan_chosen = data;
          setNewPlan(plan_chosen);
          setScreenFlow({
            scene: Scenes.CONFIRM,
          });
        } else if (action === Acts.CHOOSE_CANCEL) {
          returnMainScreen();
        }
        break;
      }

      case Scenes.CONFIRM: {
        if (action === Acts.CONFIRM_GO_PREV) {
          setScreenFlow({
            scene: Scenes.CHOOSE,
          });
        } else if (action === Acts.CONFIRM_PAYMENT_ERROR) {
          setScreenFlow({
            scene: Scenes.PAYMENT_ERROR,
            ...data,
          });
        } else if (action === Acts.CONFIRM_POST_PAYMENT_ERROR) {
          setScreenFlow({
            scene: Scenes.POST_PAYMENT_ERROR,
            ...data,
          });
        } else returnMainScreen();

        break;
      }

      default: {
        debug(`To ${action}`, data);
        setScreenFlow({
          scene: action,
          data,
        });
        break;
      }
    }
  };

  const returnMainScreen = (message) => {
    setScreenFlow({
      scene: Scenes.MAIN_SCREEN,
    });
  };

  // Component to display
  let showDlg, subWindow;
  switch (flow.scene) {
    case Scenes.CHOOSE: {
      showDlg = (
        <ChoosePlanDialog
          plans={all_plans}
          currentPlan={currentPlan}
          wantedPlan={newerPlan}
          onCancel={screenFlow.bind(null, Acts.CHOOSE_CANCEL)}
          onNext={(chosen) => {
            screenFlow(Acts.CHOOSE_OK, chosen);
          }}
        />
      );
      break;
    }

    case Scenes.CONFIRM: {
      showDlg = (
        <ConfirmDialog
          plan={newerPlan}
          onCancel={screenFlow.bind(null, Acts.CHOOSE_CANCEL)}
          onGoPrevious={screenFlow.bind(null, Acts.CONFIRM_GO_PREV)}
          onComplete={update_subscription}
          onPaypalError={handle_payment_error}
        />
      );
      break;
    }

    case Scenes.PAYMENT_ERROR:
    case Scenes.POST_PAYMENT_ERROR: {
      let details, dialog_type;
      if (flow.scene === Scenes.PAYMENT_ERROR) {
        dialog_type = "payment error dialog";
        details = [flow.error];
      } else if (flow.scene === Scenes.POST_PAYMENT_ERROR) {
        const { status_code, payload } = flow || {};
        debug("POST_PAYMENT_ERROR - payload", flow.error, payload);
        dialog_type = "post-payment error dialog";

        details = [
          ttt(`settings-subscription:${dialog_type}.error.${status_code}`),
        ];
        if (payload) {
          details.push(
            ttt(
              "settings-subscription:post-payment error dialog.payment details"
            )
          );
          ["email_address", "payer_id", "subscription_id"]
            .map(
              (item) =>
                ttt(`form-fields:subscription.payment.paypal.${item}`) +
                ": " +
                ld.get(payload, item)
            )
            .filter((item) => item)
            .map((item) => details.push(item));
          details.push();
        }
      }

      showDlg = (
        <SingleButtonDialog
          open={true}
          title={ttt(`settings-subscription:${dialog_type}.title`)}
          messages={[
            ...ttt(`settings-subscription:${dialog_type}.messages`, {
              returnObjects: true,
            }),
            ...details,
          ]}
          onClose={screenFlow.bind(null, Scenes.MAIN_SCREEN)}
        />
      );
      break;
    }

    default:
      showDlg = null;
      break;
  }

  return (
    <React.Fragment>
      {subWindow}
      {showDlg}
      <FusePageCarded
        innerScroll
        classes={{
          content: "flex",
        }}
        header={
          <HeaderTitle
            key="SubscriptionHeader"
            text={ttt("settings-subscription:page header.title")}
            icon="credit_card"
          ></HeaderTitle>
        }
        content={
          <div className="w-full px-16 pt-24 items-start">
            <div className={classNames(formRow)}>
              <Typography
                className={classNames(formCol, "pb-8 text-18 font-300")}
              >
                {ttt("form-fields:subscription.plan_subscribed")}
              </Typography>

              <div className="flex-1">
                {currentPlan ? (
                  <SubscriptionCard
                    className="mb-16"
                    company={company}
                    subscription={subscription}
                    plan={currentPlan}
                  />
                ) : (
                  <Typography className="text-18 font-600 mb-16">
                    {ttt("settings-subscription:no plan subscribed")}
                  </Typography>
                )}
                <Button
                  onClick={(ev) => screenFlow(Acts.GO_CHOOSE)}
                  variant="contained"
                >
                  <AddIcon />
                  <span className="ml-4 sm:flex">
                    {ttt("settings-subscription:change subscription")}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSubscription,
      getSubscription,
      getAsangaPlans,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ company, parami, test }) {
  return {
    company: company.info,
    subscription: company.subscription,
    plans: parami.asangaPlans,
    trigger: isCy ? test.cypress.trigger : undefined,
  };
}

let myComp = connect(mapStateToProps, mapDispatchToProps)(Subscription);
myComp = withStyles(styles, { withTheme: true })(myComp);
export { SubsPagePath, myComp as Subscription, Acts };
