/** @module */
import React from "react";
import { withStyles, Icon, IconButton } from "@material-ui/core";

const styles = {
  fieldHeader: {
    width: "fit-content",
    padding: "2px 4px",
    display: "block",
    fontSize: "1.2rem",
    userSelect: "none",
    color: "rgb(0 0 0 / 0.4)",
  },
};

let FieldTitle = (props) => {
  return <span className={props.classes.fieldHeader}>{props.children}</span>;
};

FieldTitle = withStyles((theme) => styles, { withTheme: true })(FieldTitle);

export default FieldTitle;
