import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import Formsy from "formsy-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Debug from "debug";

import i18n from "i18n.js";
import { i18nNamespaces } from "constants.js";
import AllRegions from "app/data/regions.json";
import { TextFieldPFE, AutoComplete } from "app/parami-layouts";
import { getRegionList } from "app/services/paramiService/data";
import { updateCompany } from "app/store/actions/company/info.actions";
import { showMessage } from "app/store/actions";

const debug = Debug("pfe:settings:ui:PAInfoDialog");

const styles = (theme) => ({});

const AddressTab = (props) => {
  const { formEl, company: pCompany, language } = props,
    { updateCompany, setSubmitButton, showMessage } = props,
    [regions, setRegions] = useState([]),
    // Notes: Use Internal state to allow update to redux only on save
    [company, setInfo] = useState(pCompany),
    { t: ttt } = useTranslation();
  debug("AddressTab", company.address);

  let updateRegions = async (lng) => {
    const result = await getRegionList(lng);
    setRegions(
      AllRegions.map((ccode) => ({
        value: ccode,
        label: result[ccode],
      }))
    );
  };

  const onValidSubmit = async () => {
    const to_update = formEl.current.getModel();
    to_update.company_id = company.company_id;
    debug("onValidSubmit", to_update);
    try {
      await updateCompany(to_update, true);
      showMessage({ message: ttt("settings-company:save address success") });
    } catch (err) {
      debug("err", err.stack);
      showMessage({ message: ttt("settings-company:save address failed") });
    }
  };

  const disableButton = () => {
    setSubmitButton(false);
  };

  const enableButton = () => {
    setSubmitButton(true);
  };

  useEffect(() => {
    updateRegions(language);
    i18n.on("languageChanged", updateRegions);

    // returned function will be called on component unmount
    return () => {
      i18n.off("languageChanged", updateRegions);
      debug("Unsubscribed languageChanged");
    };
  }, []);

  // Notes: Wait for loading from PBE. Since during the time component loaded,
  // data from PBE may not be ready.
  useEffect(() => {
    setInfo(pCompany);
  }, [pCompany]);

  // Styles
  const smallForm = "block sm:flex",
    formAddrBlock = classNames("block mt-8 mb-8"),
    formAddrRow = classNames(smallForm, "mb-8");
  return (
    <div className="flex w-full pt-16">
      <Formsy
        id="company-form"
        onValidSubmit={onValidSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formEl}
        className="flex flex-col w-full mx-16"
      >
        <div className={classNames(formAddrBlock)}>
          <div className={classNames(formAddrRow)}>
            <TextFieldPFE
              className="mb-16 sm:mb-0 mr-16"
              autoFocus
              name="address.unit"
              variant="outlined"
              fullWidth
              label={ttt("form-fields:address.unit")}
              value={company.address.unit}
              pfeValidType="general.address_unit"
            />

            <TextFieldPFE
              className="mb-16 sm:mb-0"
              name="address.building"
              variant="outlined"
              fullWidth
              label={ttt("form-fields:address.building")}
              value={company.address.building}
              pfeValidType="general.building"
            />
          </div>

          <TextFieldPFE
            className="mt-8 mb-8 mr-16"
            name="address.address_line_1"
            variant="outlined"
            fullWidth
            label={ttt("form-fields:address.address_line_1")}
            value={company.address.address_line_1}
            pfeValidType="general.address_lines"
          />

          <TextFieldPFE
            className="mt-8 mb-8 mr-16"
            name="address.address_line_2"
            variant="outlined"
            fullWidth
            label={ttt("form-fields:address.address_line_2")}
            value={company.address.address_line_2}
            pfeValidType="general.address_lines"
          />

          <div className={classNames(formAddrRow)}>
            <TextFieldPFE
              className="mt-8 mb-16 sm:mb-0 mr-16 sm:w-1/3"
              name="address.postal_code"
              variant="outlined"
              fullWidth
              label={ttt("form-fields:address.postal_code")}
              value={company.address.postal_code}
              pfeValidType="general.postal_code"
            />

            <AutoComplete
              name="address.region"
              className={classNames("mt-8 mb-16 sm:mb-0 sm:w-2/3")}
              options={regions}
              // options={regions}
              label={ttt("form-fields:address.region")}
              value={company.address.region}
              isPureValue={true}
              variant="outlined"
            />
          </div>
        </div>

        {/* Placeholder */}
        <div className="mb-32"></div>
      </Formsy>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCompany,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ company, auth }) {
  return {
    language: auth.user.preferences.ui_language,
    company: company.info,
  };
}

let myComp = connect(mapStateToProps, mapDispatchToProps)(AddressTab);
myComp = withStyles(styles, { withTheme: true })(myComp);
export default myComp;
