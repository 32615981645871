export default {
  access_token: {},
  identifier: {},
  fully_scanned: {},
  name: {
    minLength: [1, "minLength character"],
    maxLength: [256, "maxLength character"],
  },
  platform: {
    matchRegexp: [
      /^(facebook|ebay|whatsapp|parami|amazon)$/,
      "Only one of the following values: facebook, ebay.",
    ],
  },
  signature: {
    minLength: [1, "minLength character"],
    maxLength: [512, "maxLength character"],
  },
};
