import React from "react";
import {
  withStyles,
  Table,
  TableBody,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import i18n from "i18n.js";
import { getIcon } from "app/utils/entities/plfacc";
import fnKeeper from "app/utils/fnKeeper";
import Platforms from "app/data/platforms";
import Pavatar from "app/parami-layouts/Pavatar";
import { copyTextToClipboard } from "app/utils/common";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from "react-i18next";

import IconX from "app/parami-layouts/IconX";

import { AgGrid } from "app/parami-layouts";

const ttt = i18n.getFixedT(null, "general"),
  ts2String = fnKeeper.formatter.timestamp;

const styles = (theme) => {};

let AccountNameRenderer = (props) => {
  const { company, t: ttt } = props;
  const { showMessage } = props.colDef;
  const is_asangaweb = props.data.platform == "asangaweb";
  const url_title = `${(company &&
    (company.alias_code || company.company_code)) ||
    "unknown"}/${props.data.plfacc_code}`;
  const url_for_asangaweb =
    is_asangaweb &&
    (process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_PBE_PROTOCOL
      ? `${window.location.protocol}//${window.location.host}/chatbot/${url_title}`
      : "http://self.parami.ai:7000/api/v1/chatbot/" + url_title);

  return (
    <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Pavatar className="" src={getIcon(props.data)} alt={props.data.name} />
      <div className="pl-8 w-full flex justify-center items-center flex-row">
        <span className="w-full">{props.data.name}</span>
        {is_asangaweb && (
          <Tooltip
            title={
              <span className="flex items-center">
                {url_title}
                <Icon style={{ marginLeft: 2, fontSize: 12 }}>launch</Icon>
              </span>
            }
            placement="top"
          >
            <div className="flex justify-end">
              <a
                onClick={() => {
                  window.open(url_for_asangaweb, "", "width=420,height=600");
                }}
                className="text-10 cursor-pointer flex ml-8 w-32 h-32"
              >
                <IconX svgOnly className="mr-12" name="link"></IconX>
              </a>
              <a
                className="text-10 cursor-pointer flex ml-8 w-32 h-32"
                onClick={() => {
                  copyTextToClipboard(url_for_asangaweb);
                  showMessage({
                    message: ttt("general:Copied to clipboard"),
                  });
                }}
              >
                <IconX svgOnly className="mr-12" name="copy"></IconX>
              </a>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

AccountNameRenderer = connect(({ company }) => {
  return {
    company: company.info,
  };
})(AccountNameRenderer);
AccountNameRenderer = withTranslation()(AccountNameRenderer);

const PlatformRenderer = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Pavatar
        className=""
        // src={plfacc.icon}
        src={Platforms[props.data.platform].icon}
        fallback={props.data.platform}
      />
      <div className="pl-8 flex items-center">
        {ttt(`platform.${props.data.platform}`)}
      </div>
    </div>
  );
};

const CreatedAtRenderer = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {ts2String(props.data.created_at)}
    </div>
  );
};

const PaActionRenderer = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        className="text-20"
        onClick={(event) => props.colDef.onEditInfo(event, props.data)}
      >
        <Icon>edit</Icon>
      </IconButton>
    </div>
  );
};

const frameworkComponents = {
  accountNameRenderer: AccountNameRenderer,
  platformRenderer: PlatformRenderer,
  createdAtRenderer: CreatedAtRenderer,
  paActionRenderer: PaActionRenderer,
};

const PATable = (props) => {
  const { order, selections, plfacc_list, showMessage, ttt } = props;
  const {
    onEditInfo,
    onSetPassword,
    handleSelectAllClick,
    handleRequestSort,
    whenSelect,
  } = props;

  const columnDefs = [
    {
      headerName: ttt("settings-plfaccs:table.header.name"),
      field: "name",
      sortable: true,
      flex: 2,
      minWidth: 220,
      cellRenderer: "accountNameRenderer",
      showMessage,
    },
    {
      headerName: ttt("settings-plfaccs:table.header.platform"),
      field: "platform",
      sortable: true,
      flex: 2,
      minWidth: 200,
      cellRenderer: "platformRenderer",
    },
    {
      headerName: ttt("settings-plfaccs:table.header.created_at"),
      field: "created_at",
      sortable: true,
      flex: 1,
      minWidth: 120,
      cellRenderer: "createdAtRenderer",
    },
    {
      headerName: "",
      field: "id",
      sortable: false,
      width: 100,
      cellRenderer: "paActionRenderer",
      onEditInfo,
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AgGrid
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        data={plfacc_list}
      />
    </div>
    // <FuseScrollbars className="flex-grow overflow-x-auto">
    //   <Table aria-labelledby="tableTitle">
    //     <PATableHeader
    //       sortDir={order.dir}
    //       sortCol={order.byCol}
    //       selections={selections}
    //       rowCount={ld.size(plfacc_list)}
    //       numSelected={ld.size(selections)}
    //       onSelectAllClick={handleSelectAllClick}
    //       onRequestSort={handleRequestSort}
    //     />

    //     <TableBody>
    //       {plfacc_list.map((plfacc, ind) => {
    //         return (
    //           <PATableRow
    //             key={`plfacc-row-${ind}`}
    //             company={company}
    //             plfacc={plfacc}
    //             selected={selections[plfacc.plfacc_id] || false}
    //             whenSelect={whenSelect}
    //             onEditInfo={onEditInfo}
    //             onSetPassword={onSetPassword}
    //             showMessage={showMessage}
    //             ttt={ttt}
    //           />
    //         );
    //       })}
    //     </TableBody>
    //   </Table>
    // </FuseScrollbars>
  );
};

export default withStyles(styles, { withTheme: true })(PATable);
