var _isExisty = function(value) {
  return value !== null && value !== undefined;
};

var isEmpty = function isEmpty(value) {
  return value === "";
};

export const validations = {
  isDefaultRequiredValue: function(values, value) {
    return value === undefined || value === null || value === "";
  },
  isExisty: function(values, value) {
    return _isExisty(value);
  },
  matchRegexp: function(values, value, regexp) {
    return !_isExisty(value) || isEmpty(value) || regexp.test(value);
  },
  isUndefined: function(values, value) {
    return value === undefined;
  },
  isEmptyString: function(values, value) {
    return isEmpty(value);
  },
  isEmail: function(values, value) {
    // Regex from http://emailregex.com/
    return validations.matchRegexp(
      values,
      value,
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    );
  },
  isUrl: function isUrl(values, value) {
    return validations.matchRegexp(
      values,
      value,
      /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/i
    );
  },
  isTrue: function(values, value) {
    return value === true;
  },
  isFalse: function(values, value) {
    return value === false;
  },
  isNumeric: function(values, value) {
    if (typeof value === "number") {
      return true;
    }

    return validations.matchRegexp(values, value, /^[-+]?(?:\d*[.])?\d+$/);
  },
  isAlpha: function(values, value) {
    return validations.matchRegexp(values, value, /^[A-Z]+$/i);
  },
  isAlphanumeric: function(values, value) {
    return validations.matchRegexp(values, value, /^[0-9A-Z]+$/i);
  },
  isInt: function(values, value) {
    return validations.matchRegexp(values, value, /^(?:[-+]?(?:0|[1-9]\d*))$/);
  },
  isFloat: function(values, value) {
    return validations.matchRegexp(
      values,
      value,
      /^(?:[-+]?(?:\d+))?(?:\.\d*)?(?:[eE][+-]?(?:\d+))?$/
    );
  },
  isWords: function(values, value) {
    return validations.matchRegexp(values, value, /^[A-Z\s]+$/i);
  },
  isSpecialWords: function(values, value) {
    return validations.matchRegexp(values, value, /^[A-Z\s\u00C0-\u017F]+$/i);
  },
  isLength: function(values, value, length) {
    return !_isExisty(value) || isEmpty(value) || value.length === length;
  },
  equals: function(values, value, eql) {
    return !_isExisty(value) || isEmpty(value) || value === eql;
  },
  equalsField: function(values, value, field) {
    return value === values[field];
  },
  maxLength: function(values, value, length) {
    return !_isExisty(value) || value.length <= length;
  },
  minLength: function(values, value, length) {
    return !_isExisty(value) || value.length >= length;
  },
};

/**
 *
 *  All custom validations
 *
 */

validations.isNotBlankString = (_values, value) =>
  value && ("" + value).trim().length > 0;

/**
 *
 *  All prefixers
 *
 *  1. every-     (array, all satisfy)
 *  2. any-       (array, any satisfies)
 *  3. every-key  (object, all keys satisfy)
 *  4. any-key    (object, any key satisfies)
 *  5. every-key  (object, all values satisfy)
 *  6. any-key    (object, any value satisfies)
 *
 */

const origRules = Object.keys(validations);

function isPlainObject(input) {
  return input && !Array.isArray(input) && typeof input === "object";
}

origRules.forEach((rule_name) => {
  const rule = validations[rule_name];
  validations["every-" + rule_name] = (_values, value, args) => {
    if (!Array.isArray(value)) return false;
    return value.every((e) => rule(_values, e, args));
  };
});

origRules.forEach((rule_name) => {
  const rule = validations[rule_name];
  validations["any-" + rule_name] = (_values, value, args) => {
    if (!Array.isArray(value)) return false;
    return value.some((e) => rule(_values, e, args));
  };
});

origRules.forEach((rule_name) => {
  const rule = validations[rule_name];
  validations["every-key-" + rule_name] = (_values, value, args) => {
    if (!isPlainObject(value)) return false;
    return Object.keys(value).every((e) => rule(_values, e, args));
  };
});

origRules.forEach((rule_name) => {
  const rule = validations[rule_name];
  validations["any-key-" + rule_name] = (_values, value, args) => {
    if (!isPlainObject(value)) return false;
    return Object.keys(value).some((e) => rule(_values, e, args));
  };
});

origRules.forEach((rule_name) => {
  const rule = validations[rule_name];
  validations["every-value-" + rule_name] = (_values, value, args) => {
    if (!isPlainObject(value)) return false;
    return Object.values(value).every((e) => rule(_values, e, args));
  };
});

origRules.forEach((rule_name) => {
  const rule = validations[rule_name];
  validations["any-value-" + rule_name] = (_values, value, args) => {
    if (!isPlainObject(value)) return false;
    return Object.values(value).some((e) => rule(_values, e, args));
  };
});
