import React, { Component } from "react";
import {
  FormControl,
  FormHelperText,
  FilledInput,
  OutlinedInput,
  InputLabel,
  Input,
  Select,
} from "@material-ui/core";
import { withFormsy } from "formsy-react";
import _ from "@lodash";

import i18n from "i18n.js";
const ttt = i18n.t.bind(i18n);

class _SelectFormsy extends Component {
  changeValue = (event) => {
    this.props.setValue(event.target.value);

    // TS: Target name is missing after selecting item. which makes
    // event handling failed. So add it back.
    if (!event.target.name) event.target.name = this.props.name;

    if (this.props.onChange) {
      this.props.onChange(
        event,
        this.props.name,
        this.props.required ? this.props.isRequired() : undefined
      );
    }
  };

  render() {
    const importedProps = _.pick(this.props, [
      "autoWidth",
      "children",
      "classes",
      "disabled",
      "displayEmpty",
      "input",
      "inputProps",
      "MenuProps",
      "multiple",
      "native",
      "onChange",
      "onClose",
      "onOpen",
      "open",
      "renderValue",
      "SelectDisplayProps",
      "value",
      "variant",
    ]);

    // NOTE: Missing required field is shown as error too
    const errorMessage = this.props.showRequired()
      ? ttt("form-fields:validations.required")
      : this.props.getErrorMessage();
    const value = this.props.getValue();

    this.input = () => {
      switch (importedProps.variant) {
        case "outlined":
          return (
            <OutlinedInput
              labelWidth={this.props.label.length * 8}
              id={this.props.name}
            />
          );
        case "filled":
          return <FilledInput id={this.props.name} />;
        default:
          return <Input id={this.props.name} />;
      }
    };

    return (
      <FormControl
        required={this.props.required}
        error={Boolean(errorMessage)}
        className={this.props.className}
        variant={importedProps.variant}
      >
        {this.props.label && (
          <InputLabel
            htmlFor={this.props.name}
            shrink={true}
            FormLabelClasses={{ root: "" }}
          >
            {this.props.label}
          </InputLabel>
        )}
        <Select
          {...importedProps}
          value={value}
          onChange={this.changeValue}
          input={this.input()}
        />
        {Boolean(errorMessage) && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

let SelectFormsy = _SelectFormsy;
export default withFormsy(SelectFormsy);
