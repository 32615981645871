import * as Actions from "app/store/actions/parami";

const initialState = {
  pfe_instance_id: null,
};

const user_pref = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_ALL_PREF: {
      return action.user_pref;
    }

    default: {
      return state;
    }
  }
};

export default user_pref;
