const navigationConfig = [
  {
    id: "applications",
    title: "Applications",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: "/dashboard",
      },
      {
        id: "settings-plfaccs",
        title: "Channels",
        icon: "settings_input_component",
        type: "item",
        url: "/channels",
        // help: 3,
      },
      {
        id: "settings-flow-template",
        title: "Projects",
        icon: "waves",
        type: "item",
        url: "/projects",
        help: 1,
      },
      {
        id: "settings-intention",
        title: "Intentions",
        icon: "flash_on",
        type: "item",
        url: "/intentions",
        help: 2,
      },
      {
        id: "pfeChat",
        title: "Chats",
        type: "item",
        icon: "chat",
        url: "/chats",
      },
      {
        id: "settings-staff",
        title: "Users",
        type: "item",
        icon: "people",
        url: "/users",
      },
      {
        id: "settings-company",
        title: "Account",
        type: "item",
        icon: "settings",
        url: "/account",
      },
      {
        id: "settings-subscription",
        title: "Subscription",
        type: "item",
        icon: "credit_card",
        url: "/subscription",
      },
      {
        id: "logout",
        title: "Logout",
        type: "item",
        icon: "meeting_room",
        url: "/logout",
      },
    ],
  },
];

export default navigationConfig;
