export default {
  email_address: {
    maxLength: [256, "maxLength character"],
    matchRegexp: [
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/imu,
      "only email format",
    ],
  },
  phone_number: {
    minLength: [4, "minLength phone character"],
    maxLength: [20, "maxLength phone character"],
    matchRegexp: [/^(\(?\+?[0-9]*\)?)?[0-9\-\(\)]*$/, "only phone character"],
  },
  address_lines: {
    minLength: [1, "minLength character"],
    maxLength: [256, "maxLength character"],
  },
  building: {
    minLength: [1, "minLength character"],
    maxLength: [100, "maxLength character"],
  },
  address_unit: {
    minLength: [1, "minLength character"],
    maxLength: [100, "maxLength character"],
  },
  region: {},
  postal_code: {
    minLength: [1, "minLength alphanumeric"],
    maxLength: [10, "maxLength alphanumeric"],
    isAlphanumeric: [null, "only alphanumeric"],
  },
  password: {
    minLength: [4, "minLength character"],
    maxLength: [16, "maxLength character"],
  },
};
