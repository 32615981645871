import { auth0Service } from "app/services/auth0Service";
import { CompanyApi } from "app/client";
import Debug from "debug";

import { REST } from "app/client_proxied";

const debug = Debug("pfe:services:parami:company");

export async function is_code_available(company_code) {
  // TODO: Testing/SIT
  /*
  const access_token = auth0Service.getAccessToken();

  const api = new CompanyApi({
    accessToken: access_token,
  });
  */

  try {
    await REST.CompanyApi.companiesAvailableCompanyCodeCompanyCodeGet(
      company_code
    );
    return true;
  } catch (err) {
    if (err.response.status === 409) return false;
    else throw err;
  }
}

/**
 *
 * Ask PBE to suggest company code with the given hints.
 *
 * @param {string} hints Hints for company code suggestion. Company name will
 * be used.
 */
export async function suggest_company_code(hints) {
  /*
  const access_token = auth0Service.getAccessToken();

  const api = new CompanyApi({
    accessToken: access_token,
  });
  */

  const ret = await REST.CompanyApi.companiesAvailableCompanyCodeGet(hints);
  debug("ret", ret);
  return ret.data;
}
