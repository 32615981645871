import React from "react";
import {
  Checkbox,
  TableHead,
  TableSortLabel,
  TableCell,
  TableRow,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import {
  genDisplayClasses,
  genExtraStyles,
  ColumnDefinition as cols,
} from "./StaffTableDef";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  stickyHeader: {
    top: 0,
    position: "sticky",
    backgroundColor: "white",
    zIndex: 1,
  },
  actionsButtonWrapper: {
    background: theme.palette.background.paper,
  },
  "hd-wrap-text": {
    // width: '80px',
    maxWidth: "80px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  rowEdge: {
    padding: "0 2em",
  },
});

const StaffTableHeader = (props) => {
  const {
      onRequestSort,
      onSelectAllClick,
      sortDir,
      sortCol,
      numSelected,
      classes,
      rowCount,
    } = props,
    { t: ttt } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classNames("h-48")}>
        {cols.map((row, ind) => {
          const isEdge = ind === 0 || ind === cols.length - 1,
            { class_list, extra_styles } = generateDisplay(
              row,
              classes,
              isEdge
            );
          if (row.fieldType === "checkbox")
            return (
              <TableCell
                align={row.align}
                padding="checkbox"
                className={classNames(...class_list, classes.stickyHeader)}
                style={extra_styles}
              >
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            );
          else
            return (
              <TableCell
                key={`hd-cell-${ind}`}
                align={row.align}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={sortCol === row.id ? sortDir : false}
                className={classNames(...class_list, classes.stickyHeader)}
                style={extra_styles}
              >
                {row.sort && (
                  <Tooltip
                    title="Sort"
                    placement={
                      row.align === "right" ? "bottom-end" : "bottom-start"
                    }
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={sortCol === row.id}
                      direction={sortDir}
                      onClick={createSortHandler(row.id)}
                    >
                      {ttt(`settings-staff:${row.label}`)}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            );
        }, this)}
      </TableRow>
    </TableHead>
  );
};

function generateDisplay(columnDef, classes, isRowEdge) {
  const extra_styles = genExtraStyles(columnDef);
  const class_list = genDisplayClasses(columnDef, classes, "headerClassNames");

  if (isRowEdge) class_list.push(classes.rowEdge);
  return { class_list, extra_styles };
}

export default withStyles(styles, { withTheme: true })(StaffTableHeader);
