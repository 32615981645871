import Debug from "debug";
import ld from "lodash";
import { normalize, schema } from "normalizr";

import { asyncThunk } from "app/utils/common";
import { toPFE } from "app/utils/entities/company";
import { auth0Service } from "app/services/auth0Service";
import { LoginToPBE, setUserDataAuth0 } from "app/auth/store/actions";
import ParamiError, {
  CREATE_COMPANY_CREATE_FAILED,
  CREATE_COMPANY_REFRESH_TOKEN_FAILED,
} from "app/utils/ParamiError";

import { REST } from "app/client_proxied";

export const GET_COMPANY = "[COMPANY] GET";
export const CREATE_COMPANY = "[COMPANY] CREATE";
export const UPDATE_COMPANY = "[COMPANY] UPDATE";
export const GET_FLOW_TEMPS = "[COMPANY] GET_FLOW_TEMPS";

const debug = Debug("pfe:store:company:action:info");

const FTSchema = new schema.Entity(
  "flow_temps",
  {},
  {
    idAttribute: "id",
  }
);

export function getCompany() {
  return asyncThunk(async (dispatch, getState) => {
    const user = getState().auth.user;

    const companyRaw = (await REST.UsersApi.usersUserIdCompanyGet(user.user_id))
        .data,
      company = toPFE(companyRaw);
    dispatch({
      type: GET_COMPANY,
      company,
    });
  });
}

/**
 * @since v1, PRMF-25
 * @param {object} company The Company Info for creation
 * @return {object} The Company created.
 */

export function createCompany(company, user) {
  let tz;
  try {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {}
  const new_company = {
    // address: ,
    company_code: company.company_code,
    conversation_types: [],
    email_address: company.email_address,
    fullname: company.fullname,
    type: company.isPersonal === "true" ? "personal" : "company",
    // phone_number: company.phone_number,
    plan_subscribed: "",
    timezone: tz,
    testing: true,
  };

  return asyncThunk(async (dispatch) => {
    let stage;
    try {
      stage = CREATE_COMPANY_CREATE_FAILED;
      const ret = (await REST.CompanyApi.companiesPost(new_company)).data,
        companyInfo = { ...new_company, ...ret };
      // Update current token if creation is successful. So to get the
      // latest current_company_id from the token.
      stage = CREATE_COMPANY_REFRESH_TOKEN_FAILED;
      await auth0Service.refreshSession();
      const refreshed_token_data = await auth0Service.getUserData();
      dispatch(setUserDataAuth0(refreshed_token_data));

      // Login to PBE again with new token
      await dispatch(LoginToPBE());

      dispatch({
        type: CREATE_COMPANY,
        companyInfo,
      });
    } catch (err) {
      throw new ParamiError(stage, err);
    }
  });
}

export function updateCompany(company, isAddr = false) {
  debug("updateCompany", company);
  return asyncThunk(async (dispatch) => {
    const field_list = isAddr
        ? ["address"]
        : [
            "company_id",
            "alias_code",
            "email_address",
            "fullname",
            "phone_number",
            "timezone",
            "preferences",
          ],
      to_update = ld.pick(company, field_list);
    debug("updateCompany - to_update", to_update);
    await REST.CompanyApi.companiesCompanyIdPatch(
      company.company_id,
      to_update
    );

    return dispatch({
      type: UPDATE_COMPANY,
      company,
    });
  });
}

export function getFlowTemplates() {
  return asyncThunk(async (dispatch) => {
    debug("getFlowTemplates");

    const ft_raw = (await REST.FlowTemplatesApi.flowTemplatesGet()).data.Result,
      ft_norm = normalize(ft_raw, new schema.Array(FTSchema)),
      flow_templates = ft_norm.entities.flow_temps;
    // debug('ft_norm', ft_norm);

    dispatch({
      type: GET_FLOW_TEMPS,
      flow_templates,
    });
  });
}
