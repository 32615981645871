import { get as ldget } from "lodash";

import store from "app/store";
import { currLanguage } from "app/utils/rState";
import * as locales from "app/data/locales";

export function isLogin() {
  const state = store.getState(),
    auth_user = ldget(state, "auth.user"),
    has_user = auth_user && auth_user.role !== "guest";
  return has_user;
}

export function currAuth0Lang() {
  const currLang = currLanguage(),
    auth0Locale = ldget(locales, `${currLang}.auth0`);
  return auth0Locale || currLang;
}
