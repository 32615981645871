// import i18n from 'i18next';
import { normalize } from "normalizr";
import Debug from "debug";

import { asyncThunk } from "app/utils/common";
import { PlansResultSchema } from "app/utils/entities/plan";
import { REST } from "app/client_proxied";

export const GET_ASANGA_PLANS = "[Asanga Plans] GET";

const debug = Debug("pfe:store:asangaPlans:actions");

// Actions
export function getAsangaPlans() {
  debug("getAsangaPlans");
  return asyncThunk(async (dispatch) => {
    const ret_data = (await REST.SubscriptionPlansApi.plansGet()).data,
      data_norm = normalize(ret_data, PlansResultSchema),
      plans = data_norm.entities.plans;
    debug("getPlans", plans, data_norm);

    return dispatch({
      type: GET_ASANGA_PLANS,
      plans,
    });
  });
}
