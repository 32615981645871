import React from "react";
import { Card, withStyles } from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ld from "lodash";

import { i18nNamespaces } from "constants.js";
import { displayPrice } from "app/utils/entities/plan";

// const debug = Debug('pfe:settings:subscription:planCard');

const styles = (theme) => ({
  glowing: {
    outline: "none",
    borderColor: "#9ecaed",
    boxShadow: "0 0 10px 3px #9ecaed",
  },
});

const PlanCard = (props) => {
  const { classes, className, plan, chosen, showDescription = true } = props,
    { onClick } = props,
    { t: ttt } = useTranslation();
  let plan_contents = ttt(`plans:plan-${plan.id}.contents`, {
    returnObjects: true,
  });
  if (!ld.isArray(plan_contents)) plan_contents = [];

  const card_classes = ["px-12 py-12 cursor-pointer", className];
  let card_inline_style = {};
  if (chosen) {
    card_classes.push(classes.glowing, "border-1");
    card_inline_style = {
      outline: "none",
      borderColor: plan.color || "pink",
      boxShadow: `0 0 10px 3px ${plan.color}`,
    };
  }

  const cardColor = { color: plan.color || "pink" },
    bigCaptions = classNames(classes.cardColor, "text-center text-18 my-6"),
    priceClasses = classNames(bigCaptions, showDescription ? "h-60" : null),
    price_info = displayPrice(plan);
  return (
    <Card
      name={`plan-card-${plan.id}`}
      style={card_inline_style}
      className={classNames(...card_classes, "py-24")}
      // onClick={onClick(plan.code)}
      onClick={onClick && onClick.bind(null, plan.id)}
    >
      {/* <img src={plan.icon} className="h-60 w-60 mx-0 my-auto"/> */}
      <div style={cardColor} className={classNames(bigCaptions, "font-bold")}>
        {ttt(`plans:plan-${plan.id}.name`)}
      </div>
      <div style={cardColor} className={priceClasses}>
        {price_info}
      </div>
      {showDescription && (
        <div className="text-16 text-bold">
          <ul className="pl-20">
            {plan_contents.map((str, ind) => (
              <li key={ind} className="my-4">
                {str}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Card>
  );
};

let myComp = withStyles(styles, { withTheme: true })(PlanCard);
export default myComp;
