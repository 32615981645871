// import i18n from 'i18next';
import Debug from "debug";

export const GET_VALIDATIONS = "[validations] GET";

const debug = Debug("pfe:store:validations:actions");

// Actions
export function getValidations(validations) {
  debug("getValidations");

  return {
    type: GET_VALIDATIONS,
    validations,
  };
}
