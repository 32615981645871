import React from "react";
import { FuseLoadable } from "@fuse";
import { Redirect } from "react-router-dom";

import { authRoles } from "app/auth";
// NOTE: see parami-flow-editor/index.js for routes

export const FlowEditorAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acc_usage,
  routes: [
    {
      path: [`/projects/:ft_id/flows/:f_id/edit`],
      component: FuseLoadable({
        loader: () => import("./FlowEditorApp"),
      }),
    },
    {
      path: [`/projects/:ft_id/flows/:f_id`],
      component: FuseLoadable({
        loader: () => import("./FlowDetailApp"),
      }),
    },
    {
      path: [`/projects/:ft_id/flows`],
      component: FuseLoadable({
        loader: () => import("./FlowsApp"),
      }),
    },
    {
      path: [`/projects/:ft_id`],
      component: FuseLoadable({
        loader: () => import("./FlowTemplateDetailApp"),
      }),
    },
    {
      path: [`/projects`],
      component: FuseLoadable({
        loader: () => import("./FlowTemplatesApp"),
      }),
    },
    {
      path: [`/intentions/:i_id`],
      component: FuseLoadable({
        loader: () => import("./IntentionDetailApp"),
      }),
    },
    {
      path: [`/intentions`],
      component: FuseLoadable({
        loader: () => import("./IntentionsApp"),
      }),
    },
  ],
};
