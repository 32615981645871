import { normalize, schema } from "normalizr";
import Debug from "debug";

import { isEmpty, asyncThunk } from "app/utils/common";

import { REST } from "app/client_proxied";

// const ttt = i18n.t.bind(i18n);

export const GET_CONV_TYPES = "[COMPANY][CONV TYPES] GET";

const debug = Debug("pfe:store:convTypes.actions");

const CTSchema = new schema.Entity(
  "conv_types",
  {},
  {
    idAttribute: "conv_type_id",
    processStrategy: (value, parent, key) => ({
      ...value,
      id: value.conv_type_id,
      style: `bg-${value.color}`,

      // NOTES: Removed unused fields
      conv_type_id: undefined,
      color: undefined,
    }),
  }
);

export function getConvTypes() {
  return asyncThunk(async (dispatch, getState) => {
    const cts_raw = (await REST.ConversationTypesApi.convTypesGet()).data,
      cts_norm = normalize(cts_raw, new schema.Array(CTSchema)),
      convTypes = cts_norm.entities.conv_types;
    debug("cts_norm", cts_norm, convTypes);

    if (!isEmpty(convTypes))
      dispatch({
        type: GET_CONV_TYPES,
        convTypes,
      });
  });
}
