import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse/index";
import Debug from "debug";

import { PFEDefault } from "constants.js";
import { appsConfigs } from "app/main/apps/appsConfigs";
import {
  LoginConfig,
  EmailNotVerifiedConfig,
} from "app/main/login/LoginConfig";
import { LogoutConfig } from "app/main/logout/LogoutConfig";
import { CallbackConfig } from "app/main/callback/CallbackConfig";
import { CreateCompanyConfig } from "app/main/create-company/CreateCompanyConfig";
import { SettingPagesConfig } from "app/main/settings/SettingPagesConfig";
import { SubsPageConfig } from "app/main/settings/subscription/SubsPageConfig";
import { ErrorPagesConfig } from "app/main/errors/ErrorPagesConfig";
import { DashboardConfig } from "app/main/dashboard/DashboardConfig";
import { PlaygroundConfig } from "app/main/playground/PlaygroundConfig";

import { isLogin } from "app/auth/utils";

const routeConfigs = [
  ...appsConfigs,
  LoginConfig,
  EmailNotVerifiedConfig,
  LogoutConfig,
  CallbackConfig,
  CreateCompanyConfig,
  SettingPagesConfig,
  SubsPageConfig,
  ErrorPagesConfig,
  DashboardConfig,
];

if (process.env.REACT_APP_ENV !== "production")
  routeConfigs.push(PlaygroundConfig);

const debug = Debug("pfe:routesConfig");

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    // Route for root
    path: "/",
    exact: true,
    component: () => {
      if (!isLogin()) {
        debug("not logged in");
        return <Redirect to="/login" />;
      }
      debug("logged in");
      return <Redirect to={PFEDefault.first_screen} />;
    },
  },
  {
    // Default Route
    component: () => {
      // if (!isLogin()) {
      //   return <Redirect to="/login"/>;
      // }
      return <Redirect to="/error/404" />;
    },
  },
];

export default routes;
