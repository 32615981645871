const settingsConfig = {
  layout: {
    style: "layout4", // layout-1 layout-2 layout-3
    config: {
      // checkout layout configs at app/fuse-configs/layout-1/Layout1Config.js
      // navbar: {
      //     folded: true
      // }
      footer: {
        display: false,
      },
    },
  },
  customScrollbars: true,
  theme: {
    main: "parami",
    navbar: "parami",
    toolbar: "mainThemeLight",
    footer: "parami",
  },
};

export default settingsConfig;
