import { InputAdornment, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import ld from "lodash";

import { TextFieldPFE } from "./";
import { withPFEValidators } from "app/services/paramiService";

const omit_props_pw = ["InputProps", "type"];

const PasswordField = (props) => {
  const importedProps = ld.omit(props, omit_props_pw),
    [showPW, setShowPW] = useState(false);
  const setShowing = () => {
    setShowPW(!showPW);
  };

  return (
    <TextFieldPFE
      type={showPW ? "text" : "password"}
      pfeValidType="general.password"
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment onClick={setShowing} position="end">
            <IconButton id="visible-trigger">
              {showPW ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      {...importedProps}
      required
    />
  );
};

let comp = withPFEValidators(PasswordField, ["general"]);
export default comp;
