import moment from "moment-timezone";
import ld from "lodash";
import Debug from "debug";

import { createServerSocket } from "./fake_io";
import { last, split_by, hashFnv32a } from "app/utils/common";

import conversations from "./dialogs";

// debug('prep_messages', prep_time, prep_messages);
// debug('conversations', conversations);

const debug = Debug("pfe:mock:server");

let serverSocket = createServerSocket("msg_broker_1");

async function taking_over(pSessionID, args, cb) {
  if (!cb) return;

  const { conv_id } = args,
    conv = conversations[conv_id];
  conv.session_id = pSessionID;
  debug("[server] take_over_conv:", conv_id, conv.session_id);

  setTimeout(() => {
    serverSocket.emit("conv_update", {
      changes: [
        {
          _id: conv_id,
          session_id: conv.session_id,
        },
      ],
    });
  }, ld.random(1000, 1200));

  // await delay(15000);
  cb("OK");
}

// Test Fake Server
serverSocket.on("connect", (...params) => {
  debug("[Msg Broker] connected", params);
  serverSocket.emit("connect");
});

serverSocket.on("conversations", (args, cb) => {
  debug("[Msg Broker] conversation request", args);
  if (cb) {
    const my_convs = ld.map(conversations, (conv) =>
      ld.pick(conv, [
        "_id",
        "me",
        "session_id",
        "staff_in_charge",
        "conv_type",
        "folder",
        "active_session",
        "conv.created_at",
        "last_msg_at",
        "last_message",
        "last_modified",
      ])
    );
    cb(my_convs);
  }
});

// let messages_req_cnt = 0;
serverSocket.on("messages", (args, cb) => {
  if (cb) {
    // if (messages_req_cnt > 4) {
    //   debug('messages_req_cnt over limit');
    //   return;
    // } else
    //   debug('messages_req_cnt', messages_req_cnt);

    // params: before_ts, limit, after_ts, conv_id,
    const {
        conv_id,
        before_ts,
        after_ts,
        load_dir = "FORWARD",
        limit = 5,
      } = args,
      dialogs = conversations[conv_id],
      load_as_forward =
        !before_ts || (before_ts && after_ts && load_dir === "FORWARD");
    debug("[server] messages:", before_ts, after_ts, dialogs.messages);

    // cb(dialogs.messages.map(msg => {
    //   msg.conv_id = dialogs.conv_id;
    //   msg.conv_code = dialogs.conv_code;
    //   if (after_ts) {
    //     msg.datetime = after_ts + 600;
    //   } else if (before_ts) {
    //     msg.datetime = before_ts - 600;
    //   }
    //   msg.body = messages_req_cnt + ', ' + msg.body;
    //   return msg;
    // }).slice(0, 8));

    let msgs_filter = dialogs.messages;
    if (before_ts)
      msgs_filter = msgs_filter.filter((msg) => {
        return msg.datetime <= before_ts;
      });

    if (after_ts)
      msgs_filter = msgs_filter.filter((msg) => {
        return msg.datetime >= after_ts;
      });

    if (limit) {
      if (load_as_forward) msgs_filter = msgs_filter.slice(0, limit);
      else msgs_filter = msgs_filter.slice(-1 * limit);
    }

    msgs_filter = msgs_filter.map((msg) => {
      const new_msg = {
        ...msg,
        _id: msg.id,
        conv_id: dialogs.conv_id,
        conv_code: dialogs.conv_code,
      };
      return new_msg;
    });

    debug("WWWW", msgs_filter);

    cb(msgs_filter);

    // messages_req_cnt += 1;
  }
});

serverSocket.on("translate", (args, cb) => {
  if (!cb) return;

  const msg_tuples = args;
  const result = msg_tuples.map((tuple) => {
    const { conv_id, msg_id, to_lang: language } = tuple,
      dialogs = conversations[conv_id].messages || [],
      curr_msg = dialogs.find((msg) => msg.id === msg_id);
    debug("RRRR 2", dialogs, curr_msg, msg_id);
    if (!curr_msg) {
      debug("translate: Msg not found:", msg_id);
      return cb();
    }

    if (!curr_msg.translation) curr_msg.translation = {};

    if (!curr_msg.translation[language])
      curr_msg.translation[language] = {
        title: "テーマは：" + curr_msg.title,
        body: "日本語で：" + curr_msg.body,
      };

    return {
      conv_id,
      msg_id,
      datetime: curr_msg.datetime,
      translation: curr_msg.translation,
    };
  });

  // Test for insert and update together
  // const xts = result[0].datetime + 2;
  // result.push({
  //   _id:  'xxxxyyyz123',
  //   sender: '5725a6802d10e277a0f35724',
  //   datetime:  xts,
  //   body: `Extra one for you`,
  //   read: 0,
  // });

  debug("RRRR", result, msg_tuples);
  cb(result);
});

serverSocket.on("send_msg", (args, cb) => {
  if (cb) {
    const { conv_id, message: msg_text } = args,
      dialogs = conversations[conv_id],
      datetime = moment().valueOf(),
      message = {
        id: hashFnv32a(msg_text + " " + datetime),
        sender: "5725a6802d10e277a0f35724",
        parami_sender: "5725a6802d10e277a0f35724",
        datetime,
        body: msg_text,
        read: 0,
      };
    dialogs.messages.push(message);

    debug("[server] send_msg:", conv_id, message);
    cb({ _id: message._id, datetime: message.datetime });
  }
});

// Take Over
serverSocket.on(
  "take_over_conv",
  taking_over.bind(null, serverSocket.getClientSessionID())
);

serverSocket.take_over_test = (conv_id) => {
  taking_over(null, { conv_id }, () => {
    debug("take_over_test: Done");
  });
};

let timer_for_emitter = {};
serverSocket.on("listen_conv", (args, cb) => {
  if (cb) {
    const { conv_id } = args;
    // if (conv_id === 'parami chatroom default') {
    timer_for_emitter[conv_id] = setInterval(() => {
      // const msg_update = genTestMsg(conv_id);
      const ts_now = moment().valueOf(),
        msg_prepared = conversations[conv_id].prepared_messages;
      if (!msg_prepared || msg_prepared.length === 0) {
        debug("all msg_prepaed consumed", conv_id);
        clearInterval(timer_for_emitter[conv_id]);
        return;
      }

      // Withdraw message from prepared_messages of conv.
      const spt = split_by(msg_prepared, (msg) => msg.datetime <= ts_now),
        new_msgs = spt[0],
        first_msg = msg_prepared[0] || {};
      debug(
        "yyy",
        ts_now,
        first_msg.datetime,
        ts_now - first_msg.datetime,
        msg_prepared.length
      );
      conversations[conv_id].prepared_messages = spt[1];

      // Only do update and notifying if there is new messages.
      if (!new_msgs || new_msgs.length === 0) {
        // debug(`listen_conv: No messages for ${conv_id}.`);
        return;
      } else {
        debug(`listen_conv: ${new_msgs.length} new messages for ${conv_id}.`);
        debug("listen_conv", conv_id, conversations[conv_id], spt);
      }

      // Notify PFE
      debug("new_msgs", new_msgs);
      serverSocket.emit("message_update", {
        data: new_msgs.map((msg) => ({
          event: "update",
          msg_id: msg.id,
          conv_id,
          changes: msg,
        })),
        timestamp: ts_now,
      });

      serverSocket.emit("conv_update", {
        data: [
          {
            event: "update",
            conv_id,
            changes: {
              folder: "New",
              last_message: last(new_msgs),
            },
            timestamp: ts_now,
          },
        ],
      });

      // Insert to message list so PFE can refer later on.
      let prev_cnt = conversations[conv_id].messages.length;
      conversations[conv_id].messages = [
        ...conversations[conv_id].messages,
        ...new_msgs,
      ];
      let new_cnt = conversations[conv_id].messages.length;
      debug(`listen_conv: msg count for ${conv_id}: ${prev_cnt} -> ${new_cnt}`);
    }, 3000);
    // }
    cb("OK");
  }
});

serverSocket.on("stop_listen_conv", (args, cb) => {
  if (cb) {
    const { conv_id } = args;
    clearInterval(timer_for_emitter[conv_id]);
    debug("stop_listen_conv", conv_id);
    cb("OK");
  }
});

// const genTestMsg = (conv_id, ts) => {
//   const mts = ts ? ts : genTS();
//   return {
//     event: "update",
//     msg_id: "parami chatroom ",
//     conv_id,
//     changes: {
//       title: `At ${moment().format('MMDD.HHmm.ss')}, msg...`,
//       body: `The time now is ${shortTimestamp(mts)}`,
//       datetime: mts,
//       conv_code: "default",
//       conv_id,
//       sender: "system",
//       read: 0,
//       attachments: null,
//     },
//     timestamp: mts,
//   };
// }
