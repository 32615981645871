/**
 * @module
 * @typedef {object} Plan
 */

import { schema } from "normalizr";
import ld from "lodash";
import Debug from "debug";

import {
  i18nNamespaces,
  SubsAndPlans,
  PaymentMethod,
  PriceModel,
} from "constants.js";
import i18n from "i18n.js";
import store from "app/store";

const debug = Debug("pfe:entities:plan");

const ttt = i18n.getFixedT(null, "plans");

const // PaymentMethod = ld.get(SubsAndPlans, 'payment.method'),
  IntervalUnit = ld.get(SubsAndPlans, "interval.unit"),
  PlanStatus = ld.get(SubsAndPlans, "status"),
  recurrent_list = ld.get(SubsAndPlans, "interval.recurrents");
// Schema
const PlanSchema = new schema.Entity(
  "plans",
  {},
  {
    idAttribute: "id",
    processStrategy: (value, parent, key) => ({
      ...value,
      id: "" + value.id,
      // org: setOrg(value),
      color: "#a391b9",
      price_model: getPriceModel(value),
      // NOTES: Removed unused fields
      _id: undefined,
    }),
  }
);

function getPriceModel(plan_raw) {
  if (isRecurrent(plan_raw.interval_unit)) return PriceModel.recurrent;
  else if (plan_raw.price === 0) return PriceModel.free;
  else return PriceModel.one_off;
}

/**
 * Schema of Get Plan from PBE
 */
export const PlansResultSchema = new schema.Entity("PlansResultSchema", {
  Result: [PlanSchema],
});

// Routines
export function isPaidPlan(plan) {
  const ret = [PriceModel.recurrent, PriceModel.one_off].includes(
    plan.price_model
  );
  debug("isPaidPlan", ret);
  return ret;
}

export function isRecurrent(interval) {
  return recurrent_list.includes(interval);
}

export function isActive(plan) {
  return plan.status === PlanStatus.active;
}

export function isDowngrading(current_plan_id, new_plan_id) {
  const state = store.getState(),
    plans = state.parami.asangaPlans,
    curr_plan = plans[current_plan_id],
    new_plan = plans[new_plan_id];
  debug(
    "isDowngrading",
    plans,
    current_plan_id,
    new_plan_id,
    curr_plan,
    new_plan
  );
  if (!curr_plan || !new_plan) return false;
  return isPaidPlan(curr_plan) && !isPaidPlan(new_plan);
}

// Subscription
export function getPaymentMethod(subscription) {
  return subscription.paypal ? PaymentMethod.paypal : PaymentMethod.none;
}

/**
 * @param {*} plan
 */
export function displayPrice(plan) {
  if (plan.interval_unit === IntervalUnit.NA) {
    if (plan.price === 0) return ttt("free");
    else return ttt("one time payment", ld.pick(plan, ["price", "currency"]));
  } else {
    return ttt(
      "price per interval",
      ld.pick(plan, ["price", "currency", "interval_count", "interval_unit"])
    );
  }
}
