import ld from "lodash";

import { CompanyConst } from "constants.js";

const { type: CompType } = CompanyConst;

export function toPFE(companyPBE) {
  const result = ld.pick(companyPBE, [
    "address",
    "company_code",
    "alias_code",
    "company_id",
    "name",
    "fullname",
    "phone_number",
    "email_address",
    "timezone",
    "plan_subscribed",
    "preferences",
    // 'service_disabled',
    "last_modified",
    "created_at",
  ]);
  result.isPersonal = companyPBE.type === CompType.personal;
  return result;
}
