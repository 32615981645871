export default {
  company_code: {
    minLength: [4, "minLength character"],
    maxLength: [15, "maxLength character"],
    matchRegexp: [/^[0-9a-zA-Z_\-.]+$/, "only company code character"],
  },
  fullname: {
    minLength: [4, "minLength character"],
    maxLength: [256, "maxLength character"],
  },
  type: {
    matchRegexp: [/^(personal|company)$/, "can only be personal or company"],
  },
};
