import ld from "lodash";
import moment from "moment-timezone";
import Debug from "debug";

import { hashFnv32a } from "app/utils/common";

const debug = Debug("pfe:mock:message_gen");

const // ts_store = {},
  tfmt = "MMDD.HHmm.ss",
  fmt = (ts) => moment(ts).format(tfmt);
// function gTS(id, base_ts, deviation) {
//   if (id && ts_store[id])
//     return ts_store[id];

//   const dd_orig = moment.duration(...deviation).asSeconds(),
//         dd = ld.random(dd_orig, dd_orig * 0.85),
//         base_mm = moment(base_ts)
//         // base_before_add = base_mm.format(tfmt)
//         ;

//   ts_store[id] = base_mm.add(dd, 's');
// //   debug('TTT', base_before_add, dd_orig, ts_store[id].format(tfmt));
//   return ts_store[id];
// }

export function gen_msgs_by_distribution(base_ts, dists) {
  return dists.reduce((agg, dist) => {
    const dd_orig = moment.duration(...dist).asSeconds(),
      dd = ld.random(dd_orig, dd_orig * 0.6),
      base_mm = moment(base_ts),
      ts_for_msg = base_mm.add(dd, "s");
    debug("gen_msgs_by_distribution: T=", ts_for_msg.format(tfmt));
    // return [ ...agg, ...genMsgs(ts_for_msg.valueOf()) ];
    return [...agg, ...genMsgsPattern1(ts_for_msg.valueOf())];
  }, []);
}

function genMsgsPattern1(ts) {
  const result = [];
  for (let cnt = 0; cnt < 500; cnt++) {
    const tstamp = ts + (cnt - 250) * 2000;
    result.push({
      msg_id: hashFnv32a("" + tstamp),
      fkey: `parami chatroom ${ts}`,
      sender: "5725a680b3249760ea21de52",
      parami_sender: null,
      body: `#${cnt}. msg at ${fmt(tstamp)}: `,
      translation: null,
      created_at: tstamp,
      datetime: tstamp,
      last_modified: tstamp,
      read: 0,
    });
  }
  return result;
}

// function genMsgs(ts) {
// const ttt = 0.1;
//   return [
//     {
//         msg_id:hashFnv32a('' + gTS(1, ts, [-5, 'm'])),
//         fkey: `parami chatroom ${gTS(1)}`,
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         body: `#1. ${fmt(gTS(1))}: I'm the oldest. Quickly come to the meeting room 1B.`,
//         translation: null,
//         created_at:  gTS(1),
//         datetime:  gTS(1),
//         last_modified:  gTS(1),
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(2, ts, [-4.5, 'm'])),
//         sender: '5725a6802d10e277a0f35724',
//         parami_sender: '5725a6802d10e277a0f35724',
//         datetime:  gTS(2, ts, [-4.5, 'm']),
//         body: `#2. ${fmt(gTS(2))}: I’m having breakfast right now, can’t you wait for 10 minutes?`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(3, ts, [-4, 'm'])),
//         sender: 'social_page_yyy001',
//         parami_sender: null,
//         datetime:  gTS(3),
//         body: `#3. ${fmt(gTS(3))}: I’m having breakfast right now, can’t you wait for 10 minutes?`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(4, ts, [-3, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(4, ts, [-3, 'm']),
//         body: `#4. ${fmt(gTS(4))}: We are losing money! Quick!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(5, ts, [-2.5, 'm'])),
//         sender: '5725a6802d10e277a0f35724',
//         parami_sender: '5725a6802d10e277a0f35724',
//         datetime: gTS(5),
//         body: `#5. ${fmt(gTS(5))}: It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(6, ts, [-1, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime:  gTS(6),
//         body: `#6. ${fmt(gTS(6))}: You are the worst!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(7, ts, [ttt + 0.1, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(7),
//         body: `#7. ${fmt(gTS(7))}: We are losing money! Quick!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(8, ts, [ttt + 0.15, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(8),
//         body: `#8. ${fmt(gTS(8))}: You are the worst!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(9, ts, [ttt + 0.5, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(9),
//         body: `#9. ${fmt(gTS(9))}: We are losing money! Quick!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(10, ts, [ttt + 0.7, 'm'])),
//         sender: '5725a6802d10e277a0f35724',
//         parami_sender: '5725a6802d10e277a0f35724',
//         datetime: gTS(10),
//         body: `#10. ${fmt(gTS(10))}: It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(11, ts, [ttt + 0.85, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(11),
//         body: `#11. ${fmt(gTS(11))}: You are the worst!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(12, ts, [ttt + 1, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(12),
//         body: `#12. ${fmt(gTS(12))}: We are losing money! Quick!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(13, ts, [ttt + 1.2, 'm'])),
//         sender: '5725a6802d10e277a0f35724',
//         parami_sender: '5725a6802d10e277a0f35724',
//         datetime: gTS(13),
//         body: `#13. ${fmt(gTS(13))}: It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(14, ts, [ttt + 1.3, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(14),
//         body: `#14. ${fmt(gTS(14))}: You are the worst!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(15, ts, [ttt + 1.4, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(15),
//         body: `#15. ${fmt(gTS(15))}: You are really the worst!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(16, ts, [ttt + 1.6, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(16),
//         body: `#16. ${fmt(gTS(16))}: We are losing money! Quick!`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(17, ts, [ttt + 1.7, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(17),
//         body: `#17. ${fmt(gTS(17))}: Hold on, let me see`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(18, ts, [8.8, 'm'])),
//         sender: '5725a6802d10e277a0f35724',
//         parami_sender: '5725a6802d10e277a0f35724',
//         datetime: gTS(18),
//         body: `#18. ${fmt(gTS(18))}: It’s not my money, you know. I will eat my breakfast and then I will come to the meeting room.`,
//         read: 0,
//     },
//     {
//         msg_id: hashFnv32a('' + gTS(19, ts, [10, 'm'])),
//         sender: '5725a680b3249760ea21de52',
//         parami_sender: null,
//         datetime: gTS(19),
//         body: `#19. ${fmt(gTS(19 ))}: You are the worst!`,
//         read: 0,
//     }
//   ];

// }
