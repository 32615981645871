import React from "react";
import { withStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  wrapperContent: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
};

export default withStyles(styles)(function FlexWrapper({ classes, children }) {
  return (
    <span className={classes.wrapper}>
      <span className={classes.wrapperContent}>{children}</span>
    </span>
  );
});
