import React, { Component } from "react";
import { FuseSplashScreen } from "@fuse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { onAuth0Events } from "app/auth";
import * as userActions from "app/auth/store/actions";
import * as Actions from "app/store/actions";

class Callback extends Component {
  componentDidMount() {
    const { t: ttt } = this.props;

    this.props.showMessage({ message: ttt("general:login.progress") });

    onAuth0Events.call(this, "Callback.js");
  }

  render() {
    return <FuseSplashScreen />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserDataAuth0: userActions.setUserDataAuth0,
      PFELogin: userActions.PFELogin,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
    },
    dispatch
  );
}

let myComp = withRouter(connect(null, mapDispatchToProps)(Callback));
myComp = withTranslation("general")(myComp);
export default myComp;
