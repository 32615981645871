import { FuseLoadable } from "@fuse";
import { authRoles } from "app/auth";

export const ErrorPagesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.acc_usage,
  routes: [
    {
      path: "/error/500",
      component: FuseLoadable({
        loader: () => import("./Error500Page"),
      }),
    },
    {
      path: "/error/404",
      component: FuseLoadable({
        loader: () => import("./Error404Page"),
      }),
    },
  ],
};
