import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({});

let component = (props) => {
  const {
      onClose,
      title,
      messages,
      content,
      leftButtonCaption,
      rightButtonCaption,
      fullwidth,
      classes,
      contentClasses,
      BackdropProps,
      open = true,
    } = props,
    { onLeft, onRight } = props,
    { t: ttt } = useTranslation();
  const dialog_content = messages
    ? messages.map((msg, ind) => {
        return (
          <Typography key={ind} gutterBottom>
            {msg}
          </Typography>
        );
      })
    : null;

  return (
    <Dialog
      onClose={onClose}
      classes={classes}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullwidth}
      BackdropProps={BackdropProps}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent classes={contentClasses}>
        {content || dialog_content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onLeft} color="primary">
          {leftButtonCaption || ttt("general:button.cancel")}
        </Button>
        <Button onClick={onRight} color="primary">
          {rightButtonCaption || ttt("general:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

component = withStyles(styles, { withTheme: true })(component);
export const DoubleButtonDialog = component;
