import React from "react";
import {
  Hidden,
  withStyles,
  TextField,
  IconButton,
  Icon,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inAsset } from "app/utils/common";
import {
  faCommentDots,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { MultiLevelSelect } from "app/parami-layouts";
import LabelsList from "parami-flow-editor/src/components/LabelsList";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const applyStyle = withStyles(
  (theme) => ({
    stories: {
      maxHeight: "calc(100vh - 105px)",
      width: "36rem",
      maxWidth: "calc(100vw - 105px)",
      ["@media (min-width:960px)"]: {
        // eslint-disable-line no-useless-computed-key
        maxWidth: 960,
        width: "calc(100vw - 105px)",
      },
      display: "flex",
      "& > .swiper-container": {
        padding: "20px 0",
        paddingBottom: 60,
        ["@media (min-width:480px)"]: {
          // eslint-disable-line no-useless-computed-keyw
          padding: "20px 20px",
          paddingBottom: 60,
        },
      },
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 10,
    },
    story: {
      position: "relative",
      height: "115%",
      width: "100%",
    },
    bannerBg: {
      position: "absolute",
      height: "50px",
      width: "calc(100% - 20px)",
      background: "#00bdc459",
      zIndex: -1,
      display: "flex",
      display: "none",
      transform: "translateX(-15px)",
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        display: "block",
        border: "solid transparent",
        borderWidth: 25,
      },
      "&:before": {
        left: -1,
        borderLeftColor: "white",
      },
      "&:after": {
        left: "100%",
        borderLeftColor: "#00bdc459",
      },
    },
    banner: {
      height: 60,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1.8rem",
      padding: "20px 20px",
      paddingTop: 5,
      textAlign: "center",
      whiteSpace: "pre-wrap",
      fontWeight: "bold",
    },
    content: {
      minHeight: 240,
      "& .multi-level-options-container": {
        minWidth: "14rem !important",
      },
    },
    circle: {
      position: "absolute",
      width: "15rem",
      height: "15rem",
      borderRadius: 9999,
      borderColor: "#d7d7d7",
      borderWidth: 2,
      background: "white",
      boxShadow: "0 0 0 5px white",
      overflow: "hidden",
      boxSizing: "border-box",
    },
    fakeHeaderTitle: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingTop: "0.8rem",
      paddingBottom: "0.8rem",
      background: "#9067b9",
      marginTop: "1.5rem",
      color: "white",
      alignItems: "center",
      display: "flex",
      fontWeight: "bold",
      marginLeft: 1,
      marginright: 1,
      whiteSpace: "nowrap",
    },
    fakeItemRow: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      paddingTop: "0.8rem",
      paddingBottom: "0.8rem",
      background: "white",
      alignItems: "center",
      display: "flex",
      borderBottom: "1px solid #e0e0e0",
      width: "100%",
      justifyContent: "center",
    },
    arrowCurved1: {
      position: "absolute",
      border: "none",
      borderWidth: 3,
      borderColor: "#d7d7d7",
      borderLeftStyle: "dashed",
      borderBottomStyle: "dashed",
      borderRadius: "0 0 0 100px",
    },
    arrowHead: {
      width: 0,
      height: 0,
      borderTop: "0.8rem solid transparent",
      borderBottom: "0.8rem solid transparent",
      borderLeft: "1.6rem solid #d7d7d7",
      position: "absolute",
    },
    fakeNode: {
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      color: "#000",
      border: "2px solid transparent",
      background: "#fff",
      boxShadow: "0 0 10px #c1c1c1a8",
      overflow: "visible",
      fontSize: 11,
      width: 130,
    },
    fakeNodeIcon: {
      position: "absolute",
      top: "-1rem",
      left: "-1rem",
      fontSize: "2rem",
      width: 40,
      height: 40,
      color: "white",
      display: "flex",
      alignItems: "center",
      borderRadius: 30,
      justifyContent: "center",
      background: "linear-gradient( 45deg,#8d62b8,#ba9fde)",
      boxShadow: "0px 0px 6px -1px #00000091",
    },
    fakeNodeStartIcon: {
      position: "absolute",
      top: "-2.3rem",
      right: "0.4rem",
      fontSize: "1.2rem",
      width: 28,
      height: 28,
      color: "white",
      display: "flex",
      alignItems: "center",
      borderRadius: 30,
      justifyContent: "center",
      background: "linear-gradient(45deg, #1cb233 0%, #73d395 100%)",
      boxShadow: "0px 0px 6px -1px #0000004a",
    },
    fakeNodeOutPort: {
      position: "absolute",
      top: "1rem",
      right: "-0.7rem",
      background: "#fff",
      borderRadius: 20,
      width: "0.7rem",
      height: "0.7rem",
      borderWidth: 2,
      borderColor: "#333",
      zIndex: 9,
    },
    fakeNodeTitle: {
      padding: "0.5rem",
      paddingLeft: "4rem",
    },
    fakeNodeOutputText: {
      margin: "0.8rem 0.4rem",
      marginBottom: "0.4rem",
      borderWidth: 1,
      borderTopRightRadius: "0.8rem",
      borderBottomRightRadius: "0.8rem",
      borderBottomLeftRadius: "0.8rem",
      borderColor: "#dbdbdb91",
      borderRadius: 2,
      padding: "0.8rem 1.6rem",
    },
    fakeNodeOutputMedia: {
      margin: "0.8rem 0.4rem",
      marginBottom: "0.4rem",
      borderWidth: 1,
      borderTopRightRadius: "0.8rem",
      borderBottomRightRadius: "0.8rem",
      borderBottomLeftRadius: "0.8rem",
      borderColor: "#dbdbdb91",
      borderRadius: 2,
      padding: "0.8rem 1.6rem",
    },
    fadingGradientLeft: {
      position: "absolute",
      height: "100%",
      width: "1rem",
      background: "linear-gradient(90deg, white, transparent)",
      left: 0,
      top: 0,
    },
    fadingGradientRight: {
      position: "absolute",
      height: "100%",
      width: "1rem",
      background: "linear-gradient(-90deg, white, transparent)",
      right: 0,
      top: 0,
    },
    container: {
      marginTop: "0.8rem",
      position: "relative",
      padding: 10,
      borderRadius: 4,
      border: "1px solid #cdcdcd",
    },
    containerHeader: {
      top: "-0.7em",
      fontSize: 12,
      color: "#00000066",
      padding: "0 5px",
      background: "white",
      marginLeft: -10,
      display: "inline-block",
      position: "absolute",
      fontWeight: 100,
      pointerEvents: "none",
    },
  }),

  { withTheme: true }
);

let Stories = (props) => {
  const { classes, stories, t: ttt, handleClose } = props;
  return (
    <span className={classes.stories}>
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <Icon>close</Icon>
      </IconButton>
      <Swiper
        grabCursor
        slidesPerView={1}
        spaceBetween={5}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          960: {
            slidesPerView: 3,
          },
        }}
      >
        {stories &&
          stories.map((Story, i) => (
            <SwiperSlide key={i}>
              <div className={classes.story}>
                <Story classes={classes} ttt={ttt} />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </span>
  );
};

Stories = applyStyle(Stories);
Stories = withTranslation()(Stories);

const Cursor = (props) => {
  if (!props.style) props.style = {};
  if (!props.style.width) props.style.width = 24;
  if (!props.style.height) props.style.height = 24;
  return (
    <span {...props}>
      <svg
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M507.606 422.754l-86.508-86.508 42.427-42.426a15 15 0 0 0-5.863-24.837L203.104 184.13a15 15 0 0 0-18.974 18.974l84.853 254.559a15 15 0 0 0 10.784 9.855 14.99 14.99 0 0 0 14.053-3.992l42.427-42.426 86.508 86.507c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l63.64-63.64a15 15 0 0 0-.001-21.213zm-74.246 53.033l-86.508-86.507c-5.857-5.858-15.356-5.858-21.213 0l-35.871 35.871-67.691-203.073 203.073 67.691-35.871 35.871c-5.853 5.852-5.858 15.356 0 21.213l86.508 86.508-42.427 42.426zM195 120c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15s-15 6.716-15 15v90c0 8.284 6.716 15 15 15zM78.327 57.114c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l63.64 63.64c5.857 5.858 15.356 5.858 21.213 0s5.858-15.355 0-21.213l-63.64-63.64zm42.427 190.919l-63.64 63.64c-5.858 5.858-5.858 15.355 0 21.213s15.356 5.858 21.213 0l63.64-63.64c5.858-5.858 5.858-15.355 0-21.213s-15.356-5.858-21.213 0zm148.492-106.066l63.64-63.64c5.858-5.858 5.858-15.355 0-21.213s-15.355-5.858-21.213 0l-63.64 63.64c-5.858 5.858-5.858 15.355 0 21.213s15.356 5.858 21.213 0zM120 195c0-8.284-6.716-15-15-15H15c-8.284 0-15 6.716-15 15s6.716 15 15 15h90c8.284 0 15-6.716 15-15z" />
      </svg>
    </span>
  );
};

let StoryProjects_1 = (props) => {
  const { classes, ttt } = props;
  return (
    <>
      <div key="1" className={classes.banner}>
        <div className={classes.bannerBg}></div>
        {ttt("tutorials:Create Projects & Flows")}
      </div>
      <div
        key="2"
        style={{ width: "23rem", margin: "auto" }}
        className={classes.content}
      >
        <div key="1" className="absolute z-10">
          <div
            key="1"
            style={{ top: "11rem", left: "9rem" }}
            className={classes.circle}
          >
            <div
              key="1"
              style={{ background: "#67b9b9", paddingLeft: "3rem" }}
              className={classes.fakeHeaderTitle}
            >
              <Icon className="mr-8">waves</Icon>
              <span className="mt-2">{ttt("settings-flow:Header.Title")}</span>
            </div>

            <div key="2" className={classes.fakeItemRow}>
              <span className="mt-2">
                {ttt("settings-flow:Header.Title")} 1
              </span>
            </div>

            <div key="3" className={classes.fakeItemRow}>
              <span className="mt-2">
                {ttt("settings-flow:Header.Title")} 2
              </span>
            </div>
          </div>
          <div key="2" style={{ top: 0, left: 0 }} className={classes.circle}>
            <div
              key="1"
              style={{ background: "#6775b9" }}
              className={classes.fakeHeaderTitle}
            >
              <Icon className="mr-8">folder</Icon>
              <span className="mt-2">{ttt("general:Projects")}</span>
            </div>

            <div key="2" className={classes.fakeItemRow}>
              <span className="mt-2">{ttt("general:Project")} A</span>
            </div>

            <div key="3" className={classes.fakeItemRow}>
              <span className="mt-2">{ttt("general:Project")} B</span>
            </div>
          </div>

          <span
            key="2"
            style={{
              top: "13.5rem",
              left: "4.5rem",
              width: "7rem",
              height: "7rem",
            }}
            className={classes.arrowCurved1}
          ></span>

          <span
            key="3"
            style={{ top: "19.9rem", left: "11.6rem" }}
            className={classes.arrowHead}
          ></span>

          <Cursor
            key="4"
            style={{ top: "10.5rem", left: "11.5rem", position: "absolute" }}
          />

          <Cursor
            key="5"
            style={{ top: "21.5rem", left: "19.5rem", position: "absolute" }}
          />
        </div>
        <div key="2" style={{ height: "26rem" }}></div>
      </div>
    </>
  );
};

let StoryProjects_2 = (props) => {
  const { classes, ttt } = props;
  return (
    <>
      <div key="1" className={classes.banner}>
        <div className={classes.bannerBg}></div>
        {ttt("tutorials:Design your Chatbot")}
      </div>
      <div
        key="2"
        style={{ width: "23rem", margin: "auto" }}
        className={classes.content}
      >
        <div key="1" className="absolute z-10 mt-8">
          <span
            key="3"
            style={{
              top: "2.9rem",
              left: "9.2rem",
              width: "6rem",
              height: "6rem",
              transform: "rotate(240deg)",
              borderLeftStyle: "solid",
              borderBottomStyle: "solid",
              borderColor: "#5b5c5c",
            }}
            className={classes.arrowCurved1}
          ></span>
          <span key="1" className={classes.fakeNode}>
            <span className={classes.fakeNodeIcon}>
              <FontAwesomeIcon icon={faCommentDots} />
            </span>
            <span className={classes.fakeNodeStartIcon}>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </span>
            <span className={classes.fakeNodeOutPort}></span>
            <span className={classes.fakeNodeTitle}>
              {ttt("flow_editor:NodeWidget.output.Output")} #1
            </span>
            <span className={classes.fakeNodeOutputText}>Hello!</span>
          </span>

          <span
            key="2"
            style={{ top: "11rem", left: "8rem" }}
            className={classes.fakeNode}
          >
            <span className={classes.fakeNodeIcon}>
              <FontAwesomeIcon icon={faCommentDots} />
            </span>
            <span className={classes.fakeNodeOutPort}></span>
            <span className={classes.fakeNodeTitle}>
              {ttt("flow_editor:NodeWidget.output.Output")} #2
            </span>
            <span className={classes.fakeNodeOutputMedia}>
              <img src={inAsset("images/platforms/asanga.svg")} />
            </span>
          </span>

          <span
            key="4"
            style={{
              top: "9.1rem",
              left: "10.8rem",
              transform: "rotate(142deg)",
              borderLeftColor: "#5b5c5c",
            }}
            className={classes.arrowHead}
          ></span>
        </div>
      </div>
    </>
  );
};

let StoryProjects_3 = (props) => {
  const { classes, ttt } = props;
  return (
    <>
      <div key="1" className={classes.banner}>
        <div className={classes.bannerBg}></div>
        {ttt("tutorials:Test your Chatbot")}
      </div>
      <div
        key="2"
        style={{ width: "23rem", margin: "auto" }}
        className={classes.content}
      >
        <div key="1" className="absolute z-10">
          <div
            key="1"
            style={{
              position: "absolute",
              top: "4rem",
              left: "0.5rem",
              width: "22rem",
              height: "22rem",
            }}
          >
            <div key="8" className={classes.fadingGradientLeft}></div>
            <div key="9" className={classes.fadingGradientRight}></div>
            <div
              key="1"
              style={{ background: "#6775b9" }}
              className={classes.fakeHeaderTitle}
            >
              <Icon className="mr-8">folder</Icon>
              <span className="mt-2">{ttt("general:Project")}</span>
              <span className="ml-auto flex">
                <Icon>add</Icon>
              </span>
            </div>

            <div
              key="2"
              style={{ paddingRight: 0 }}
              className={classes.fakeItemRow}
            >
              <span className="mt-2 w-96">{ttt("general:Project")} A</span>
              <span className="ml-12 flex">
                <Icon>launch</Icon>
              </span>
              <span className="ml-12 flex">
                <Icon>edit</Icon>
              </span>
            </div>

            <div
              key="3"
              style={{ paddingRight: 0 }}
              className={classes.fakeItemRow}
            >
              <span className="mt-2 w-96">{ttt("general:Project")} B</span>
              <span className="ml-12 flex">
                <Icon>launch</Icon>
              </span>
              <span className="ml-12 flex">
                <Icon>edit</Icon>
              </span>
            </div>
          </div>

          <Cursor
            key="4"
            style={{ top: "16.5rem", left: "17.5rem", position: "absolute" }}
          />
        </div>
        <div key="2" style={{ height: "26rem" }}></div>
      </div>
    </>
  );
};

let StoryIntentions_1 = (props) => {
  const { classes, ttt } = props;
  return (
    <>
      <div key="1" className={classes.banner}>
        <div className={classes.bannerBg}></div>
        {ttt("tutorials:Create Intentions")}
      </div>
      <div
        key="2"
        style={{ width: "23rem", margin: "auto" }}
        className={classes.content}
      >
        <div key="1" className="absolute z-10">
          <div
            key="1"
            style={{ top: "11rem", left: "9rem" }}
            className={classes.circle}
          >
            <div key="1" className={classes.fakeHeaderTitle}>
              <Icon className="mr-8">flash_on</Icon>
              <span className="mt-2">{ttt("general:Intention")} B</span>
            </div>

            <span
              style={{
                marginLeft: 10,
                marginTop: 5,
                width: "300%",
                display: "flex",
                flexDirection: "column",
                transform: "scale(0.6)",
                transformOrigin: "0 0",
                pointerEvents: "none",
              }}
            >
              <div className={classes.container}>
                <span className={classes.containerHeader}>
                  {ttt("settings-intention:Detail.General.Examples")}
                </span>
                <LabelsList
                  labels={[
                    { id: 252, name: "hello" },
                    { id: 454, name: "hey yo" },
                    { id: 455, name: "Nice to meet you" },
                  ]}
                  placeholder={ttt(
                    "settings-intention:Detail.Examples.Create.Placeholder"
                  )}
                />
              </div>
            </span>
          </div>
          <div key="2" style={{ top: 0, left: 0 }} className={classes.circle}>
            <div key="1" className={classes.fakeHeaderTitle}>
              <Icon className="mr-8">flash_on</Icon>
              <span className="mt-2">{ttt("general:Intentions")}</span>
            </div>

            <div key="2" className={classes.fakeItemRow}>
              <span className="mt-2">{ttt("general:Intention")} A</span>
            </div>

            <div key="3" className={classes.fakeItemRow}>
              <span className="mt-2">{ttt("general:Intention")} B</span>
            </div>
          </div>

          <span
            key="2"
            style={{
              top: "13.5rem",
              left: "4.5rem",
              width: "5.2rem",
              height: "7rem",
            }}
            className={classes.arrowCurved1}
          ></span>

          <span
            key="3"
            style={{ top: "19.9rem", left: "10rem" }}
            className={classes.arrowHead}
          ></span>

          <Cursor
            key="4"
            style={{ top: "10.5rem", left: "12rem", position: "absolute" }}
          />

          <Cursor
            key="5"
            style={{ top: "21.5rem", left: "19.5rem", position: "absolute" }}
          />
        </div>
        <div key="2" style={{ height: "26rem" }}></div>
      </div>
    </>
  );
};

let StoryIntentions_2 = (props) => {
  const { classes, ttt } = props;

  return (
    <>
      <div key="1" className={classes.banner}>
        <div className={classes.bannerBg}></div>
        {ttt("tutorials:Apply intention to trigger a flow")}
      </div>
      <div
        key="2"
        style={{ width: "23rem", margin: "auto" }}
        className={classes.content}
      >
        <div key="1" className="absolute z-10">
          <div
            key="1"
            style={{
              position: "absolute",
              top: "-1rem",
              left: "0.5rem",
              width: "22rem",
              height: "28rem",
              overflow: "hidden",
            }}
          >
            <div key="8" className={classes.fadingGradientLeft}></div>
            <div key="9" className={classes.fadingGradientRight}></div>
            <div key="1" className={classes.fakeHeaderTitle}>
              <Icon className="mr-8">folder</Icon>
              <span className="mt-2">{ttt("general:Intention")} B</span>
            </div>

            <span
              style={{
                display: "flex",
                flexDirection: "column",
                transform: "scale(0.8)",
                transformOrigin: "0 0",
                width: "300%",
              }}
            >
              <TextField
                className="block m-12 mt-16 pointer-events-none"
                required
                label={ttt("settings-intention:Detail.General.Name")}
                value={ttt("general:Intention") + " B"}
                variant="outlined"
                fullWidth
              />

              <MultiLevelSelect
                noOptionsText={ttt(
                  "settings-intention:Detail.General.noOptions"
                )}
                hideChosen
                hideSearch
                isMenuOpen
                options={[
                  {
                    value: "11",
                    label: ttt("general:Project") + " A",
                    options: [
                      {
                        id: 1,
                        value: "1",
                        label: ttt("settings-flow:Header.Title") + " 1",
                      },
                      {
                        id: 2,
                        value: "2",
                        label: ttt("settings-flow:Header.Title") + " 2",
                      },
                    ],
                  },
                  {
                    value: "12",
                    label: ttt("general:Project") + " B",
                    options: [
                      {
                        id: 1,
                        value: "1",
                        label: ttt("settings-flow:Header.Title") + " 1",
                      },
                      {
                        id: 2,
                        value: "2",
                        label: ttt("settings-flow:Header.Title") + " 2",
                      },
                    ],
                  },
                ]}
                className="m-12 mt-0 mb-16 pointer-events-none"
                placeholder={"Select a flow to apply the intention"}
              />

              <div
                className="multi-level-selector-container"
                style={{
                  width: "fit-content",
                  left: "14rem",
                  top: "16.2rem",
                  position: "absolute",
                  zIndex: 9,
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    width: "13rem",
                  }}
                  className="options-sub-menu-container"
                >
                  <div className="options-sub-menu-header">11</div>
                  <label>
                    <div className="options-sub-menu">
                      <input checked type="checkbox" name="Flows 1" value="1" />
                      <div className="checkbox">
                        <span className="checkmark"></span>
                      </div>
                      <div className="options-label">Flows 1</div>
                    </div>
                  </label>
                  <label>
                    <div className="options-sub-menu">
                      <input type="checkbox" name="Flows 2" value="2" />
                      <div className="checkbox">
                        <span className="checkmark"></span>
                      </div>
                      <div className="options-label">Flows 2</div>
                    </div>
                  </label>
                </div>
              </div>
            </span>
          </div>

          <Cursor
            key="4"
            style={{ top: "18.5rem", left: "20rem", position: "absolute" }}
          />
        </div>
        <div key="2" style={{ height: "26rem" }}></div>
      </div>
    </>
  );
};

const PopupHelp = {
  1: (handleClose) => {
    return (
      <Stories
        handleClose={handleClose}
        stories={[StoryProjects_1, StoryProjects_2, StoryProjects_3]}
      />
    );
  },
  2: (handleClose) => {
    return (
      <Stories
        handleClose={handleClose}
        stories={[StoryIntentions_1, StoryIntentions_2, StoryProjects_3]}
      />
    );
  },
};
export default PopupHelp;
