import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import { FusePageSimple } from "@fuse";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import Debug from "debug";
import connect from "react-redux/es/connect/connect";

import history from "history.js";
import { i18nNamespaces } from "constants.js";
import CreateDialog from "./CreateDialog";
import ResultDialog from "./ResultDialog";
import { SingleButtonDialog } from "app/parami-layouts/dialogs";
import { showMessage } from "app/store/actions";
import { createCompany as createCompAction } from "app/store/actions/company/info.actions";
import { logoutUser } from "app/auth/store/actions/user.actions";
import {
  CREATE_COMPANY_CREATE_FAILED,
  CREATE_COMPANY_REFRESH_TOKEN_FAILED,
} from "app/utils/ParamiError";
import { withPFEValidators } from "app/services/paramiService";

const debug = Debug("pfe:company:ui:CreateDialog");

const styles = (theme) => {
  return {};
};

const CREATE = "CREATE",
  CREATING = "CREATING",
  CREATE_OK = "CREATE_OK",
  CREATE_ERR = "CREATE_ERR",
  REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";
const CreateCompany = (props) => {
  const { user, createCompAction, logoutUser } = props,
    [step, setStep] = useState(CREATE),
    [company, setCompany] = useState({}),
    { t: ttt } = useTranslation();
  let dialog;

  const closeDialog = (error, company) => {
    debug("closeDialog", step);
    setCompany(company);
    switch (step) {
      case CREATE_OK:
        history.push("/");
        break;

      case CREATE:
        if (error) {
          setStep(CREATE_ERR);
        } else logoutUser();
        break;

      case CREATE_ERR:
      case REFRESH_TOKEN_FAILED:
        logoutUser();
        break;

      default:
        break;
    }
  };

  const goBackToCreateDialog = () => {
    setStep(CREATE);
  };

  const submitCreateCompany = (company) => {
    setStep(CREATING);
    setCompany(company);

    createCompAction(company)
      .then(() => {
        // TODO: Remove? Since free plan is created from server (2020.0110).
        // PRMF-49: Add free plan for new company
        // const ret = await getPlans(),
        //       plans = ret.entities.plan,
        //       freePlan = ld.find(plans, pl => pl.price_model === PriceModel.free)
        //       ;
        // await updateSubscription(freePlan, null);

        setStep(CREATE_OK);
      })
      .catch((err) => {
        debug(`submitCreateCompany:`, err.stack);
        if (err.message === CREATE_COMPANY_CREATE_FAILED) setStep(CREATE_ERR);
        else if (err.message === CREATE_COMPANY_REFRESH_TOKEN_FAILED) {
          setStep(REFRESH_TOKEN_FAILED);
        }
      });
  };

  switch (step) {
    case CREATE:
      dialog = (
        <CreateDialog
          company={company}
          user={user}
          closeDialog={closeDialog}
          submitCreateCompany={submitCreateCompany}
        />
      );
      break;

    case CREATING:
      dialog = (
        <SingleButtonDialog
          open
          title={ttt("create-company:creating.title")}
          messages={[ttt("create-company:creating.desc")]}
          // onClose={(screenFlow.bind(null, 'cancel'))}
          // closeButtonCaption={[ttt("general:ok")]}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        />
      );
      break;

    case CREATE_OK:
    case CREATE_ERR:
      dialog = (
        <ResultDialog
          id="resultDialog"
          isOpen={true}
          creationResult={step === CREATE_OK}
          goBack={goBackToCreateDialog}
          closeDialog={closeDialog}
        />
      );
      break;

    case REFRESH_TOKEN_FAILED:
      dialog = (
        <ResultDialog
          id="resultDialog"
          isOpen
          desc={ttt("create-company:result.re-login failed")}
          creationResult={false}
          closeDialog={closeDialog}
        />
      );
      break;

    default:
      dialog = <div></div>;
      break;
  }

  return <FusePageSimple content={dialog} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCompAction,
      logoutUser,
      showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ company, auth }) {
  return {
    company: company.info,
    user: auth.user,
  };
}

let myComp = withStyles(styles, { withTheme: true })(CreateCompany);
myComp = withPFEValidators(myComp, ["company", "general"]);
export default connect(mapStateToProps, mapDispatchToProps)(myComp);
