'use strict';

exports.__esModule = true;
exports.logIn = logIn;
exports.signUp = signUp;
exports.resetPassword = resetPassword;
exports.showLoginActivity = showLoginActivity;
exports.showSignUpActivity = showSignUpActivity;
exports.showResetPasswordActivity = showResetPasswordActivity;
exports.cancelResetPassword = cancelResetPassword;
exports.cancelMFALogin = cancelMFALogin;
exports.toggleTermsAcceptance = toggleTermsAcceptance;
exports.showLoginMFAActivity = showLoginMFAActivity;

var _immutable = require('immutable');

var _immutable2 = _interopRequireDefault(_immutable);

var _index = require('../../store/index');

var _web_api = require('../../core/web_api');

var _web_api2 = _interopRequireDefault(_web_api);

var _actions = require('../../core/actions');

var _index2 = require('../../core/index');

var l = _interopRequireWildcard(_index2);

var _index3 = require('../../field/index');

var c = _interopRequireWildcard(_index3);

var _index4 = require('./index');

var _i18n = require('../../i18n');

var i18n = _interopRequireWildcard(_i18n);

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function logIn(id) {
  var needsMFA = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var usernameField = (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username';
  var username = c.getFieldValue(m, usernameField);
  var params = {
    connection: (0, _index4.databaseConnectionName)(m),
    username: username,
    password: c.getFieldValue(m, 'password')
  };
  if (params.connection === 'Username-Password-Authentication') params.username = (params.username || '').replace(/\@/g, '+');
  var fields = [usernameField, 'password'];
  var mfaCode = c.getFieldValue(m, 'mfa_code');

  if (needsMFA) {
    params['mfa_code'] = mfaCode;
    fields.push('mfa_code');
  }

  (0, _actions.logIn)(id, fields, params, function (id, error, fields, next) {
    if (error.error === 'a0.mfa_required') {
      return showLoginMFAActivity(id);
    }

    return next();
  });
}

function signUp(id) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var fields = ['email', 'password'];
  if ((0, _index4.databaseConnectionRequiresUsername)(m)) fields.push('username');
  (0, _index4.additionalSignUpFields)(m).forEach(function (x) {
    return fields.push(x.get('name'));
  });
  (0, _actions.validateAndSubmit)(id, fields, function (m) {
    var params = {
      connection: (0, _index4.databaseConnectionName)(m),
      email: c.getFieldValue(m, 'email'),
      password: c.getFieldValue(m, 'password'),
      autoLogin: (0, _index4.shouldAutoLogin)(m)
    };

    if ((0, _index4.databaseConnectionRequiresUsername)(m)) {
      params.username = c.getFieldValue(m, 'username');
    }

    if (!(0, _index4.additionalSignUpFields)(m).isEmpty()) {
      params.user_metadata = {};
      (0, _index4.additionalSignUpFields)(m).forEach(function (x) {
        params.user_metadata[x.get('name')] = c.getFieldValue(m, x.get('name'));
      });
    }

    _web_api2.default.signUp(id, params, function (error, result, popupHandler) {
      for (var _len = arguments.length, args = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }

      if (error) {
        if (!!popupHandler) {
          popupHandler._current_popup.kill();
        }

        setTimeout(function () {
          return signUpError(id, error);
        }, 250);
      } else {
        signUpSuccess.apply(undefined, [id, result, popupHandler].concat(args));
      }
    });
  });
}

function signUpSuccess(id, result, popupHandler) {
  var lock = (0, _index.read)(_index.getEntity, 'lock', id);

  if ((0, _index4.shouldAutoLogin)(lock)) {
    (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
      return m.set('signedUp', true);
    }); // TODO: check options, redirect is missing

    var options = {
      connection: (0, _index4.databaseConnectionName)(lock),
      username: c.email(lock),
      password: c.password(lock)
    };

    if (!!popupHandler) {
      options.popupHandler = popupHandler;
    }

    return _web_api2.default.logIn(id, options, l.auth.params(lock).toJS(), function (error) {
      for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      if (error) {
        setTimeout(function () {
          return autoLogInError(id, error);
        }, 250);
      } else {
        _actions.logInSuccess.apply(undefined, [id].concat(args));
      }
    });
  }

  var autoclose = l.ui.autoclose(lock);

  if (!autoclose) {
    (0, _index.swap)(_index.updateEntity, 'lock', id, function (lock) {
      return l.setSubmitting(lock, false).set('signedUp', true);
    });
  } else {
    (0, _actions.closeLock)(id, false);
  }
}

function signUpError(id, error) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var invalidPasswordKeys = {
    PasswordDictionaryError: 'password_dictionary_error',
    PasswordNoUserInfoError: 'password_no_user_info_error',
    PasswordStrengthError: 'password_strength_error'
  };
  var errorKey = error.code === 'invalid_password' && invalidPasswordKeys[error.name] || error.code;
  var errorMessage = i18n.html(m, ['error', 'signUp', errorKey]) || i18n.html(m, ['error', 'signUp', 'lock.fallback']);
  l.emitEvent(m, 'signup error', error);
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
}

function autoLogInError(id, error) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    var errorMessage = l.loginErrorMessage(m, error);

    if ((0, _index4.hasScreen)(m, 'login')) {
      return l.setSubmitting((0, _index4.setScreen)(m, 'login'), false, errorMessage);
    } else {
      return l.setSubmitting(m, false, errorMessage);
    }
  });
}

function resetPassword(id) {
  (0, _actions.validateAndSubmit)(id, ['email'], function (m) {
    var params = {
      connection: (0, _index4.databaseConnectionName)(m),
      email: c.getFieldValue(m, 'email')
    };

    _web_api2.default.resetPassword(id, params, function (error) {
      if (error) {
        setTimeout(function () {
          return resetPasswordError(id, error);
        }, 250);
      } else {
        resetPasswordSuccess(id);
      }
    });
  });
}

function resetPasswordSuccess(id) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);

  if ((0, _index4.hasScreen)(m, 'login')) {
    (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
      return (0, _index4.setScreen)(l.setSubmitting(m, false), 'login', ['']);
    } // array with one empty string tells the function to not clear any field
    ); // TODO: should be handled by box

    setTimeout(function () {
      var successMessage = i18n.html(m, ['success', 'forgotPassword']);
      (0, _index.swap)(_index.updateEntity, 'lock', id, l.setGlobalSuccess, successMessage);
    }, 500);
  } else {
    if (l.ui.autoclose(m)) {
      (0, _actions.closeLock)(id);
    } else {
      (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
        return l.setSubmitting(m, false).set('passwordResetted', true);
      });
    }
  }
}

function resetPasswordError(id, error) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var errorMessage = i18n.html(m, ['error', 'forgotPassword', error.code]) || i18n.html(m, ['error', 'forgotPassword', 'lock.fallback']);
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
}

function showLoginActivity(id) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['password'];
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setScreen, 'login', fields);
}

function showSignUpActivity(id) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['password'];
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setScreen, 'signUp', fields);
}

function showResetPasswordActivity(id) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['password'];
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setScreen, 'forgotPassword', fields);
}

function cancelResetPassword(id) {
  return showLoginActivity(id);
}

function cancelMFALogin(id) {
  return showLoginActivity(id);
}

function toggleTermsAcceptance(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.toggleTermsAcceptance);
}

function showLoginMFAActivity(id) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['mfa_code'];
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setScreen, 'mfaLogin', fields);
}