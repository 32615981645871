import * as MM from "moment-timezone";
import linkify from "linkify-it";
import URL from "url";

const {
  // For PFE
  PROTOCOL: PFE_PROTOCOL,
  HOST: PFE_HOST,
  PORT: PFE_PORT,
  BASE_PATH,

  REACT_APP_PBE_PROTOCOL_,
  REACT_APP_PBE_HOST_,
  REACT_APP_PBE_PORT_,
  REACT_APP_PBE_PROTOCOL,
  REACT_APP_PBE_HOST,
  REACT_APP_PBE_PORT,
  REACT_APP_PBE_MB_PORT,
  REACT_APP_PBE_ORIG_PROTOCOL,
  REACT_APP_PBE_ORIG_HOST,
  REACT_APP_PBE_ORIG_PORT,
  REACT_APP_PFE_FB_APP_ID,
  REACT_APP_PFE_EBAY_APP_ID,
  REACT_APP_PFE_EBAY_APP_RUNAME,
  REACT_APP_IS_BUNDLE,
  REACT_APP_PFE_BNGO,
} = process.env;

//
// Constants
//
export const TestMode = {
  SIT: "SIT",
  UT: "UNIT_TEST",
};

export const Layout = {
  FusePageCarded: {},
};

export const DISP_LANG_ORIG = "orig";

export const i18nNamespaces = {
  register: "register",
  welcome_page: "welcome_page",
  general: "general",
  error_message: "error-message",
  currency: "currency",
  formFields: "form-fields",
  navigation: "navigation",
  regions: "regions",
  languages: "languages",
  plans: "plans",
  createCompany: "create-company",
  dashboard: "dashboard",
  components: "components",
  tutorials: "tutorials",
  settings: {
    user: "settings-user",
    company: "settings-company",
    staff: "settings-staff",
    plfaccs: "settings-plfaccs",
    subscription: "settings-subscription",
  },
  flow: {
    editor: "flow_editor",
    templates: "settings-flow-template",
    flows: "settings-flow",
    intentions: "settings-intention",
  },
  chat: {
    convPanel: "chat-conv-panel",
    dialogPanel: "chat-dialog-panel",
    core: "chat-core",
    RSIPanel: "chat-rsi-panel",
  },
};

export const Rights = {
  ASSIGN_CONVERSATION: "assign conversation",
  EDIT_FLOW: "edit_flow",
  VIEW_DASHBOARD: "view_dashboard",
  UPDATE_STAFF: "update_staff",
  CREATE_STAFF: "create_staff",
  DELETE_STAFF: "delete_staff",
};

export const LangsForChatbots = ["en", "zh-CN", "zh-TW", "zh-HK"];

export const TimeZones = MM.tz.names().map((item) => ({
  label: item,
  value: item,
}));

export const Schema = {
  Subscription: ["paypal_id", "subsccription", "plan_id", "payment"],
};

// Subscriptions and Plans
export const PriceModel = {
  none: "none",
  free: "free",
  recurrent: "recurrent",
  one_off: "one_off",
};

export const PaymentMethod = {
  none: "none",
  paypal: "paypal",
  unknwon: "unknwon",
};

export const SubsAndPlans = {
  status: {
    active: "ACTIVE",
    inactive: "INACTIVE",
  },
  interval: {
    unit: {
      month: "MONTH",
      NA: "NA",
    },
  },
};

SubsAndPlans.interval.recurrents = [SubsAndPlans.interval.unit.month];

export const PARAMI_PLAN_NONE = "none";

export const Roles = {
  sysAdmin: "sysAdmin",
  sysOps: "sysOps",
  companyOwner: "companyOwner",
  companyManager: "companyManager",
  companyStaff: "companyStaff",
  guest: "guest",
};

export const CompanyConst = {
  type: {
    personal: "personal",
    company: "company",
  },
};

export const Salutation = {
  mr: "mr",
  mrs: "mrs",
  ms: "ms",
  professor: "professor",
  sir: "sir",
  madam: "madam",
  rev: "rev",
};

export const Gender = {
  male: "male",
  female: "female",
  "not specified": "not specified",
};

export const Dashboard = {
  duration: {
    YEAR: "year",
    MONTH: "month",
    WEEK: "week",
    DAY: "day",
  },
  ALL: "all",
};

//
// Configs
// Notes: Depends on env and constants
// TODO: Separate configs from constants
//

export const landing_page_url = URL.format({
  protocol: PFE_PROTOCOL,
  hostname: PFE_HOST,
  port: PFE_PORT,
});

export const asanga_app_url = URL.format({
  protocol: PFE_PROTOCOL,
  hostname: PFE_HOST,
  port: PFE_PORT,
  pathname: (BASE_PATH || "").replace(/^\//g, ""),
});

export const apiOrigin = REACT_APP_PBE_ORIG_HOST
  ? {
      protocol: REACT_APP_PBE_PROTOCOL_ || REACT_APP_PBE_ORIG_PROTOCOL,
      host: REACT_APP_PBE_HOST_ || REACT_APP_PBE_ORIG_HOST,
      port: REACT_APP_PBE_PORT_ || REACT_APP_PBE_ORIG_PORT,
    }
  : { protocol: null, host: null, port: null };

export const api = {
  protocol: REACT_APP_PBE_PROTOCOL_ || REACT_APP_PBE_PROTOCOL,
  host: REACT_APP_PBE_HOST_ || REACT_APP_PBE_HOST,
  port: REACT_APP_PBE_PORT_ || REACT_APP_PBE_PORT,
  base_path: "api",
  version: "v1",
  request_timeout: 15000,
};

export const resources = {
  protocol: REACT_APP_PBE_PROTOCOL_ || REACT_APP_PBE_PROTOCOL,
  host: "demo.parami.ai" || REACT_APP_PBE_HOST_ || REACT_APP_PBE_HOST,
  port: 9004,
};

export const MsgBroker = {
  host: REACT_APP_PBE_HOST_ || REACT_APP_PBE_HOST,
  port: REACT_APP_PBE_MB_PORT,
  path: "/test",
};

// NOTES:
// ======================
// "first_screen": The page where user will be redirected after
// login. Hence this page must be available to all users with
// rights to login. Otherwise, the redirection mechanism will
// be triggered and make the program hangs after login.
//
export const PFEDefault = {
  timezone: "Asia/Hong_Kong",
  first_screen: "/projects",
};

export const General = {
  is_bundle: REACT_APP_IS_BUNDLE,
  git_commit_id: REACT_APP_PFE_BNGO,
};

export const Facebook = {
  app_id: REACT_APP_PFE_FB_APP_ID,
};

export const eBay = {
  app_id: REACT_APP_PFE_EBAY_APP_ID,
  ru_name: REACT_APP_PFE_EBAY_APP_RUNAME,
};

export const PayPal = {
  client_id: process.env.REACT_APP_PFE_PAYPAL_CLIENT_ID,
};

export const Test = {
  mode: TestMode.SIT,
  sio_test_mode: false,
  sit_samples: process.env.PFE_TEST_SIT_SAMPLES,
  isSIT: () => Test.mode === TestMode.SIT,
  expose_store: true,
};

export const Purpose = {
  notProduction: process.env.REACT_APP_ENV !== "production",
};

export const Linkify = linkify();
