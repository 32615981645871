import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as ld from "lodash";

const styles = (theme) => ({});

let component = (props) => {
  const { open, onClose, title, messages, content, closeButtonCaption } = props,
    { t: ttt } = useTranslation();
  const picked = ld.pickBy(props, (val, key) =>
    ["disableBackdropClick", "disableEscapeKeyDown"].includes(key)
  );

  const dialog_content = messages
    ? messages.map((msg, ind) => {
        return (
          <Typography key={ind} gutterBottom>
            {msg}
          </Typography>
        );
      })
    : null;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...picked}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {ttt(title)}
      </DialogTitle>
      <DialogContent>{content || dialog_content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {closeButtonCaption || ttt("general:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

component = withStyles(styles, { withTheme: true })(component);
export const SingleButtonDialog = component;
