import React from "react";
import { connect } from "react-redux";
import { Typography, withStyles } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { useTranslation } from "react-i18next";

import { FusePageCarded } from "@fuse";
import PFEThemeEnhance from "app/parami-layouts/PFEThemeEnhance";
import { HeaderTitle } from "app/parami-layouts";
import { nameList } from "app/utils/entities/plfacc";

import { FVStore } from "validations/rules";

import { LazyForm } from "app/parami-layouts/LazyForm";

import Debug from "debug";
import { getValidations } from "../../store/actions/parami/validations.actions";
const debug = Debug("pfe:playground");

const fallback_entity_1 = {
  fallbacks: {
    "1": { en: "English 1", "zh-TW": "中文 1" },
    "2": { en: "English 2", "zh-TW": "中文 2" },
    "3": { en: "English 3", "zh-TW": "中文 3" },
  },
};

const textareaXVMulti_entity_1 = {
  texts: {
    en: "It is a @@a.1@@!",
    "zh-TW": "今天是@@a.1@@!",
  },
};

const array_of_textMulti_entity_1 = {
  array_of_sth: [
    {
      en: "asd@gmail.com",
      "zh-TW": "asd@gmail.",
    },
    {
      en: "It is good today",
      "zh-TW": "今天很好",
    },
  ],
};

const array_of_sth_entity = {
  array_of_sth: ["asd@gmail.com", "It is good today"],
};

const Playground = (props) => {
  const { t: ttt } = useTranslation();
  const { classes, G } = props;
  return (
    <FusePageCarded
      header={
        <HeaderTitle
          key="PlaygroundHeader"
          text="Playground"
          icon="toys"
        ></HeaderTitle>
      }
      innerScroll
      contentScroll
      moreClasses={{
        content: "pb-96",
      }}
      content={
        <React.Fragment>
          <section className={classes.section}>
            <LazyForm
              id="form001"
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "array",
                name: "array_of_eq",
                props: {
                  type: "row",
                  className: "flex flex-1 items-start",
                  defaultValue: {
                    operator: "+",
                    content: { en: "default !!!" },
                  },
                  items: [
                    {
                      type: "select",
                      label: "Operator",
                      name: `operator`,
                      rowItemProps: {
                        className: "mt-8",
                      },
                      ...FVStore.flow_editor.string(ttt),
                      className: "w-128",
                      placeholder: "Placeholder",
                      InputLabelProps: {
                        shrink: true,
                      },
                      options: [
                        {
                          label: "+",
                          value: "+",
                        },
                        {
                          label: "-",
                          value: "-",
                        },
                      ],
                    },
                    {
                      type: "textMulti",
                      name: `content`,
                      ...FVStore.flow_editor.email(ttt),
                      className: "flex flex-1 items-center",
                      persistent: "multiTextFieldLang",
                      placeholder: "Placeholder",
                      multiline: true,
                      rowsMax: 4,
                      rowItemProps: {
                        className: "flex flex-1 items-center",
                      },
                      tabs: [
                        {
                          label: "English",
                          value: "en",
                        },
                        {
                          label: "Chinese",
                          value: "zh-TW",
                        },
                      ],
                    },
                  ],
                },
              }}
            />
          </section>
          <section className={classes.section}>
            <LazyForm
              id="form00"
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "row",
                items: ["1", "2", "3"].map((i) => ({
                  type: "textMulti",
                  name: `fallbacks.${i}`,
                  className: "flex-1",
                  persistent: "multiTextFieldLang",
                  placeholder: "Placeholder",
                  multiline: true,
                  rowsMax: 4,
                  tabs: [
                    {
                      label: "English",
                      value: "en",
                    },
                    {
                      label: "Chinese",
                      value: "zh-TW",
                    },
                  ],
                })),
              }}
            />
          </section>
          <section className={classes.section}>
            <p>
              Even if we edit the field in this section, after switching the
              tab, it will go back to original value, because the entity is
              hardcoded here.
            </p>
            <p>
              Why? <br />
              Because every time this block is rendered. the entity is actually
              rebuilt (different from the last rendered object). This will
              trigger the `useEffect` in the LazyForm, which will watch for
              changes in the `entity` field.
            </p>
            <LazyForm
              id="form3244"
              entity={{
                fallbacks: {
                  "1": { en: "English 1", "zh-TW": "中文 1" },
                  "2": { en: "English 2", "zh-TW": "中文 2" },
                  "3": { en: "English 3", "zh-TW": "中文 3" },
                },
              }}
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "row",
                items: ["1", "2", "3"].map((i) => ({
                  type: "textMulti",
                  name: `fallbacks.${i}`,
                  className: "flex-1",
                  persistent: "multiTextFieldLang",
                  placeholder: "Placeholder",
                  multiline: true,
                  rowsMax: 4,
                  tabs: [
                    {
                      label: "English",
                      value: "en",
                    },
                    {
                      label: "Chinese",
                      value: "zh-TW",
                    },
                  ],
                })),
              }}
            />
          </section>
          <section className={classes.section}>
            <p>
              This is when using a static variable for entity, and will behave
              normally
            </p>
            <LazyForm
              id="form"
              entity={fallback_entity_1}
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "row",
                items: ["1", "2", "3"].map((i) => ({
                  type: "textMulti",
                  name: `fallbacks.${i}`,
                  className: "flex-1",
                  persistent: "multiTextFieldLang",
                  placeholder: "Placeholder",
                  multiline: true,
                  rowsMax: 4,
                  tabs: [
                    {
                      label: "English",
                      value: "en",
                    },
                    {
                      label: "Chinese",
                      value: "zh-TW",
                    },
                  ],
                })),
              }}
            />
          </section>
          <section className={classes.section}>
            <LazyForm
              id="form2"
              entity={{
                some_tags: ["en", "zh-TW"],
              }}
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "tags",
                name: `some_tags`,
                className: "flex-1",
                placeholder: "Placeholder",
                items: [
                  {
                    label: "English",
                    value: "en",
                  },
                  {
                    label: "Chinese",
                    value: "zh-TW",
                  },
                ],
              }}
            />
          </section>
          <section className={classes.section}>
            <LazyForm
              id="form3"
              entity={textareaXVMulti_entity_1}
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "textareaXVMulti",
                debug: true,
                name: `texts`,
                className: "flex-1",
                placeholder: "Placeholder",
                tabs: [
                  {
                    label: "English",
                    value: "en",
                  },
                  {
                    label: "Chinese",
                    value: "zh-TW",
                  },
                ],
              }}
            />
          </section>
          <section className={classes.section}>
            <LazyForm
              id="form4"
              entity={array_of_textMulti_entity_1}
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "array",
                name: "array_of_sth",
                props: {
                  type: "textMulti",
                  defaultValue: {},
                  className: "flex-1",
                  ...FVStore.flow_editor.email(ttt, "every-value-"),
                  persistent: "multiTextFieldLang",
                  placeholder: "Placeholder",
                  multiline: true,
                  rowsMax: 4,
                  tabs: [
                    {
                      label: "English",
                      value: "en",
                    },
                    {
                      label: "Chinese",
                      value: "zh-TW",
                    },
                  ],
                },
              }}
            />
          </section>
          <section className={classes.section}>
            <LazyForm
              id="form5"
              entity={array_of_sth_entity}
              onValidSubmit={async (updates) => {
                console.log(">>", updates);
              }}
              onChange={(event) => {
                debug("LazyForm onChange", event);
              }}
              fields={{
                type: "array",
                name: "array_of_sth",
                ...FVStore.flow_editor.email(ttt),
                props: {
                  type: "text",
                  defaultValue: "default here",
                  className: "flex-1",
                  placeholder: "Placeholder",
                },
              }}
            />
          </section>
        </React.Fragment>
      }
    />
  );
};

const styles = {
  section: {
    marginTop: "2rem",
    padding: "1rem",
    border: "1px solid #ccc",
    borderRadius: "1rem",
  },
};

function mapStateToProps({ dashboard, company, globals }) {
  return {
    G: globals.variables,
  };
}

let PlaygroundComp = connect(mapStateToProps)(Playground);

PlaygroundComp = withStyles((theme) => styles, { withTheme: true })(
  PlaygroundComp
);
const PlaygroundExport = (props) => {
  return (
    <PFEThemeEnhance>
      <PlaygroundComp />
    </PFEThemeEnhance>
  );
};
export default PlaygroundExport;
