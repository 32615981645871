// PRMF-3
import i18n from "i18next";
import ld from "lodash";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import RecursiveIterator from "recursive-iterator";

import { i18nNamespaces } from "constants.js";
import { inAsset } from "app/utils/common";
import fnKeeper from "app/utils/fnKeeper";
import React from "react";
import ReactDOM from "react-dom";
import ReactMarkdown from "react-markdown";

const ns_list = [];
for (let { node: val } of new RecursiveIterator(i18nNamespaces)) {
  if (typeof val === "string") ns_list.push(val);
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .on("initialized", (options) => {
    ld.set(
      fnKeeper,
      "ttt.error",
      i18n.getFixedT(null, i18nNamespaces.error_message)
    );
  })
  .use({
    type: "postProcessor",
    name: "formatted-text",
    process: (value, key, options, translator) => {
      //if (value.match(/^ *html: */))
      //  return renderHTML(value.replace(/^ *html: */, ""));
      if (value.match(/^ *markdown: */))
        return <ReactMarkdown source={value.replace(/^ *markdown: */, "")} />;
      return value;
    },
  })
  .init({
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    fallbackLng: {
      "zh-TW": ["en"],
      default: ["en"],
    },
    postProcess: "formatted-text",
    load: "currentOnly",
    // debug: true,
    ns: ns_list,
    defaultNS: "general",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === "2dp" && typeof value === "number")
          return (Math.round(value * 100) / 100).toFixed(2);
        else if (format === "date") {
          const date_fn = ld.get(fnKeeper, "formatter.date");
          if (typeof value === "number") return date_fn(value);
          else return value;
        }
      },
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: "languageChanged",
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      // NOTES: useSuspense has to be false to work.
      useSuspense: false,
    },

    // XHR Options
    backend: {
      loadPath: inAsset("translation/{{lng}}/{{ns}}.json"),
    },
  });

export default i18n;
