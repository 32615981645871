import company from "./company";
import flow_editor from "./flow_editor";
import general from "./general";
import plfacc from "./plfacc";
import user from "./user";
import "validations";

const rules = {
  company,
  flow_editor,
  general,
  plfacc,
  user,
};

const FVN_handler = function(formsyRepr, ttt, prefix) {
  // We cannot mutate the formsyRepr,
  // so we clone it first.
  const ret = {};

  if (prefix !== undefined) {
    ret.validations = Object.keys(formsyRepr.validations).reduce((acc, k) => {
      acc[prefix + k] = formsyRepr.validations[k];
      return acc;
    }, {});
    ret.validationErrors = Object.keys(formsyRepr.validationErrors).reduce(
      (acc, k) => {
        acc[prefix + k] = ttt(formsyRepr.validationErrors[k]);
        return acc;
      },
      {}
    );

    return ret;
  } else {
    ret.validations = formsyRepr.validations;
    ret.validationErrors = Object.keys(formsyRepr.validationErrors).reduce(
      (acc, k) => {
        acc[k] = ttt(formsyRepr.validationErrors[k]);
        return acc;
      },
      {}
    );
  }
  return ret;
};

const FVNs = Object.keys(rules);

const FVStore = FVNs.reduce((acc, ns) => {
  acc[ns] = new Proxy(
    {},
    {
      get: function(target, name) {
        return target.hasOwnProperty(name)
          ? target[name]
          : () => {
              console.error("Unknown validation name is used: " + name);
            };
      },
    }
  );
  return acc;
}, {});

FVNs.forEach((ns, i) => {
  const validations = rules[ns];
  /**
     * 
     *  validations:
     
        {
          "node_name": {
            "minLength": [1, "minLength character"],
            "maxLength": [100, "maxLength character"]
          },
        } 

     * 
     */
  /**
     * 
     *  We want the data to be transformed to format below 
     *  which will be consumed by formsy
     * 
       
        {
          node_name: {
            validations: {
              minLength: 1,
              maxLength: 100,
            },
            validationErrors: {
              minLength: ttt("minLength character"),
              maxLength: ttt("maxLength character"),
            },
          }
        }

     * 
     */

  /**
   *
   *  Since ttt("minLength character") is computed in real time,
   *
   *  we make the value in FVStore a factory of formsy-validation-props,
   *  such that it can be computed everytime retrieved
   *
   */

  /**
   *
   *  Considering a change in i18n should trigger a change in the
   *  above i18n-enabled error message, we requre the factory to
   *  accept `ttt` as the arguments to enforce the use of i18n hook
   *  to the components that use this form validation.
   *
   */

  Object.keys(validations).forEach((key) => {
    // e.g.  key = "node_name"
    let formsyRepr = {
      validations: {},
      validationErrors: {},
    };
    /**
       * 
       *  Here in `formsyRepr`, we do no include i18n, which is handled later,
       *  and looks like below
       * 
         
          {
            node_name: {
              validations: {
                minLength: 1,
                maxLength: 100,
              },
              validationErrors: {
                minLength: "minLength character",
                maxLength: "maxLength character",
              },
            }
          }

       * 
       */
    Object.keys(validations[key]).forEach((formsyValidationKey) => {
      // e.g.  formsyValidationKey = "minLength"
      // e.g.  validationContent   = [1, "minLength character"]
      const validationContent = validations[key][formsyValidationKey];
      const args = validationContent[0];
      const i18nKey = validationContent[1];
      formsyRepr.validations[formsyValidationKey] = args;
      formsyRepr.validationErrors[formsyValidationKey] = i18nKey;
    });

    const bindedHandler = FVN_handler.bind(null, formsyRepr);

    FVStore[ns][key] = bindedHandler;
    const nsCamel = ns.replace(/-([a-z])/g, function(g) {
      return g[1].toUpperCase();
    });
    if (nsCamel != ns) FVStore[nsCamel][key] = bindedHandler;
  });
});

var FVStoreHandler = {
  get: function(target, name) {
    return target.hasOwnProperty(name)
      ? target[name]
      : () => {
          console.error("Unknown validation namespace is used: " + name);
        };
  },
};

const FVStore_ = new Proxy(FVStore, FVStoreHandler);

export { rules, FVStore_ as FVStore };
