import React, { useState, useEffect } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import ld from "@lodash";
// import Debug from 'debug';

// const debug = Debug('pfe:ui:NestedMenu');

export const NestedMenu = (props) => {
  const { children: pChildren, anchorEl: pAnchorEl, onClose: pOnClose } = props,
    importedProps = ld.pickBy(props, (val, key) => !["children"].includes(key)),
    [anchorElms, setAnchorElms] = useState([]),
    [itemPath, setItemPath] = useState([]);
  const onRootItemClick = (targets, path) => {
    setItemPath(path);
    setAnchorElms(targets);
  };

  const closeMenus = (ev, levels = 1) => {
    console.log("closeMenus", levels);
    const newArr =
      levels === -1 ? [] : anchorElms.slice(0, anchorElms.length - levels);
    setAnchorElms(newArr);
    if (newArr.length === 0) pOnClose(ev);
  };

  useEffect(() => {
    setAnchorElms(pAnchorEl ? [pAnchorEl] : []);
    setItemPath(pAnchorEl ? [0] : []);
  }, [props.anchorEl]);

  let subMenus = create_submenus(
    0,
    itemPath,
    anchorElms,
    pChildren,
    closeMenus,
    onRootItemClick,
    importedProps
  );

  return <React.Fragment>{subMenus}</React.Fragment>;
};

function create_submenus(
  level,
  pItemPath,
  pAnchorsArr,
  pChildren,
  pCloseMenus,
  onRootItemClick,
  pRootProps
) {
  // console.log('TTT1', level, pItemPath, anchorElms);
  if (pItemPath.length === 0 || level + 1 > pItemPath.length) return [];

  const isRootMenu = level === 0,
    rootProps = isRootMenu
      ? ld.pickBy(pRootProps, (val, key) => !["anchorEl", "open"].includes(key))
      : {},
    subItems = isRootMenu
      ? pChildren
      : ld.get(pChildren, `[${pItemPath[level]}].props.children`),
    currAnchor = pAnchorsArr ? pAnchorsArr[level] : null;
  // dummy = console.log('TTT2', level, pItemPath, subItems, currAnchor)
  // Return if not an array
  if (!subItems.map) return [];
  // debug('create_submenus', level, pChildren, subItems);

  // Spreading the element if it is an array
  let subItemsFlatten = subItems.reduce((acc, item, ind) => {
    if (item.map) return acc.concat(item);
    else {
      acc.push(item);
      return acc;
    }
  }, []);

  const currLevelItems =
    subItemsFlatten &&
    subItemsFlatten.map((item, ind) => {
      const currItemPath = [...pItemPath.slice(0, level + 1), ind];
      if (!item.props) return null;

      const {
          onClick: pItemOnClick,
          onClose: pItemOnClose,
          close_parent,
        } = item.props,
        [pv, has_submenus] = preview_item(item),
        close_on_click = ld.get(item, "props.close_on_click", !has_submenus);
      let lvlToClose = 1;
      if (!close_on_click) {
        lvlToClose = 0;
      } else if (close_parent === "direct") lvlToClose = 2;
      else if (close_parent === "all") lvlToClose = -1;
      // debug('JJJ-B', pv, has_submenus);

      const itemOnClick = (ev) => {
        if (pItemOnClick) pItemOnClick(ev);

        // Notes: Determine and if no submenus, handle as normal
        // close.
        if (has_submenus)
          onRootItemClick([...pAnchorsArr, ev.currentTarget], currItemPath);
        else if (close_on_click) closeItem(ev);
      };

      const closeItem = (ev) => {
        if (pItemOnClose) pItemOnClose(ev);
        pCloseMenus(ev, lvlToClose);
      };

      return (
        <MenuItem
          key={currItemPath.join(".")}
          id={currItemPath.join(".")}
          onClick={(ev) => {
            if (itemOnClick) itemOnClick(ev);
            if (close_on_click) closeItem(ev);
          }}
          onClose={closeItem}
        >
          {pv}
        </MenuItem>
      );
    });

  const currLevelID = `nested_menu_${pItemPath.slice(0, level).join("_")}`,
    currLevel = (
      <Menu
        {...rootProps}
        key={currLevelID}
        id={currLevelID}
        anchorEl={currAnchor}
        anchorOrigin={
          isRootMenu
            ? undefined
            : {
                vertical: "top",
                horizontal: "right",
              }
        }
        transformOrigin={
          isRootMenu
            ? undefined
            : {
                vertical: "top",
                horizontal: "left",
              }
        }
        open={Boolean(currAnchor)}
        // onClose={isRootMenu ? rootProps.onClose : pCloseMenus}
        onClose={pCloseMenus}
        PaperProps={{
          style: {
            maxHeight: 48 * 3.5,
            // width: 100,
          },
        }}
      >
        {currLevelItems}
      </Menu>
    );

  const nextLevel = create_submenus(
    level + 1,
    pItemPath,
    pAnchorsArr,
    subItemsFlatten,
    pCloseMenus,
    onRootItemClick
  );

  return [currLevel, nextLevel];
}

function preview_item(pItem) {
  let subItems = ld.get(pItem, `props.children`, []);
  if (subItems && !subItems.map) subItems = [subItems];
  else subItems = flattenItems(subItems);

  let leadingElms = [],
    isLeadingElmsEnd = false,
    has_submenus = false;
  subItems &&
    subItems.map((item, ind) => {
      const { isNestedMenuItem } = item.props || {};
      if (isNestedMenuItem) {
        isLeadingElmsEnd = true;
        has_submenus = true;
      } else if (!isLeadingElmsEnd) {
        leadingElms.push(item);
      }
      return null;
    });
  return [leadingElms, has_submenus];
}

function flattenItems(pItems) {
  return pItems.reduce((acc, item, ind) => {
    if (item.map) return acc.concat(item);
    else {
      acc.push(item);
      return acc;
    }
  }, []);
}

export const NestedMenuItem = (props) => {};

NestedMenuItem.defaultProps = {
  isNestedMenuItem: true,
};

// export default NestedMenu;
