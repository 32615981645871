import React from "react";
import axios from "axios";
import ld from "@lodash";
import { mapValues as ld_mapval } from "lodash";
import Debug from "debug";

import i18n from "i18n.js";
import { i18nNamespaces } from "constants.js";
import store from "app/store";
import { inAsset, asArray } from "app/utils/common";
import { getValidations } from "app/store/actions/parami";

const debug = Debug("pfe:services:data"),
  ttt = i18n.t.bind(i18n);
export async function getRegionList(locale) {
  try {
    const resp = await axios.get(
      inAsset(`/translation/${locale}/regions.json`)
    );
    // const resp = await axios.get(inAsset(`/regions/zh_hk.json`);
    return resp.data;
  } catch (err) {
    return {};
  }
}

export async function getFieldsValidations(pNamespaces) {
  const is_single = !ld.isArray(pNamespaces),
    namespaces = asArray(pNamespaces).filter((item) => item),
    fieldsValidators = {};
  if (ld.isEmpty(pNamespaces)) {
    debug(`getFieldsValidations - return current validators only.`);
    return is_single ? {} : [];
  }

  try {
    const reqs = namespaces.map((ns) =>
        axios.get(inAsset(`/validations/${ns}.json`))
      ),
      resps = await Promise.all(reqs),
      validations = resps.map((rp) => rp.data);
    const result = namespaces.map((ns, ind) => {
      const vTuples = validations[ind];
      debug(`getFieldsValidations - ${ns}`, vTuples);
      if (ld.isEmpty(vTuples)) return null;

      // Clean up all existing validators under the namespace when update.
      fieldsValidators[ns] = {};
      ld.mapValues(vTuples, (tuple, fieldName) => {
        fieldsValidators[ns][fieldName] = convertTuple(tuple);
      });
      return fieldsValidators[ns];
    });
    debug("getFieldsValidations", fieldsValidators);

    store.dispatch(getValidations(fieldsValidators));
    return is_single ? result[0] : result;
  } catch (err) {
    debug("getFieldsValidations - error", err.stack);
    return {};
  }
}

function convertTuple(tuple) {
  let validations = {},
    validationErrors = {},
    valErrorsTransKeys = {},
    maxLength = undefined;

  ld_mapval(tuple, (content, ruleName) => {
    if (ruleName === "matchRegexp") {
      const re_str = str2RegEx(content[0]);
      validations[ruleName] = new RegExp(re_str);
    } else {
      validations[ruleName] = content[0];
    }

    valErrorsTransKeys[ruleName] = content[1];

    // TODO: convert other args for translation interpolation
    // minLength: length
    // maxLength: length
    // https://github.com/formsy/formsy-react/blob/master/API.md
    let opts;
    if (["minLength", "maxLength"].includes(ruleName)) {
      opts = { length: content[0] };
    }

    validationErrors[ruleName] = ttt(
      `form-fields:validations.${content[1]}`,
      opts
    );

    // NOTES: "maxLength" property at return
    // ==============================================
    // 1. For PFEFields usage;
    // 2. Passing maxLength into the component as the property so that the
    // textfield can stop accepting characters (HTML property, not formsy);
    // 3. Other properties, like "minLength", are not included since they are
    // not useful for HTML displaying;

    if (ruleName === "maxLength") maxLength = content[0];
  });

  return { validations, validationErrors, valErrorsTransKeys, maxLength };
}

export function withPFEValidators(WrappedComponent, namespaces) {
  debug("withPFEValidators - wrapping");
  return (props) => {
    debug("withValidators - load validations");
    getFieldsValidations(namespaces);
    return <WrappedComponent {...props} />;
  };
}

export function str2RegEx(pStr, getAll = false) {
  const flags_found = pStr.match(/.*\/([gimsuy]*)$/) || [],
    flags = flags_found[1] || "",
    pattern = pStr.replace(new RegExp("^/(.*?)/" + flags + "$"), "$1");
  // debug(`toRE: flags=${flags}, pattern=${pattern}`);
  if (getAll)
    return {
      regex: new RegExp(pattern, flags),
      pattern,
      flags,
    };
  else return new RegExp(pattern, flags);
}
