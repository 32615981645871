import { withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { TextFieldFormsy } from "@fuse";
import classNames from "classnames";
import {
  omit as ld_omit,
  get as ld_get,
  pick as ld_pick,
  mapValues as ld_mapValues,
} from "lodash";
// import Debug from 'debug';

import i18n from "i18n.js";
import { i18nNamespaces } from "constants.js";

// const debug = Debug('pfe:layout:PFEFields');

const pfePropsList = [
  "pfeValidType",
  "inputProps",
  "validations",
  "validationErrors",
  "className",
];
const skipList = ["validator"].concat(pfePropsList);

const ttt = i18n.t.bind(i18n);

const styles = (theme) => ({
  label: {
    '& > [data-shrink="false"] ': {
      "pointer-events": "none",
    },
    '& > [data-shrink="false"] > * > * ': {
      opacity: 0,
    },
  },
});

function process_props(props, validator, list = pfePropsList) {
  const importedProps = ld_omit(props, skipList),
    pfeProps = ld_pick(props, list);
  // debug('importedProps', props);
  pfeProps.inputProps = {
    maxLength: ld_get(validator, `maxLength`, undefined),
    ...props.inputProps,
  };
  pfeProps.validations = {
    ...ld_get(validator, `validations`),
    ...props.validations,
  };

  const errMsgsMap = ld_get(validator, `valErrorsTransKeys`);
  pfeProps.validationErrors = {};

  // Notes: Adding translation to error message since they cannot be
  // added when UI is showing.
  ld_mapValues(errMsgsMap, (msg, ruleName) => {
    let opts;
    if (["minLength", "maxLength"].includes(ruleName))
      opts = { length: pfeProps.validations[ruleName] };
    pfeProps.validationErrors[ruleName] = ttt(
      "form-fields:validations." + msg,
      opts
    );
  });

  pfeProps.validationErrors = {
    ...pfeProps.validationErrors,
    ...props.validationErrors,
  };
  // debug('process_props - result', props.name, importedProps);

  return [pfeProps, importedProps];
}

const TextFieldPFE = (props) => {
  const { validator, label, classes } = props,
    [pfeProps, importedProps] = process_props(props, validator);
  const {
    className: pClassName,
    inputProps,
    validations,
    validationErrors,
  } = pfeProps;

  return (
    <TextFieldFormsy
      className={classNames([
        !React.isValidElement(label) && classes.noPointer,
        classes.label,
        pClassName,
      ])}
      variant="outlined"
      {...importedProps}
      inputProps={inputProps}
      validations={validations}
      validationErrors={validationErrors}
    />
  );
};

function mapStateToProps({ parami }, { pfeValidType }) {
  return {
    validator: ld_get(parami, `validations.${pfeValidType}`),
  };
}
let myComp = connect(mapStateToProps)(TextFieldPFE);
myComp = withStyles(styles, { withTheme: true })(myComp);

export { myComp as TextFieldPFE };
