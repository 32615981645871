import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  Button,
  Typography,
  FormControlLabel,
  Icon,
  Radio,
  Tooltip,
} from "@material-ui/core";
import { RadioGroupFormsy } from "@fuse";
import { useTranslation } from "react-i18next";
// import classNames from 'classnames';
import Formsy from "formsy-react";
import ld from "@lodash";
import Debug from "debug";

import { i18nNamespaces } from "constants.js";
import {
  is_code_available,
  suggest_company_code,
} from "app/services/paramiService";
import { TextFieldPFE } from "app/parami-layouts/PFEFields";

const debug = Debug("pfe:company:ui:CreateDialog");

const styles = (theme) => {
  const left = 50,
    top = 50;
  return {
    modalBox: {
      position: "absolute",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      "max-width": "40rem",
      width: "80%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: "3rem 2rem 1rem 2rem",
      border: "none",
      "border-radius": "1.5rem 1.5rem",
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
      "z-index": 1,
    },
    [theme.breakpoints.up("sm")]: {
      // [`@media (min-width: ${screens.sm})`]: {
      modalTitle: {
        "font-size": "1.8rem",
      },
      modalBox: {
        width: "50%",
        extend: "MuiTypography-h4",
      },
    },
  };
};

const CreateDialog = (props) => {
  const { company = {}, user, closeDialog, submitCreateCompany } = props,
    { t: ttt } = useTranslation(),
    [open, setOpen] = useState(true),
    [firstSuggest, setfirstSuggest] = useState(true),
    [companyCode, setCompanyCode] = useState(company.company_code || ""),
    [canSub, setSubmit] = useState(false),
    // user = store.getState().auth.user,
    variant = "standard";
  if (!company.email_address) company.email_address = user.data.email;
  if (!company.fullname) company.fullname = user.username;
  if (company.isPersonal === undefined) company.isPersonal = "true";

  const disableButton = () => {
    setSubmit(false);
  };

  const enableButton = () => {
    setSubmit(true);
  };

  const toClose = () => {
    setOpen(false);
    closeDialog();
  };

  const suggestCode = ld.debounce(async (val) => {
    // NOTES: Receive the value instead of event since event content may be
    // changed after the wait for debounce.
    try {
      debug(`suggestCode - input`, val);
      const suggested_code = await suggest_company_code(val);
      setCompanyCode(suggested_code);
    } catch (err) {
      debug(`suggestCode - err`, err.stack);
    }
  }, 200);

  if (user.username && !companyCode && firstSuggest) {
    suggestCode(user.username);
    setfirstSuggest(false);
  }

  const beforeSubmit = async (values, reset, invalidate) => {
    debug("beforeSubmit", values);
    try {
      const avail = await is_code_available(values.company_code);
      if (avail) {
        submitCreateCompany(values);
      } else invalidate({ company_code: "this code is already taken." });
    } catch (err) {
      closeDialog(err, values);
    }
  };

  return (
    // <div className={classNames(classes.modalBox)} >
    <Dialog
      id="create-dialog"
      open={open}
      onClose={toClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="customized-dialog-title">
        {ttt("create-company:create_owner.title")}
      </DialogTitle>
      <DialogContent>
        <Formsy
          id="create-company-form"
          onSubmit={beforeSubmit}
          // onValidSubmit={submitCreateCompany}
          onValid={enableButton}
          onInvalid={disableButton}
          // ref={(form) => form = form}
          className="flex flex-col justify-center"
          style={{
            width: "450px",
          }}
        >
          <TextFieldPFE
            required
            className="mb-16"
            type="text"
            name="fullname"
            value={company.fullname}
            label={ttt("form-fields:business.fullname")}
            pfeValidType="company.fullname"
            onChange={(ev) => suggestCode(ev.target.value)}
            variant={variant}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextFieldPFE
            required
            className="mb-16"
            type="text"
            name="company_code"
            value={companyCode}
            InputLabelProps={{
              shrink: true,
            }}
            label={
              <span className="flex items-center whitespace-no-wrap">
                {ttt("form-fields:business.company_code")}

                <Tooltip
                  className="ml-4"
                  title={
                    ttt("form-fields:business.company_code_help") +
                    " (e.g. user01@" +
                    companyCode.toLowerCase() +
                    ")"
                  }
                  placement="right"
                >
                  <Icon>info</Icon>
                </Tooltip>
                <span className="ml-8 text-purple-light">
                  ({ttt("form-fields:business.company_code_warn")})
                </span>
              </span>
            }
            pfeValidType="company.company_code"
            variant={variant}
          />

          <TextFieldPFE
            required
            className="mb-16"
            type="text"
            name="email_address"
            value={company.email_address}
            label={ttt("form-fields:business.email_address")}
            pfeValidType="general.email_address"
            variant={variant}
          />

          <RadioGroupFormsy
            className="my-8 hidden"
            name="isPersonal"
            defaultValue={"true"}
            value={company.isPersonal}
            label={ttt("form-fields:business.isPersonal")}
            required
          >
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              className="mb-4"
              label={ttt("form-fields:business.personal")}
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label={ttt("form-fields:business.business")}
            />
            {/* <FormControlLabel
                            value="disabled"
                            disabled
                            control={<Radio/>}
                            label="(Disabled option)"
                        /> */}
          </RadioGroupFormsy>
        </Formsy>
      </DialogContent>
      <DialogActions>
        <Button aria-label="CLOSE" color="primary" onClick={toClose}>
          {ttt("general:button.cancel")}
        </Button>
        <Button
          type="submit"
          form="create-company-form"
          // variant="contained"
          aria-label="LOG IN"
          disabled={!canSub}
          color="primary"
        >
          {ttt("general:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

let myComp = withStyles(styles, { withTheme: true })(CreateDialog);
export default myComp;
